import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import "apexcharts/dist/apexcharts.css";
import useChart from "../scenes/dashboard/UseChart";
import Chart from "../scenes/dashboard/Chart";
export default function UserOverviewCardChart({
  title,
  subheader,
  chart,
  queryParameters,
  setQueryParameters,
  height,
  width,
  ...other
}) {
  const { colors, series, options } = chart;

  const chartOptions = useChart({
    colors,
    plotOptions: {
      bar: {
        columnWidth: "16%",
      },
      line: {
        curve: "straight",
      },
    },
    fill: {
      type: Array.isArray(series) ? series.map((i) => i.fill) : [],
    },
    labels: {
      show: false,
    },
    axisX: {
      position: "start",
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
      position: "start",
      type: "category",
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    series: {
      line: {
        curve: "straight",
      },
    },
    stroke: {
      curve: "straight",
      width: 1.5,
      dashArray: [1, 10],
    },
    chart: {
      toolbar: {},
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value) => {
          if (typeof value !== "undefined") {
            return `${value.toFixed(0)}`;
          }
          return value;
        },
      },
    },
    ...options,
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <Box sx={{}}>
        <Chart
          dir="ltr"
          series={series}
          options={chartOptions}
          width={width}
          height={height}
        />
      </Box>
    </Card>
  );
}

UserOverviewCardChart.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string,
};
