import React from "react";
import { Snackbar, Alert } from "@mui/material";
import { useSelector } from "react-redux";

const ReusableSnackbar = ({ handleClose, autoHideDuration = 2000 }) => {
  const { message, severity, variant, openSnack } = useSelector(
    (state) => state.errorMessage
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={openSnack}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        variant={variant}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ReusableSnackbar;
