import React, { useEffect } from "react";
import {
  fetchAllTowns,
  deleteCity,
  deleteTown,
  useCities,
} from "../../services/apis/locations";
import { useState } from "react";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { withStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import AddCityForm from "./AddCityForm";
import AddTownForm from "./AddTownForm";
import { DataGridStyle } from "../../styles";
import { TabContext, TabPanel } from "@mui/lab";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import ActionAlert from "../user-profile/ActionAlert";
import { handlePageChange } from "../../services/utils/filters";
import CustomDialog from "../../components/CustomDialog";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { FirstBox, SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
const CitiesAndTowns = () => {
  const [disableWhileDelete, setDisableWhileDelete] = useState(false);

  const [towns, setTowns] = useState([]);
  const [openCityForm, setOpenCityForm] = useState(false);
  const [totalTowns, setTotalTowns] = useState(10);
  const [openTownForm, setOpenTownForm] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [target, setTarget] = useState(null);
  const [value, setValue] = useState("1");
  const [openDeletedAlert, setOpenDeletedAlert] = useState(false);
  const [openDeletedTownAlert, setOpenDeletedTownAlert] = useState(false);
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [selectedTownId, setSelectedTownId] = useState(null);
  const [successCityDialogOpen, setCitySuccessDialogOpen] = useState(false);
  const [successTownDialogOpen, setTownSuccessDialogOpen] = useState(false);
  const [townLoading, setTownLoading] = useState(true);
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
  });
  const { data: cities = [], isFetching } = useCities(queryParameters.page);
  const [totalTownPages, setTotalTownPages] = useState(0);

  const AntTabs = withStyles({
    root: {
      borderBottom: "none",
    },
    indicator: {
      display: "none",
    },
  })(Tabs);
  const AntTab = withStyles((theme) => ({
    root: {
      "&:hover": {
        color: "#00303C",
        opacity: 1,
      },
      "&$selected": {
        color: "#ffffff",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        borderBottom: "none",
      },
      "&:focus": {
        color: "#00303C",
      },
    },
    selected: {
      "&$selected": {
        borderBottom: "none",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        color: "#ffffff !important",
      },
    },
  }))((props) => <Tab disableRipple {...props} />);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "1") {
      setQueryParameters({
        ...queryParameters,
        page: 0,
      });
    } else if (newValue === "2") {
      setQueryParameters({
        ...queryParameters,
        page: 0,
      });
    }
  };
  const handleOpenCityForm = () => {
    setIsEditMode(false);
    setOpenCityForm(true);
  };

  const handleCloseCityForm = () => {
    setOpenCityForm(false);
  };

  const handleOpenTownForm = () => {
    setIsEditMode(false);
    setOpenTownForm(true);
  };

  const handleCloseTownForm = () => {
    setOpenTownForm(false);
  };

  const handleEditMode = (type, target) => {
    setIsEditMode(true);
    setTarget(target);
    if (type === "city") setOpenCityForm(true);
    if (type === "town") setOpenTownForm(true);
  };
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };

  const getAllTowns = async () => {
    setTowns([]);
    setTownLoading(true);
    try {
      const response = await fetchAllTowns(queryParameters);
      setTowns(response.data.data.result);
      setTotalTowns(response.data.data.totalItems);
      setTotalTownPages(response.data.data.totalPages);
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
      setTownLoading(false);
    }
  };

  const handleOpenDeleteAlert = (cityId) => {
    setSelectedCityId(cityId);
    setOpenDeletedAlert(true);
  };
  const handleOpenDeleteTownAlert = (townId) => {
    setSelectedTownId(townId);
    setOpenDeletedTownAlert(true);
  };
  const handleDeleteCity = async () => {
    setDisableWhileDelete(true);
    if (selectedCityId) {
      try {
        await deleteCity(selectedCityId);
        setDisableWhileDelete(false);
        setCitySuccessDialogOpen(true);
        handleCloseDeleteAlert();
        // getAllCities();
      } catch (error) {
        dispatch(
          setData({
            openSnack: true,
            message: error?.data?.data?.message,
          })
        );
      }
    }
  };

  const handleDeleteTown = async () => {
    if (selectedTownId) {
      setDisableWhileDelete(true);
      try {
        await deleteTown(selectedTownId);
        setDisableWhileDelete(false);
        setTownSuccessDialogOpen(true);
        handleCloseDeleteTownAlert();
        getAllTowns();
      } catch (error) {
        dispatch(
          setData({
            openSnack: true,
            message: error?.data?.data?.message,
          })
        );
      }
    }
  };

  const handleCloseDeleteAlert = () => {
    setOpenDeletedAlert(false);
  };
  const handleCloseDeleteTownAlert = () => {
    setOpenDeletedTownAlert(false);
  };
  const citiesColumns = ItemsColumns([
    { field: "id" },
    { field: "name_en", headerName: "Name" },
    { field: "name_ar", headerName: "Name(Arabic)" },
    {
      field: "action",
      actions: {
        edit: (params) => {
          handleEditMode("city", params.row);
        },
        delete: (params) => {
          handleOpenDeleteAlert(params.row.id);
        },
      },
    },
  ]);

  const townsColumns = ItemsColumns([
    { field: "id" },
    { field: "name_en", headerName: "Name" },
    { field: "delivery", headerName: "Delivery Fees" },
    {
      field: "city",
      specialRender: "customRender",
      renderFunction: (params) => params?.value?.name_en,
    },
    {
      field: "action",
      actions: {
        edit: (params) => {
          handleEditMode("town", params.row);
        },
        delete: (params) => {
          handleOpenDeleteTownAlert(params.row.id);
        },
      },
    },
  ]);

  useEffect(() => {
    // getAllCities();
    getAllTowns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParameters]);
  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Delivery Locations" marginL={"25px"} />
      <SecondBox>
        <FirstBox m="40px 0 0 0" height="100%">
          <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
            <TabContext value={value}>
              <Box
                sx={{
                  width: "100%",
                  paddingTop: "5%",
                  paddingBottom: "1%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <AntTabs
                  sx={{
                    backgroundColor: "#F5F6FF",
                    borderRadius: "9px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  value={value}
                  onChange={handleChange}
                  aria-label="ant example"
                >
                  <AntTab
                    sx={{ width: "512px", display: "flex" }}
                    label="Cities"
                    value="1"
                  />
                  <AntTab sx={{ width: "512px" }} label="Areas" value="2" />
                </AntTabs>
              </Box>
              <TabPanel sx={{ marginLeft: "4%" }} value="1">
                <Box
                  sx={{
                    height: "auto",
                    width: "100%",
                    marginTop: "3%",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      backgroundColor: "#fff",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{
                        border: "2px solid #E9C237",
                        borderRadius: "9px",
                        background: "#ffffff",
                        "&:hover": {
                          background: "#ffffff",
                        },
                        boxShadow: "none",
                        width: "171px",
                        height: "48px",
                        color: "#E9C237",
                        fontSize: "16px",
                        textTransform: "none",
                        display: "flex",
                        alignSelf: "flex-end",
                        alignItems: "flex-end",
                        marginBottom: "20px",
                      }}
                      onClick={handleOpenCityForm}
                    >
                      + New City
                    </Button>
                  </Box>
                  <DataGrid
                    rows={isFetching ? [] : cities?.data?.result}
                    columns={citiesColumns}
                    pageSize={10}
                    loading={isFetching}
                    rowsPerPageOptions={[10]}
                    rowCount={cities?.data?.totalItems || 0}
                    paginationMode="server"
                    sx={{
                      height: "630px",
                      backgroundColor: "#fff",
                    }}
                    onPageChange={(p) =>
                      handlePageChange(p, queryParameters, setQueryParameters)
                    }
                    totalPages={cities?.data?.totalPages}
                    localeText={{
                      noRowsLabel: "No cities Available",
                    }}
                  />
                </Box>
              </TabPanel>
              <TabPanel sx={{ marginLeft: "4%" }} value="2">
                <Box
                  sx={{
                    height: "auto",
                    width: "100%",
                    marginTop: "3%",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{
                        border: "2px solid #E9C237",
                        borderRadius: "9px",
                        background: "#ffffff",
                        "&:hover": {
                          background: "#ffffff",
                        },
                        boxShadow: "none",
                        width: "171px",
                        height: "48px",
                        color: "#E9C237",
                        fontSize: "16px",
                        textTransform: "none",
                        display: "flex",
                        alignSelf: "flex-end",
                        alignItems: "flex-end",
                        marginBottom: "20px",
                      }}
                      onClick={handleOpenTownForm}
                    >
                      + New Area
                    </Button>
                  </Box>
                  <DataGrid
                    rowsPerPageOptions={[10]}
                    rows={towns}
                    columns={townsColumns}
                    pageSize={10}
                    loading={townLoading}
                    rowCount={totalTowns || 0}
                    paginationMode="server"
                    sx={{
                      backgroundColor: "#fff",
                      height: "630px",
                    }}
                    onPageChange={(p) =>
                      handlePageChange(p, queryParameters, setQueryParameters)
                    }
                    totalTownPages={totalTownPages}
                    localeText={{
                      noRowsLabel: "No Areas Available",
                    }}
                  />
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </FirstBox>
      </SecondBox>
      <AddCityForm
        // getAllCities={getAllCities}
        // setCities={setCities}
        open={openCityForm}
        handleClose={handleCloseCityForm}
        isEditMode={isEditMode}
        data={target}
      />

      <AddTownForm
        getAllTowns={getAllTowns}
        handleClose={handleCloseTownForm}
        open={openTownForm}
        cities={cities}
        isEditMode={isEditMode}
        data={target}
      />
      <ActionAlert
        openAlert={openDeletedAlert}
        handleCloseAlert={handleCloseDeleteAlert}
        handleReject={handleDeleteCity}
        message={`Are you sure you want to Delete this city ?`}
        label={"Delete"}
        disabled={disableWhileDelete}
      />
      <ActionAlert
        openAlert={openDeletedTownAlert}
        handleCloseAlert={handleCloseDeleteTownAlert}
        handleReject={handleDeleteTown}
        message={`Are you sure you want to Delete this Area ?`}
        label={"Delete"}
        disabled={disableWhileDelete}
      />
      <CustomDialog
        open={successCityDialogOpen}
        onClose={() => setCitySuccessDialogOpen(false)}
        title="city has been deleted Successfully."
        message=""
        buttonText="Go to Cities"
      />
      <CustomDialog
        open={successTownDialogOpen}
        onClose={() => setTownSuccessDialogOpen(false)}
        title="Area has been deleted Successfully."
        message=""
        buttonText="Go to Areas"
      />
      <ReusableSnackbar handleClose={handleCloseSnack} />
    </Box>
  );
};

export default CitiesAndTowns;
