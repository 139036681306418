import { axiosInstance as axios } from "../config";

export const fetchFaqs = async () => {
  try {
    const response = await axios.get(`/api/faq/index`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addQuestion = async (body) => {
  try {
    const response = await axios.post(`/api/faq/add`,body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editQuestion = async (id, body) => {
  try {
    const response = await axios.post(`/api/faq/edit/${id}`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteQuestion = async (id) => {
  try {
    const response = await axios.get(`/api/faq/delete/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
