import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { imageBaseUrl } from "../../services/config";
import {
  handlePageChange,
  handleSort,
  checkString,
} from "../../services/utils/filters";
import useTransactions, {
  useUserTransactions,
  changeTransactionStatus,
} from "../../services/apis/transactions";
import FilterDrawer from "../../components/common/FilterDrawer";
import { handleFilterByNumber } from "../../services/utils/filters";
import TransactionsPopup from "./TransactionsPopup";
import { DataGridStyle } from "../../styles";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
const Transactions = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [invoiceImage, setInvoiceImage] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const [amount, setAmount] = useState("");
  const [reference_number, setReferenceNumber] = useState("");
  const { id } = useParams();
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [queryParameters, setQueryParameters] = useState({
    date_from: null,
    date_to: null,
    orderBy: null,
    sort: null,
    page: 0,
    type: [1, 2, 5],
    status: "",
    id: null,
    phone: null,
    email: null,
  });
  const {
    data: transactionData,
    isFetching: transactionsLoading,
    refetch: refetchTransactions,
  } = useTransactions(queryParameters);
  const {
    data: userTransactionData,
    isFetching: userTransactionLoading,
    refetch: refetchUserTransactions,
  } = useUserTransactions(id, queryParameters);
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const transactionsColumns = ItemsColumns([
    { field: "id", headerName: "Transaction ID", flex: 2 },
    { field: "user_id", headerName: "User ID" },
    {
      field: "amount",
      headerName: "Transaction amount",
      flex: 2,
    },
    {
      field: "reference_number",
      headerName: "Reference Number",
      flex: 2,
    },
    {
      field: "created",
      headerName: "Created At",
      flex: 2,
      date: true,
    },
    { field: "type" },
    {
      field: "paymentMethod",
      headerName: "Payment Method",
      flex: 2,
      specialRender: "paymentMethods",
    },
    { field: "status_id", headerName: "Status", flex: 2, kyc: true },
    {
      field: "actions",
      specialRender: "customView",
      actions: {
        view: (params) => {
          handleOpenDialog(params);
        },
      },
    },
  ]);

  const handleOpenDialog = (params) => {
    const transaction = params.row;
    setTransaction(transaction);
    const { invoice } = transaction;
    setInvoiceImage(`${imageBaseUrl}/${invoice}`);
    if (
      transaction?.type === "Withdraw" ||
      (transaction?.type === "Deposit" &&
        transaction?.paymentMethod !== "paymob")
    ) {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    clearFields();
  };
  const clearFields = () => {
    setAmount("");
    setReferenceNumber("");
  };

  const handleAccept = async () => {
    try {
      setTransactionStatus("approved");
      if (!amount || !reference_number) {
        dispatch(
          setData({
            openSnack: true,
            message: "Please fill in all required fields",
          })
        );
      } else if (isNaN(amount)) {
        dispatch(
          setData({
            openSnack: true,
            message: "Amount must be a number",
          })
        );
      } else {
        setIsSubmitting(true);
        const payload = {
          id: transaction.id,
          status: 2,
          amount: +amount,
          reference_number: reference_number,
        };
        const response = await changeTransactionStatus(payload);
        if (response.status === 200) {
          transaction.status = "Accept";
          if (id) refetchUserTransactions();
          else refetchTransactions();
        }
        clearFields();
        setSuccessDialogOpen(true);
        handleCloseDialog();
      }
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReject = async () => {
    try {
      setIsSubmitting(true);
      setTransactionStatus("rejected");
      const payload = {
        id: transaction.id,
        status: 3,
      };
      const response = await changeTransactionStatus(payload);
      if (response.status === 200) {
        transaction.status = "Reject";
        if (id) refetchUserTransactions();
        else refetchTransactions();
      }
      clearFields();
      setSuccessDialogOpen(true);
      handleCloseDialog();
    } catch (error) {
      handleCloseDialog();
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSearchInput = (e) => {
    const { value } = e.target;
    checkString(value, queryParameters, setQueryParameters);
  };

  const handleTypeFilterCheckbox = (e) => {
    handleFilterByNumber(
      e.target.value,
      "type",
      queryParameters,
      setQueryParameters
    );
  };

  const handlePaymentFilterCheckbox = (e) => {
    handleFilterByNumber(
      e.target.value,
      "status",
      queryParameters,
      setQueryParameters
    );
  };

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <DashboardMainHeader />
      <Header title="Money Transactions" marginL={"20px"} />
      <SecondBox>
        <Box m="40px 0 0 0" height="100%" sx={DataGridStyle}>
          <TransactionsPopup
            openDialog={openDialog}
            handleAccept={handleAccept}
            handleCloseDialog={handleCloseDialog}
            handleReject={handleReject}
            invoiceImage={invoiceImage}
            transaction={transaction}
            amount={amount}
            setAmount={setAmount}
            reference_number={reference_number}
            isSubmitting={isSubmitting}
            setReferenceNumber={setReferenceNumber}
          />
          <Box sx={{ display: "flex", width: "50%", gap: 2, mb: 2, p: 2 }}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "600px",
                height: "48px",
                backgroundColor: "#F5F5F5",
                boxShadow: "none",
                borderRadius: "12px",
              }}
              onSubmit={(event) => {
                event.preventDefault();
              }}
            >
              <InputBase
                onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search National Id ,Phone number or Email ....."
              />
              <FilterDrawer
                firstList={[
                  { id: 1, name: "Deposit" },
                  { id: 2, name: "Withdraw" },
                  { id: 5, name: "Refund" },
                ]}
                secondList={[
                  { id: 1, name: "Pending" },
                  { id: 2, name: "Accept" },
                  { id: 3, name: "Rejected" },
                ]}
                firstTitle="Transaction Type"
                secondTitle="Payment Status"
                queryParameters={queryParameters}
                setQueryParameters={setQueryParameters}
                firstKey="type"
                secondKey="status"
                handleFirstCheckBox={handleTypeFilterCheckbox}
                handleSecondCheckBox={handlePaymentFilterCheckbox}
              />
            </Paper>
          </Box>
          <DataGrid
            rows={
              id
                ? userTransactionLoading
                  ? userTransactionData?.data ?? [] // Keep previous data if loading
                  : userTransactionData?.data
                : transactionsLoading
                ? transactionData?.data ?? [] // Keep previous data if loading
                : transactionData?.data
            }
            columns={transactionsColumns}
            sortingMode="server"
            pageSize={10}
            rowCount={
              id
                ? userTransactionLoading
                  ? userTransactionData?.totalItems ?? 0 // Keep previous count if loading
                  : userTransactionData?.totalItems
                : transactionsLoading
                ? transactionData?.totalItems ?? 0 // Keep previous count if loading
                : transactionData?.totalItems
            }
            loading={id ? userTransactionLoading : transactionsLoading}
            paginationMode="server"
            onPageChange={(p) =>
              handlePageChange(p, queryParameters, setQueryParameters)
            }
            rowsPerPageOptions={[10]}
            onSortModelChange={(params) =>
              handleSort(params, queryParameters, setQueryParameters)
            }
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
              height: "630px",
            }}
          />
        </Box>
      </SecondBox>
      <Dialog
        open={successDialogOpen}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: {
            width: "585px",
            height: "506px",
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#D9F4DD",
              height: "80px",
              width: "80px",
              alignSelf: "center",
              marginRight: "15px",
            }}
          >
            <img src={`../../assets/Vector.png`} alt="vector" />
          </Avatar>
          <Typography
            sx={{ marginTop: "10%", marginBottom: "10%" }}
            variant="h6"
          >
            {transactionStatus === "approved"
              ? "Transaction has been approved successfully."
              : transactionStatus === "rejected"
              ? "Transaction has been rejected successfully."
              : "Transaction status"}
          </Typography>
          <Button
            onClick={() => {
              setSuccessDialogOpen(false);
            }}
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#E9C237",
              "&:hover": { backgroundColor: "#E9C237" },
              height: "46px",
              width: "291px",
              borderRadius: "9px",
            }}
          >
            Money Transactions
          </Button>
        </DialogContent>
      </Dialog>
      <ReusableSnackbar handleClose={handleCloseSnack} />
    </Box>
  );
};

export default Transactions;
