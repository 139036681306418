import { Box, Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  deleteRequestsSecondTab,
  fetchRequestsSecondTab,
  resendRequestsSecondTab,
} from "../../../services/apis/roles-and-permissions";
import { useEffect } from "react";
import { useState } from "react";
import { DataGridStyle } from "../../../styles";
import { handlePageChange } from "../../../services/utils/filters";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import CustomDialog from "../../../components/CustomDialog";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import { ItemsColumns } from "../../../components/common/Columns";

const SecondAdminTabPanel = () => {
  const [deleting, setDeleting] = useState(false);
  const [requests, setRequests] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pagesCount, setPagesCount] = useState(10);
  const [loading, setLoading] = useState(true);
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
  });
  const dispatch = useDispatch();
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const getRequests = async () => {
    setLoading(true);
    setRequests([]);
    try {
      const response = await fetchRequestsSecondTab(queryParameters);
      const request = response?.data?.data?.result;
      setRequests(request);
      const requestscount = response.data.data.totalItems;
      const requestsPagecount = response.data.data.totalPages;
      setTotalPages(requestsPagecount);
      setPagesCount(requestscount);
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  const handleResendRequest = async (id) => {
    try {
      await resendRequestsSecondTab(id);
      getRequests();
      setSuccessDialogOpen(true);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
  };
  const handleDeleteRequest = async (id) => {
    try {
      const response = await deleteRequestsSecondTab(id);
      setDeleting(false);
      console.log("response", response);
      getRequests();
      setDeleteDialogOpen(true);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
  };
  useEffect(() => {
    getRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParameters]);
  const requestsColumns = ItemsColumns([
    { field: "email" },
    { field: "role" },
    {
      field: "Status",
      specialRender: "customRender",
      renderFunction: (params) =>
        params?.row?.is_registered === 0 ? (
          <Typography
            sx={{ color: "#ECB800", fontSize: "14px", fontWeight: "400" }}
          >
            Pending
          </Typography>
        ) : (
          <Typography
            sx={{ color: "#40C754", fontSize: "14px", fontWeight: "400" }}
          >
            Success
          </Typography>
        ),
    },
    {
      field: "Actions",
      specialRender: "customRender",
      renderFunction: (params) =>
        params?.row?.is_registered === 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <Button
              disabled={deleting}
              onClick={() => {
                setDeleting(true);
                handleDeleteRequest(params.row.id);
              }}
              sx={{
                color: "#F23045",
                fontSize: "14px",
                fontWeight: "400",
                backgroundColor: "rgba(242, 48, 69, 0.05)",
                padding: "8px 24px",
                borderRadius: "100px",
                cursor: "pointer",
                textTransform: "none",
              }}
            >
              Delete
            </Button>
            <Typography
              onClick={() => handleResendRequest(params.row.id)}
              sx={{
                color: "#E9C237",
                fontSize: "14px",
                fontWeight: "400",
                backgroundColor: "rgba(122, 128, 240, 0.05)",
                padding: "8px 24px",
                borderRadius: "100px",
                cursor: "pointer",
              }}
            >
              Resend
            </Typography>
          </Box>
        ) : (
          " "
        ),
    },
  ]);

  return (
    <Box>
      <Box
        sx={{
          height: "auto",
          background: "#FFF",
          borderRadius: "12px",
        }}
      >
        <Box m="40px 0 0 0" height="100%" sx={DataGridStyle}>
          <DataGrid
            rows={requests}
            columns={requestsColumns}
            pageSize={10}
            rowCount={pagesCount}
            loading={loading}
            paginationMode="server"
            onPageChange={(p) =>
              handlePageChange(p, queryParameters, setQueryParameters)
            }
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
              height: "630px",
            }}
            rowsPerPageOptions={[10]}
            totalPages={totalPages}
            disableSelectionOnClick
          />
        </Box>
      </Box>
      <ReusableSnackbar handleClose={handleCloseSnack} />
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Email has been Resend Successfully."
        message=""
        buttonText="Go to Admins"
      />
      <CustomDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        title="Email has been Deleted Successfully."
        message=""
        buttonText="Go to Admins"
      />
    </Box>
  );
};
export default SecondAdminTabPanel;
