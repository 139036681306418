import React from "react";
import {
  Button,
  Box,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  MenuItem,
  Select,
  OutlinedInput,
  FormControl,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useState } from "react";
import BlockFilter from "./BlockFilter";
import { useBlockReasons } from "../../services/apis/blockReasons";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";

const BlockUserDialog = ({
  openAlert,
  handleCloseAlert,
  handleReject,
  label,
  message,
  userGroup,
}) => {
  const [blockType, setBlockType] = useState("1");
  const handleBlockTypeChange = (event) => {
    setBlockType(event.target.value);
  };
  const [queryParameters, setQueryParameters] = useState({
    date_from: null,
    date_to: null,
  });
  const [selectedReason, setSelectedReason] = useState("");
  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
  };
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const { data } = useBlockReasons({ page: 0, limit: 100 });
  return (
    <>
      <Dialog
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "560px",
            height: userGroup !== "blockedUser" ? "auto" : "431px",
            paddingBottom: "15%",
            paddingTop: "5%",
            borderRadius: "24px",
            background: "#FFF",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={handleCloseAlert}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box
              sx={{
                width: "90%",
                //   height: "60%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Box>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  fill="none"
                >
                  <path
                    d="M31.9987 16.6669C33.1033 16.6669 33.9987 17.5624 33.9987 18.6669V34.6669C33.9987 35.7715 33.1033 36.6669 31.9987 36.6669C30.8941 36.6669 29.9987 35.7715 29.9987 34.6669V18.6669C29.9987 17.5624 30.8941 16.6669 31.9987 16.6669Z"
                    fill="#E9C237"
                  />
                  <path
                    d="M31.9987 45.3336C33.4715 45.3336 34.6654 44.1397 34.6654 42.6669C34.6654 41.1942 33.4715 40.0003 31.9987 40.0003C30.5259 40.0003 29.332 41.1942 29.332 42.6669C29.332 44.1397 30.5259 45.3336 31.9987 45.3336Z"
                    fill="#E9C237"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.33203 32.0003C3.33203 16.1681 16.1665 3.33359 31.9987 3.33359C47.8309 3.33359 60.6654 16.1681 60.6654 32.0003C60.6654 47.8324 47.8309 60.6669 31.9987 60.6669C16.1665 60.6669 3.33203 47.8324 3.33203 32.0003ZM31.9987 7.33359C18.3757 7.33359 7.33203 18.3772 7.33203 32.0003C7.33203 45.6233 18.3757 56.6669 31.9987 56.6669C45.6217 56.6669 56.6654 45.6233 56.6654 32.0003C56.6654 18.3772 45.6217 7.33359 31.9987 7.33359Z"
                    fill="#E9C237"
                  />
                </svg>
              </Box>
              <Typography
                sx={{
                  textAlign: "center",
                  fontFamily: "Lato",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                {message}
              </Typography>
              {userGroup !== "blockedUser" && (
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      marginTop: "15px",
                      width: "100%",
                    }}
                  >
                    <FormLabel
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#4C4C4C",
                      }}
                      id="demo-row-radio-buttons-group-label"
                    >
                      Block Time
                    </FormLabel>
                    <RadioGroup
                      style={{
                        marginTop: "15px",
                      }}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={handleBlockTypeChange}
                      value={blockType}
                    >
                      <FormControlLabel
                        style={{
                          marginRight: "4%",
                          color: "#4C4C4C",
                          fontSize: "24px",
                        }}
                        value="1"
                        control={
                          <Radio
                            sx={{
                              "&, &.Mui-checked": {
                                color: "#E9C237",
                              },
                              "&, &.Mui-unchecked": {
                                color: "#B3B3B3",
                              },
                            }}
                          />
                        }
                        label="Temporary"
                      />
                      <FormControlLabel
                        style={{ color: "#4C4C4C", fontSize: "24px" }}
                        value="0"
                        control={
                          <Radio
                            sx={{
                              "&, &.Mui-checked": {
                                color: "#E9C237",
                              },
                              "&, &.Mui-unchecked": {
                                color: "#B3B3B3",
                              },
                            }}
                          />
                        }
                        label="Permanently"
                      />
                    </RadioGroup>
                  </Box>
                  {blockType === "1" && (
                    <BlockFilter
                      queryParameters={queryParameters}
                      setQueryParameters={setQueryParameters}
                    />
                  )}

                  <Box sx={{ width: "100%" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#4C4C4C",
                        marginBottom: "5px",
                        marginTop: "15px",
                      }}
                    >
                      Select Reason
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        displayEmpty
                        onChange={handleReasonChange}
                        value={selectedReason}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          if (!selected) {
                            return <em>Select Reason</em>;
                          }
                          const selectedItem = data?.result?.find(
                            (item) => item.id === selected
                          );
                          return selectedItem ? (
                            selectedItem.trans.en
                          ) : (
                            <em>Select Reason</em>
                          );
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {data?.result?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.trans.en}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              )}

              <Box sx={{ display: "flex", gap: 2, marginTop: "10px" }}>
                <Button
                  variant="contained"
                  sx={{
                    width: "200px",
                    borderRadius: "6px",
                    fontSize: "18px",
                    background: "#E9C237",
                    height: "46px",
                    fontWeight: 600,
                    "&:hover": {
                      background: "#E9C237",
                    },
                  }}
                  onClick={() => {
                    if (
                      (blockType === "0" && selectedReason) ||
                      (blockType === "1" &&
                        selectedReason &&
                        queryParameters?.date_to)
                    ) {
                      handleReject({
                        blockType,
                        dateTo: queryParameters?.date_to,
                        selectedReason,
                      });
                    } else if (userGroup === "blockedUser") {
                      handleReject();
                    } else {
                      dispatch(
                        setData({
                          openSnack: true,
                          message: "Please Fill All Fields",
                        })
                      );
                    }
                  }}
                >
                  {label}
                </Button>

                <Button
                  variant="outlined"
                  sx={{
                    width: "200px",
                    borderRadius: "6px",
                    height: "46px",
                    color: "#E9C237",
                    fontWeight: 600,
                    borderColor: "#E9C237",
                    fontSize: "18px",
                    "&:hover": {
                      borderColor: "#E9C237",
                    },
                  }}
                  onClick={handleCloseAlert}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <ReusableSnackbar handleClose={handleCloseSnack} />
    </>
  );
};

export default BlockUserDialog;
