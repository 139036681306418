import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "../../styles";
import ActionAlert from "../user-profile/ActionAlert";
import CustomDialog from "../../components/CustomDialog";
import { deleteBank, fetchBanks } from "../../services/apis/banks";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { handlePageChange } from "../../services/utils/filters";
import AddBankDialog from "./AddBankDialog";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import AddButton from "../../components/common/AddButton";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
const Banks = () => {
  const [disableWhileDelete, setDisableWhileDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [banks, setBanks] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [pagesCount, setPagesCount] = useState(10);
  const [loading, setLoading] = useState(true);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [openDeletedAlert, setOpenDeletedAlert] = useState(false);
  const [functypeparams, setFuncTypeParams] = useState(null);
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
  });
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getBanks = async () => {
    setLoading(true);
    setBanks([]);
    try {
      const response = await fetchBanks(queryParameters);
      const bankscount = response.data.data.totalItems;
      const banksPagecount = response.data.data.totalPages;
      setTotalPages(banksPagecount);
      setPagesCount(bankscount);
      setBanks(response.data.data.result);
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  const handleAddBank = () => {
    setOpen(true);
    setFuncTypeParams("add");
    setSelectedBankId(null);
  };
  const handleOpenDeleteAlert = (id) => {
    setSelectedBankId(id);
    setOpenDeletedAlert(true);
  };
  const handleEditBank = (bankId) => {
    setSelectedBankId(bankId);
    setOpen(true);
    setFuncTypeParams("edit");
  };
  const handleDeleteBank = async () => {
    if (selectedBankId) {
      setDisableWhileDelete(true);
      try {
        await deleteBank(selectedBankId);
        setDisableWhileDelete(false);
        setSuccessDialogOpen(true);
        handleCloseDeleteAlert();
        getBanks();
      } catch (error) {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      }
    }
  };
  const handleCloseDeleteAlert = () => {
    setOpenDeletedAlert(false);
  };
  const bankColumns = ItemsColumns([
    { field: "id", headerName: "ID" },
    { field: "name_en", headerName: "Bank Name - English" },
    { field: "name_ar", headerName: "Bank Name - Arabic" },
    {
      field: "action",
      headerName: "Actions",
      actions: {
        edit: (params) => handleEditBank(params.row.id),
        delete: (params) => handleOpenDeleteAlert(params.row.id),
      },
    },
  ]);

  useEffect(() => {
    getBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParameters]);
  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Banks" marginL={"25px"} />
      <SecondBox>
        <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              gap: 2,
              pr: 2,
              mt: "3%",
            }}
          >
            <AddButton
              title={"+ New Bank"}
              secondDesign={true}
              onClick={() => {
                handleAddBank();
              }}
            />
          </Box>
          <DataGrid
            rows={banks}
            columns={bankColumns}
            loading={loading}
            rowsPerPageOptions={[10]}
            totalPages={totalPages}
            rowCount={pagesCount}
            pageSize={10}
            paginationMode="server"
            onPageChange={(p) =>
              handlePageChange(p, queryParameters, setQueryParameters)
            }
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
              height: "630px",
            }}
            localeText={{
              noRowsLabel: "No Banks",
            }}
          />
        </Box>
      </SecondBox>
      <ActionAlert
        openAlert={openDeletedAlert}
        handleCloseAlert={handleCloseDeleteAlert}
        handleReject={handleDeleteBank}
        message={`Are you sure you want to Delete this Bank ?`}
        label={"Delete"}
        disabled={disableWhileDelete}
      />
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Bank has been deleted Successfully."
        message=""
        buttonText="Go to Banks"
      />
      <AddBankDialog
        functypeparams={functypeparams}
        queryParameters={queryParameters}
        fetchData={fetchBanks}
        handleResponse={getBanks}
        setBanks={setBanks}
        banks={banks}
        bankId={selectedBankId}
        open={open}
        onClose={handleClose}
        label={functypeparams === "add" ? "Add" : "Save"}
      />
      <ReusableSnackbar handleClose={handleCloseSnack} />
    </Box>
  );
};

export default Banks;
