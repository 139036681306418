import React from "react";
import {
  Button,
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import TextFieldComponent from "../../components/TextFieldComponent";
import { useEffect } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { editQuestion } from "../../services/apis/faq";
import CustomDialog from "../../components/CustomDialog";
import CustomTextArea from "../../components/CustomTextArea";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { MenuProps } from "../../components/common/MenuProps";

const EditFaq = ({ open, onClose, label, setFaqs, faqs, faqId, fetchData }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const faqsToEdit = faqs?.find((faq) => faq.id === faqId);
  const [productsPayload, setProductsPayload] = useState({
    is_popular: null,
    local: "en",
    title: "",
    description: "",
  });
  const lang = [
    { id: 1, local: "en", name: "English" },
    { id: 2, local: "ar", name: "Arabic" },
    { id: 3, local: "eg", name: "Egyptian Arabic" },
  ];
  const handleLangChange = (event) => {
    const selectedLang = event.target.value;
    const selectedLangObj = lang.find((item) => item.local === selectedLang);
    if (selectedLangObj) {
      if (faqsToEdit) {
        setProductsPayload((prevState) => ({
          ...prevState,
          is_popular: faqsToEdit?.is_popular,
          local: selectedLang,
          title:
            selectedLang === "en"
              ? faqsToEdit?.trans?.en?.title
              : selectedLang === "ar"
              ? faqsToEdit?.trans?.ar?.title
              : faqsToEdit?.trans?.eg?.title,
          description:
            selectedLang === "en"
              ? faqsToEdit?.trans?.en?.description
              : selectedLang === "ar"
              ? faqsToEdit?.trans?.ar?.description
              : faqsToEdit?.trans?.eg?.description,
        }));
      }
    }
  };
  const [selectedTopic, setSelectedTopic] = useState(null);
  const topic = [
    { id: 1, name: "Is Popular", is_popular: 1 },
    { id: 2, name: "Other", is_popular: 0 },
  ];
  const handleTopicChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedTopic(selectedValue);
    const isPopularValue = selectedValue?.is_popular || 0;
    setProductsPayload((prevState) => ({
      ...prevState,
      is_popular: isPopularValue,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await editQuestion(faqId, productsPayload);
      setSelectedTopic(null);
      setIsSubmitting(true);
      onClose();
      setSuccessDialogOpen(true);
      const response = await fetchData();
      const data = response?.data?.data?.result;
      setFaqs(data);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    if (faqsToEdit) {
      setProductsPayload({
        is_popular: faqsToEdit?.is_popular,
        local: "en",
        title: faqsToEdit?.trans?.en?.title,
        description: faqsToEdit?.trans?.en?.description,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqId, faqs, open]);
  return (
    <>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Question has been updated Successfully."
        message=""
        buttonText="Go to FAQ"
      />
      <ReusableSnackbar handleClose={handleCloseSnack} />
      <Dialog
        maxWidth="850px"
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "850px",
            height: "auto",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            paddingTop: "5%",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              width: "80%",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={onClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  color: "#000000",
                  fontWeight: "400",
                  fontSize: "24px",
                  display: "flex",
                  alignSelf: "flex-start",
                }}
              >
                Edit Question
              </Box>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                Select Language
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  displayEmpty
                  onChange={handleLangChange}
                  value={productsPayload?.local || ""}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>{productsPayload.name}</em>;
                    }
                    return (
                      lang.find((item) => item.local === selected)?.name || ""
                    );
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {lang.map((item) => (
                    <MenuItem key={item.local} value={item.local}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                Select Topic
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  displayEmpty
                  onChange={handleTopicChange}
                  value={selectedTopic || ""}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <em>
                          {faqsToEdit?.is_popular === 1
                            ? "Is Popular"
                            : "Other"}
                        </em>
                      );
                    }
                    return selected?.name;
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {topic.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <TextFieldComponent
              sx={{ "& fieldset": { border: "none" } }}
              style={{
                height: "48px",
                width: "608px",
                backgroundColor: "#F7F7F7",
                borderRadius: "9px",
              }}
              name="Question"
              placeholder="Enter Question"
              value={productsPayload?.title}
              onChange={(event) => {
                const disallowEnglishRegex = /^[^a-zA-Z]*$/;
                if (
                  event.target.value === "" ||
                  disallowEnglishRegex.test(event.target.value)
                ) {
                  var temppayload = { ...productsPayload };
                  temppayload.title = event.target.value;
                  setProductsPayload({ ...temppayload });
                } else {
                  if (productsPayload.local === "en") {
                    var temppayloadEn = { ...productsPayload };
                    temppayloadEn.title = event.target.value;
                    setProductsPayload({ ...temppayloadEn });
                  }
                }
              }}
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                Enter Answer
              </Typography>
              <CustomTextArea
                style={{
                  minHeight: "54px",
                  maxHeight: "200px",
                  maxWidth: "608px",
                  minWidth: "608px",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                  boxShadow: "none",
                  border: "none",
                }}
                name="Answer"
                placeholder="Enter Answer"
                value={productsPayload?.description}
                onChange={(event) => {
                  const disallowEnglishRegex = /^[^a-zA-Z]*$/;
                  if (
                    event.target.value === "" ||
                    disallowEnglishRegex.test(event.target.value)
                  ) {
                    var temppayload = { ...productsPayload };
                    temppayload.description = event.target.value;
                    setProductsPayload({ ...temppayload });
                  } else {
                    if (productsPayload.local === "en") {
                      var temppayloadEn = { ...productsPayload };
                      temppayloadEn.description = event.target.value;
                      setProductsPayload({ ...temppayloadEn });
                    }
                  }
                }}
              />
            </Box>

            <Box sx={{ display: "flex", gap: 2, marginTop: "10px" }}>
              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  background: "#E9C237",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#E9C237",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#E9C237",
                  fontWeight: 600,
                  borderColor: "#E9C237",
                  "&:hover": {
                    borderColor: "#E9C237",
                  },
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditFaq;
