import { Box, Divider, Grid, Typography } from "@mui/material";

const StepFourComponent = ({
  roleData,
  categoryName,
  selectedCheckboxName,
  selectedCheckboxPermissions,
}) => {
  return (
    <Box sx={{ marginTop: "3%" }}>
      <Box>
        <Typography sx={{ color: "#4C4C4C", fontSize: "20px" }}>
          Role Details
        </Typography>
        <Box
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "4%",
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Typography
              sx={{ color: "#808080", fontSize: "14px", fontWeight: "500" }}
            >
              Role name :
            </Typography>
            <Typography
              sx={{
                color: "#808080",
                fontSize: "14px",
                fontWeight: "400",
                marginLeft: "5px",
              }}
            >
              {roleData.name}
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Typography
              sx={{ color: "#808080", fontSize: "14px", fontWeight: "500" }}
            >
              Category :
            </Typography>
            <Typography
              sx={{
                color: "#808080",
                fontSize: "14px",
                fontWeight: "400",
                marginLeft: "5px",
              }}
            >
              {categoryName}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            width: "70%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Typography
              sx={{
                color: "#808080",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Description :
            </Typography>
            <Typography
              sx={{
                color: "#808080",
                fontSize: "14px",
                fontWeight: "400",
                marginLeft: "5px",
              }}
            >
              {roleData.description}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "3%",
          }}
        >
          <Typography sx={{ color: "#4C4C4C", fontSize: "20px" }}>
            Resource Group
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            <Grid container spacing={2}>
              {selectedCheckboxName.map((checkboxName, index) => (
                <Grid item key={index} xs={2}>
                  <Box
                    key={index}
                    style={{
                      display: "flex",
                      backgroundColor: "#F5F6FF",
                      borderRadius: "100px",
                      padding: "5px",
                      width: "157px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ color: "#4C4C4C", fontSize: "14px" }}>
                      {checkboxName}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "3%",
          }}
        >
          <Typography sx={{ color: "#4C4C4C", fontSize: "20px" }}>
            Permissions
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            <Grid container spacing={2}>
              {selectedCheckboxPermissions.map((checkboxPermission, index) => (
                <Grid item key={index} xs={2.4}>
                  <Box
                    key={index}
                    style={{
                      display: "flex",
                      backgroundColor: "#F5F6FF",
                      borderRadius: "100px",
                      padding: "5px 10px",
                      //   width: "157px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#4C4C4C",
                        fontSize: "14px",
                        display: "flex",
                        alignSelf: "center",
                      }}
                    >
                      {checkboxPermission}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default StepFourComponent;
