import { useQuery } from "react-query";
import { axiosInstance as axios } from "../config";

export const fetchAllCities = async ({ queryKey }) => {
  const [, { page }] = queryKey;
  try {
    const response = await axios.get("/api/cities/index", {
      params: { page },
    });
    return response.data; // Make sure to return the data
  } catch (error) {
    throw error;
  }
};

export const useCities = (page) => {
  return useQuery(["cities", { page }], fetchAllCities, {
    keepPreviousData: true, // Optional: Keeps previous data while fetching new data
  });
};

export const fetchAllCitiesDD = async () => {
  try {
    const response = await axios.get(`/api/cities/dropdown`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addCity = async (body) => {
  try {
    const response = await axios.post(`/api/cities/add`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editCity = async (id, body) => {
  try {
    const response = await axios.post(`/api/cities/edit/${id}`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteCity = async (id) => {
  try {
    const response = await axios.get(`/api/cities/delete/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchAllTowns = async (p) => {
  try {
    const response = await axios.get(`/api/towns/index`, {
      params: {
        page: p.page,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const addTown = async (body) => {
  try {
    const response = await axios.post(`/api/towns/add`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editTown = async (id, body) => {
  try {
    const response = await axios.post(`/api/towns/edit/${id}`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteTown = async (id) => {
  try {
    const response = await axios.get(`/api/towns/delete/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
