import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import LoadingSpinner from "../../components/LoadingSpinner";
import { fetchAllSplashes } from "../../services/apis/splashscreen";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Header from "../../components/Header";
import Modal from "@mui/material/Modal";
import KycModalBody from "../../components/KycModalBody";
import AddSplashModal from "./AddSplashModal";
import EditSplashModal from "./EditSplashModal";

import { EditSvgWithFunc } from "../../components/common/SvgWithFunc";

const AppManagement = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const cellsStyle = {
    border: "1px solid #e0e0e0",
    p: "20px",
  };

  const [openImage, setOpenImage] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [splashes, setSplashes] = useState([]);
  const [image] = useState(null);
  const [currentSplash, setCurrentSplash] = useState(null);

  const getSplashes = async () => {
    setLoading(true);
    try {
      const response = await fetchAllSplashes();
      const splashes = response.data.data;
      setSplashes(splashes);
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseImage = async () => {
    setOpenImage(false);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    getSplashes();
  };

  const handleOpenEditModal = (splash) => {
    setCurrentSplash(splash);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    getSplashes();
  };

  useEffect(() => {
    getSplashes();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box
          sx={{
            height: "auto",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
            background: colors.primary,
            m: "20px",
          }}
        >
          <Box gridColumn="span 1" sx={{ width: "100%" }}>
            <Header title="Onboarding Screen" subtitle="" />

            <TableContainer sx={{ marginTop: "2%" }} component={Paper}>
              <Table sx={{ borderCollapse: "collapse" }}>
                <TableHead sx={{ border: "1px solid" }}>
                  <TableRow>
                    <TableCell style={{ textAlign: "center" }}>Order</TableCell>
                    <TableCell style={{ textAlign: "center" }}>Title</TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      Screen Text
                    </TableCell>
                    {/* <TableCell style={{ textAlign: "center" }}>Image</TableCell> */}
                    <TableCell style={{ textAlign: "center" }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* <TableCell sx={cellsStyle}>Admin Roles</TableCell> */}
                  {splashes?.map((splash) => (
                    <TableRow key={splash.id} sx={cellsStyle}>
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {splash.sort}
                      </TableCell>
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {splash?.trans?.en?.title}
                      </TableCell>
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {splash?.trans?.en?.text}
                      </TableCell>
                      {/* <TableCell
            sx={cellsStyle}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              height="50px"
              width="50px"
              src={`${dn}/${splash.image}`}
              alt="image"
              crossOrigin="anonymous"
              style={{ cursor: "pointer", borderRadius: "2px" }}
              onClick={() => handleOpenImage(splash.image)}
            />
          </TableCell> */}
                      <TableCell sx={cellsStyle}>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            gap: 2,
                            justifyContent: "center",
                          }}
                        >
                          <Box>
                            <EditSvgWithFunc
                              onClick={() => {
                                handleOpenEditModal(splash);
                              }}
                            />
                          </Box>
                        </Box>
                        {/* Add any additional content or components for Actions here */}
                      </TableCell>
                      {/* <TableCell sx={cellsStyle}>{role.name}</TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box display="flex" justifyContent="end" mt="20px">
              <Modal
                open={openImage}
                onClose={handleCloseImage}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <KycModalBody src={image} />
              </Modal>

              <Modal
                open={openAddModal}
                onClose={handleCloseAddModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <AddSplashModal handleClose={handleCloseAddModal} />
              </Modal>

              <Modal
                open={openEditModal}
                onClose={handleCloseEditModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <EditSplashModal
                  handleClose={handleCloseEditModal}
                  splash={currentSplash}
                />
              </Modal>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AppManagement;
