import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Users from "./scenes/users";
import RegistrationForm from "./scenes/auth/register";
import Transactions from "./scenes/transactions";
import GoldTransactions from "./scenes/gold-transactions";
import ANR from "./scenes/add-new-role";
import AddNewUser from "./scenes/add-new-user";
import Bar from "./scenes/bar";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import TransactionsProvider from "./contexts/TransactionsContext";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import UserProfile from "./scenes/user-profile";
import Permissions from "./scenes/permissions";
import ProtectedRoute from "./hoc/ProtectedRoute";
import { useEffect } from "react";
import AppManagement from "./scenes/app-content-management";
import Notifications from "./scenes/notifications";
import Orders from "./scenes/orders";
import Terms from "./scenes/terms";
import CitiesAndTowns from "./scenes/cities-and-towns";
import PaymentInfo from "./scenes/paymentInfo";
import AddNewProduct from "./scenes/add-new-product";
import ForgetPassword from "./scenes/auth/ForgetPassword";
import ResetPassword from "./scenes/auth/ResetPassword";
import ViewRole from "./scenes/add-new-role/ViewRole";
import ViewAdminProfile from "./scenes/add-new-role/ViewAdminProfile";
import AddNewRole from "./scenes/add-new-role/AddNewRole";
import RolesCategories from "./scenes/permissions/RolesCategories";
import ResourcePermissions from "./scenes/permissions/ResourcePermissions";
import RolePermissions from "./scenes/permissions/RolePermissions";
import UserPermissions from "./scenes/permissions/UserPermissions";
import AdminProfile from "./scenes/AdminProfile";
import DeletedUsers from "./scenes/DeletedUsers";
import DeactivatedAdmin from "./scenes/deactivatedAdmin";
import Configuration from "./scenes/configuration";
import FractionsTransfer from "./scenes/fractionsTransfer";
import Requests from "./scenes/Inventory/requests";
import RequestDetails from "./scenes/Inventory/requests/RequestDetails";
import Stock from "./scenes/Inventory/stock";
import StockDetails from "./scenes/Inventory/stock/StockDetails";
import AddNewFAQ from "./scenes/faq/AddNewFAQ";
import PendingOrders from "./scenes/orders/pendingOrders";
import PendingOrdersDetails from "./scenes/orders/pendingOrders/PendingOrdersDetails";
import Banks from "./scenes/banks";
import Tickets from "./scenes/tickets";
import TicketsDetails from "./scenes/tickets/ticketsDetails";
import AddNewTerms from "./scenes/terms/AddNewTerms";
import AddNewNotification from "./scenes/notifications/AddNewNotification";
import NotificationDetails from "./scenes/notifications/NotificationDetails";
import TicketSubjects from "./scenes/ticketSubjects";
import TicketQuestions from "./scenes/ticketQuestions";
import AddNewTicketQuestion from "./scenes/ticketQuestions/AddNewTicketQuestion";
import BlockReasons from "./scenes/blockReasons/blockReasons";
import AddNewReason from "./scenes/blockReasons/AddNewReason";
import ClosedReasons from "./scenes/closedReasons";
import AddClosedReason from "./scenes/closedReasons/AddClosedReason";
import SubQuestionsDetails from "./scenes/ticketQuestions/SubQuestionsDetails";
import AddNewSubQuestion from "./scenes/ticketQuestions/AddNewSubQuestion";
import EditSubQuestion from "./scenes/ticketQuestions/EditSubQuestion";
import EditTicketQuestion from "./scenes/ticketQuestions/EditTicketQuestion";
import { useDispatch, useSelector } from "react-redux";
import NewLogin from "./scenes/auth/login/NewLogin";
import SellFractionsIngot from "./scenes/sellFractionsIngot";
import Articles from "./scenes/articles";
import About from "./scenes/about";
import AddArticle from "./scenes/articles/AddArticle";
import EditArticle from "./scenes/articles/EditArticle";
import SoldIngots from "./scenes/Inventory/soldIngots/Index";
import ProductDetailsPage from "./scenes/product-details";
import AppBadge from "./scenes/app-badge/AppBadge";
import AddBadge from "./scenes/app-badge/AddBadge";
import AppBadgeDetails from "./scenes/app-badge/AppBadgeDetails";
import { clearToken } from "./store/slices/authenticationSlice";
function App() {
  const { isAuthenticated } = useSelector((state) => state.authentication);
  const [theme, colorMode] = useMode();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const noSidebarPaths = [
    "/forget-password",
    "/ResetPassword",
    "/register",
    "/deactivatedAdmin",
  ];
   useEffect(() => {
    const authRestrictedPaths = ["/login"];
    // when the storage change
    window.addEventListener("storage", () => {
      const token = localStorage.getItem("token");
      if (!token) {
        dispatch(clearToken());
        navigate("/login", { replace: true });
      }
    });
    
    
    if (isAuthenticated && authRestrictedPaths.includes(pathname)) {
      navigate("/");
    }
    if(!isAuthenticated&&!noSidebarPaths.includes(pathname)){
      navigate("/login", { replace: true });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, pathname, navigate]);

  return (
    <TransactionsProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app" style={{ background: "#F0F0F0" }}>
            {isAuthenticated && !noSidebarPaths.includes(pathname) && (
              <Sidebar />
            )}
            <main className="content">
              {/* <Topbar setIsSidebar={setIsSidebar} /> */}
              <Routes>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/login"
                  element={
                    <ProtectedRoute>
                      <NewLogin />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/deactivatedAdmin"
                  element={
                    <ProtectedRoute>
                      <DeactivatedAdmin />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/register"
                  element={
                    <ProtectedRoute>
                      <RegistrationForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/forget-password"
                  element={
                    <ProtectedRoute>
                      <ForgetPassword />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/AdminProfile"
                  element={
                    <ProtectedRoute>
                      <AdminProfile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/ResetPassword"
                  element={
                    <ProtectedRoute>
                      <ResetPassword />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/team"
                  element={
                    <ProtectedRoute>
                      <Team />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/users"
                  element={
                    <ProtectedRoute>
                      <Users />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/deleted-users"
                  element={
                    <ProtectedRoute>
                      <DeletedUsers />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/invoices"
                  element={
                    <ProtectedRoute>
                      <Invoices />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/add-new-role"
                  element={
                    <ProtectedRoute>
                      <ANR />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/ViewRole/:id"
                  element={
                    <ProtectedRoute>
                      <ViewRole />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/ViewAdminProfile/:id"
                  element={
                    <ProtectedRoute>
                      <ViewAdminProfile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/AddNewRole"
                  element={
                    <ProtectedRoute>
                      <AddNewRole />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/AddNewFAQ"
                  element={
                    <ProtectedRoute>
                      <AddNewFAQ />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/AddNewNotification"
                  element={
                    <ProtectedRoute>
                      <AddNewNotification />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/add-new-admin"
                  element={
                    <ProtectedRoute>
                      <AddNewUser />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/Role-Categories"
                  element={
                    <ProtectedRoute>
                      <RolesCategories />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/pages-permissions"
                  element={
                    <ProtectedRoute>
                      <RolePermissions />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/bar"
                  element={
                    <ProtectedRoute>
                      <Bar />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/pie"
                  element={
                    <ProtectedRoute>
                      <Pie />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/line"
                  element={
                    <ProtectedRoute>
                      <Line />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/faq"
                  element={
                    <ProtectedRoute>
                      <FAQ />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/blockReasons"
                  element={
                    <ProtectedRoute>
                      <BlockReasons />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/closedReasons"
                  element={
                    <ProtectedRoute>
                      <ClosedReasons />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/AddClosedReason"
                  element={
                    <ProtectedRoute>
                      <AddClosedReason />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/AddNewReason"
                  element={
                    <ProtectedRoute>
                      <AddNewReason />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/paymentInfo"
                  element={
                    <ProtectedRoute>
                      <PaymentInfo />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/banks"
                  element={
                    <ProtectedRoute>
                      <Banks />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/ticketSubjects"
                  element={
                    <ProtectedRoute>
                      <TicketSubjects />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/ticketQuestions"
                  element={
                    <ProtectedRoute>
                      <TicketQuestions />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/AddNewTicketQuestion"
                  element={
                    <ProtectedRoute>
                      <AddNewTicketQuestion />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/AddNewTicketQuestion/:id"
                  element={
                    <ProtectedRoute>
                      <AddNewTicketQuestion />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/AddNewSubQuestion/:id"
                  element={
                    <ProtectedRoute>
                      <AddNewSubQuestion />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/EditSubQuestion/:id"
                  element={
                    <ProtectedRoute>
                      <EditSubQuestion />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/EditTicketQuestion/:id"
                  element={
                    <ProtectedRoute>
                      <EditTicketQuestion />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/SubQuestionsDetails/:id"
                  element={
                    <ProtectedRoute>
                      <SubQuestionsDetails />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/tickets"
                  element={
                    <ProtectedRoute>
                      <Tickets />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/ticketsDetails/:id"
                  element={
                    <ProtectedRoute>
                      <TicketsDetails />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/tickets/:id"
                  element={
                    <ProtectedRoute>
                      <Tickets />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/add-new-product"
                  element={
                    <ProtectedRoute>
                      <AddNewProduct />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/product-details/:id"
                  element={
                    <ProtectedRoute>
                      <ProductDetailsPage />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/aboutUs"
                  element={
                    <ProtectedRoute>
                      <About />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/configuration"
                  element={
                    <ProtectedRoute>
                      <Configuration />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/terms"
                  element={
                    <ProtectedRoute>
                      <Terms />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/AddNewTerms"
                  element={
                    <ProtectedRoute>
                      <AddNewTerms />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/delivery-locations"
                  element={
                    <ProtectedRoute>
                      <CitiesAndTowns />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/calendar"
                  element={
                    <ProtectedRoute>
                      <Calendar />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/geography"
                  element={
                    <ProtectedRoute>
                      <Geography />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/profile/:id"
                  element={
                    <ProtectedRoute>
                      <UserProfile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute>
                      <UserProfile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/transactions/:id"
                  element={
                    <ProtectedRoute>
                      <Transactions />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/transactions"
                  element={
                    <ProtectedRoute>
                      <Transactions />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/gold-transactions/:id"
                  element={
                    <ProtectedRoute>
                      <GoldTransactions />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/gold-transactions"
                  element={
                    <ProtectedRoute>
                      <GoldTransactions />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/fractionsTransfer"
                  element={
                    <ProtectedRoute>
                      <FractionsTransfer />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/requests"
                  element={
                    <ProtectedRoute>
                      <Requests />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/stock"
                  element={
                    <ProtectedRoute>
                      <Stock />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/soldIngots"
                  element={
                    <ProtectedRoute>
                      <SoldIngots />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/sellFractionsIngot"
                  element={
                    <ProtectedRoute>
                      <SellFractionsIngot />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/articles"
                  element={
                    <ProtectedRoute>
                      <Articles />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/AddArticle"
                  element={
                    <ProtectedRoute>
                      <AddArticle />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/EditArticle/:id"
                  element={
                    <ProtectedRoute>
                      <EditArticle />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/requests/:id"
                  element={
                    <ProtectedRoute>
                      <RequestDetails />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/stock/:id"
                  element={
                    <ProtectedRoute>
                      <StockDetails />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/orders"
                  element={
                    <ProtectedRoute>
                      <Orders />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/pendingOrders"
                  element={
                    <ProtectedRoute>
                      <PendingOrders />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/pendingOrders/:id"
                  element={
                    <ProtectedRoute>
                      <PendingOrdersDetails />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/onboarding-screens"
                  element={
                    <ProtectedRoute>
                      <AppManagement />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/permissions"
                  element={
                    <ProtectedRoute>
                      <Permissions />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/notifications"
                  element={
                    <ProtectedRoute>
                      <Notifications />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/notifications/:id"
                  element={
                    <ProtectedRoute>
                      <NotificationDetails />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/users-permissions"
                  element={
                    <ProtectedRoute>
                      <UserPermissions />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin-permission"
                  element={
                    <ProtectedRoute>
                      <ResourcePermissions />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/app-badge"
                  element={
                    <ProtectedRoute>
                      <AppBadge />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/add-badge"
                  element={
                    <ProtectedRoute>
                      <AddBadge />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/badge-details/:id"
                  element={
                    <ProtectedRoute>
                      <AppBadgeDetails />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </TransactionsProvider>
  );
}

export default App;
