import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import useChart from "./UseChart";
import Chart from "./Chart";
import FilterChartDate from "./FilterChartDate";
import "apexcharts/dist/apexcharts.css";

export default function UserSingleLineChart({
  title,
  subheader,
  chart,
  queryParameters,
  setQueryParameters,
  height,
  width,
  ...other
}) {
  const { labels, colors, series, options } = chart;

  const chartOptions = useChart({
    colors,
    plotOptions: {
      bar: {
        columnWidth: "20px",
        horizontal: false,
      },
      line: {
        curve: "straight",
      },
    },
    fill: {
      type: Array.isArray(series) ? series.map((i) => i.fill) : [],
    },

    labels,
    axisX: {
      position: "start",
    },
    xaxis: {
      position: "start",
      type: "category",
    },
    series: {
      line: {
        curve: "straight",
      },
    },
    stroke: {
      curve: "straight",
    },
    chart: {
      toolbar: {},
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value) => {
          if (typeof value !== "undefined") {
            return `${value.toFixed(0)}`;
          }
          return value;
        },
      },
    },
    ...options,
  });

  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
        // action={}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: "2%",
          marginRight: "2%",
        }}
      >
        <Box>
          <FilterChartDate
            queryParameters={queryParameters}
            setQueryParameters={setQueryParameters}
          />
        </Box>
      </Box>

      <Box sx={{ p: 3, pb: 1 }}>
        <Chart
          dir="ltr"
          series={series}
          options={chartOptions}
          width={width}
          height={height}
        />
      </Box>
    </Card>
  );
}

UserSingleLineChart.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string,
};
