import { Box } from "@mui/material";
import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "../../styles";
import { handlePageChange } from "../../services/utils/filters";
import AddButton from "../../components/common/AddButton";
const BadgeQuestions = () => {
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
    orderBy: null,
    sort: null,
    inventory_type: "ingot",
  });
  const questionsColumns = [
    {
      field: "question",
      headerName: "Question",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "bold-header",
    },
    {
      field: "answer",
      headerName: "Answer",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "bold-header",
    },

    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                // onClick={() => handleEditProduct(params.row.id)}
                style={{ cursor: "pointer" }}
              >
                <path
                  d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H11C11.41 1.25 11.75 1.59 11.75 2C11.75 2.41 11.41 2.75 11 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V13C21.25 12.59 21.59 12.25 22 12.25C22.41 12.25 22.75 12.59 22.75 13V15C22.75 20.43 20.43 22.75 15 22.75Z"
                  fill="#00303C"
                />
                <path
                  d="M8.49984 17.69C7.88984 17.69 7.32984 17.47 6.91984 17.07C6.42984 16.58 6.21984 15.87 6.32984 15.12L6.75984 12.11C6.83984 11.53 7.21984 10.78 7.62984 10.37L15.5098 2.49C17.4998 0.499998 19.5198 0.499998 21.5098 2.49C22.5998 3.58 23.0898 4.69 22.9898 5.8C22.8998 6.7 22.4198 7.58 21.5098 8.48L13.6298 16.36C13.2198 16.77 12.4698 17.15 11.8898 17.23L8.87984 17.66C8.74984 17.69 8.61984 17.69 8.49984 17.69ZM16.5698 3.55L8.68984 11.43C8.49984 11.62 8.27984 12.06 8.23984 12.32L7.80984 15.33C7.76984 15.62 7.82984 15.86 7.97984 16.01C8.12984 16.16 8.36984 16.22 8.65984 16.18L11.6698 15.75C11.9298 15.71 12.3798 15.49 12.5598 15.3L20.4398 7.42C21.0898 6.77 21.4298 6.19 21.4798 5.65C21.5398 5 21.1998 4.31 20.4398 3.54C18.8398 1.94 17.7398 2.39 16.5698 3.55Z"
                  fill="#00303C"
                />
                <path
                  d="M19.8501 9.83003C19.7801 9.83003 19.7101 9.82003 19.6501 9.80003C17.0201 9.06003 14.9301 6.97003 14.1901 4.34003C14.0801 3.94003 14.3101 3.53003 14.7101 3.41003C15.1101 3.30003 15.5201 3.53003 15.6301 3.93003C16.2301 6.06003 17.9201 7.75003 20.0501 8.35003C20.4501 8.46003 20.6801 8.88003 20.5701 9.28003C20.4801 9.62003 20.1801 9.83003 19.8501 9.83003Z"
                  fill="#00303C"
                />
              </svg>
            </Box>
            <Box>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                cursor="pointer"
                // onClick={() => handleOpenDeleteAlert(params.row.id)}
              >
                <path
                  d="M21.4997 6.72998C21.4797 6.72998 21.4497 6.72998 21.4197 6.72998C16.1297 6.19998 10.8497 5.99998 5.61967 6.52998L3.57967 6.72998C3.15967 6.76998 2.78967 6.46998 2.74967 6.04998C2.70967 5.62998 3.00967 5.26998 3.41967 5.22998L5.45967 5.02998C10.7797 4.48998 16.1697 4.69998 21.5697 5.22998C21.9797 5.26998 22.2797 5.63998 22.2397 6.04998C22.2097 6.43998 21.8797 6.72998 21.4997 6.72998Z"
                  fill="#CF2424"
                />
                <path
                  d="M8.99977 5.72C8.95977 5.72 8.91977 5.72 8.86977 5.71C8.46977 5.64 8.18977 5.25 8.25977 4.85L8.47977 3.54C8.63977 2.58 8.85977 1.25 11.1898 1.25H13.8098C16.1498 1.25 16.3698 2.63 16.5198 3.55L16.7398 4.85C16.8098 5.26 16.5298 5.65 16.1298 5.71C15.7198 5.78 15.3298 5.5 15.2698 5.1L15.0498 3.8C14.9098 2.93 14.8798 2.76 13.8198 2.76H11.1998C10.1398 2.76 10.1198 2.9 9.96977 3.79L9.73977 5.09C9.67977 5.46 9.35977 5.72 8.99977 5.72Z"
                  fill="#CF2424"
                />
                <path
                  d="M15.7104 22.75H9.29039C5.80039 22.75 5.66039 20.82 5.55039 19.26L4.90039 9.19001C4.87039 8.78001 5.19039 8.42001 5.60039 8.39001C6.02039 8.37001 6.37039 8.68001 6.40039 9.09001L7.05039 19.16C7.16039 20.68 7.20039 21.25 9.29039 21.25H15.7104C17.8104 21.25 17.8504 20.68 17.9504 19.16L18.6004 9.09001C18.6304 8.68001 18.9904 8.37001 19.4004 8.39001C19.8104 8.42001 20.1304 8.77001 20.1004 9.19001L19.4504 19.26C19.3404 20.82 19.2004 22.75 15.7104 22.75Z"
                  fill="#CF2424"
                />
                <path
                  d="M14.1601 17.25H10.8301C10.4201 17.25 10.0801 16.91 10.0801 16.5C10.0801 16.09 10.4201 15.75 10.8301 15.75H14.1601C14.5701 15.75 14.9101 16.09 14.9101 16.5C14.9101 16.91 14.5701 17.25 14.1601 17.25Z"
                  fill="#CF2424"
                />
                <path
                  d="M15 13.25H10C9.59 13.25 9.25 12.91 9.25 12.5C9.25 12.09 9.59 11.75 10 11.75H15C15.41 11.75 15.75 12.09 15.75 12.5C15.75 12.91 15.41 13.25 15 13.25Z"
                  fill="#CF2424"
                />
              </svg>
            </Box>
          </Box>
        );
      },
    },
  ];
  return (
    <Box
      m="20px"
      sx={{
        height: "auto",
        background: "#FFF",
        borderRadius: "12px",
        boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08);",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 2,
          p: 2,
          justifyContent: "end",
        }}
      >
        <AddButton
          variant="outlined"
          title={"+ New Question"}
          onClick={() => {
            // handleAddProduct()
          }}
          InWhiteSpace={true}
        />
      </Box>
      <Box
        m="40px 0 0 0"
        height="auto"
        style={{ paddingBottom: "2%" }}
        sx={DataGridStyle}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: "100%",
            marginTop: "3%",
          }}
        >
          <DataGrid
            disableSelectionOnClick
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
              height: "630px",
            }}
            // loading={loading}
            pageSize={10}
            rowCount={0}
            rows={[{ id: 1, question: "test", answer: "Testing" }]}
            columns={questionsColumns}
            paginationMode="server"
            onPageChange={(p) =>
              handlePageChange(p, queryParameters, setQueryParameters)
            }
            rowsPerPageOptions={[10]}
            // totalPages={totalPages}
            localeText={{
              noRowsLabel: "No App Badges Available",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BadgeQuestions;
