import React, { useEffect } from "react";
import { Typography, Box, Button, Divider } from "@mui/material";
import {
  approveUser,
  unBlockUser,
  useNewBlock,
  useUserDetails,
} from "../../services/apis/users";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { imageBaseUrl } from "../../services/config";
import LoadingSpinner from "../../components/LoadingSpinner";
import KycDialog from "./KycDialog";
import Header from "../../components/Header";
import UserInfoField from "./UserInfoField";
import { makeStyles } from "@mui/styles";
import KycCard from "./KycCard";
import ActionAlert from "./ActionAlert";
import BlockUserDialog from "./BlockUserDialog";
import CustomDialog from "../../components/CustomDialog";
import dayjs from "dayjs";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";

const useStyles = makeStyles({
  root: {
    textTransform: "none",
  },
});

const UserProfile = () => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useUserDetails(id);
  const block = "";
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    reject_kyc_reason: "",
  });
  const [approveButton, setApproveButton] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [rejectButton, setRejectButton] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openBlockedAlert, setOpenBlockedAlert] = useState(false);
  const [type, setType] = useState("");
  const [userGroup, setUserGroup] = useState(null);
  const classes = useStyles();
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const { mutateAsync } = useNewBlock();
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };

  const handleApprove = async (nid) => {
    try {
      await approveUser("approved", id, nid);
      refetch();
      setApproveButton(false);
      setOpenDialog(false);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: "invalid nationalId",
        })
      );
    }
  };

  const handleReject = async () => {
    try {
      await approveUser("rejected", id, formData);
      refetch();
      setRejectButton(false);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error?.data?.data?.message,
        })
      );
    }
    setOpenAlert(false);
  };

  const handleBlock = async (data) => {
    const parsedDate = dayjs(data?.dateTo, "MM/DD/YYYY");
    await mutateAsync({
      id: Number(id),
      block_reason_id: data?.selectedReason,
      unBlock_at: data?.dateTo
        ? parsedDate.format("YYYY-MM-DDTHH:mm:ss")
        : null,
    });
    refetch();
    setOpenBlockedAlert(false);
  };
  const handleUnBlock = async () => {
    try {
      await unBlockUser(id);
      refetch();
      setSuccessDialogOpen(true);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error?.data?.data?.message,
        })
      );
    }
    setOpenBlockedAlert(false);
  };

  const checkUserStatus = (user) => {
    if (user.kyc_status.toLowerCase() === "pending") {
      setApproveButton(true);
      setRejectButton(true);
    }

    if (user.kyc_status.toLowerCase() === "approved") {
      setApproveButton(false);
      setRejectButton(true);
    }

    if (user.kyc_status.toLowerCase() === "rejected") {
      setApproveButton(true);
      setRejectButton(false);
    }
  };

  useEffect(() => {
    if (data?.data) {
      checkUserStatus(data?.data);
      setUserGroup(data?.data?.group);
      console.log("data data ", data?.data);
    }
  }, [data?.data]);

  if (isLoading) return <LoadingSpinner />;
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: 2,
        background: "#F0F0F0",
        alignItems: "center",
        overflowY: "auto",
        maxHeight: "calc(100vh)",
        paddingBottom: "2%",
      }}
    >
      <KycDialog
        openDialog={openDialog}
        handleCloseDialog={() => {
          setOpenDialog(false);
        }}
        handleApprove={handleApprove}
        image={
          data?.data?.national_front
            ? `${imageBaseUrl}/${data?.data?.national_front}`
            : `../../assets/noKyc.png`
        }
      />
      <ActionAlert
        setFormData={setFormData}
        formData={formData}
        openAlert={openAlert}
        handleCloseAlert={() => {
          setOpenAlert(false);
        }}
        type={type}
        handleReject={handleReject}
        message={`Are you sure you want to ${
          type === "Approve" ? "Approve" : "Reject"
        } KYC?`}
        label={type === "Approve" ? "Approve" : "Reject"}
        alertType={"KYC"}
      />
      {/* block user action alert  */}
      <BlockUserDialog
        openAlert={openBlockedAlert}
        handleCloseAlert={() => {
          setOpenBlockedAlert(false);
        }}
        type={block}
        userGroup={userGroup}
        handleReject={userGroup === "blockedUser" ? handleUnBlock : handleBlock}
        message={`Are you sure you want to ${
          userGroup === "blockedUser" ? "Unblock" : "Block"
        } this user`}
        label={userGroup === "blockedUser" ? "Unblock" : "Block"}
        alertType={"Block"}
      />
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title={`User has been ${
          userGroup === "blockedUser" ? "Blocked" : "UnBlocked"
        } Successfully`}
        message=""
        buttonText="Users Information"
      />
      <ReusableSnackbar handleClose={handleCloseSnack} />
      <Header
        title="Personal Information"
        marginT={"5%"}
        marginL={3}
        sx={{ alignSelf: "start" }}
      />
      <Box sx={{ display: "flex", width: "95%" }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <UserInfoField title="Full Name" data={data?.data?.full_name} />
            <Button
              sx={{
                color: "#00303C",
                fontFamily: "Lato",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 700,
                textDecorationLine: "underline",
              }}
              className={classes.root}
              onClick={() => {
                navigate(`/transactions/${id}`);
              }}
            >
              See All user Transactions
            </Button>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <UserInfoField
              title="National ID Name"
              data={data?.data?.national_name}
            />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <UserInfoField title="National ID" data={data?.data?.nationalId} />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <UserInfoField title="KYC Status" data={data?.data?.kyc_status} />
          </Box>
          {data?.data?.kyc_status === "rejected" ? (
            <>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <UserInfoField
                  title="KYC Rejection reason"
                  data={data?.data?.reject_kyc_reason}
                />
              </Box>
            </>
          ) : null}
          <Divider />
          {data?.data?.deleted_at === null && (
            <>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <UserInfoField
                  title="User Status"
                  data={userGroup === "blockedUser" ? "Blocked" : "Active"}
                />
              </Box>
              <Divider />
            </>
          )}

          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <UserInfoField title="Email" data={data?.data?.email} />
          </Box>
          <Divider />

          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <UserInfoField title="Mobile" data={data?.data?.phone} />
          </Box>
          <Divider />

          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <UserInfoField
              title="Wallet Balance"
              data={
                data?.data?.currentBalance
                  ? parseFloat(data?.data.currentBalance).toLocaleString()
                  : ""
              }
            />
          </Box>
          <Divider />

          {data?.data?.deleted_at === null ? (
            <Button
              variant="contained"
              sx={{
                "&:hover": { backgroundColor: "#F23045" },
                color: "#FFF",
                width: "150px",
                background: "#F23045",
                height: "37px",
                borderRadius: "9px",
                alignSelf: "flex-end",
              }}
              className={classes.root}
              onClick={() => {
                setOpenBlockedAlert(true);
              }}
            >
              {userGroup === "blockedUser" ? "Unblock" : "Block"}
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{
                "&:hover": { backgroundColor: "#E9C237" },
                color: "#FFF",
                width: "150px",
                background: "#E9C237",
                height: "37px",
                borderRadius: "9px",
                alignSelf: "flex-end",
              }}
              className={classes.root}
              // onClick={handleOpenBlockAlert}
            >
              Restore Account
            </Button>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "85%",
          // height: "370px",
          background: "#FFF",
          borderRadius: "12px",
          boxShadow: "1px 1px 20px 0px rgba(0, 0, 0, 0.10)",
          // flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            // ml: 4,
            gap: 2,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: "#333",
              fontFamily: "Lato",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 600,
              ml: 4,
            }}
          >
            KYC
          </Typography>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <KycCard
              title="Personal Picture"
              img={
                data?.data?.photo_verification
                  ? `${imageBaseUrl}/${data?.data?.photo_verification}`
                  : `../../assets/noKyc.png`
              }
            />
            <KycCard
              title="Front ID attachment"
              img={
                data?.data?.national_front
                  ? `${imageBaseUrl}/${data?.data?.national_front}`
                  : `../../assets/noKyc.png`
              }
            />
            <KycCard
              title="Back ID attachment"
              img={
                data?.data?.national_back
                  ? `${imageBaseUrl}/${data?.data?.national_back}`
                  : `../../assets/noKyc.png`
              }
            />
          </Box>
          {data?.data?.deleted_at === null && (
            <Box sx={{ display: "flex", alignSelf: "end" }}>
              {approveButton && (
                <Button
                  className={classes.root}
                  variant="contained"
                  sx={{
                    // display: "flex",
                    width: "150px",
                    borderRadius: "6px",
                    background: "#24CF80",
                    // alignSelf: "end"
                    mr: 4,
                  }}
                  onClick={() => {
                    setOpenDialog(true);
                  }}
                  id="Approve"
                >
                  Approve KYC{" "}
                </Button>
              )}

              {rejectButton && (
                <Button
                  className={classes.root}
                  variant="contained"
                  sx={{
                    "&:hover": { backgroundColor: "#F23045" },
                    // display: "flex",
                    width: "150px",
                    borderRadius: "6px",
                    background: "#F23045",
                    // alignSelf: "end"
                    mr: 4,
                  }}
                  onClick={(e) => {
                    setType(e.target.id);
                    setOpenAlert(true);
                  }}
                  id="Reject"
                >
                  Reject KYC{" "}
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default UserProfile;
