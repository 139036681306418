import { useMutation, useQuery, useQueryClient } from "react-query";
import { axiosInstance as axios } from "../config";

//? Get Badges
export const getBadges = async (page = "index") => {
  try {
    const response = await axios.get(`/api/badges/${page}`);
    console.log("Response", response);
    return response.data.data; // Assuming the data you need is in the 'data' property
  } catch (error) {
    throw error;
  }
};

export const useBadges = (page) => {
  return useQuery(["badges", page], () => getBadges(page), {
    refetchOnWindowFocus: false,
  });
};

//? Add Badges
// Function to post a badge
export const postBadge = async (badgeData) => {
  try {
    console.log("badgeData", badgeData);
    const response = await axios.post(`/api/badges/add`, badgeData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Custom hook to use the postBadge function
export const usePostBadge = () => {
  const queryClient = useQueryClient();

  return useMutation(postBadge, {
    onSuccess: () => {
      // Invalidate and refetch badges after posting a new one
      queryClient.invalidateQueries("badges");
    },
  });
};
