import { useEffect, useState } from "react";
import { Box, InputBase } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import AddRoleModal from "./AddRoleModal";
import EditRoleModal from "./EditRoleModal";
import { fetchRoles } from "../../services/apis/roles-and-permissions";
import Header from "../../components/Header";
import { addNewRole } from "../../services/apis/roles-and-permissions";
import { DataGridStyle } from "../../styles";
import { Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import AddButton from "../../components/common/AddButton";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";

const ANR = ({ rolesdetials }) => {
  const [roles, setRoles] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentRole] = useState(null);
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const navigate = useNavigate();

  const handleClose = async () => {
    setOpen(false);
    getRoles();
  };

  const handleCloseEditModel = () => {
    setOpenEditModal(false);
    getRoles();
  };

  const getRoles = async () => {
    setLoading(true);
    setRoles([]);
    try {
      const response = await fetchRoles();
      const roles = response.data.data;
      setRoles(roles);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClick = (params) => {
    const id = params.row.id;
    const selectedRole = roles.find((role) => role.id === id);
    navigate(`/ViewRole/${id}`, { state: { rolesdetials: selectedRole } });
  };
  const rolesColumns = ItemsColumns([
    { field: "name", flex: 2 },
    { field: "permissions", specialRender: "permissions", flex: 4 },
    { field: "totalUsers", headerName: "Number of Users" },
    { field: "category" },
    {
      field: "is_built",
      headerName: "Type",
      specialRender: "customRender",
      renderFunction: (params) =>
        params?.row?.is_built === 0 ? "Created" : "Built in",
    },
    {
      field: "orderDetails",
      headerName: "Actions",
      actions: {
        view: (params) => handleClick(params),
      },
    },
  ]);

  const handleAddRole = () => {
    navigate(`/AddNewRole`);
  };

  // if (roles.length === 0) return <LoadingSpinner />;
  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Roles" marginL={"25px"} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          gap: 2,
          pr: 2,
        }}
      >
        <AddButton
          title={"+ New Role"}
          onClick={() => {
            handleAddRole();
          }}
        />
      </Box>

      <SecondBox>
        <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
          <Box sx={{ display: "flex", width: "50%", gap: 2, mb: 2, p: 2 }}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "600px",
                height: "48px",
                backgroundColor: "#F5F5F5",
                boxShadow: "none",
                borderRadius: "12px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Filter by Category or Type...."
              />
              {/* <FilterDrawer
                queryParameters={queryParameters}
                setQueryParameters={setQueryParameters}
                firstList={roles}
                firstKey="role_category"
                firstTitle="Role Category"
                secondTitle="Type"
                handleFirstCheckBox={handleUserFilterCheckbox}
                isRole={true}
              /> */}
            </Paper>
          </Box>

          <DataGrid
            rows={roles}
            columns={rolesColumns}
            loading={loading}
            autoHeight
            components={{
              Footer: () => null,
            }}
            // pageSize={10}
          />
        </Box>
        <Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <AddRoleModal handleClose={handleClose} addNewRole={addNewRole} />
          </Modal>

          <Modal open={openEditModal} onClose={handleCloseEditModel}>
            <EditRoleModal
              handleClose={handleCloseEditModel}
              role={currentRole}
            />
          </Modal>
        </Box>
      </SecondBox>

      <ReusableSnackbar handleClose={handleCloseSnack} />
    </Box>
  );
};

// const phoneRegExp =
//   /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export default ANR;
