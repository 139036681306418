import { useQuery } from "react-query";
import { axiosInstance as axios } from "../config";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";

const fetchSubjects = async () => {
  const response = await axios.get("/api/contactUsTypes/index");
  return response.data?.data; // Assuming you only want the data part of the response
};

export const useFetchSubjects = () => {
  const dispatch = useDispatch();

  return useQuery("subjects", fetchSubjects, {
    onError: (error) => {
      dispatch(
        setData({
          openSnack: true,
          message: error.response?.data?.message || "An error occurred",
        })
      );
    },
  });
};  

export const deleteSubject = async (id) => {
  try {
    const response = await axios.get(`/api/contactUsTypes/delete/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const editSubject = async (id, body) => {
  try {
    const response = await axios.post(`/api/contactUsTypes/edit/${id}`, body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const addNewSubject = async (body) => {
  try {
    const response = await axios.post("/api/contactUsTypes/add", body);
    return response;
  } catch (error) {
    throw error;
  }
};
