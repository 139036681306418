import { Typography } from "@mui/material";

export const FirstTypography = ({ title, extraSx }) => {
  return (
    <Typography
      sx={{
        ...extraSx,
        fontSize: "16px",
        fontWeight: "500",
        color: "#4C4C4C",
        marginBottom: "5px",
        marginTop: "15px",
      }}
    >
      {title}
    </Typography>
  );
};
