import { axiosInstance as axios } from "../config";

export const fetchArticles = async () => {
  try {
    const response = await axios.get(`/api/articles/index`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const deleteArticle = async (id) => {
  try {
    const response = await axios.get(`/api/articles/delete/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const addArticle = async (body) => {
  try {
    const response = await axios.post("/api/articles/add", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const editArticle = async (id, body) => {
  try {
    const response = await axios.post(`/api/articles/edit/${id}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
