import React from "react";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import ProductViewEdit from "./ProductViewEdit";
import ProductSerial from "./ProductSerial";
import { Box } from "@mui/material";

const ProductDetailsPage = () => {
  return (
    <Box sx={{
      overflowY:"auto",
      maxHeight:"calc(100vh)"
    }}>
      <DashboardMainHeader />
      <ProductViewEdit />
      <ProductSerial />
    </Box>
  );
};

export default ProductDetailsPage;
