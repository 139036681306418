import TextFieldComponent from "../../components/TextFieldComponent";
import { Box } from "@mui/material";

const StepOneNotification = ({ setFormData, formData }) => {
  const handleTransChange = (local, field, newValue) => {
    setFormData((prevState) => {
      const updatedTrans = prevState?.trans?.map((transItem) => {
        if (transItem?.local === local) {
          return { ...transItem, [field]: newValue };
        }
        return transItem;
      });
      return { ...prevState, trans: updatedTrans };
    });
  };
  return (
    <Box sx={{ marginTop: "3%" }}>
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Notification Title - English"
        placeholder="eg. New Items "
        value={
          formData?.trans?.find((transItem) => transItem.local === "en").title
        }
        onChange={(event) =>
          handleTransChange("en", "title", event.target.value)
        }
      />
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Notification - English"
        placeholder="Enter Notification"
        value={
          formData?.trans?.find((transItem) => transItem.local === "en").message
        }
        onChange={(event) =>
          handleTransChange("en", "message", event.target.value)
        }
      />
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Notification Title - Arabic"
        placeholder="eg. New Items "
        value={
          formData?.trans?.find((transItem) => transItem.local === "ar").title
        }
        onChange={(event) =>
          handleTransChange("ar", "title", event.target.value)
        }
      />
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Notification - Arabic"
        placeholder="Notification (Arabic)"
        value={
          formData?.trans?.find((transItem) => transItem.local === "ar").message
        }
        onChange={(event) =>
          handleTransChange("ar", "message", event.target.value)
        }
      />
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Notification Title - Egyptian Arabic"
        placeholder="eg. New Items "
        value={
          formData?.trans?.find((transItem) => transItem.local === "eg").title
        }
        onChange={(event) =>
          handleTransChange("eg", "title", event.target.value)
        }
      />
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Notification - Egyptian Arabic"
        placeholder="Enter Notification"
        value={
          formData?.trans?.find((transItem) => transItem.local === "eg").message
        }
        onChange={(event) =>
          handleTransChange("eg", "message", event.target.value)
        }
      />
    </Box>
  );
};
export default StepOneNotification;
