import React from "react";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import BadgeViewEdit from "./BadgeViewEdit";
import BadgeQuestions from "./BadgeQuestions";
import { Box } from "@mui/material";
import Header from "../../components/Header";
const AppBadgeDetails = () => {
  return (
    <Box
      sx={{
        overflowY: "auto",
        maxHeight: "calc(100vh)",
      }}
    >
      <DashboardMainHeader />
      <BadgeViewEdit />
      <Box
        sx={{
          marginTop: "2%",
          width: "93%",
          display: "flex",
          flexDirection: "column",
          marginInline: "auto",
        }}
      >
        <Header title="Questions" />
        <BadgeQuestions />
      </Box>
    </Box>
  );
};

export default AppBadgeDetails;
