import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { formDate } from "../../../services/helpers";

const IngotDetails = ({ open, onClose, value }) => {
  return (
    <Dialog
      maxWidth="700px"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          borderRadius: "24px",
        },
      }}
    >
      <DialogContent
        sx={{
          padding: "5% 5% 10% 10%",
          height: "auto",
          width: "700px",
          borderRadius: "24px",
          background: "#FFF",
          display: "flex",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <Box
          sx={{
            justifyContent: "center",
            height: "auto",
            width: "100%",
            borderRadius: "24px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignSelf: "center",
          }}
        >
          <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
            <IoIosCloseCircleOutline
              onClick={onClose}
              color="#4C4C4C"
              style={{
                width: "24px",
                height: "24px",
                cursor: "pointer",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                color: "#4C4C4C",
                fontWeight: "400",
                fontSize: "24px",
                display: "flex",
                alignSelf: "flex-start",
              }}
            >
              Ingot Details
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "50%",
              }}
            >
              <Typography
                sx={{ fontWeight: "500", fontSize: "14px", color: "#808080" }}
              >
                Product :
              </Typography>
              <Typography
                style={{
                  marginLeft: "10px",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#808080",
                }}
              >
                {value?.name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontWeight: "500", fontSize: "14px", color: "#808080" }}
              >
                Serial Number :
              </Typography>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#808080",
                  marginLeft: "10px",
                }}
              >
                {value?.serial}
              </Typography>
            </Box>
          </Box>
          <Box style={{ borderBottom: "1px solid #E0E0E0", width: "90%" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "50%",
              }}
            >
              <Typography
                sx={{ fontWeight: "500", fontSize: "14px", color: "#808080" }}
              >
                Weight :
              </Typography>
              <Typography
                style={{
                  marginLeft: "10px",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#808080",
                }}
              >
                {value?.weight} Gm
              </Typography>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontWeight: "500", fontSize: "14px", color: "#808080" }}
              >
                Merged Serial Number :
              </Typography>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#808080",
                  marginLeft: "10px",
                }}
              >
                {value?.serial}
              </Typography>
            </Box> */}
          </Box>
          <Box style={{ borderBottom: "1px solid #E0E0E0", width: "90%" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "50%",
              }}
            >
              <Typography
                sx={{ fontWeight: "500", fontSize: "14px", color: "#808080" }}
              >
                Ingot Price :
              </Typography>
              <Typography
                style={{
                  marginLeft: "10px",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#808080",
                }}
              >
                {value.sold_price}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontWeight: "500", fontSize: "14px", color: "#808080" }}
              >
                Date :
              </Typography>
              <Typography
                style={{
                  marginLeft: "10px",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#808080",
                }}
              >
                {formDate(value?.date)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default IngotDetails;
