import { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { withStyles } from "@mui/styles";
import { TabContext, TabPanel } from "@mui/lab";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import { DataGridStyle } from "../../../styles";
import Header from "../../../components/Header";
import FirstPendingTabPanel from "./FirstPendingTabPanel";
import SecondPendingTabPanel from "./SecondPendingTabPanel";
import ThirdPendingTabPanel from "./ThirdPendingTabPanel";
import { SecondBox } from "../../../components/common/CustomBox";

const PendingOrders = () => {
  const [value, setValue] = useState("1");

  const AntTabs = withStyles({
    root: {
      borderBottom: "none",
    },
    indicator: {
      display: "none",
    },
  })(Tabs);
  const AntTab = withStyles((theme) => ({
    root: {
      "&:hover": {
        color: "#00303C",
        opacity: 1,
      },
      "&$selected": {
        color: "#ffffff",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        borderBottom: "none",
      },
      "&:focus": {
        color: "#00303C",
      },
    },
    selected: {
      "&$selected": {
        borderBottom: "none",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        color: "#ffffff !important",
      },
    },
  }))((props) => <Tab disableRipple {...props} />);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Pending Orders" marginL={"25px"} />
      <SecondBox>
        <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
          <TabContext value={value}>
            <Box
              sx={{
                width: "100%",
                paddingTop: "5%",
                paddingBottom: "1%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <AntTabs
                sx={{
                  backgroundColor: "#F5F6FF",
                  borderRadius: "9px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                value={value}
                onChange={handleChange}
                aria-label="ant example"
              >
                <AntTab sx={{ width: "345px" }} label="All" value="1" />
                <AntTab sx={{ width: "345px" }} label="Gold Ingots" value="2" />
                <AntTab sx={{ width: "345px" }} label="Fractions" value="3" />
              </AntTabs>
            </Box>
            <TabPanel sx={{ marginLeft: "4%" }} value="1">
              <FirstPendingTabPanel />
            </TabPanel>
            <TabPanel sx={{ marginLeft: "4%" }} value="2">
              <SecondPendingTabPanel />
            </TabPanel>
            <TabPanel sx={{ marginLeft: "4%" }} value="3">
              <ThirdPendingTabPanel />
            </TabPanel>
          </TabContext>
        </Box>
      </SecondBox>
      {/* <AddNewAdminModal
        onAddAdmin={getAdmins}
        open={openDialog}
        onClose={handleClose}
        label={"Save"}
      /> */}
    </Box>
  );
};

export default PendingOrders;
