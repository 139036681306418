import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Typography, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

const KycCard = ({ img, title }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card sx={{ width: 326, height: 197 }}>
      <CardMedia
        component="img"
        sx={{ height: 140, cursor: "pointer" }}
        title={title}
        image={img}
        onClick={handleClickOpen}
      />
      <CardContent>
        <Typography
          sx={{
            color: "#595959",
            fontFamily: "Lato",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 500,
          }}
        >
          {title}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            boxShadow: "none",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "600px",
            height: "778px",
            padding: "0px",
            overflow: "hidden",
            backgroundColor: "transparent",
          }}
        >
          <img
            src={img}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
            alt="kyc"
          />
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default KycCard;
