import React from "react";
import { Typography, Box } from "@mui/material";
import { changeStatusColor } from "../../services/utils/stylesModifiers";

const UserInfoField = ({ title, data, ...props }) => {
  const style = {
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    color: "#808080",
    alignSelf: "center",
  };
  const statusStyle = {
    background: "",
    font: "",
    circle: "",
  };

  changeStatusColor(statusStyle, data);
  // const md = capitalizeFirstLetter(data)
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        width: title === "KYC Status" ? "100%" : "85%",
      }}
    >
      {/* 595959 */}
      <Typography sx={style}>{title} :</Typography>
      {title !== "KYC Status" && (
        <Typography
          sx={{
            fontFamily: "Lato",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            color:
              data === "Blocked"
                ? "#CF2424"
                : data === "Active"
                ? "#2492CF"
                : "#808080",
            alignSelf: "center",
            ...props,
          }}
        >
          {data}
        </Typography>
      )}

      {title === "KYC Status" && (
        <Box
          sx={{
            display: "flex",
            alignItem: "center",
            gap: 1,
            height: "37px",
            width: "10%",
            justifyContent: "center",
            borderRadius: "100px",
            background: statusStyle.background,
            ...props,
            // alignSelf: "stretch",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="8"
              viewBox="0 0 9 8"
              fill="none"
            >
              <circle cx="4.22852" cy="4" r="4" fill={statusStyle.circle} />
            </svg>
          </Box>

          <Typography
            sx={{
              alignSelf: "center",
              color: statusStyle.font,
              fontFamily: "Lato",
              fontStyle: "normal",
              fontSize: "12px",
              fontWeight: 400,
            }}
          >
            {data}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default UserInfoField;
