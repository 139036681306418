import TextFieldComponent from "../../components/TextFieldComponent";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import CustomTextArea from "../../components/CustomTextArea";

const StepTwoSubQuestion = ({ setFaqData, faqData, setIsValid }) => {
  const { subQuestions: SubQuestions } = faqData;
  const handleSubQuestionChange = (index, field, fieldType, value) => {
    setFaqData((prevFaqData) => ({
      ...prevFaqData,
      subQuestions: prevFaqData.subQuestions.map((subQuestion, i) => {
        if (i === index) {
          return {
            ...subQuestion,
            trans: subQuestion.trans.map((translation) => {
              if (translation.local === field) {
                return {
                  ...translation,
                  [fieldType]: value,
                };
              }
              return translation;
            }),
          };
        }
        return subQuestion;
      }),
    }));
  };

  useEffect(() => {
    const result = SubQuestions?.map((item) => {
      if (item?.trans[1]?.question !== "" && item?.trans[1]?.answer !== "") {
        return true;
      } else {
        return false;
      }
    });
    if (!result?.includes(false)) {
      setIsValid([true, true, false]);
    } else {
      setIsValid([true, false, false]);
    }
  }, [SubQuestions, setIsValid]);
  return (
    <Box sx={{ marginTop: "3%" }}>
      <Box>
        <Box>
          {faqData.subQuestions.map((subQuestion, index) => (
            <Box key={index}>
              <TextFieldComponent
                sx={{ "& fieldset": { border: "none" } }}
                style={{
                  height: "48px",
                  width: "100%",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                }}
                name={`Sub Question ${index + 1} (Arabic)`}
                placeholder={`Enter Sub Question ${index + 1} (Arabic)`}
                value={
                  faqData.subQuestions[index].trans.find(
                    (t) => t.local === "ar"
                  ).question
                }
                onChange={(event) =>
                  handleSubQuestionChange(
                    index,
                    "ar",
                    "question",
                    event.target.value
                  )
                }
              />

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#4C4C4C",
                    marginBottom: "5px",
                    marginTop: "15px",
                  }}
                >
                  Answer (Arabic)
                </Typography>
                <CustomTextArea
                  style={{
                    minHeight: "80px",
                    maxHeight: "200px",
                    maxWidth: "100%",
                    minWidth: "100%",
                    backgroundColor: "#FFF",
                    borderRadius: "9px",
                    boxShadow: "none",
                    border: "1px solid #E9C237",
                  }}
                  name="Answer"
                  placeholder="Enter Answer"
                  value={
                    faqData.subQuestions[index].trans.find(
                      (t) => t.local === "ar"
                    ).answer
                  }
                  onChange={(event) => {
                    const disallowEnglishRegex = /^[^a-zA-Z]*$/;
                    if (
                      event.target.value === "" ||
                      disallowEnglishRegex.test(event.target.value)
                    ) {
                      handleSubQuestionChange(
                        index,
                        "ar",
                        "answer",
                        event.target.value
                      );
                    }
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
export default StepTwoSubQuestion;
