import { useQuery } from "react-query";
import { axiosInstance as axios } from "../config";

export const fetchInventoryRequest = async ({ page, orderBy, sort, type }) => {
  const response = await axios.get("/api/inventory/index", {
    params: {
      page,
      orderBy,
      sort,
      type,
    },
  });
  return response.data;
};

export const useFetchInventoryRequest = (params) => {
  return useQuery(
    ["inventoryRequest", params],
    () => fetchInventoryRequest(params),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true, // Optional: Useful for paginated data
    }
  );
};
export const fetchInventoryStock = async ({ page, orderBy, sort, type }) => {
  const response = await axios.get("/api/inventory/stock", {
    params: {
      page,
      orderBy,
      sort,
      type,
    },
  });
  return response.data;
};

export const useFetchInventoryStock = (params) => {
  return useQuery(
    ["inventoryStock", params],
    () => fetchInventoryStock(params),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true, // Optional: Useful for paginated data
    }
  );
};
export const fetchInventoryRequestSerials = async (id, body) => {
  try {
    const response = await axios.get(`/api/inventory/getSerialsByOrder/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const addNewInventoryRequest = async (body) => {
  try {
    const response = await axios.post("/api/inventory/addToInventory", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const changeInventoryRequestStatus = async (body) => {
  try {
    const response = await axios.post("/api/inventory/changeStatus", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const mergeFractions = async (body) => {
  try {
    const response = await axios.post("/api/inventory/mappingSerial", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const sellFraction = async (body) => {
  try {
    const response = await axios.post("/api/inventory/sell", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchSoldIngots = async ({ page }) => {
  const response = await axios.get("/api/inventory/soldFraction", {
    params: {
      page,
    },
  });
  return response;
};
export const useFetchSoldIngots = (params) => {
  return useQuery(["fetch_SoldIngots", params], () => fetchSoldIngots(params), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
export const fetchSellFractionsIngot = async (q) => {
  try {
    const response = await axios.get(
      `/api/inventory/currentInventoryFraction`,
      {
        params: {
          page: q.page,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const useFetchSellFractionsIngot = (params) => {
  return useQuery(
    ["fetch_SellFractionsIngot", params],
    () => fetchSellFractionsIngot(params),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};
