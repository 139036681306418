import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";

function UploadImage({ setImage, setFile, image, handleChange }) {
  const [inputKey] = useState(0);
  const fileInputRef = useRef();

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setFile(file);

    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileInputChange = (event) => {
    handleChange(event);
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      {!image && (
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          style={{
            marginTop: "5px",
            width: "100%",
            height: "53px",
            border: "2px dashed #ccc",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            flexDirection: "row",
            borderRadius: "12px",
            background: "rgba(204, 204, 204, 0.3)",
          }}
        >
          <img src={`../../assets/export.png`} alt="" />
          <Box sx={{ display: "flex" }}>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileInputChange}
            />

            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileInputChange}
              key={inputKey}
            />
            {!image && (
              <Button
                sx={{
                  color: "#E9C237",
                  textDecoration: "underLine",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
                onClick={handleUploadButtonClick}
              >
                click to upload
              </Button>
            )}
            {image ? (
              <img src={image} alt="Dropped" height="152px" width="100%" />
            ) : (
              <p
                style={{
                  marginTop: "9px",
                  color: "#808080",
                  fontSize: "12px",
                }}
              >
                or drag and drop
              </p>
            )}
          </Box>
          <Typography
            sx={{
              color: "#CCCCCC",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              fontFamily: "Roboto",
              marginLeft: "5px",
            }}
          >
            {"  "}
            SVG, PNG, JPG or GIF (max. 3MB)
          </Typography>
        </div>
      )}
    </>
  );
}

export default UploadImage;
