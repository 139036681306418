import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";

const StepTwoComponent = ({
  onCheckboxChange,
  resource,
  roleData,
  setRoleData,
  setSelectedCheckboxName,
}) => {
  const handleCheckboxChange = (page, item) => {
    const titleId = item.id;
    let updatedSelectedPages = [...roleData.pages];
    if (roleData.pages.includes(page.id)) {
      updatedSelectedPages = updatedSelectedPages.filter(
        (selectedPage) => selectedPage !== page.id
      );
      const remainingSidebarPages = updatedSelectedPages.filter(
        (selectedPage) => selectedPage !== titleId
      );

      if (remainingSidebarPages.length === 0) {
        updatedSelectedPages = updatedSelectedPages.filter(
          (selectedPage) => selectedPage !== titleId
        );
      }
    } else {
      updatedSelectedPages = [...updatedSelectedPages, page.id];
      if (!updatedSelectedPages.includes(titleId)) {
        updatedSelectedPages.push(titleId);
      }
    }
    setRoleData((prevData) => ({
      ...prevData,
      pages: updatedSelectedPages,
    }));
    const selectedCheckboxNames = resource
      .map((resourceItem) => {
        const isSelectedTitle =
          updatedSelectedPages.includes(resourceItem.id) ||
          updatedSelectedPages.some((id) =>
            resourceItem.sidebarPages.map((p) => p.id).includes(id)
          );
        const sidebarPageTitles = resourceItem.sidebarPages
          .filter((p) => updatedSelectedPages.includes(p.id))
          .map((p) => p.title);
        return isSelectedTitle
          ? [resourceItem.title, ...sidebarPageTitles]
          : [];
      })
      .flat();
    if (onCheckboxChange) {
      onCheckboxChange(updatedSelectedPages);
    }

    if (setSelectedCheckboxName) {
      setSelectedCheckboxName(selectedCheckboxNames);
    }
  };
  const handleTitleCheckboxChange = (item) => {
    if (item?.sidebarPages && item.sidebarPages.length > 0) {
      const allPageIds = item.sidebarPages.map((page) => page.id);
      const shouldCheck = allPageIds.some((id) => roleData.pages.includes(id));

      let updatedSelectedPages = shouldCheck
        ? roleData.pages.filter((id) => !allPageIds.includes(id))
        : [...roleData.pages, ...allPageIds];

      if (shouldCheck) {
        const titleIdIndex = updatedSelectedPages.indexOf(item.id);
        if (titleIdIndex !== -1) {
          updatedSelectedPages.splice(titleIdIndex, 1);
        }
      } else {
        updatedSelectedPages.push(item.id);
      }

      setRoleData((prevData) => ({
        ...prevData,
        pages: updatedSelectedPages,
      }));

      const selectedCheckboxNames = resource
        .map((rItem) =>
          rItem.sidebarPages
            .filter((p) => updatedSelectedPages.includes(p.id))
            .map((p) => p.title)
        )
        .flat();

      if (onCheckboxChange) {
        onCheckboxChange(updatedSelectedPages);
      }

      if (setSelectedCheckboxName) {
        setSelectedCheckboxName((prevNames) => {
          const updatedNames = shouldCheck
            ? prevNames.filter((name) => name !== item.title)
            : prevNames.filter(
                (name) =>
                  name !== item.title && !selectedCheckboxNames.includes(name)
              );
          if (!shouldCheck) {
            updatedNames.push(item.title);
          }
          const filteredNames = updatedNames.filter(
            (name) =>
              !resource
                .find((r) => r.title === item.title)
                ?.sidebarPages.find((p) => p.title === name)
          );
          const newNames = [...filteredNames, ...selectedCheckboxNames];

          return [...new Set(newNames)];
        });
      }
    } else {
      const shouldCheck = roleData.pages.includes(item.id);
      const updatedSelectedPages = shouldCheck
        ? roleData.pages.filter((selectedPage) => selectedPage !== item.id)
        : [...roleData.pages, item.id];

      setRoleData((prevData) => ({
        ...prevData,
        pages: updatedSelectedPages,
      }));

      const selectedCheckboxNames = resource
        .filter((resourceItem) => {
          if (resourceItem.sidebarPages.length === 0) {
            return true;
          }

          if (updatedSelectedPages.includes(resourceItem.id)) {
            return true;
          }

          const sidebarPageTitles = resourceItem.sidebarPages
            .filter((p) => updatedSelectedPages.includes(p.id))
            .map((p) => p.title);

          if (sidebarPageTitles.length > 0) {
            return true;
          }

          return false;
        })
        .map((resourceItem) => {
          if (updatedSelectedPages.includes(resourceItem.id)) {
            return resourceItem.title;
          }

          return resourceItem.sidebarPages
            .filter((p) => updatedSelectedPages.includes(p.id))
            .map((p) => p.title);
        })
        .flat();

      if (onCheckboxChange) {
        onCheckboxChange(updatedSelectedPages);
      }
      if (setSelectedCheckboxName) {
        setSelectedCheckboxName((prevNames) => {
          const updatedNames = shouldCheck
            ? prevNames.filter((name) => name !== item.title)
            : prevNames.filter(
                (name) =>
                  name !== item.title &&
                  !selectedCheckboxNames.includes(name) &&
                  !resource
                    .filter((r) => r.sidebarPages.length > 0)
                    .some((r) =>
                      r.sidebarPages.some((p) =>
                        selectedCheckboxNames.includes(p.title)
                      )
                    )
              );
          if (!shouldCheck) {
            updatedNames.push(item.title);
          }
          const newNames = [...updatedNames, ...selectedCheckboxNames];

          return [...new Set(newNames)];
        });
      }
    }
  };
  return (
    <Box sx={{ marginTop: "3%" }}>
      {resource.map((item) => (
        <Box sx={{ marginTop: "1%" }} key={item.id}>
          <Typography
            sx={{ color: "#4C4C4C", fontSize: "23px", fontWeight: "500" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    "&.Mui-checked": {
                      color: "#E9C237",
                    },
                    "&.MuiCheckbox-root:not(.Mui-checked)": {
                      color: "#4C4C4C",
                    },
                  }}
                  checked={
                    item.sidebarPages.length > 0
                      ? item.sidebarPages.some((page) =>
                          roleData.pages.includes(page.id)
                        )
                      : roleData.pages.includes(item.id)
                  }
                  onChange={() => handleTitleCheckboxChange(item)}
                />
              }
              label={
                <span style={{ fontWeight: "600", fontSize: "20px" }}>
                  {item.title}
                </span>
              }
            />
          </Typography>
          {item?.sidebarPages && (
            <>
              {item?.sidebarPages.map((page) => (
                <FormControlLabel
                  key={page.id}
                  sx={{ marginTop: "10px", marginLeft: "15px" }}
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: "#E9C237",
                        },
                        "&.MuiCheckbox-root:not(.Mui-checked)": {
                          color: "#4C4C4C",
                        },
                      }}
                      checked={roleData.pages.includes(page.id)}
                      onChange={() => handleCheckboxChange(page, item)}
                    />
                  }
                  label={page.title}
                />
              ))}
            </>
          )}
          <Divider sx={{ my: 1 }} />
        </Box>
      ))}
    </Box>
  );
};
export default StepTwoComponent;
