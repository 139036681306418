import React from "react";
import { Box } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const KycModalBody = React.forwardRef((props, ref) => {
  return (
    <Box sx={style}>
      <img
        src={props.src}
        alt=""
        height="100%"
        width="100%"
        crossOrigin="anonymous"
      />
    </Box>
  );
});

export default KycModalBody;
