import React from "react";
import { Button, Box, FormControl } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import TextFieldComponent from "../../components/TextFieldComponent";
import { editPaymentInfo } from "../../services/apis/paymentInfo";
import { useEffect } from "react";
import CustomDialog from "../../components/CustomDialog";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";

const PaymentInfoDialog = ({
  openEditDialog,
  handleCloseAlert,
  initialValues,
  label,
  setBankPayment,
  fetchData,
}) => {
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const dispatch = useDispatch()
    const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const [bankPayload, setBankPayload] = useState({
    id: 3,
    meta: {
      accountName: "",
      bankName: "",
      IBAN: "",
      swiftCode: "",
    },
  });
  const resetForm = () => {
    setBankPayload({
      id: 3,
      meta: {
        accountName: initialValues.accountName || "",
        bankName: initialValues.bankName || "",
        IBAN: initialValues.IBAN || "",
        swiftCode: initialValues.swiftCode || "",
      },
    });
  };
  const handleClose = () => {
    resetForm();
    handleCloseAlert();
  };
  useEffect(() => {
    setBankPayload((prevPayload) => ({
      ...prevPayload,
      meta: {
        ...prevPayload.meta,
        ...initialValues,
      },
    }));
  }, [initialValues]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await editPaymentInfo(bankPayload);
      handleCloseAlert();
      setSuccessDialogOpen(true);
      const response = await fetchData();
      const data = response?.data?.data;
      const bankPaymentObject = data.length > 0 ? [data[2]] : [];
      setBankPayment(bankPaymentObject);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
  };
  return (
    <>
      <Dialog
        open={openEditDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "600px",
            height: "auto",
            borderRadius: "24px",
            paddingTop: "10%",
            paddingBottom: "10%",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box sx={{ color: "#000000", fontWeight: "400", fontSize: "24px" }}>
              Bank account
            </Box>
            <FormControl sx={{ display: "flex" }}>
              <TextFieldComponent
                name="Account name"
                placeholder="Enter Account name"
                value={bankPayload.meta.accountName}
                onChange={(event) => {
                  var temppayload = { ...bankPayload };
                  temppayload.meta.accountName = event.target.value;
                  setBankPayload({ ...temppayload });
                }}
              />
              <TextFieldComponent
                name="Bank name"
                placeholder="Enter Bank Name"
                value={bankPayload.meta.bankName}
                onChange={(event) => {
                  var temppayload = { ...bankPayload };
                  temppayload.meta.bankName = event.target.value;
                  setBankPayload({ ...temppayload });
                }}
              />
              <TextFieldComponent
                name="IBAN"
                placeholder="Enter IBAN"
                value={bankPayload.meta.IBAN}
                onChange={(event) => {
                  var temppayload = { ...bankPayload };
                  temppayload.meta.IBAN = event.target.value;
                  setBankPayload({ ...temppayload });
                }}
              />
              <TextFieldComponent
                name="Swift code"
                placeholder="Enter Swift Code"
                value={bankPayload.meta.swiftCode}
                onChange={(event) => {
                  var temppayload = { ...bankPayload };
                  temppayload.meta.swiftCode = event.target.value;
                  setBankPayload({ ...temppayload });
                }}
              />
            </FormControl>

            <Box sx={{ display: "flex", gap: 2, marginTop: "10px" }}>
              <Button
                variant="contained"
                sx={{
                  width: "200px",
                  borderRadius: "6px",
                  background: "#E9C237",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#E9C237",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: "200px",
                  borderRadius: "6px",
                  height: "48px",
                  color: "#E9C237",
                  fontWeight: 600,
                  borderColor: "#E9C237",
                  "&:hover": {
                    borderColor: "#E9C237",
                  },
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Payment information has been
        updated Successfully."
        message=""
        buttonText="Go to Payment information"
        buttonLink="/paymentInfo"
      />
      <ReusableSnackbar
                 handleClose={handleCloseSnack}

      />
    </>
  );
};

export default PaymentInfoDialog;
