import { useState } from "react";
import TextFieldComponent from "../../components/TextFieldComponent";
import {
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { fetchCategory } from "../../services/apis/roles-and-permissions";
import { useEffect } from "react";
import AddNewCategoryModal from "./AddNewCategoryModal";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { MenuProps } from "../../components/common/MenuProps";

const StepOneComponent = ({ setRoleData, setSelectedCategoryName }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const [newRolePayload, setNewRolePayload] = useState({
    name: "",
    description: "",
  });
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const getCategory = async () => {
    try {
      const response = await fetchCategory();
      const categoryData = await response.data.data;
      setCategory(categoryData);
      return categoryData;
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
  };
  const handleClose = async () => {
    setOpenDialog(false);
  };
  const handleOpen = async () => {
    setOpenDialog(true);
  };
  useEffect(() => {
    getCategory();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (setRoleData) {
      setRoleData((prevData) => ({
        ...prevData,
        name: newRolePayload.name,
        user_group_type_id: selectedCategory ? selectedCategory.id : "",
        description: newRolePayload.description,
      }));
    }
    if (setSelectedCategoryName) {
      setSelectedCategoryName(selectedCategory ? selectedCategory.name : "");
    }
  }, [newRolePayload, selectedCategory, setRoleData, setSelectedCategoryName]);

  return (
    <Box sx={{ marginTop: "3%" }}>
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Enter Role Name"
        placeholder="eg. Admin "
        value={newRolePayload.name}
        onChange={(event) => {
          var temppayload = { ...newRolePayload };
          temppayload.name = event.target.value;
          setNewRolePayload({ ...temppayload });
        }}
      />
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "500",
          color: "#4C4C4C",
          marginBottom: "5px",
          marginTop: "15px",
        }}
      >
        Select Role Category
      </Typography>
      <FormControl sx={{ width: "100%" }}>
        <Select
          displayEmpty
          value={selectedCategory || ""}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return <em>Select Role Category</em>;
            }
            return selected?.name;
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          {category.map((item) => (
            <MenuItem key={item.id} value={item}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          marginTop: "15px",
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            backgroundColor: "rgba(233, 194, 55, 0.1)",
            width: "20px",
            height: "20px",
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "5px",
          }}
        >
          <img
            style={{ alignSelf: "center", display: "flex" }}
            alt="search-normal"
            src={`../../assets/plusiconnew.png`}
          />
        </Box>
        <Typography
          onClick={handleOpen}
          sx={{
            marginLeft: "10px",
            color: "#E9C237",
            fontWeight: "400",
            fontSize: "14px",
          }}
        >
          Add new category
        </Typography>
      </Box>
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Enter Role Description"
        placeholder="Enter Role Description"
        value={newRolePayload.description}
        onChange={(event) => {
          var temppayload = { ...newRolePayload };
          temppayload.description = event.target.value;
          setNewRolePayload({ ...temppayload });
        }}
      />
      <AddNewCategoryModal
        onAddCategory={getCategory}
        setSelectedCategoryName={setSelectedCategoryName}
        open={openDialog}
        onClose={handleClose}
        label={"Add"}
      />
      <ReusableSnackbar handleClose={handleCloseSnack} />
    </Box>
  );
};
export default StepOneComponent;
