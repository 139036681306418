import { IconButton, InputBase, Paper } from "@mui/material";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { useDeletedUsers } from "../../services/apis/users";
import { useNavigate } from "react-router-dom";

import { FirstBox, SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";

const DeletedUsers = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useDeletedUsers();
  const deletedUsersColumns = ItemsColumns([
    { field: "full_name", headerName: "Username" },
    { field: "deleted_at", headerName: "Delete Date", date: true },
    { field: "phone", headerName: "Phone number" },
    { field: "created", headerName: "Join Date", date: true },
    { field: "kyc_status", headerName: "KYC", kyc: true },
    {
      field: "viewUser",
      headerName: "Actions",
      actions: {
        view: (params) => {
          navigate(`/profile/${params.row.id}`);
        },
      },
    },
  ]);

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Deleted Users" marginL={"20px"} />
      <SecondBox>
        <FirstBox>
          <Box sx={{ display: "flex", width: "50%", gap: 2, mb: 2, p: 2 }}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "600px",
                height: "48px",
                backgroundColor: "#F5F5F5",
                boxShadow: "none",
                borderRadius: "12px",
              }}
              onSubmit={(e) => e.preventDefault()}
            >
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <img
                  alt="search-normal"
                  src={`../../assets/search-normal.png`}
                />
              </IconButton>
              <InputBase
                // onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Email or Phone number ....."
              />
            </Paper>
          </Box>
          <DataGrid
            hideFooterSelectedRowCount={true}
            rows={data?.data.length > 0 ? data?.data : []}
            columns={deletedUsersColumns}
            loading={isLoading}
            autoHeight={true}
            components={{
              Footer: () => null,
            }}
            disableColumnMenu={true}
          />
        </FirstBox>
      </SecondBox>
    </Box>
  );
};

export default DeletedUsers;
