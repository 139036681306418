import React from "react";
import { Button, Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import { useEffect } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import TextFieldComponent from "../../components/TextFieldComponent";
import { addNewBank, editBank } from "../../services/apis/banks";
import CustomDialog from "../../components/CustomDialog";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";

const AddBankDialog = ({
  open,
  onClose,
  label,
  fetchData,
  handleResponse,
  bankId,
  banks,
  functypeparams,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const bankToEdit = banks.find((bank) => bank.id === bankId);
  const [banksPayload, setBanksPayload] = useState({
    name_ar: "",
    name_en: "",
  });
  const resetForm = () => {
    setBanksPayload({
      name_ar: "",
      name_en: "",
    });
    onClose();
  };
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const handleCloseDialog = () => {
    resetForm();
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (functypeparams === "edit") {
        if (!banksPayload.name_ar || !banksPayload.name_en) {
          dispatch(
            setData({
              openSnack: true,
              message: "Please fill in all the fields.",
            })
          );
        } else {
          setIsSubmitting(true);
          await editBank(bankId, banksPayload);
          onClose();
          setSuccessDialogOpen(true);
          await handleResponse();
        }
      } else {
        if (!banksPayload.name_ar || !banksPayload.name_en) {
          dispatch(
            setData({
              openSnack: true,
              message: "Please fill in all the fields.",
            })
          );
        } else {
          setIsSubmitting(true);
          await addNewBank(banksPayload);
          onClose();
          setSuccessDialogOpen(true);
          await handleResponse();
        }
      }
    } catch (error) {
      if (error.response.status === 422) {
        const validationErrors = error.response.data.validation;
        const nameEnError = validationErrors.name_en;
        const nameArError = validationErrors.name_ar;

        let errorMessage = "";
        if (nameEnError) {
          errorMessage += `${nameEnError}`;
        } else if (nameArError) {
          errorMessage += `${nameArError}`;
        }
        dispatch(
          setData({
            openSnack: true,
            message: errorMessage,
          })
        );
      } else {
        dispatch(
          setData({
            openSnack: true,
            message: error?.data?.data?.message,
          })
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    if (functypeparams === "edit") {
      setBanksPayload({
        name_ar: bankToEdit?.name_ar,
        name_en: bankToEdit?.name_en,
      });
    } else {
      setBanksPayload({
        name_ar: "",
        name_en: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, fetchData, bankId]);
  return (
    <>
      <Dialog
        maxWidth="721px"
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "600px",
            height: "auto",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            paddingTop: "5%",
            paddingBottom: "8%",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              width: "80%",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={handleCloseDialog}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  color: "#000000",
                  fontWeight: "400",
                  fontSize: "24px",
                  display: "flex",
                  alignSelf: "flex-start",
                }}
              >
                {functypeparams === "edit" ? "Edit Bank" : "Add New Bank"}
              </Box>
            </Box>
            <TextFieldComponent
              sx={{ "& fieldset": { border: "none" } }}
              style={{
                height: "48px",
                width: "465px",
                backgroundColor: "#F7F7F7",
                borderRadius: "9px",
              }}
              name="Bank name - English"
              placeholder="eg. QNB"
              value={banksPayload.name_en}
              onChange={(event) => {
                var temppayload = { ...banksPayload };
                temppayload.name_en = event.target.value;
                setBanksPayload({ ...temppayload });
              }}
            />
            <TextFieldComponent
              sx={{ "& fieldset": { border: "none" } }}
              style={{
                height: "48px",
                width: "465px",
                backgroundColor: "#F7F7F7",
                borderRadius: "9px",
              }}
              name="Bank name - Arabic"
              placeholder="eg. بنك مصر"
              value={banksPayload.name_ar}
              onChange={(event) => {
                const disallowEnglishRegex = /^[^a-zA-Z]*$/;
                if (
                  event.target.value === "" ||
                  disallowEnglishRegex.test(event.target.value)
                ) {
                  var temppayload = { ...banksPayload };
                  temppayload.name_ar = event.target.value;
                  setBanksPayload({ ...temppayload });
                }
              }}
            />

            <Box sx={{ display: "flex", gap: 2, marginTop: "10px" }}>
              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  background: "#E9C237",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#E9C237",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#E9C237",
                  fontWeight: 600,
                  borderColor: "#E9C237",
                  "&:hover": {
                    borderColor: "#E9C237",
                  },
                }}
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title={
          functypeparams === "edit"
            ? "Bank has been edited Successfully."
            : "Bank has been added Successfully."
        }
        message=""
        buttonText="Go to Banks and Branches"
      />
      <ReusableSnackbar handleClose={handleCloseSnack} />
    </>
  );
};

export default AddBankDialog;
