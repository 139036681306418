import React, { useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import SiderbarItem from "./SidebarItem";
import CustomIcon from "./CustomIcon";
import { Box } from "@mui/material";

const SideBarDropdown = ({
  section,
  selected,
  setSelected,
  isCollapsed,
  icons,
}) => {
  const [open, setOpen] = useState(false);
  const handleClick = (e) => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton
        key={section?.title}
        onClick={() => {
          handleClick(section?.title);
        }}
        sx={{
          display: isCollapsed ? "flex" : "",
          justifyContent: isCollapsed ? "center" : "",
        }}
      >
        <CustomIcon iconName={section?.icon} />
        <ListItemText
          primary={isCollapsed ? "" : section?.title}
          sx={{
            color: "#4C4C4C",
            marginLeft: "15px",
            display: isCollapsed ? "none" : "block",
          }}
        />
        {!isCollapsed &&
          (open ? (
            <ExpandLess sx={{ color: "#4C4C4C" }} />
          ) : (
            <ExpandMore sx={{ color: "#4C4C4C" }} />
          ))}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {section?.pages?.length !== 0 && (
          <List component="div" disablePadding>
            {section?.pages?.map((page) => (
              <Box key={page?.id}>
                <SiderbarItem
                  title={page?.title}
                  to={page?.to}
                  icon={
                    <CustomIcon
                      title={page?.title}
                      selected={selected}
                      iconName={page?.icon}
                    />
                  }
                  selected={selected}
                  setSelected={setSelected}
                />
              </Box>
            ))}
          </List>
        )}
      </Collapse>
    </>
  );
};

export default SideBarDropdown;
