import { useState } from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "../../styles";
import ActionAlert from "../user-profile/ActionAlert";
import CustomDialog from "../../components/CustomDialog";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { handlePageChange } from "../../services/utils/filters";
import { useNavigate } from "react-router-dom";
import {
  deleteTicketQuestion,
  useTicketQuestions,
} from "../../services/apis/tickets";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import newEye from "../../svg/neweye.svg";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import AddButton from "../../components/common/AddButton";
import {
  DeleteSvgWithFunc,
  EditSvgWithFunc,
} from "../../components/common/SvgWithFunc";
import { ItemsColumns } from "../../components/common/Columns";
const TicketQuestions = () => {
  const [disableWhileDelete, setDisableWhileDelete] = useState(false);

  const [selectedSubjectId, setSelectedSubjectId] = useState(null);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [openDeletedAlert, setOpenDeletedAlert] = useState(false);
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
  });
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const { data, isLoading, refetch } = useTicketQuestions(queryParameters);

  const navigate = useNavigate();
  const handleAddSubject = () => {
    navigate(`/AddNewTicketQuestion`);
  };
  const handleOpenDeleteAlert = (id) => {
    setSelectedSubjectId(id);
    setOpenDeletedAlert(true);
  };

  const handleDeleteSubject = async () => {
    if (selectedSubjectId) {
      setDisableWhileDelete(true);
      try {
        await deleteTicketQuestion(selectedSubjectId);
        setDisableWhileDelete(false);
        setSuccessDialogOpen(true);
        handleCloseDeleteAlert();
        refetch(queryParameters);
      } catch (error) {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      }
    }
  };
  const handleCloseDeleteAlert = () => {
    setOpenDeletedAlert(false);
  };
  const getRowId = (row) => row.id;
  const subjectColumns = ItemsColumns([
    { field: "sort", headerName: "Order" },
    {
      field: "Question",
      specialRender: "customRender",
      renderFunction: (params) => params?.row?.trans?.en?.question,
    },
    { field: "subQuestionsCount", headerName: "No. Sub Questions" },
    { field: "subject" },
    {
      field: "action",
      specialRender: "customRender",
      renderFunction: (params) => {
        return (
          <Box sx={{ display: "flex", gap: 2 }}>
            {params.row.subQuestionsCount > 0 && (
              <Box sx={{ display: "flex", gap: 2 }}>
                <img
                  style={{ cursor: "pointer" }}
                  src={newEye}
                  alt="eye"
                  onClick={() => handleSubQuestionsDetails(params)}
                />
              </Box>
            )}
            <Box>
              <EditSvgWithFunc onClick={() => handleEditSubject(params)} />
            </Box>
            <Box>
              <DeleteSvgWithFunc
                onClick={() => handleOpenDeleteAlert(params.row.id)}
              />
            </Box>
          </Box>
        );
      },
    },
  ]);

  const handleEditSubject = (params) => {
    const id = params.row.id;
    const TicketQuestion = params.row;
    navigate(`/EditTicketQuestion/${id}`, {
      state: { selectedTicketData: TicketQuestion },
    });
  };

  const handleSubQuestionsDetails = (params) => {
    const id = params.row.id;
    const selectedid = data?.result.find((subjectid) => subjectid.id === id);
    navigate(`/SubQuestionsDetails/${id}`, {
      state: { id: selectedid },
    });
  };

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Ticket Questions" marginL={"25px"} />
      <Box
        m="20px"
        sx={{
          height: "auto",
          background: "#FFF",
          borderRadius: "12px",
          boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Box m="40px 0 0 0" height="100%" sx={DataGridStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              gap: 2,
              pr: 2,
              mt: "3%",
            }}
          >
            <AddButton
              title={"+ New Question"}
              secondDesign={true}
              onClick={() => {
                handleAddSubject();
              }}
            />
          </Box>
          <DataGrid
            getRowId={getRowId}
            rows={data?.result || []}
            columns={subjectColumns}
            loading={isLoading}
            rowsPerPageOptions={[10]}
            pageSize={10}
            totalPages={data?.totalPages}
            rowCount={data?.totalItems}
            onPageChange={(p) =>
              handlePageChange(p, queryParameters, setQueryParameters)
            }
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
              height: "630px",
            }}
            localeText={{
              noRowsLabel: "No Ticket Questions",
            }}
          />
        </Box>
      </Box>
      <ActionAlert
        openAlert={openDeletedAlert}
        handleCloseAlert={handleCloseDeleteAlert}
        handleReject={handleDeleteSubject}
        message={`Are you sure you want to Delete this Question ?`}
        label={"Delete"}
        disabled={disableWhileDelete}
      />
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Question has been deleted Successfully."
        message=""
        buttonText="Go to Ticket Questions"
      />
      <ReusableSnackbar handleClose={handleCloseSnack} />
    </Box>
  );
};

export default TicketQuestions;
