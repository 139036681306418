import { axiosInstance as axios } from "../config";

export const fetchBanks = async (q) => {
  try {
    const response = await axios.get(`/api/banks/index`, {
      params: {
        page: q.page,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const deleteBank = async (id) => {
  try {
    const response = await axios.get(`/api/banks/delete/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const addNewBank = async (body) => {
  try {
    const response = await axios.post("/api/banks/add", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const editBank = async (id, body) => {
  try {
    const response = await axios.post(`/api/banks/edit/${id}`, body);
    return response;
  } catch (error) {
    throw error;
  }
};
