export const EditSvgWithFunc = ({ onClick, className }) => {
  return (
    <div className={`${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        cursor="pointer"
        onClick={() => onClick()}
      >
        <path
          d="M15.5 22.75H9.5C4.07 22.75 1.75 20.43 1.75 15V9C1.75 3.57 4.07 1.25 9.5 1.25H11.5C11.91 1.25 12.25 1.59 12.25 2C12.25 2.41 11.91 2.75 11.5 2.75H9.5C4.89 2.75 3.25 4.39 3.25 9V15C3.25 19.61 4.89 21.25 9.5 21.25H15.5C20.11 21.25 21.75 19.61 21.75 15V13C21.75 12.59 22.09 12.25 22.5 12.25C22.91 12.25 23.25 12.59 23.25 13V15C23.25 20.43 20.93 22.75 15.5 22.75Z"
          fill="#00303C"
        />
        <path
          d="M9.00032 17.69C8.39032 17.69 7.83032 17.47 7.42032 17.07C6.93032 16.58 6.72032 15.87 6.83032 15.12L7.26032 12.11C7.34032 11.53 7.72032 10.78 8.13032 10.37L16.0103 2.49C18.0003 0.499998 20.0203 0.499998 22.0103 2.49C23.1003 3.58 23.5903 4.69 23.4903 5.8C23.4003 6.7 22.9203 7.58 22.0103 8.48L14.1303 16.36C13.7203 16.77 12.9703 17.15 12.3903 17.23L9.38032 17.66C9.25032 17.69 9.12032 17.69 9.00032 17.69ZM17.0703 3.55L9.19032 11.43C9.00032 11.62 8.78032 12.06 8.74032 12.32L8.31032 15.33C8.27032 15.62 8.33032 15.86 8.48032 16.01C8.63032 16.16 8.87032 16.22 9.16032 16.18L12.1703 15.75C12.4303 15.71 12.8803 15.49 13.0603 15.3L20.9403 7.42C21.5903 6.77 21.9303 6.19 21.9803 5.65C22.0403 5 21.7003 4.31 20.9403 3.54C19.3403 1.94 18.2403 2.39 17.0703 3.55Z"
          fill="#00303C"
        />
        <path
          d="M20.3496 9.83003C20.2796 9.83003 20.2096 9.82003 20.1496 9.80003C17.5196 9.06003 15.4296 6.97003 14.6896 4.34003C14.5796 3.94003 14.8096 3.53003 15.2096 3.41003C15.6096 3.30003 16.0196 3.53003 16.1296 3.93003C16.7296 6.06003 18.4196 7.75003 20.5496 8.35003C20.9496 8.46003 21.1796 8.88003 21.0696 9.28003C20.9796 9.62003 20.6796 9.83003 20.3496 9.83003Z"
          fill="#00303C"
        />
      </svg>
    </div>
  );
};

export const DeleteSvgWithFunc = ({ onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      cursor="pointer"
      onClick={() => {
        onClick();
      }}
    >
      <path
        d="M21.4997 6.72998C21.4797 6.72998 21.4497 6.72998 21.4197 6.72998C16.1297 6.19998 10.8497 5.99998 5.61967 6.52998L3.57967 6.72998C3.15967 6.76998 2.78967 6.46998 2.74967 6.04998C2.70967 5.62998 3.00967 5.26998 3.41967 5.22998L5.45967 5.02998C10.7797 4.48998 16.1697 4.69998 21.5697 5.22998C21.9797 5.26998 22.2797 5.63998 22.2397 6.04998C22.2097 6.43998 21.8797 6.72998 21.4997 6.72998Z"
        fill="#CF2424"
      />
      <path
        d="M8.99977 5.72C8.95977 5.72 8.91977 5.72 8.86977 5.71C8.46977 5.64 8.18977 5.25 8.25977 4.85L8.47977 3.54C8.63977 2.58 8.85977 1.25 11.1898 1.25H13.8098C16.1498 1.25 16.3698 2.63 16.5198 3.55L16.7398 4.85C16.8098 5.26 16.5298 5.65 16.1298 5.71C15.7198 5.78 15.3298 5.5 15.2698 5.1L15.0498 3.8C14.9098 2.93 14.8798 2.76 13.8198 2.76H11.1998C10.1398 2.76 10.1198 2.9 9.96977 3.79L9.73977 5.09C9.67977 5.46 9.35977 5.72 8.99977 5.72Z"
        fill="#CF2424"
      />
      <path
        d="M15.7104 22.75H9.29039C5.80039 22.75 5.66039 20.82 5.55039 19.26L4.90039 9.19001C4.87039 8.78001 5.19039 8.42001 5.60039 8.39001C6.02039 8.37001 6.37039 8.68001 6.40039 9.09001L7.05039 19.16C7.16039 20.68 7.20039 21.25 9.29039 21.25H15.7104C17.8104 21.25 17.8504 20.68 17.9504 19.16L18.6004 9.09001C18.6304 8.68001 18.9904 8.37001 19.4004 8.39001C19.8104 8.42001 20.1304 8.77001 20.1004 9.19001L19.4504 19.26C19.3404 20.82 19.2004 22.75 15.7104 22.75Z"
        fill="#CF2424"
      />
      <path
        d="M14.1601 17.25H10.8301C10.4201 17.25 10.0801 16.91 10.0801 16.5C10.0801 16.09 10.4201 15.75 10.8301 15.75H14.1601C14.5701 15.75 14.9101 16.09 14.9101 16.5C14.9101 16.91 14.5701 17.25 14.1601 17.25Z"
        fill="#CF2424"
      />
      <path
        d="M15 13.25H10C9.59 13.25 9.25 12.91 9.25 12.5C9.25 12.09 9.59 11.75 10 11.75H15C15.41 11.75 15.75 12.09 15.75 12.5C15.75 12.91 15.41 13.25 15 13.25Z"
        fill="#CF2424"
      />
    </svg>
  );
};
