import React from "react";
import { Box } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { handleFromDate, handleToDate } from "../../services/utils/filters";
import dayjs from "dayjs";
const DateFiltering = ({ queryParameters, setQueryParameters }) => {
  return (
    <Box
      sx={{
        display: "flex",
        // width: "50%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          // alignItems: "center",
          flexDirection: "column",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label="From"
              onChange={(d) =>
                handleFromDate(d, queryParameters, setQueryParameters)
              }
              value={
                queryParameters.date_from !== ""
                  ? dayjs(queryParameters.date_from)
                  : null
              }
              maxDate={dayjs()}
            />
          </DemoContainer>
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              value={
                queryParameters.date_to !== ""
                  ? dayjs(queryParameters.date_to)
                  : null
              }
              label="To"
              onChange={(d) =>
                handleToDate(d, queryParameters, setQueryParameters)
              }
              minDate={
                queryParameters.date_from !== ""
                  ? dayjs(queryParameters.date_from)
                  : null
              }
              maxDate={dayjs()}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default DateFiltering;
