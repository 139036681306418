import React from "react";
import graphsvg from "../svg/graph.svg";
import profileusersvg from "../svg/profile-2user.svg";
import convertcardsvg from "../svg/convert-card.svg";
import documenttextsvg from "../svg/document-text.svg";
import baghappysvg from "../svg/bag-happy.svg";
import shieldsvg from "../svg/shield.svg";
import category2svg from "../svg/category-2.svg";
import directnotificationsvg from "../svg/direct-notification.svg";
import setting2 from "../svg/setting-2.svg";
import discountcircle from "../svg/discount-circle.svg";
import usersearchsvg from "../svg/user-search.svg";
import userremovesvg from "../svg/user-remove.svg";
import eyesvg from "../svg/eye.svg";
import moneysvg from "../svg/money.svg";
import goldsvg from "../svg/gold.svg";
import shieldsecuritysvg from "../svg/shield-security.svg";
import useroctagonsvg from "../svg/user-octagon.svg";
import scanbarcodesvg from "../svg/scan-barcode.svg";
import tasksquaresvg from "../svg/task-square.svg";
import unlocksvg from "../svg/unlock.svg";
import boxsvg from "../svg/box.svg";
import receipteditsvg from "../svg/receipt-edit.svg";
import shop from "../svg/shop.svg";
import notesvg from "../svg/note.svg";
import messagequestionsvg from "../svg/message-question.svg";
import mobilesvg from "../svg/mobile.svg";

const CustomIcon = ({ iconName, selected, title }) => {
  const iconColor = selected === title ? "#FFF" : "#808080";
  switch (iconName) {
    case "graph":
      return (
        // <svg
        //   width="20"
        //   height="20"
        //   viewBox="0 0 20 20"
        //   fill="none"
        //   xmlns="http://www.w3.org/2000/svg"
        // >
        //   <path
        //     d="M15.2667 10.6254H12.5C10.425 10.6254 9.375 9.45037 9.375 7.12537V4.73371C9.375 3.88371 9.475 2.66704 10.3583 2.00037C11.1 1.45037 12.1667 1.40871 13.7417 1.86704C15.8083 2.46704 17.5333 4.19204 18.1333 6.25871C18.5917 7.82537 18.55 8.90037 18 9.63371C17.3333 10.5254 16.1167 10.6254 15.2667 10.6254ZM11.9 2.80037C11.5583 2.80037 11.2917 2.86704 11.1167 3.00037C10.7917 3.24204 10.6333 3.80871 10.6333 4.73371V7.13371C10.6333 9.00037 11.35 9.38371 12.5083 9.38371H15.275C16.1917 9.38371 16.7583 9.22537 17.0083 8.90037C17.3 8.51704 17.275 7.75037 16.95 6.62537C16.4667 4.98371 15.05 3.55871 13.4083 3.08371C12.7917 2.89204 12.2917 2.80037 11.9 2.80037Z"
        //     fill={iconColor}
        //   />
        //   <path
        //     d="M9.22466 18.9583C8.78299 18.9583 8.33299 18.9249 7.88299 18.8499C4.47466 18.2999 1.69966 15.5333 1.14966 12.1249C0.441325 7.74161 3.26632 3.60828 7.59132 2.72494C7.93299 2.65828 8.25799 2.87494 8.33299 3.20828C8.39966 3.54994 8.18299 3.87494 7.84966 3.94994C4.19133 4.69994 1.79132 8.19994 2.39966 11.9249C2.86632 14.8083 5.20799 17.1499 8.09133 17.6166C11.833 18.2166 15.3247 15.8083 16.0663 12.1333C16.133 11.7916 16.4663 11.5749 16.7997 11.6416C17.1413 11.7083 17.358 12.0416 17.2913 12.3749C16.4997 16.2666 13.0997 18.9583 9.22466 18.9583Z"
        //     fill={iconColor}
        //   />
        // </svg>
        <img
          src={graphsvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "profile-2user":
      return (
        <img
          src={profileusersvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case ".":
      return (
        <svg
          width="7"
          height="7"
          viewBox="0 0 6 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="3" cy="3" r="3" fill={iconColor} />
        </svg>

        // <img
        //   src={profileusersvg}
        //   alt="SVG Icon"
        //   style={{
        //     width: "20px",
        //     height: "20px",
        //   }}
        // />
      );
    case "convert-card":
      return (
        <img
          src={convertcardsvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "document-text":
      return (
        <img
          src={documenttextsvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "bag-happy":
      return (
        <img
          src={baghappysvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "shield":
      return (
        <img
          src={shieldsvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "category-2":
      return (
        <img
          src={category2svg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "direct-notification":
      return (
        <img
          src={directnotificationsvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "user-search":
      return (
        <img
          src={usersearchsvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "user-remove":
      return (
        <img
          src={userremovesvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "eye":
      return (
        <img
          src={eyesvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "money":
      return (
        <img
          src={moneysvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "gold":
      return (
        <img
          src={goldsvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "shield-security":
      return (
        <img
          src={shieldsecuritysvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "user-octagon":
      return (
        <img
          src={useroctagonsvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "scan-barcode":
      return (
        <img
          src={scanbarcodesvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "task-square":
      return (
        <img
          src={tasksquaresvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "unlock":
      return (
        <img
          src={unlocksvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "box":
      return (
        <img
          src={boxsvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "receipt-edit":
      return (
        <img
          src={receipteditsvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "note":
      return (
        <img
          src={notesvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "message-question":
      return (
        <img
          src={messagequestionsvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "mobile":
      return (
        <img
          src={mobilesvg}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "discount-circle":
      return (
        <img
          src={discountcircle}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "setting-2":
      return (
        <img
          src={setting2}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    case "shop":
      return (
        <img
          src={shop}
          alt="SVG Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      );
    default:
  }
};

export default CustomIcon;
