import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";

const RHFTextField = ({
  sx = { height: "48px" },
  name,
  control,
  label,
  isRequired = true,
  defaultValue = "",
  variant = "outlined",
  type = "text",
  id = label,
  errors,
  disabled = false,
  placeholder = label,
  classNameDiv = "items-center",
  InputProps = {},
  multiline = false,
  rows = 0,
  fullWidth = true,
  onChangeData = () => {},
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "500",
          color: "#4C4C4C",
          marginBottom: "5px",
          marginTop: "15px",
        }}
      >
        {label}
      </Typography>
      <div className={classNameDiv}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth={fullWidth}
              id={id}
              multiline={multiline}
              rows={rows}
              disabled={disabled}
              {...field}
              sx={sx}
              InputProps={{
                ...InputProps,
                endAdornment: type === "password" && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder={placeholder}
              className="w-full"
              type={showPassword ? "text" : type}
              variant={variant}
              error={Boolean(errors)}
              onChange={(e) => {
                field.onChange(e.target.value);
                onChangeData(e);
              }}
              required={isRequired}
              onKeyDown={(event) => {
                if (event.key === " " && event.target.selectionStart === 0) {
                  event.preventDefault();
                }
              }}
            />
          )}
        />
      </div>
      {errors?.message && (
        <Typography
          sx={{ color: "red", fontSize: "14px", marginTop: "5px" }}
          variant="body2"
        >
          {errors?.message}
        </Typography>
      )}
    </Box>
  );
};

export default RHFTextField;
