import TextFieldComponent from "../../components/TextFieldComponent";
import { Box } from "@mui/material";

const StepTwoFaqComponent = ({ setFaqData, faqData }) => {
  return (
    <Box sx={{ marginTop: "3%" }}>
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Question (Arabic)"
        placeholder="Enter Question"
        value={faqData.trans.find((t) => t.local === "ar").title}
        onChange={(event) => {
          const disallowEnglishRegex = /^[^a-zA-Z]*$/;
          if (
            event.target.value === "" ||
            disallowEnglishRegex.test(event.target.value)
          ) {
            const updatedPayload = { ...faqData };
            const enTranslation = updatedPayload.trans.find(
              (t) => t.local === "ar"
            );
            if (enTranslation) {
              enTranslation.title = event.target.value;
              setFaqData(updatedPayload);
            }
          }
        }}
      />
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Answer (Arabic)"
        placeholder="Enter Answer"
        value={faqData.trans.find((t) => t.local === "ar").description}
        onChange={(event) => {
          const disallowEnglishRegex = /^[^a-zA-Z]*$/;
          if (
            event.target.value === "" ||
            disallowEnglishRegex.test(event.target.value)
          ) {
            const updatedPayload = { ...faqData };
            const enTranslation = updatedPayload.trans.find(
              (t) => t.local === "ar"
            );
            if (enTranslation) {
              enTranslation.description = event.target.value;
              setFaqData(updatedPayload);
            }
          }
        }}
      />
    </Box>
  );
};
export default StepTwoFaqComponent;
