import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import Header from "../../../components/Header";
import { DataGridStyle } from "../../../styles";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import {
  addRoleToPermission,
  fetchResourceGroupSections,
  fetchRoleDropdown,
  getforResource,
} from "../../../services/apis/roles-and-permissions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import { MenuProps } from "../../../components/common/MenuProps";
import { SecondBox } from "../../../components/common/CustomBox";

const RolePermissions = () => {
  const [selectedResourceGroup, setSelectedResourceGroup] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [roleLoading, setRoleLoading] = useState(true);
  const [resources, setResources] = useState([]);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleResourceGroupChange = (event) => {
    setSelectedResourceGroup(event.target.value);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };
  const handleApiRequest = async () => {
    if (selectedResourceGroup && selectedRole) {
      try {
        const dataToSend = {
          user_group_id: selectedRole.id,
          sidebar_id: selectedResourceGroup.id,
          type: 2,
        };
        const response = await getforResource(dataToSend);
        setResources(response.data.data);
      } catch (error) {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      }
    }
  };
  const handleCheckboxChange = async (resourceId, isChecked) => {
    try {
      const updatedResources = resources.map((resource) => {
        if (resource.id === resourceId) {
          return { ...resource, isChecked: !resource.isChecked };
        }
        return resource;
      });
      setResources(updatedResources);
      const dataToSend = {
        id: resourceId,
        role: selectedRole.id,
      };
      await addRoleToPermission(dataToSend);
      setOpen(true);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
  };
  const [sections, setSections] = useState([]);
  const [role, setRole] = useState([]);
  const getResourceGroup = async () => {
    try {
      setLoading(true);
      const response = await fetchResourceGroupSections();
      const sections = response.data.data;
      setSections(sections);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const getRoleDropdown = async () => {
    try {
      setRoleLoading(true);
      const response = await fetchRoleDropdown();
      const role = response.data.data;
      setRole(role);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    } finally {
      setRoleLoading(false);
    }
  };
  useEffect(() => {
    if (selectedResourceGroup && selectedRole) {
      handleApiRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResourceGroup, selectedRole]);
  useEffect(() => {
    getResourceGroup();
    getRoleDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading && roleLoading) return <LoadingSpinner />;
  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Role Permissions" marginL={"25px"} />
      <SecondBox>
        <Box
          m="40px 0 0 0"
          height="auto"
          style={{ paddingBottom: "2%" }}
          sx={DataGridStyle}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              width: "100%",
              marginTop: "3%",
            }}
          >
            <Box sx={{ width: "45%" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                Select Role
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  displayEmpty
                  onChange={handleRoleChange}
                  value={selectedRole || ""}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return <em>Select Role</em>;
                    }
                    return selected?.name;
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {role.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "45%" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                Select Resource Group
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  displayEmpty
                  onChange={handleResourceGroupChange}
                  value={selectedResourceGroup || ""}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return <em>Select Resource Group</em>;
                    }
                    return selected?.title;
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {sections.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          {resources.length !== 0 && (
            <Box
              sx={{
                width: "100%",
                marginTop: "3%",
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "90%",
                }}
              >
                <Typography
                  sx={{
                    width: "20%",
                    color: "#4C4C4C",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  variant="h6"
                >
                  Resource Name
                </Typography>
                <Typography
                  sx={{
                    width: "5%",
                    color: "#4C4C4C",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  variant="h6"
                >
                  Allow
                </Typography>
              </Box>
              <Divider sx={{ my: 1 }} />
              {resources?.map((resource) => (
                <div key={resource?.id}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      width: "90%",
                    }}
                    key={resource.id}
                    mb={2}
                  >
                    <Typography sx={{ width: "20%" }} variant="h6">
                      {resource.name}
                    </Typography>
                    <FormControlLabel
                      sx={{
                        width: "3%",
                      }}
                      key={resource.id}
                      control={
                        <Checkbox
                          sx={{
                            "&.Mui-checked": {
                              color: "#E9C237",
                            },
                            "&.MuiCheckbox-root:not(.Mui-checked)": {
                              color: "#4C4C4C",
                            },
                          }}
                          checked={resource.isChecked === 1 ? true : false}
                          onChange={() => handleCheckboxChange(resource.id)}
                        />
                      }
                      // label={resource.name}
                    />
                  </Box>
                  <Divider sx={{ my: 1 }} />
                </div>
              ))}
            </Box>
          )}
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Action added to Role
            </Alert>
          </Snackbar>
        </Box>
      </SecondBox>
      <ReusableSnackbar handleClose={handleCloseSnack} />
    </Box>
  );
};

export default RolePermissions;
