import { axiosInstance as axios } from "../config";

export const fetchClosedReasons = async (q) => {
  try {
    const response = await axios.get(`/api/contactUsCloseReasons/index`, {
      params: {
        page: q.page,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchAllClosedReasons = async (q) => {
  try {
    const response = await axios.get(`/api/contactUsCloseReasons/dropdown`, {
      params: {
        type: q.type,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const addClosedReasons = async (body) => {
  try {
    const response = await axios.post("/api/contactUsCloseReasons/add", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const deleteClosedReason = async (id) => {
  try {
    const response = await axios.get(`/api/contactUsCloseReasons/delete/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const editClosedReason = async (id, body) => {
  try {
    const response = await axios.post(
      `/api/contactUsCloseReasons/edit/${id}`,
      body
    );
    return response;
  } catch (error) {
    throw error;
  }
};
