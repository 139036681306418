import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGridStyle } from "../../../styles";
import Header from "../../../components/Header";
import { handlePageChange } from "../../../services/utils/filters";
import { withStyles } from "@mui/styles";
import { TabContext, TabPanel } from "@mui/lab";
import {
  fetchInventoryRequest,
  useFetchInventoryRequest,
} from "../../../services/apis/inventory";
import RequestDialog from "./RequestDialog";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import AddButton from "../../../components/common/AddButton";
import { FirstBox, SecondBox } from "../../../components/common/CustomBox";
import { ItemsColumns } from "../../../components/common/Columns";
const Requests = () => {
  const navigate = useNavigate();
  const [requests, setRequests] = useState([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("1");
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
    orderBy: null,
    sort: null,
    type: "ingot",
  });
  const { data, refetch, isFetching } =
    useFetchInventoryRequest(queryParameters);

  const handleViewRequestDetails = (request) => {
    navigate(`/requests/${request.order_id}`, {
      state: { requestData: request },
    });
  };
  const AntTabs = withStyles({
    root: {
      borderBottom: "none",
    },
    indicator: {
      display: "none",
    },
  })(Tabs);
  const AntTab = withStyles((theme) => ({
    root: {
      "&:hover": {
        color: "#00303C",
        opacity: 1,
      },
      "&$selected": {
        color: "#ffffff",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        borderBottom: "none",
      },
      "&:focus": {
        color: "#00303C",
      },
    },
    selected: {
      "&$selected": {
        borderBottom: "none",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        color: "#ffffff !important",
      },
    },
  }))((props) => <Tab disableRipple {...props} />);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "1") {
      setQueryParameters({
        ...queryParameters,
        page: 0,
        type: "ingot",
      });
    } else if (newValue === "2") {
      setQueryParameters({
        ...queryParameters,
        page: 0,
        type: "fraction",
      });
    }
  };
  const requestsColumns = ItemsColumns([
    { field: "order_id", headerName: "Request ID" },
    { field: "name", headerName: "Product" },
    { field: "amount" },
    { field: "created", headerName: "Date", date: true },
    {
      field: "orderDetails",
      headerName: "Actions",

      actions: { view: (params) => handleViewRequestDetails(params.row) },
    },
  ]);
  const fractionsColumns = ItemsColumns([
    { field: "order_id", headerName: "Order ID" },
    { field: "name", headerName: "Product" },
    { field: "amount" },
    { field: "created", headerName: "Date", date: true },
    {
      field: "orderDetails",
      headerName: "Actions",
      actions: { view: (params) => handleViewRequestDetails(params.row) },
    },
  ]);

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <DashboardMainHeader />

      <Header title="Requests" marginL={"20px"} />
      <RequestDialog
        value={value}
        queryParameters={queryParameters}
        fetchData={fetchInventoryRequest}
        handleResponse={() => {
          refetch(queryParameters);
        }}
        setRequests={setRequests}
        requests={requests}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        label={"Request"}
      />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 2,
          p: 2,
          justifyContent: "end",
        }}
      >
        <AddButton title={"+ Request Order"} onClick={() => setOpen(true)} />
      </Box>
      <SecondBox>
        <FirstBox m="40px 0 0 0" height="100%">
          <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
            <TabContext value={value}>
              <Box
                sx={{
                  width: "100%",
                  paddingTop: "2%",
                  paddingBottom: "1%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <AntTabs
                  sx={{
                    backgroundColor: "#F5F6FF",
                    borderRadius: "9px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& .Mui-selected": {
                      borderBottom: "none !important",
                    },
                  }}
                  value={value}
                  onChange={handleChange}
                  aria-label="ant example"
                >
                  <AntTab
                    sx={{
                      width: "512px",
                    }}
                    label="Ingots"
                    value="1"
                  />
                  <AntTab sx={{ width: "512px" }} label="Fractions" value="2" />
                </AntTabs>
              </Box>
              <TabPanel
                sx={{
                  borderBottom: "none  !important",
                }}
                value="1"
              >
                <Box
                  sx={{
                    height: "auto",
                    width: "100%",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                  }}
                >
                  <DataGrid
                    disableSelectionOnClick
                    sx={{
                      cursor: "pointer",
                      backgroundColor: "#ffffff",
                      height: "630px",
                    }}
                    pageSize={10}
                    rowCount={data?.data?.totalItems || 0}
                    rows={isFetching ? [] : data?.data?.result}
                    columns={requestsColumns}
                    loading={isFetching}
                    paginationMode="server"
                    onPageChange={(p) =>
                      handlePageChange(p, queryParameters, setQueryParameters)
                    }
                    rowsPerPageOptions={[10]}
                    totalPages={data?.data?.totalPages}
                    getRowId={(row) => row.order_id}
                    localeText={{
                      noRowsLabel: "No gold ingots requests",
                    }}
                  />
                </Box>
              </TabPanel>
              <TabPanel
                sx={{
                  borderBottom: "none !important",
                }}
                value="2"
              >
                <Box
                  sx={{
                    height: "auto",
                    width: "100%",
                    justifyContent: "center",
                    background: "#ffffff",
                  }}
                >
                  <DataGrid
                    disableSelectionOnClick
                    sx={{
                      cursor: "pointer",
                      backgroundColor: "#ffffff",
                      height: "630px",
                    }}
                    pageSize={10}
                    rowCount={data?.data?.totalItems || 0}
                    rows={isFetching ? [] : data?.data?.result}
                    loading={isFetching}
                    columns={fractionsColumns}
                    paginationMode="server"
                    onPageChange={(p) =>
                      handlePageChange(p, queryParameters, setQueryParameters)
                    }
                    rowsPerPageOptions={[10]}
                    totalPages={data?.data?.totalPages}
                    getRowId={(row) => row.order_id}
                    localeText={{
                      noRowsLabel: "No gold fractions requests",
                    }}
                  />
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </FirstBox>
      </SecondBox>
    </Box>
  );
};

export default Requests;
