import { axiosInstance as axios } from "../config";

export const fetchPaymentInfo = async () => {
  try {
    const response = await axios.get(`/api/paymentTypes/index`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const editPaymentInfo = async (data) => {
  try {
    const response = await axios.post(`/api/paymentTypes/edit`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.error("Error submitting data:", error);
    throw error;
  }
};
