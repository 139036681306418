import React, { useEffect, useState } from "react";
import { fetchRoles } from "../../services/apis/roles-and-permissions";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import PermissionsTable from "./PermissionsTable";
import LoadingSpinner from "../../components/LoadingSpinner";
import { DataGridStyle } from "../../styles";

const Permissions = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [permissions] = useState([]);
  const [adminRoles, setAdminRoles] = useState([]);
  const [mobileUsersRoles, setMobileUsersRoles] = useState([]);

  useEffect(() => {
    const getRolesAndPermissions = async () => {
      try {
        const rolesResponse = await fetchRoles();
        const r = rolesResponse.data.data;
        const adminRoles = r.filter((r) => r.isDashboard === true);
        const mobileRols = r.filter((r) => r.isDashboard === false);
        setAdminRoles(adminRoles);
        setMobileUsersRoles(mobileRols);
      } catch (error) {
        console.log(error.response.data.message);
      }
    };

    getRolesAndPermissions();

    return;
  }, []);
  if (adminRoles.length === 0 || mobileUsersRoles.length === 0)
    return <LoadingSpinner />;

  const checkIfHasPermission = () => {
    if (permissions.length > 0) {
    }
  };
  checkIfHasPermission();
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      m="40px 20px 0 "
      height="85vh"
      sx={DataGridStyle}
    >
      {/* <DataGrid rows={tableData} columns={col} disableRowSelectionOnClick /> */}

      <PermissionsTable
        roles={adminRoles}
        colors={colors}
        type={2}
        title="Admin Permissions"
      />

      <PermissionsTable
        roles={mobileUsersRoles}
        colors={colors}
        type={1}
        title="Users Permissions"
      />
    </Box>
  );
};

export default Permissions;
