// path/to/authenticationSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
  isAuthenticated: localStorage.getItem("token") ? true : false,
};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      state.isAuthenticated = !!action.payload; // Update isAuthenticated based on token presence
    },
    clearToken: (state) => {
      state.token = "";
      state.isAuthenticated = false;
    },
  },
});

export const { setToken, clearToken } = authenticationSlice.actions;
export default authenticationSlice.reducer;
