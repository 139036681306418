import { useState } from "react";
import { ProSidebar, Menu } from "react-pro-sidebar";
import { Box, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useEffect } from "react";
import Logout from "../../components/Logout";
import SiderbarItem from "../../components/SidebarItem";
import { useFetchUserPages } from "../../services/apis/pages";
import CustomIcon from "../../components/CustomIcon";
import SideBarDropdown from "../../components/SideBarDropdown";
import newlogo from "../../svg/newlogo.png";

const Sidebar = () => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("");
  const location = useLocation();
  const { data } = useFetchUserPages();

  useEffect(() => {
    const storedTitle = sessionStorage.getItem("selectedTitle");
    const isPageRefreshed = sessionStorage.getItem("isPageRefreshed");
    if (isPageRefreshed) {
      setSelected(storedTitle || "Overview");
      sessionStorage.removeItem("isPageRefreshed");
    } else {
      setSelected("");
    }
    sessionStorage.setItem("isPageRefreshed", "false");
  }, [data, location?.pathname]);
  return (
    <Box
      sx={{
        display: localStorage.getItem("token") ? "" : "none",
        height: "100vh",
        "& .pro-sidebar-inner": {
          background: `#FFFFFF !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#E9C237 !important",
        },
        "& .pro-menu-item.active": {
          color: "#FFF !important",
        },
        "& .pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper":
          {
            marginRight: "0px",
          },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={2}
            sx={{
              margin: "10px 0 20px 0",
            }}
            // ml={isCollapsed ? "24px" : "15px"}
          >
            <Box
              sx={{
                width: "150px",
                height: "83px",
                display: isCollapsed ? "none" : "block",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={newlogo}
                alt="overView"
                onClick={() => {
                  navigate("/");
                }}
              />
            </Box>
            <IconButton
              sx={{ color: "#E9C237" }}
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              <MenuOutlinedIcon />
            </IconButton>
          </Box>

          <Box>
            {data &&
              data.map((section) => {
                if (section?.to !== null) {
                  return (
                    <Box sx={{ marginLeft: "-4.5%" }} key={section?.id}>
                      <SiderbarItem
                        key={section.id}
                        title={isCollapsed ? "" : section.title}
                        to={section?.to}
                        icon={<CustomIcon iconName={section.icon} />}
                        selected={selected}
                        setSelected={setSelected}
                        isCollapsed={isCollapsed}
                        setIsCollapsed={setIsCollapsed}
                      />
                    </Box>
                  );
                } else if (
                  section?.to === null &&
                  section?.pages?.length !== 0
                ) {
                  return (
                    <Box
                      key={section?.id}
                      onClick={() => {
                        if (isCollapsed) {
                          setIsCollapsed(false);
                        }
                      }}
                    >
                      <SideBarDropdown
                        key={section?.id}
                        section={section}
                        selected={selected}
                        setSelected={setSelected}
                        isCollapsed={isCollapsed}
                        setIsCollapsed={setIsCollapsed}
                      />
                    </Box>
                  );
                }
                return null; // Default return value
              })}
            <Logout isCollapsed={isCollapsed} title="Logout" />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
