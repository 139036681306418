import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../../components/Header";
import { DataGridStyle } from "../../../styles";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import { fetchCategory } from "../../../services/apis/roles-and-permissions";
import AddNewCategoryModal from "../../add-new-role/AddNewCategoryModal";
import RolesCategoriesModal from "./RolesCategoriesModal";
import AddButton from "../../../components/common/AddButton";
import { SecondBox } from "../../../components/common/CustomBox";
import { ItemsColumns } from "../../../components/common/Columns";

const RolesCategories = () => {
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectCategoryId, setSelectCategoryId] = useState(null);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = async () => {
    setOpen(false);
  };
  const getCategories = async () => {
    setLoading(true);
    setCategories([]);
    try {
      const response = await fetchCategory();
      const categories = response.data.data;
      setCategories(categories);
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);
  const handleClick = (roleCatId) => {
    setSelectCategoryId(roleCatId);
    setOpenDetails(true);
  };
  const handleCloseDetails = async () => {
    setOpenDetails(false);
  };
  const categoriesColumns = ItemsColumns([
    { field: "name", headerName: "Category Name" },
    { field: "description", flex: 3 },
    {
      field: "type",
      specialRender: "customRender",
      renderFunction: (params) =>
        params?.row?.is_built === 0 ? "Created" : "Built in",
    },
    {
      field: "orderDetails",
      headerName: "Actions",
      actions: {
        view: (params) => handleClick(params.row.id),
      },
    },
  ]);

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Role Categories" marginL={"25px"} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          gap: 2,
          pr: 2,
        }}
      >
        <AddButton
          title={"+ New Categories"}
          onClick={() => {
            handleOpen();
          }}
        />
      </Box>
      <SecondBox>
        <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
          <DataGrid
            rows={categories}
            columns={categoriesColumns}
            autoHeight={true}
            loading={loading}
            components={{
              Footer: () => null,
            }}
          />
        </Box>
        <AddNewCategoryModal
          onAddCategory={getCategories}
          open={open}
          onClose={handleClose}
          label={"Add"}
        />
        <RolesCategoriesModal
          categories={categories}
          categoryId={selectCategoryId}
          open={openDetails}
          onClose={handleCloseDetails}
        />
      </SecondBox>
    </Box>
  );
};

export default RolesCategories;
