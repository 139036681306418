import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";

const StepThreeComponent = ({
  resource,
  roleData,
  setRoleData,
  onCheckboxChange,
  setSelectedCheckboxPermissions,
}) => {
  const handleCheckboxChange = (page) => {
    const updatedSelectedPages = roleData.permissions.includes(page.id)
      ? roleData.permissions.filter((selectedPage) => selectedPage !== page.id)
      : [...roleData.permissions, page.id];
    setRoleData((prevData) => ({
      ...prevData,
      permissions: updatedSelectedPages,
    }));
    const selectedCheckboxNames = resource
      .map((item) =>
        item.permissions
          .filter((p) => updatedSelectedPages.includes(p.id))
          .map((p) => p.name)
      )
      .flat();

    if (onCheckboxChange) {
      onCheckboxChange(updatedSelectedPages);
    }

    if (setSelectedCheckboxPermissions) {
      setSelectedCheckboxPermissions(selectedCheckboxNames);
    }
  };
  const handleTitleCheckboxChange = (item) => {
    const allPageIds = item.permissions.map((page) => page.id);
    const areAllChecked = allPageIds.every((id) =>
      roleData.permissions.includes(id)
    );
    // const isAnyChecked = allPageIds.some((id) =>
    //   roleData.permissions.includes(id)
    // );

    const updatedSelectedPages = areAllChecked
      ? roleData.permissions.filter((id) => !allPageIds.includes(id))
      : [...roleData.permissions, ...allPageIds];

    setRoleData((prevData) => ({
      ...prevData,
      permissions: updatedSelectedPages,
    }));

    const selectedCheckboxNames = resource
      .map((rItem) =>
        rItem.permissions
          .filter((p) => updatedSelectedPages.includes(p.id))
          .map((p) => p.name)
      )
      .flat();

    if (onCheckboxChange) {
      onCheckboxChange(updatedSelectedPages);
    }

    if (setSelectedCheckboxPermissions) {
      setSelectedCheckboxPermissions(selectedCheckboxNames);
    }
  };
  return (
    <Box sx={{ marginTop: "3%" }}>
      {resource.map((item) => (
        <Box sx={{ marginTop: "1%" }} key={item.id}>
          <Typography
            sx={{ color: "#4C4C4C", fontSize: "28px", fontWeight: "500" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    "&.Mui-checked": {
                      color: "#E9C237",
                    },
                    "&.MuiCheckbox-root:not(.Mui-checked)": {
                      color: "#4C4C4C",
                    },
                  }}
                  checked={
                    item.permissions.length > 0
                      ? item.permissions.some((page) =>
                          roleData.permissions.includes(page.id)
                        )
                      : roleData.permissions.includes(item.id)
                  }
                  onChange={() => handleTitleCheckboxChange(item)}
                />
              }
              label={
                <span style={{ fontWeight: "600", fontSize: "20px" }}>
                  {item.title}
                </span>
              }
            />
          </Typography>
          {/* {item?.permissions.length !== 0 && (
            <Typography
              sx={{ color: "#4C4C4C", fontSize: "20px", fontWeight: "400" }}
            >
              {item.title}
            </Typography>
          )} */}
          {item?.permissions && (
            <>
              {item?.permissions.map((page) => (
                <FormControlLabel
                  key={page.id}
                  sx={{ marginTop: "10px", marginLeft: "15px" }}
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: "#E9C237",
                        },
                        "&.MuiCheckbox-root:not(.Mui-checked)": {
                          color: "#4C4C4C",
                        },
                      }}
                      checked={roleData.permissions.includes(page.id)}
                      onChange={() => handleCheckboxChange(page)}
                    />
                  }
                  label={page.name}
                />
              ))}
            </>
          )}
        </Box>
      ))}
    </Box>
  );
};
export default StepThreeComponent;
