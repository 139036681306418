import { useState } from "react";
import TextFieldComponent from "../../components/TextFieldComponent";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  OutlinedInput,
  RadioGroup,
  Radio,
  Select,
  InputLabel,
  Stack,
  Chip,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect } from "react";

import {
  useFetchNotificationsAreas,
  useNotificationsCities,
} from "../../services/apis/notifications";

const StepTwoNotification = ({ setFormData, formData }) => {
  const [selectedNames, setSelectedNames] = useState(formData?.city_id || []);
  const [selectedArea, setSelectedArea] = useState(formData?.area_id || []);
  const [type, setType] = useState(() =>
    formData.age !== null ? "age" : "range"
  );
  const [queryParameters, setQueryParameters] = useState({
    cityIds: formData?.city_id || [],
  });
  const { data } = useNotificationsCities(queryParameters);
  const { mutate, data: areaData } = useFetchNotificationsAreas();

  useEffect(() => {
    setSelectedNames(formData?.city_id || []);
    setSelectedArea(formData?.area_id || []);
    setType(formData.age !== null ? "age" : "range");
  }, [formData]);
  useEffect(() => {
    mutate(queryParameters, selectedArea);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNames]);

  const handleChange = (name, value) => {
    if (name === "type") {
      setType(value);
      setFormData((prevPayload) => ({
        ...prevPayload,
        age: value === "age" ? "" : null,
        age_from: value === "range" ? "" : null,
        age_to: value === "range" ? "" : null,
      }));
    } else {
      setFormData((prevPayload) => ({
        ...prevPayload,
        [name]: value,
      }));
    }
  };

  const handleGenderChange = (event) => {
    const selectedGender = event.target.value;
    const updatedNotificationData = {
      ...formData,
      gender: selectedGender === "All" ? null : selectedGender,
    };
    setFormData(updatedNotificationData);
  };

  const handleCityChange = (selectedCityIds) => {
    setQueryParameters((prevParams) => ({
      ...prevParams,
      cityIds: selectedCityIds,
    }));
    setFormData((prevData) => ({
      ...prevData,
      city_id: selectedCityIds,
    }));
  };

  const handleAreaChange = (selectedAreaIds) => {
    const idsFromSecondArray = areaData?.data?.map((item) => item.id);
    const filteredArray = selectedAreaIds.filter((id) =>
      idsFromSecondArray.includes(id)
    );
    setSelectedArea(filteredArray);
    setFormData((prevData) => ({
      ...prevData,
      area_id: filteredArray,
    }));
  };

  return (
    <Box
      sx={{
        marginTop: "3%",
        width: "95%",
        display: "flex",
        alignSelf: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          flexDirection: "row",
          alignItems: "center",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            marginTop: "15px",
            width: "100%",
          }}
        >
          <FormLabel
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#4C4C4C",
            }}
            id="demo-row-radio-buttons-group-label"
          >
            Age
          </FormLabel>
          <RadioGroup
            style={{
              marginTop: "15px",
            }}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(event) => handleChange("type", event.target.value)}
            value={type}
          >
            <FormControlLabel
              style={{ width: "30%" }}
              value="age"
              control={<Radio />}
              label="Specific Age"
            />
            <FormControlLabel
              style={{ width: "30%" }}
              value="range"
              control={<Radio />}
              label="Range"
            />
          </RadioGroup>
        </Box>
        {type === "age" ? (
          <TextFieldComponent
            sx={{ "& fieldset": { border: "none" } }}
            style={{
              height: "48px",
              width: "406px",
              backgroundColor: "#F7F7F7",
              borderRadius: "9px",
            }}
            name="Age"
            placeholder="eg. 18"
            value={formData.age === null ? "" : formData.age}
            onChange={(event) => {
              const inputValue = event.target.value;
              if (inputValue === "" || !isNaN(inputValue)) {
                var temppayload = { ...formData };
                temppayload.age =
                  inputValue === "" ? "" : parseInt(inputValue, 10);
                setFormData({ ...temppayload });
              }
            }}
          />
        ) : (
          <>
            <TextFieldComponent
              sx={{ "& fieldset": { border: "none" } }}
              style={{
                height: "48px",
                width: "191px",
                backgroundColor: "#F7F7F7",
                borderRadius: "9px",
              }}
              name="From"
              placeholder="Enter Age From"
              value={formData.age_from === null ? "" : formData.age_from}
              onChange={(event) => {
                const inputValue = event.target.value;
                if (inputValue === "" || !isNaN(inputValue)) {
                  var temppayload = { ...formData };
                  temppayload.age_from =
                    inputValue === "" ? "" : parseInt(inputValue, 10);
                  setFormData({ ...temppayload });
                }
              }}
            />
            <Box sx={{ marginLeft: "10%" }}>
              <TextFieldComponent
                sx={{ "& fieldset": { border: "none" } }}
                style={{
                  height: "48px",
                  width: "191px",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                }}
                name="To"
                placeholder="Enter Age To"
                value={formData.age_to === null ? "" : formData.age_to}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  if (inputValue === "" || !isNaN(inputValue)) {
                    var temppayload = { ...formData };
                    temppayload.age_to =
                      inputValue === "" ? "" : parseInt(inputValue, 10);
                    setFormData({ ...temppayload });
                  }
                }}
              />
            </Box>
          </>
        )}
      </Box>
      <Box
        sx={{
          marginTop: "25px",
          width: "80%",
        }}
      >
        <FormLabel
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#4C4C4C",
          }}
          id="demo-row-radio-buttons-group-label"
        >
          Select Gender
        </FormLabel>
        <RadioGroup
          style={{
            marginTop: "15px",
          }}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={formData.gender === null ? "All" : formData.gender}
          onChange={handleGenderChange}
        >
          <FormControlLabel
            style={{ marginRight: "25%" }}
            value="All"
            control={<Radio />}
            label="All"
          />
          <FormControlLabel
            style={{ marginRight: "25%" }}
            value="male"
            control={<Radio />}
            label="Male"
          />
          <FormControlLabel
            style={{ marginRight: "25%" }}
            value="Female"
            control={<Radio />}
            label="Female"
          />
        </RadioGroup>
      </Box>
      <FormControl
        sx={{ width: "100%", marginTop: "3%", marginBottom: "10px" }}
      >
        <InputLabel>Select City</InputLabel>
        <Select
          multiple
          value={selectedNames}
          onChange={(e) => {
            setSelectedNames(e.target.value);
            handleCityChange(e.target.value);
          }}
          input={<OutlinedInput label="Multiple Select" />}
          renderValue={(selected) => (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected.map((value) => (
                <Chip
                  style={{ backgroundColor: "#FAFAFA" }}
                  key={value}
                  label={
                    data?.data?.data.find((city) => city.id === value)?.name
                  }
                  onDelete={() => {
                    setSelectedNames(
                      selectedNames.filter((id) => id !== value)
                    );
                    handleCityChange(
                      selectedNames.filter((id) => id !== value)
                    );
                  }}
                  deleteIcon={
                    <CancelIcon
                      style={{ color: "#FF453A" }}
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                />
              ))}
            </Stack>
          )}
        >
          {data?.data?.data.map((item) => (
            <MenuItem
              key={item?.id}
              value={item?.id}
              sx={{ justifyContent: "space-between" }}
            >
              {item?.name}
              {selectedNames.includes(item?.id) ? (
                <CheckIcon color="info" />
              ) : null}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        sx={{ width: "100%", marginTop: "3%", marginBottom: "10px" }}
        disabled={queryParameters?.cityIds?.length === 0}
      >
        <InputLabel>Select Area</InputLabel>
        <Select
          multiple
          value={selectedArea}
          onChange={(e) => handleAreaChange(e.target.value)}
          input={<OutlinedInput label="Multiple Select" />}
          renderValue={(selected) => (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected.map((value) => (
                <Chip
                  style={{
                    display:
                      !areaData?.data?.find((area) => area.id === value)
                        ?.name || selectedNames.length === 0
                        ? "none"
                        : "",
                  }}
                  key={value}
                  label={
                    areaData?.data.find((area) => area.id === value)?.name ||
                    "لا يوجد"
                  }
                  onDelete={() => {
                    const newSelectedArea = selectedArea.filter(
                      (id) => id !== value
                    );
                    handleAreaChange(newSelectedArea);
                  }}
                  deleteIcon={
                    <CancelIcon
                      style={{ color: "#FF453A" }}
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                />
              ))}
            </Stack>
          )}
        >
          {areaData?.data?.map((item) => (
            <MenuItem
              key={item?.id}
              value={item?.id}
              sx={{ justifyContent: "space-between" }}
            >
              {item?.name}
              {selectedArea.includes(item?.id) ? (
                <CheckIcon color="info" />
              ) : null}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
export default StepTwoNotification;
