import { useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { tokens } from "../../theme";
export const FirstBox = ({ children, ...rest }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      {...rest}
      sx={{
        "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none",
        },
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
          backgroundColor: "#FFF",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#FFF",
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: "#FFF",
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: "#FFF",
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
      {children}
    </Box>
  );
};

export const SecondBox = ({ children }) => {
  return (
    <Box
      m="20px"
      sx={{
        height: "auto",
        background: "#FFF",
        borderRadius: "12px",
        boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08);",
      }}
    >
      {children}
    </Box>
  );
};
