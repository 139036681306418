import { useState } from "react";
import TextFieldComponent from "../../components/TextFieldComponent";
import { Box, Button } from "@mui/material";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import CustomDialog from "../../components/CustomDialog";
import { addBlockReasons } from "../../services/apis/blockReasons";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { SecondBox } from "../../components/common/CustomBox";

const AddNewReason = () => {
  const [reasonsPayload, setReasonsPayload] = useState({
    trans: [
      {
        local: "en",
        reason: "",
      },
      {
        local: "ar",
        reason: "",
      },
      {
        local: "eg",
        reason: "",
      },
    ],
  });
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const handleSave = async () => {
    try {
      const isEmpty = reasonsPayload.trans.some(
        (transItem) => transItem.reason.trim() === ""
      );

      if (isEmpty) {
        dispatch(
          setData({
            openSnack: true,
            message: "Please fill in all fields",
          })
        );

        return;
      }
      setLoading(true);
      await addBlockReasons(reasonsPayload);
      setSuccessDialogOpen(true);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const handleTransChange = (local, field, newValue) => {
    setReasonsPayload((prevState) => {
      const updatedTrans = prevState?.trans?.map((transItem) => {
        if (transItem?.local === local) {
          return { ...transItem, [field]: newValue };
        }
        return transItem;
      });
      return { ...prevState, trans: updatedTrans };
    });
  };
  return (
    <Box
      style={{
        overflowY: "auto",
        maxHeight: "calc(100vh)",
      }}
    >
      <DashboardMainHeader />

      <Header title="Add Reason" marginL={"25px"} />
      <SecondBox>
        <Box
          m="40px 0 0 0"
          height="auto"
          style={{
            paddingTop: "8%",
            paddingLeft: "9%",
            paddingBottom: "4%",
            width: "90%",
            alignSelf: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <TextFieldComponent
            sx={{ "& fieldset": { border: "none" } }}
            style={{
              height: "48px",
              width: "100%",
              backgroundColor: "#F7F7F7",
              borderRadius: "9px",
            }}
            name="Reason - English"
            placeholder="eg. New Items"
            value={
              reasonsPayload?.trans?.find(
                (transItem) => transItem.local === "en"
              ).reason
            }
            onChange={(event) =>
              handleTransChange("en", "reason", event.target.value)
            }
          />
          <TextFieldComponent
            sx={{ "& fieldset": { border: "none" } }}
            style={{
              height: "48px",
              width: "100%",
              backgroundColor: "#F7F7F7",
              borderRadius: "9px",
            }}
            name="Reason - Arabic"
            placeholder="eg. New Items"
            value={
              reasonsPayload?.trans?.find(
                (transItem) => transItem.local === "ar"
              ).reason
            }
            onChange={(event) => {
              const disallowEnglishRegex = /^[^a-zA-Z]*$/;
              if (
                event.target.value === "" ||
                disallowEnglishRegex.test(event.target.value)
              ) {
                handleTransChange("ar", "reason", event.target.value);
              }
            }}
          />
          <TextFieldComponent
            sx={{ "& fieldset": { border: "none" } }}
            style={{
              height: "48px",
              width: "100%",
              backgroundColor: "#F7F7F7",
              borderRadius: "9px",
            }}
            name="Reason - Egyptian Arabic"
            placeholder="eg. New Items"
            value={
              reasonsPayload?.trans?.find(
                (transItem) => transItem.local === "eg"
              ).reason
            }
            onChange={(event) => {
              const disallowEnglishRegex = /^[^a-zA-Z]*$/;
              if (
                event.target.value === "" ||
                disallowEnglishRegex.test(event.target.value)
              ) {
                handleTransChange("eg", "reason", event.target.value);
              }
            }}
          />
          <Button
            disabled={loading}
            onClick={handleSave}
            sx={{
              "&:hover": { backgroundColor: "#E9C237" },
              backgroundColor: "#E9C237",
              width: "200px",
              height: "46px",
              borderRadius: "12px",
              color: "#ffffff",
              fontSize: "14px",
              display: "flex",
              alignSelf: "flex-end",
              marginTop: "5%",
            }}
          >
            Save
          </Button>
        </Box>
      </SecondBox>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Reason has been added Successfully."
        message=""
        buttonText="Go to Block Reasons"
        buttonLink="/blockReasons"
      />
      <ReusableSnackbar handleClose={handleCloseSnack} />
    </Box>
  );
};
export default AddNewReason;
