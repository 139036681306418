import React, { useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  MenuItem,
  Select,
  OutlinedInput,
  FormControl,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useState } from "react";
import { fetchAllClosedReasons } from "../../services/apis/closedReasons";
import { adminChangeStatus } from "../../services/apis/tickets";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { MenuProps } from "../../components/common/MenuProps";

const CloseTicketDialog = ({
  openAlert,
  handleCloseAlert,
  label,
  message,
  selectedClosedStatusId,
  getTickets,
}) => {
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const [selectedAdminReason, setSelectedAdminReason] = useState(null);
  const [selectedUserReason, setSelectedUserReason] = useState(null);
  const handleAdminReasonChange = (event) => {
    setSelectedAdminReason(event.target.value);
  };
  const handleUserReasonChange = (event) => {
    setSelectedUserReason(event.target.value);
  };
  const [loading, setLoading] = useState(false);
  const [queryParameters] = useState({
    type: "user",
  });
  const [adminQueryParameters] = useState({
    type: "admin",
  });
  const [closedReasons, setClosedReasons] = useState([]);
  const [adminclosedReasons, setAdminClosedReasons] = useState([]);
  const getUserClosedReasons = async () => {
    setLoading(true);
    setClosedReasons([]);
    try {
      const response = await fetchAllClosedReasons(queryParameters);
      setClosedReasons(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  const getAdminClosedReasons = async () => {
    setLoading(true);
    setAdminClosedReasons([]);
    try {
      const response = await fetchAllClosedReasons(adminQueryParameters);
      setAdminClosedReasons(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  const handleStatusChange = async () => {
    try {
      setLoading(true);
      const payload = {
        id: selectedClosedStatusId,
        status: 4,
        admin_close_reason_id: selectedAdminReason?.id,
        user_close_reason_id: selectedUserReason.id,
      };
      await adminChangeStatus(payload);
      handleCloseAlert();
      getTickets();
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getUserClosedReasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAdminClosedReasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!openAlert) {
      setSelectedAdminReason(null);
      setSelectedUserReason(null);
    }
  }, [openAlert]);
  return (
    <>
      <Dialog
        maxWidth="1000px"
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "650px",
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "650px",
            height: "auto",
            paddingBottom: "5%",
            paddingTop: "8%",
            borderRadius: "24px",
            background: "#FFF",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={handleCloseAlert}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box
              sx={{
                width: "90%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Box>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  fill="none"
                >
                  <path
                    d="M31.9987 16.6669C33.1033 16.6669 33.9987 17.5624 33.9987 18.6669V34.6669C33.9987 35.7715 33.1033 36.6669 31.9987 36.6669C30.8941 36.6669 29.9987 35.7715 29.9987 34.6669V18.6669C29.9987 17.5624 30.8941 16.6669 31.9987 16.6669Z"
                    fill="#E9C237"
                  />
                  <path
                    d="M31.9987 45.3336C33.4715 45.3336 34.6654 44.1397 34.6654 42.6669C34.6654 41.1942 33.4715 40.0003 31.9987 40.0003C30.5259 40.0003 29.332 41.1942 29.332 42.6669C29.332 44.1397 30.5259 45.3336 31.9987 45.3336Z"
                    fill="#E9C237"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.33203 32.0003C3.33203 16.1681 16.1665 3.33359 31.9987 3.33359C47.8309 3.33359 60.6654 16.1681 60.6654 32.0003C60.6654 47.8324 47.8309 60.6669 31.9987 60.6669C16.1665 60.6669 3.33203 47.8324 3.33203 32.0003ZM31.9987 7.33359C18.3757 7.33359 7.33203 18.3772 7.33203 32.0003C7.33203 45.6233 18.3757 56.6669 31.9987 56.6669C45.6217 56.6669 56.6654 45.6233 56.6654 32.0003C56.6654 18.3772 45.6217 7.33359 31.9987 7.33359Z"
                    fill="#E9C237"
                  />
                </svg>
              </Box>
              <Typography
                sx={{
                  textAlign: "center",
                  fontFamily: "Lato",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                {message}
              </Typography>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#4C4C4C",
                      marginBottom: "5px",
                      marginTop: "15px",
                    }}
                  >
                    Select Close Reason for Admin
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      displayEmpty
                      onChange={handleAdminReasonChange}
                      value={selectedAdminReason || ""}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected?.length === 0) {
                          return <em>Select Close Reason for Admin</em>;
                        }
                        return selected?.reason;
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {adminclosedReasons.map((item) => (
                        <MenuItem key={item.id} value={item}>
                          {item?.reason}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#4C4C4C",
                      marginBottom: "5px",
                      marginTop: "15px",
                    }}
                  >
                    Select Close Reason for User
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      displayEmpty
                      onChange={handleUserReasonChange}
                      value={selectedUserReason || ""}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected?.length === 0) {
                          return <em>Select Close Reason for User</em>;
                        }
                        return selected?.reason;
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {closedReasons.map((item) => (
                        <MenuItem key={item.id} value={item}>
                          {item?.reason}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              <Box sx={{ display: "flex", gap: 2, marginTop: "10px" }}>
                <Button
                  disabled={loading}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "200px",
                    borderRadius: "6px",
                    fontSize: "18px",
                    background: "#E9C237",
                    height: "46px",
                    fontWeight: 600,
                    "&:hover": {
                      background: "#E9C237",
                      boxShadow: "none",
                    },
                    textTransform: "none",
                  }}
                  onClick={handleStatusChange}
                >
                  {label}
                </Button>

                <Button
                  variant="outlined"
                  sx={{
                    width: "200px",
                    borderRadius: "6px",
                    height: "46px",
                    color: "#E9C237",
                    fontWeight: 600,
                    borderColor: "#E9C237",
                    fontSize: "18px",
                    "&:hover": {
                      borderColor: "#E9C237",
                      backgroundColor: "#fff",
                      boxShadow: "none",
                    },
                    textTransform: "none",
                  }}
                  onClick={handleCloseAlert}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <ReusableSnackbar handleClose={handleCloseSnack} />
    </>
  );
};

export default CloseTicketDialog;
