import TextFieldComponent from "../../components/TextFieldComponent";
import { Box, Divider, Typography } from "@mui/material";
import { useEffect } from "react";
import CustomTextArea from "../../components/CustomTextArea";
import { FaMinus } from "react-icons/fa6";
import { GoPlus } from "react-icons/go";

const StepOneSubQuestion = ({
  setFaqData,
  faqData,
  handleAddSubQuestion,
  handleDeleteSubQuestion,
  selectedTicketData,
  setIsValid,
}) => {
  const { subQuestions: SubQuestions } = faqData;

  const handleSubQuestionChange = (index, field, fieldType, value) => {
    setFaqData((prevFaqData) => ({
      ...prevFaqData,
      subQuestions: prevFaqData.subQuestions.map((subQuestion, i) => {
        if (i === index) {
          return {
            ...subQuestion,
            trans: subQuestion.trans.map((translation) => {
              if (translation.local === field) {
                return {
                  ...translation,
                  [fieldType]: value,
                };
              }
              return translation;
            }),
          };
        }
        return subQuestion;
      }),
    }));
  };
  useEffect(() => {
    const result = SubQuestions.map((item) => {
      if (item?.trans[0]?.question !== "" && item?.trans[0]?.answer !== "") {
        return true;
      } else {
        return false;
      }
    });
    if (!result.includes(false)) {
      setIsValid([true, false, false]);
    } else {
      setIsValid([false, false, false]);
    }
  }, [SubQuestions, setIsValid]);
  return (
    <Box sx={{ marginTop: "5%" }}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography
          sx={{ color: "#666666", fontSize: "16px", fontWeight: 500 }}
        >
          Subject :
        </Typography>
        <Typography
          sx={{
            color: "#666666",
            fontSize: "16px",
            fontWeight: 400,
            marginLeft: "10px",
          }}
        >
          {selectedTicketData?.subject}
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ color: "#666666", fontSize: "16px", fontWeight: 500 }}
        >
          Question :
        </Typography>
        <Typography
          sx={{
            color: "#666666",
            fontSize: "16px",
            fontWeight: 400,
            marginLeft: "10px",
          }}
        >
          {selectedTicketData?.trans?.eg?.question}
        </Typography>
      </Box>
      <Box sx={{ marginTop: "2%" }}>
        <Box>
          {faqData?.subQuestions?.map((subQuestion, index) => (
            <Box key={index}>
              <TextFieldComponent
                sx={{ "& fieldset": { border: "none" } }}
                style={{
                  height: "48px",
                  width: "100%",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                }}
                name={`Sub Question ${index + 1}`}
                placeholder={`Enter Sub Question ${index + 1}`}
                value={
                  faqData.subQuestions[index].trans.find(
                    (t) => t.local === "en"
                  ).question
                }
                onChange={(event) =>
                  handleSubQuestionChange(
                    index,
                    "en",
                    "question",
                    event.target.value
                  )
                }
              />

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#4C4C4C",
                    marginBottom: "5px",
                    marginTop: "15px",
                  }}
                >
                  Answer
                </Typography>
                <CustomTextArea
                  style={{
                    minHeight: "80px",
                    maxHeight: "200px",
                    maxWidth: "100%",
                    minWidth: "100%",
                    backgroundColor: "#FFF",
                    borderRadius: "9px",
                    boxShadow: "none",
                    border: "1px solid #E9C237",
                  }}
                  name="Answer"
                  placeholder="Enter Answer"
                  value={
                    faqData.subQuestions[index].trans.find(
                      (t) => t.local === "en"
                    ).answer
                  }
                  onChange={(event) =>
                    handleSubQuestionChange(
                      index,
                      "en",
                      "answer",
                      event.target.value
                    )
                  }
                />
              </Box>

              <Box
                sx={{
                  display: SubQuestions?.length === 1 ? "none" : "flex",
                  marginTop: "10px",
                  justifyContent: "flex-end",
                  cursor: "pointer",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onClick={() => {
                  handleDeleteSubQuestion(index);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    backgroundColor: "rgba(255, 69, 58, 0.3)",
                    borderRadius: "50%",
                    marginRight: "5px",
                    padding: "2px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FaMinus
                    color="#FF453A"
                    fontSize={12}
                    style={{
                      alignSelf: "center",
                    }}
                  />
                </Box>
                <Typography sx={{ color: "#FF453A", fontSize: "14px" }}>
                  Delete
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            marginTop: "30px",
            justifyContent: "flex-start",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box
            onClick={() => {
              handleAddSubQuestion();
            }}
            sx={{
              cursor: "pointer",
              display: "flex",
              backgroundColor: "rgba(233, 194, 55, 0.3)",
              borderRadius: "50%",
              marginRight: "5px",
              padding: "2px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <GoPlus
              color="#E9C237"
              fontSize={14}
              style={{
                display: "flex",
                alignSelf: "center",
              }}
            />
          </Box>
          <Typography
            onClick={() => {
              handleAddSubQuestion();
            }}
            sx={{
              cursor: "pointer",
              color: "#E9C237",
              fontSize: "14px",
            }}
          >
            Add another sub question
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default StepOneSubQuestion;
