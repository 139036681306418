import { axiosInstance as axios } from "../config";

export const configration = async (body) => {
  try {
    const response = await axios.get(`/api/config/index`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const updateFraction = async (id, body) => {
  try {
    const response = await axios.post(`/api/config/edit/${id}`, body);
    return response;
  } catch (error) {
    throw error;
  }
};
