import React from "react";
import {
  Button,
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Avatar,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import TextFieldComponent from "../../../components/TextFieldComponent";
import { useEffect } from "react";
import { fetchProductsRequest } from "../../../services/apis/products";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { addNewInventoryRequest } from "../../../services/apis/inventory";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import { MenuProps } from "../../../components/common/MenuProps";

const RequestDialog = ({
  open,
  onClose,
  label,
  setRequests,
  fetchData,
  productId,
  handleResponse,
  queryParameters,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [type, setType] = useState("ingot");
  const [productsqueryParameters] = useState({
    inventory_type: "ingot",
  });
  const [requestsPayload, setRequestsPayload] = useState({
    product_id: null,
    amount: null,
    provider_id: null,
    storage_location: "",
  });
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const provider = [
    { id: 1, name: "Dahab masr" },
    { id: 2, name: "Dahab masr 2" },
  ];
  const handleProducts = async () => {
    setProducts([]);
    try {
      const response = await fetchProductsRequest({
        ...productsqueryParameters,
        inventory_type: type,
      });
      const products = response?.data?.data;
      setProducts(products);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  const resetForm = () => {
    setRequestsPayload({
      product_id: null,
      amount: null,
      provider_id: null,
      storage_location: "",
    });
    onClose();
  };
  const handleCloseDialog = () => {
    resetForm();
    setType("ingot");
  };
  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };
  const handleChange = (name, value) => {
    if (name === "type") {
      setType(value);
    } else {
      setRequestsPayload((prevPayload) => ({
        ...prevPayload,
        [name]: value,
      }));
    }
  };
  const validateFields = () => {
    return (
      requestsPayload.product_id &&
      requestsPayload.amount &&
      requestsPayload.provider_id &&
      requestsPayload.storage_location
    );
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (validateFields()) {
        const amount = parseFloat(requestsPayload.amount);
        const isValidAmount = /^\d+(\.\d+)?$/.test(requestsPayload.amount);
        if (isValidAmount && !isNaN(amount) && amount > 0) {
          setIsSubmitting(true);
          await addNewInventoryRequest(requestsPayload);
          const response = await fetchData(queryParameters);
          const data = response?.data?.data?.result;
          setRequests(data);
          onClose();
          setSuccessDialogOpen(true);
          await handleResponse();
        } else {
          dispatch(
            setData({
              openSnack: true,
              message: "Please enter a valid number for the amount.",
            })
          );
        }
      } else {
        dispatch(
          setData({
            openSnack: true,
            message: "Please fill in all fields.",
          })
        );
      }
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    handleProducts();
    setRequestsPayload({
      product_id: null,
      amount: null,
      provider_id: null,
      storage_location: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, productId, fetchData, productsqueryParameters, type]);
  return (
    <>
      <Dialog
        open={successDialogOpen}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: {
            width: "585px",
            height: "506px",
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#D9F4DD",
              height: "80px",
              width: "80px",
              alignSelf: "center",
              marginRight: "15px",
            }}
          >
            <img src={`../../assets/Vector.png`} alt="vector" />
          </Avatar>
          <Typography
            sx={{ marginTop: "10%", marginBottom: "10%" }}
            variant="h6"
          >
            Request has been sent Successfully.
          </Typography>
          <Button
            onClick={() => {
              handleSuccessDialogClose();
            }}
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#E9C237",
              "&:hover": { backgroundColor: "#E9C237" },
              height: "46px",
              width: "291px",
              borderRadius: "9px",
            }}
          >
            Go to Requests
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="700px"
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "700px",
            height: "auto",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            paddingTop: "5%",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              width: "80%",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={onClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  color: "#000000",
                  fontWeight: "400",
                  fontSize: "24px",
                  display: "flex",
                  alignSelf: "flex-start",
                }}
              >
                Request Order
              </Box>
            </Box>
            <FormControl
              sx={{ display: "flex", width: "100%", flexDirection: "column" }}
            >
              <Box sx={{ marginTop: "15px" }}>
                <FormLabel
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#4C4C4C",
                  }}
                  id="demo-row-radio-buttons-group-label"
                >
                  Type
                </FormLabel>
                <RadioGroup
                  style={{ marginTop: "15px" }}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={type}
                  onChange={(event) => handleChange("type", event.target.value)}
                >
                  <FormControlLabel
                    style={{ color: "#4C4C4C" }}
                    value="ingot"
                    control={<Radio />}
                    label="Ingots"
                  />
                  <FormControlLabel
                    style={{ color: "#4C4C4C" }}
                    value="fraction"
                    control={<Radio />}
                    label="Fractions"
                  />
                </RadioGroup>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#4C4C4C",
                    marginBottom: "5px",
                    marginTop: "15px",
                  }}
                >
                  Select Product
                </Typography>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    displayEmpty
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                    value={requestsPayload.product_id || ""}
                    onChange={(event) =>
                      handleChange("product_id", event.target.value)
                    }
                    inputProps={{ "aria-label": "Without label" }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Select Product</em>;
                      }
                      const selectedProduct = products.find(
                        (product) => product.id === selected
                      );
                      return selectedProduct ? selectedProduct.name : "";
                    }}
                  >
                    {products.map((product) => (
                      <MenuItem key={product.id} value={product.id}>
                        {product.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#4C4C4C",
                    marginBottom: "5px",
                    marginTop: "15px",
                  }}
                >
                  Select Provider
                </Typography>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    displayEmpty
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                    value={requestsPayload.provider_id || ""}
                    onChange={(event) =>
                      handleChange("provider_id", event.target.value)
                    }
                    inputProps={{ "aria-label": "Without label" }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Select Provider</em>;
                      }
                      return (
                        provider.find((item) => item.id === selected)?.name ||
                        ""
                      );
                    }}
                  >
                    {provider.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <TextFieldComponent
                sx={{ "& fieldset": { border: "none" } }}
                style={{
                  height: "48px",
                  width: "534px",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                }}
                name="Enter Quantity"
                placeholder="Enter Quantity"
                value={requestsPayload.amount || ""}
                onChange={(event) => {
                  var temppayload = { ...requestsPayload };
                  temppayload.amount = event.target.value;
                  setRequestsPayload({ ...temppayload });
                }}
              />
              <TextFieldComponent
                sx={{ "& fieldset": { border: "none" } }}
                style={{
                  height: "48px",
                  width: "534px",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                }}
                name="Enter Storage Location"
                placeholder="Enter Storage Location"
                value={requestsPayload.storage_location || ""}
                onChange={(event) => {
                  var temppayload = { ...requestsPayload };
                  temppayload.storage_location = event.target.value;
                  setRequestsPayload({ ...temppayload });
                }}
              />
            </FormControl>

            <Box sx={{ display: "flex", gap: 2, marginTop: "10px" }}>
              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  background: "#E9C237",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#E9C237",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#E9C237",
                  fontWeight: 600,
                  borderColor: "#E9C237",
                  "&:hover": {
                    borderColor: "#E9C237",
                  },
                }}
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <ReusableSnackbar handleClose={handleCloseSnack} />
    </>
  );
};

export default RequestDialog;
