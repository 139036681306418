import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { styled, useTheme } from "@mui/material/styles";
import { fNumber } from "./Format-number";
import useChart from "./UseChart";
import Chart from "./Chart";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 330;
const LEGEND_HEIGHT = 72;

const StyledChart = styled(Chart)(({ theme }) => ({
  height: CHART_HEIGHT,
  "& .apexcharts-canvas, .apexcharts-inner, svg, foreignObject": {
    height: `100% !important`,
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

export default function DonutChart({
  title,
  subheader,
  chart,
  donutLabel,
  ...other
}) {
  const theme = useTheme();

  const { series, options } = chart;
  const updatedSeries = series.map((item, index) => ({
    ...item,
    color:
      item.color ||
      (index === 0
        ? "#E9C237"
        : index === 1
        ? "#F2DA87"
        : index === 2
        ? "#F6E7AF"
        : "#FBF3D7"),
  }));

  const chartSeries = updatedSeries.map((i) => i.value);

  const chartOptions = useChart({
    chart: {
      type: "donut",
      sparkline: {
        enabled: true,
      },
    },
    colors: updatedSeries.map((item) => item.color),
    labels: series.map((i) => i.label),
    stroke: {
      colors: [theme.palette.background.paper],
    },
    legend: {
      floating: true,
      position: "bottom",
      horizontalAlign: "center",
    },
    dataLabels: {
      enabled: false,
      dropShadow: {
        enabled: false,
      },
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (value) => fNumber(value),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },

    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: [donutLabel],
            },
          },
        },
      },
    },
    ...options,
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} sx={{ mb: 5 }} />
      <StyledChart
        dir="ltr"
        type="donut"
        series={chartSeries}
        options={chartOptions}
        width="100%"
        height={250}
      />
    </Card>
  );
}

DonutChart.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string,
};
