import React from "react";
import { Box, Typography } from "@mui/material";
import security from "../../svg/security.svg";
const DeactivatedAdmin = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "500px",
          height: "500px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={security}
          alt="security"
          style={{
            height: "100%",
            width: "100%",
            resize: "contain",
          }}
        />
        <Typography
          sx={{ color: "#E9C237", fontSize: "40px", fontWeight: "500" }}
        >
          You have been Deactivated
        </Typography>
        <Typography
          sx={{ color: "#B3B3B3", fontSize: "18px", fontWeight: "400" }}
        >
          Contact your Supervisor for more Details
        </Typography>
      </Box>
    </Box>
  );
};

export default DeactivatedAdmin;
