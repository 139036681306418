export default function columnsGenerator(
  data,
  type,
  handleCheckboxChange,
  handleChangeKyc
) {
  const col = [];
  if (data.length > 0) {
    const firstDataItem = data[0];
    Object.keys(firstDataItem).forEach((key) => {
      if (key === "id") return
      if (key === "Policy / Role") {
        col.push({
          field: key,
          headerName: key.charAt(0).toUpperCase() + key.slice(1),
          flex: key === "id" ? 0 : 1,
        });
      } else if (key === "Is Allowed") {
        col.push({
          field: key,
          headerName: key.charAt(0).toUpperCase() + key.slice(1),
          flex: 1,
          renderCell: (params) => {
            console.log("params", params);
            const isKyc = params.row["Is Allowed"];
            return (
              <>
                <input
                  type="checkbox"
                  checked={isKyc}
                  onChange={(e) => handleChangeKyc(params.row.rid)}
                />
              </>
            );
          },
        });
      }
      else if (key === "rid") {
        col.push({
          field: key,
          headerName: key.charAt(0).toUpperCase() + key.slice(1),
          flex: 1,
          hide: true,
        });
      }
      else {
        col.push({
          field: key,
          headerName: key.charAt(0).toUpperCase() + key.slice(1),
          flex: 1,
          renderCell: (params) => {
            // console.log("params", params);
            return (
              <>
                <input
                  type="checkbox"
                  checked={type[params.id]?.roles?.includes(params.value)}
                  onChange={(event) =>
                    handleCheckboxChange(event, type[params.id], params.value)
                  }
                />
              </>
            );
          },
        });
      }
    });
  }
  return col;
}

export const merge = (p, r, setterFn, type) => {
  const data = [];
  console.log('permissions', p)
  for (let i = 0; i < p.length; i++) {
    const td =
      type === 1
        ? {
          id: i,
          "Policy / Role": p[i].name,
          "Is Allowed": p[i].isKyc,
          rid: p[i].id,
        }
        : {
          id: i,
          "Policy / Role": p[i].name,
        };

    for (let j = 0; j < r.length; j++) {
      td[r[j].name] = r[j].id;
    }
    data.push(td);
  }
  console.log("merge data", data);
  setterFn(data);
};
