import React, { useRef, useMemo } from "react";
import JoditEditor from "jodit-react";

export const MyEditor = ({ placeholder, text, setText }) => {
  const editor = useRef(null);
  const config = useMemo(
    () => ({
      disablePlugins: ["paste"],
      askBeforePasteHTML: false,
      processPasteHTML: false,
      nl2brInPlainText: false,
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: placeholder || "Start typings...",
      defaultActionOnPasteFromWord: "insert_clear_html",
    }),
    [placeholder]
  );
  return (
    <JoditEditor
      ref={editor}
      value={text}
      config={config}
      tabIndex={1} // tabIndex of textarea
      onBlur={setText} // preferred to use only this option to update the content for performance reasons
      onChange={(newContent) => {}}
    />
  );
};
