import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Tab, Tabs } from "@mui/material";
import { DataGridStyle } from "../../styles";
import Header from "../../components/Header";
import { useFetchProductsInfo } from "../../services/apis/products";
import { handlePageChange } from "../../services/utils/filters";
import ProductModal from "./ProductModal";
import ProductDetails from "./ProductDetails";
import { withStyles } from "@mui/styles";
import { TabContext, TabPanel } from "@mui/lab";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { useNavigate } from "react-router-dom";
import AddButton from "../../components/common/AddButton";
import { FirstBox, SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
import ProductEditModal from "../product-details/ProductEditModal";
const AddNewProduct = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState();
  const [functionTypeParams, setFunctionTypeParams] = useState(null);
  const [open, setOpen] = useState(false);
  const [newOpen, setNewOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [value, setValue] = useState("1");
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
    orderBy: null,
    sort: null,
    inventory_type: "ingot",
  });
  const { data, refetch, isFetching } = useFetchProductsInfo(queryParameters);
  const handleAddProduct = () => {
    setSelectedProductId(null);
    setOpen(true);
    setFunctionTypeParams("add");
  };

  const AntTabs = withStyles({
    root: {
      borderBottom: "none",
    },
    indicator: {
      display: "none",
    },
  })(Tabs);
  const AntTab = withStyles((theme) => ({
    root: {
      "&:hover": {
        color: "#00303C",
        opacity: 1,
      },
      "&$selected": {
        color: "#ffffff",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        borderBottom: "none",
      },
      "&:focus": {
        color: "#00303C",
      },
    },
    selected: {
      "&$selected": {
        borderBottom: "none",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        color: "#ffffff !important",
      },
    },
  }))((props) => <Tab disableRipple {...props} />);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "1") {
      setQueryParameters({
        ...queryParameters,
        page: 0,
        inventory_type: "ingot",
      });
    } else if (newValue === "2") {
      setQueryParameters({
        ...queryParameters,
        page: 0,
        inventory_type: "fraction",
      });
    }
  };
  const handleClose = async () => {
    setOpen(false);
  };
  const handleCloseDetails = async () => {
    setOpenDetails(false);
  };

  const IngotsColumns = ItemsColumns([
    {
      field: "nameEn",
      headerName: "Name",
    },
    {
      field: "weight",
      add: "Gm",
    },
    {
      field: "stored_fees",
      headerName: "Storage Fees",
      add: "EGP",
    },
    { field: "inventory_amount", headerName: "Inventory Amount" },
    { field: "sellCount", headerName: "Available to sell" },
    {
      field: "availability",
      headerName: "Product Status",
      specialRender: "availability",
    },
    {
      field: "action",
      headerName: "Actions",
      actions: {
        view: (params) =>
          navigate(`/product-details/${params?.id}`, {
            state: {
              product: params?.row,
            },
          }),
        edit: (params) => {
          setSelectedProduct(params?.row);
          setNewOpen(true);
        },
      },
    },
  ]);
  const fractionsColumns = ItemsColumns([
    { field: "nameEn", headerName: "Name" },
    { field: "weight", add: "Gm" },
    { field: "inventory_amount", headerName: "Inventory Amount" },
    { field: "sellCount", headerName: "Available to sell" },
    {
      field: "availability",
      headerName: "Product Status",
      specialRender: "availability",
    },
    {
      field: "action",
      headerName: "Actions",
      actions: {
        view: (params) =>
          navigate(`/product-details/${params?.id}`, {
            state: {
              product: params?.row,
            },
          }),
        edit: (params) => {
          setSelectedProductId(params?.id);
          setOpen(true);
        },
      },
    },
  ]);

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <DashboardMainHeader />
      <Header title="Gold Products" marginL={"20px"} />
      <ProductModal
        value={value}
        queryParameters={queryParameters}
        fetchData={() => refetch(queryParameters)}
        setProducts={setProducts}
        products={products}
        productId={selectedProductId}
        functionTypeParams={functionTypeParams}
        open={open}
        onClose={handleClose}
        label={"Save"}
      />
      <ProductEditModal
        open={newOpen}
        setOpen={setNewOpen}
        product={selectedProduct || {}}
      />

      <ProductDetails
        value={value}
        fetchData={() => refetch(queryParameters)}
        products={products}
        productId={selectedProductId}
        open={openDetails}
        onClose={handleCloseDetails}
      />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 2,
          p: 2,
          justifyContent: "end",
        }}
      >
        <AddButton
          title={"+ New Product"}
          onClick={() => {
            handleAddProduct();
          }}
        />
      </Box>

      <SecondBox>
        <FirstBox m="40px 0 0 0" height="100%">
          <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
            <TabContext value={value}>
              <Box
                sx={{
                  width: "100%",
                  paddingTop: "2%",
                  paddingBottom: "1%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <AntTabs
                  sx={{
                    backgroundColor: "#F5F6FF",
                    borderRadius: "9px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  value={value}
                  onChange={handleChange}
                  aria-label="ant example"
                >
                  <AntTab sx={{ width: "512px" }} label="Ingots" value="1" />
                  <AntTab sx={{ width: "512px" }} label="Fractions" value="2" />
                </AntTabs>
              </Box>
              <TabPanel value="1">
                <Box
                  sx={{
                    height: "auto",
                    width: "100%",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                  }}
                >
                  <DataGrid
                    disableSelectionOnClick
                    sx={{
                      cursor: "pointer",
                      backgroundColor: "#ffffff",
                      height: "630px",
                    }}
                    loading={isFetching}
                    pageSize={10}
                    rowCount={data?.totalItems}
                    rows={isFetching ? [] : data?.result}
                    columns={IngotsColumns}
                    paginationMode="server"
                    onPageChange={(p) =>
                      handlePageChange(p, queryParameters, setQueryParameters)
                    }
                    rowsPerPageOptions={[10]}
                    totalPages={data?.totalPages}
                    localeText={{
                      noRowsLabel: "No gold ingots available",
                    }}
                  />
                </Box>
              </TabPanel>
              <TabPanel value="2">
                <Box
                  sx={{
                    height: "auto",
                    width: "100%",
                    justifyContent: "center",
                    background: "#ffffff",
                  }}
                >
                  <DataGrid
                    disableSelectionOnClick
                    sx={{
                      cursor: "pointer",
                      backgroundColor: "#ffffff",
                      height: "630px",
                    }}
                    pageSize={10}
                    rowCount={data?.totalItems}
                    loading={isFetching}
                    rows={isFetching ? [] : data?.result}
                    columns={fractionsColumns}
                    onPageChange={(p) =>
                      handlePageChange(p, queryParameters, setQueryParameters)
                    }
                    rowsPerPageOptions={[10]}
                    totalPages={data?.totalPages}
                    localeText={{
                      noRowsLabel: "No gold fractions available",
                    }}
                  />
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </FirstBox>
      </SecondBox>
    </Box>
  );
};

export default AddNewProduct;
