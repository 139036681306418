import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { fetchTerms, deleteTerm } from "../../services/apis/terms";
import { DataGridStyle } from "../../styles";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import ActionAlert from "../user-profile/ActionAlert";
import CustomDialog from "../../components/CustomDialog";
import EditTerm from "./EditTerm";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import AddButton from "../../components/common/AddButton";
import { ItemsColumns } from "../../components/common/Columns";
const Terms = () => {
  const [disableWhileDelete, setDisableWhileDelete] = useState(false);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [terms, setTerms] = useState([]);
  const [selectedTermId, setSelectedTermId] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [openDeletedAlert, setOpenDeletedAlert] = useState(false);
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const navigate = useNavigate();
  const getTerms = async () => {
    setLoading(true);
    setTerms([]);
    try {
      const response = await fetchTerms();
      setTerms(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteTerm = async () => {
    if (selectedTermId) {
      setDisableWhileDelete(true);
      try {
        await deleteTerm(selectedTermId);
        setDisableWhileDelete(false);
        setSuccessDialogOpen(true);
        handleCloseDeleteAlert();
        getTerms();
      } catch (error) {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      }
    }
  };
  const handleEditTerm = (termId) => {
    setSelectedTerm(termId);
    setOpen(true);
  };
  const handleCloseDeleteAlert = () => {
    setOpenDeletedAlert(false);
  };
  const handleOpenDeleteAlert = (id) => {
    setSelectedTermId(id);
    setOpenDeletedAlert(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const termsColumns = ItemsColumns([
    {
      field: "id",
      headerName: "Order",
    },
    {
      field: "title_en",
      headerName: "Title",
      flex: 2,
    },
    {
      field: "description_en",
      headerName: "Text",
      flex: 2,
    },
    {
      field: "action",
      headerName: "Actions",
      actions: {
        edit: (params) => handleEditTerm(params.row.id),
        delete: (params) => handleOpenDeleteAlert(params.row.id),
      },
    },
  ]);

  const handleAddTerms = () => {
    navigate(`/AddNewTerms`);
  };
  useEffect(() => {
    getTerms();
  }, []);
  return (
    <>
      <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
        <DashboardMainHeader />

        <Header title="Terms and Conditions" marginL={"25px"} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            gap: 2,
            pr: 2,
          }}
        >
          <AddButton
            width="307px"
            title={"+ New Terms and Conditions"}
            onClick={handleAddTerms}
          />
        </Box>
        <Box
          m="20px"
          sx={{
            height: "auto",
            background: "#FFF",
            borderRadius: "12px",
            boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08);",
          }}
        >
          <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
            <DataGrid
              rows={terms}
              columns={termsColumns}
              loading={loading}
              autoHeight={true}
              components={{
                Pagination: () => null,
                Footer: () => null,
              }}
              localeText={{
                noRowsLabel: "No Terms and Conditions",
              }}
            />
          </Box>
        </Box>
        <ActionAlert
          openAlert={openDeletedAlert}
          handleCloseAlert={handleCloseDeleteAlert}
          handleReject={handleDeleteTerm}
          message={`Are you sure you want to Delete this Term ?`}
          label={"Delete"}
          disabled={disableWhileDelete}
        />
        <CustomDialog
          open={successDialogOpen}
          onClose={() => setSuccessDialogOpen(false)}
          title="Term has been deleted Successfully."
          message=""
          buttonText="Go to Terms and Conditions"
        />
        <EditTerm
          fetchData={fetchTerms}
          setTerms={setTerms}
          terms={terms}
          termId={selectedTerm}
          open={open}
          onClose={handleClose}
          label={"Save"}
        />
        <ReusableSnackbar handleClose={handleCloseSnack} />
      </Box>
    </>
  );
};

export default Terms;
