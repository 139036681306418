import { useMutation } from "react-query";
import { axiosInstance as axios } from "../config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setToken } from "../../store/slices/authenticationSlice";
export const login = async (email, password) => {
  try {
    const body = { email, password };
    const response = await axios.post(`/api/admin/login`, body);
    const { data } = response;
    const { accessToken } = response.data.data;
    localStorage.setItem("token", accessToken);
    return data;
  } catch (error) {
    // Instead of returning the error directly, throw it
    throw error;
  }
};

export const logout = async () => {
  try {
    localStorage.removeItem("token");
  } catch (error) {
    throw error;
  }
};

// hooks/useAuth.js
export const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const queryClient = useQueryClient();

  return useMutation(({ email, password }) => login(email, password), {
    onSuccess: (data) => {
      dispatch(setToken(data?.data?.accessToken));
      navigate("/", { replace: true });
    },
  });
};

export const useLogout = () => {
  // const queryClient = useQueryClient();

  return useMutation(() => logout(), {
    // onSuccess: () => {
    //   // Optionally update your query cache here
    //   queryClient.invalidateQueries("auth"); // Invalidate auth queries if needed
    // },
    // onError: (error) => {
    //   // Handle errors here
    //   console.error("Logout failed:", error);
    // },
  });
};
