import { Box, Button, Typography } from "@mui/material";
import newEye from "../../svg/neweye.svg";
import { DeleteSvgWithFunc, EditSvgWithFunc } from "./SvgWithFunc";
import { formDate } from "../../services/helpers";
import {
  changeStatusColor,
  capitalizeFirstLetter,
} from "../../services/utils/stylesModifiers";

export const ItemsColumns = (columnsConfig) => {
  const defaultFlex = 1;
  const defaultHeaderAlign = "center";
  const defaultAlign = "center";
  const defaultHeaderClassName = "bold-header";

  const columns = columnsConfig.map((column) => {
    let renderCell;

    if (column.add) {
      renderCell = (params) =>
        `${params.row[column.field] || ""} ${column.add}`;
    } else if (column.specialRender === "availability") {
      renderCell = (params) => {
        const availability = params.row.availability;
        let color = "";
        let background = "";
        let circle = "";

        if (availability === 0) {
          color = "#CF2424";
          background = "#F230450D";
          circle = "#CF2424";
        } else if (availability === 1) {
          color = "#24CF80";
          background = "#40C7540D";
          circle = "#24CF80";
        }

        return (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              borderRadius: "100px",
              background,
              width: "147px",
              height: "36px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="8"
                viewBox="0 0 9 8"
                fill="none"
              >
                <circle cx="4.22852" cy="4" r="4" fill={circle} />
              </svg>
            </Box>
            <Typography sx={{ color }}>
              {availability === 0 ? "Out Of Stock" : "In Stock"}
            </Typography>
          </Box>
        );
      };
    } else if (column.specialRender === "customView") {
      renderCell = (params) => {
        const transaction = params.row;
        const shouldRenderIcon =
          transaction.type === "Withdraw" ||
          (transaction.type === "Deposit" &&
            transaction.paymentMethod !== "paymob");

        return (
          shouldRenderIcon && (
            <img
              style={{ cursor: "pointer" }}
              src={newEye}
              alt="eye"
              onClick={() => column.actions.view(params)}
            />
          )
        );
      };
    } else if (column.specialRender === "paymentMethods") {
      renderCell = (params) =>
        params.row.paymentMethod === null
          ? "Gold Transaction"
          : params.row.paymentMethod;
    } else if (column.specialRender === "totalWeight") {
      renderCell = (params) => {
        const value = params?.value;
        if (value == null) return <span>-</span>; // Handle undefined or null

        let formattedValue;
        if (Number.isInteger(value)) {
          formattedValue = Math.abs(value);
        } else {
          const absValue = Math.abs(value);
          formattedValue = absValue.toFixed(3).replace(/\.?0+$/, "");
        }
        return <span>{formattedValue}</span>;
      };
    } else if (column.specialRender === "absLocal") {
      renderCell = (params) => {
        const value = params?.value;
        return <span>{Math.abs(value).toLocaleString()}</span>;
      };
    } else if (column.specialRender === "local") {
      renderCell = (params) => {
        const value = params?.value;
        return <span>{value.toLocaleString()}</span>;
      };
    } else if (column.specialRender === "abs") {
      renderCell = (params) => {
        const value = params?.value;
        return <span>{Math.abs(value)}</span>;
      };
    } else if (column.specialRender === "goldTransactionType") {
      renderCell = (params) => {
        let transactionTypeLabel;
        let color;
        switch (params.value) {
          case 1:
            transactionTypeLabel = "buy";
            color = "#40C754";
            break;
          case 2:
            transactionTypeLabel = "sell";
            color = "#F23045";
            break;
          default:
            transactionTypeLabel = "Unknown";
            color = "#000"; // Default color for unknown types
        }
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              color,
            }}
          >
            <Typography>{transactionTypeLabel}</Typography>
          </Box>
        );
      };
    } else if (column.specialRender === "serials") {
      renderCell = (params) => {
        const { value: serials, row } = params;
        const combinedSerials = [
          ...(serials || []),
          ...(row.sold_serials || []),
        ];

        if (Array.isArray(combinedSerials)) {
          if (combinedSerials.length > 1) {
            return (
              <Typography
                variant="body2"
                color="#E9C237"
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => column.serialsFunction(params)}
              >
                See more
              </Typography>
            );
          } else if (combinedSerials.length === 1) {
            return <span>{combinedSerials[0]}</span>;
          }
        }
        return null;
      };
    } else if (column.specialRender === "orderStatus") {
      renderCell = (params) => {
        let color = "";
        switch (params.value.name) {
          case "New order":
            color = "#CF2424";
            break;
          case "In Progress":
            color = "#ECB800";
            break;
          case "Out for Delivery":
            color = "#24CF80";
            break;
          case "Deliverd":
            color = "#2492CF";
            break;
          case "Completed":
            color = "#00303C";
            break;
          case "canceled":
            color = "#000000";
            break;
          default:
            color = "#000000"; // Default color for unknown statuses
        }
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography sx={{ color: color }}>{params?.value?.name}</Typography>
          </Box>
        );
      };
    } else if (column.specialRender === "customRender") {
      renderCell = (params) => column.renderFunction(params);
    } else if (column.specialRender === "changeStatus") {
      renderCell = (params) => {
        const status = params.row.orderStatus;
        const statusName = status?.name;
        let color = "";

        if (statusName === "canceled") {
          return (
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                color: "#000000",
              }}
            >
              Canceled
            </Typography>
          );
        } else if (statusName === "Completed") {
          return (
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                color: "#00303C",
              }}
            >
              Completed
            </Typography>
          );
        } else {
          const statusId = status?.sort;
          if (column?.orderStatus?.data[statusId]?.name === "In Progress")
            color = "#ECB800";
          if (column?.orderStatus?.data[statusId]?.name === "Out for Delivery")
            color = "#24CF80";
          if (column?.orderStatus?.data[statusId]?.name === "Deliverd")
            color = "#2492CF";

          return column?.orderStatus?.data[statusId]?.name &&
            params.row.order_type !== 3 ? (
            <Button
              variant="outlined"
              sx={{
                border: `1px solid ${color}`,
                width: "150px",
                height: "33px",
                color: color,
                borderRadius: "100px",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
              }}
              onClick={() => {
                column?.orderStatusFunction(params);
              }}
            >
              {column?.orderStatus?.data[statusId]?.name}
            </Button>
          ) : (
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                color: "#00303C",
              }}
            >
              Completed
            </Typography>
          );
        }
      };
    } else if (column.specialRender === "permissions") {
      renderCell = (params) => {
        const permissions = params.row.permissions;
        const permissionElements = permissions.map((permission) => (
          <div
            key={permission.id}
            style={{
              background: "#F5F6FF",
              padding: "6px 16px",
              margin: "2px",
              borderRadius: "100px",
            }}
          >
            {permission.name}
          </div>
        ));

        return (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {permissionElements}
            </div>
            {params?.row?.permissionsCount !== 0 && (
              <div>+ {params?.row?.permissionsCount} More</div>
            )}
          </>
        );
      };
    } else if (column.actions) {
      renderCell = (params) => (
        <Box sx={{ display: "flex", gap: 2 }}>
          {column.actions.view && (
            <Box>
              <img
                style={{ cursor: "pointer" }}
                src={newEye}
                alt="view"
                onClick={() => column.actions.view(params)}
              />
            </Box>
          )}
          {column.actions.edit && (
            <Box>
              <EditSvgWithFunc onClick={() => column.actions.edit(params)} />
            </Box>
          )}
          {column.actions.delete && (
            <Box>
              <DeleteSvgWithFunc
                onClick={() => column.actions.delete(params)}
              />
            </Box>
          )}
        </Box>
      );
    } else if (column.float) {
      renderCell = (params) => {
        const value = parseFloat(params.row[column.field]);
        return !isNaN(value) ? value.toLocaleString() : "0";
      };
    } else if (column.isOnline) {
      renderCell = (params) => {
        const style = {
          fontFamily: "Roboto",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          color: params.value === 0 ? "#CF2424" : "#2492CF",
        };
        return (
          <Typography sx={style}>
            {params.value === 0 ? "Offline" : "Online"}
          </Typography>
        );
      };
    } else if (column.date) {
      renderCell = (params) => formDate(params.value);
    } else if (column.kyc) {
      renderCell = (params) => {
        const style = {
          background: "",
          font: "",
          circle: "",
        };

        changeStatusColor(style, params.value);

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              height: "37px",
              width: "80%",
              justifyContent: "center",
              borderRadius: "100px",
              background: style.background,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="8"
                viewBox="0 0 9 8"
                fill="none"
              >
                <circle cx="4.22852" cy="4" r="4" fill={style.circle} />
              </svg>
            </Box>
            <Typography
              sx={{
                alignSelf: "center",
                color: style.font,
                fontFamily: "Lato",
                fontStyle: "normal",
                fontSize: "12px",
                fontWeight: 400,
              }}
            >
              {capitalizeFirstLetter(params.value)}
            </Typography>
          </Box>
        );
      };
    } else {
      renderCell = column.renderCell || ((params) => params.value);
    }

    return {
      headerClassName: column.headerClassName || defaultHeaderClassName,
      field: column.field,
      headerName: column?.headerName
        ? column.headerName
        : capitalizeFirstLetter(column.field),
      flex: column.flex || defaultFlex,
      headerAlign: column.headerAlign || defaultHeaderAlign,
      align: column.align || defaultAlign,
      renderCell,
    };
  });

  return columns;
};
