import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "../../../styles";
import { useState } from "react";
import { usePendingOrders } from "../../../services/apis/orders";
import RequestOrderDialog from "./RequestOrderDialog";
import { ItemsColumns } from "../../../components/common/Columns";

const FirstPendingTabPanel = () => {
  const [open, setOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const { data, isFetching, refetch } = usePendingOrders();

  const handleRequestOrder = (params) => {
    setSelectedRequest(params);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const allColumns = ItemsColumns([
    { field: "name", headerName: "Product" },
    {
      field: "amount",
      specialRender: "customRender",
      renderFunction: (params) => {
        return params.row.name === "Fractions"
          ? params.row.total_weight
          : params.row.amount;
      },
    },
    { field: "created", date: true },
    {
      field: "Action",
      specialRender: "customRender",
      renderFunction: (params) => {
        return (
          <Box
            sx={{
              backgroundColor: "#E9C237",
              padding: "8px 24px 8px 24px",
              borderRadius: "100px",
            }}
            onClick={() => handleRequestOrder(params.row)}
          >
            <Typography sx={{ color: "#fff" }}>Request Order</Typography>
          </Box>
        );
      },
    },
  ]);

  return (
    <Box>
      <Box
        sx={{
          height: "auto",
          background: "#FFF",
          borderRadius: "12px",
        }}
      >
        <Box m="40px 0 0 0" height="100%" sx={DataGridStyle}>
          <DataGrid
            rows={isFetching ? [] : data?.data?.data}
            columns={allColumns}
            loading={isFetching}
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
              height: "630px",
            }}
            getRowId={(row) => row.order_id}
            autoHeight={true}
            components={{
              Footer: () => null,
            }}
          />
        </Box>
      </Box>
      <RequestOrderDialog
        fetchData={() => {
          refetch();
        }}
        selectedRequest={selectedRequest}
        handleResponse={() => {
          refetch();
        }}
        open={open}
        onClose={handleClose}
        label={"Request"}
      />
    </Box>
  );
};
export default FirstPendingTabPanel;
