import React, { useState } from "react";
import { Button, Box, useTheme, Typography, Checkbox } from "@mui/material";
import { tokens } from "../../theme";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { addSplashScreen } from "../../services/apis/splashscreen";
import LoadingSpinner from "../../components/LoadingSpinner";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { modalStyle } from "../../styles";

const AddSplashModal = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [formData, setFormData] = useState({
    isActive: 2,
    sort: "",
    trans: [
      {
        local: "en",
        title: "",
        text: "",
      },
      {
        local: "ar",
        title: "",
        text: "",
      },
      {
        local: "eg",
        title: "",
        text: "",
      },
    ],
  });
  const [errorMessage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const handleTransChange = (local, field, newValue) => {
    setFormData((prevState) => {
      const updatedTrans = prevState?.trans?.map((transItem) => {
        if (transItem?.local === local) {
          return { ...transItem, [field]: newValue };
        }
        return transItem;
      });
      return { ...prevState, trans: updatedTrans };
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const isEmpty = formData.trans.some(
        (transItem) =>
          transItem.title.trim() === "" ||
          transItem.text.trim() === "" ||
          formData.sort.trim() === ""
      );

      if (isEmpty) {
        setIsLoading(false);
        alert("Please fill in all fields.");
        return;
      }
      const response = await addSplashScreen(formData);
      if (response.data.status === 200) {
        setIsLoading(false);
        alert("Splash Added Successfully");
        setFormData({
          isActive: 2,
          sort: "",
          trans: [
            {
              local: "en",
              title: "",
              text: "",
            },
            {
              local: "ar",
              title: "",
              text: "",
            },
            {
              local: "eg",
              title: "",
              text: "",
            },
          ],
        });
      }
    } catch (error) {
      setIsLoading(false);
      alert(error.response.data.message);
    }

    props.handleClose();
  };
  const handleChange = (e) => {
    const newValue =
      e.target.name === "isActive"
        ? e.target.checked
          ? 1
          : 2
        : e.target.value;
    setFormData({ ...formData, [e.target.name]: newValue });
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}

      <Box
        m="20px"
        display="grid"
        gridTemplateColumns="reapeat(1, 1fr)"
        alignContent="space-between"
        sx={modalStyle}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1 },
            display: "grid",
            gridTemplateColumns: "repeat(2, 2fr)",
            background: colors.blueAccent[900],
            height: "auto",
            p: "10px",
            alignContent: "space-around",
            borderRadius: "5px",
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h3" gridColumn="span 2">
            Add New Splash
          </Typography>

          <FormControl sx={{ width: "300px" }}>
            <InputLabel htmlFor="component-outlined" color="secondary">
              Text English
            </InputLabel>
            <OutlinedInput
              id="component-outlined"
              label="Text English"
              type="text"
              name="text english"
              value={
                formData?.trans?.find((transItem) => transItem.local === "en")
                  .text
              }
              onChange={(event) =>
                handleTransChange("en", "text", event.target.value)
              }
              color="secondary"
              required
            />
            {errorMessage?.text && (
              <FormHelperText id="component-error-text" sx={{ color: "red" }}>
                {errorMessage.text}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ width: "300px" }}>
            <InputLabel htmlFor="component-outlined" color="secondary">
              Title English
            </InputLabel>
            <OutlinedInput
              id="component-outlined"
              label="title English"
              type="text"
              name="title english"
              value={
                formData?.trans?.find((transItem) => transItem.local === "en")
                  .title
              }
              onChange={(event) =>
                handleTransChange("en", "title", event.target.value)
              }
              color="secondary"
              required
            />
            {errorMessage?.title && (
              <FormHelperText id="component-error-text" sx={{ color: "red" }}>
                {errorMessage.title}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ width: "300px" }}>
            <InputLabel htmlFor="component-outlined" color="secondary">
              Text Arabic
            </InputLabel>
            <OutlinedInput
              id="component-outlined"
              label="Text Arabic"
              type="text"
              name="text Arabic"
              value={
                formData?.trans?.find((transItem) => transItem.local === "ar")
                  .text
              }
              onChange={(event) =>
                handleTransChange("ar", "text", event.target.value)
              }
              color="secondary"
              required
            />
            {errorMessage?.text && (
              <FormHelperText id="component-error-text" sx={{ color: "red" }}>
                {errorMessage.text}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ width: "300px" }}>
            <InputLabel htmlFor="component-outlined" color="secondary">
              Title Arabic
            </InputLabel>
            <OutlinedInput
              id="component-outlined"
              label="title Arabic"
              type="text"
              name="title Arabic"
              value={
                formData?.trans?.find((transItem) => transItem.local === "ar")
                  .title
              }
              onChange={(event) =>
                handleTransChange("ar", "title", event.target.value)
              }
              color="secondary"
              required
            />
            {errorMessage?.title && (
              <FormHelperText id="component-error-text" sx={{ color: "red" }}>
                {errorMessage.title}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ width: "300px" }}>
            <InputLabel htmlFor="component-outlined" color="secondary">
              Text Egyption Arabic
            </InputLabel>
            <OutlinedInput
              id="component-outlined"
              label="Text Egyption Arabic"
              type="text"
              name="text Egyption Arabic"
              value={
                formData?.trans?.find((transItem) => transItem.local === "eg")
                  .text
              }
              onChange={(event) =>
                handleTransChange("eg", "text", event.target.value)
              }
              color="secondary"
              required
            />
            {errorMessage?.text && (
              <FormHelperText id="component-error-text" sx={{ color: "red" }}>
                {errorMessage.text}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ width: "300px" }}>
            <InputLabel htmlFor="component-outlined" color="secondary">
              Title Egyption Arabic
            </InputLabel>
            <OutlinedInput
              id="component-outlined"
              label="title Egyption Arabic"
              type="text"
              name="title Egyption Arabic"
              value={
                formData?.trans?.find((transItem) => transItem.local === "eg")
                  .title
              }
              onChange={(event) =>
                handleTransChange("eg", "title", event.target.value)
              }
              color="secondary"
              required
            />
            {errorMessage?.title && (
              <FormHelperText id="component-error-text" sx={{ color: "red" }}>
                {errorMessage.title}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl sx={{ width: "300px" }}>
            <InputLabel htmlFor="component-outlined" color="secondary">
              Splash Screen Order
            </InputLabel>
            <OutlinedInput
              id="component-outlined"
              label="Sort"
              type="number"
              name="sort"
              required
              value={formData.sort}
              onChange={handleChange}
              color="secondary"
            />
            {errorMessage.sort && (
              <FormHelperText id="component-error-text" sx={{ color: "red" }}>
                {errorMessage.sort}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ width: "300px" }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt="10px"
            >
              <Checkbox
                label="Is Active"
                checked={formData.isActive === 1}
                color="secondary"
                name="isActive"
                onChange={(e) => {
                  const newIsActiveValue = e.target.checked ? 1 : 2;
                  setFormData({
                    ...formData,
                    [e.target.name]: newIsActiveValue,
                  });
                }}
              />
              <Typography>Active</Typography>
            </Box>
            {errorMessage.isActive && (
              <FormHelperText id="component-error-text" sx={{ color: "red" }}>
                {errorMessage.isActive}
              </FormHelperText>
            )}
          </FormControl>
          <Box gridColumn="span 2 " display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: colors.blueAccent[500],
                height: "40px",
                width: "100px",
                "&:hover": { backgroundColor: colors.blueAccent[600] },
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default AddSplashModal;
