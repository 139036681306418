export const modalStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const DataGridStyle = {
  "& .MuiDataGrid-columnHeader.bold-header": {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#000000",
  },
  // "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
  //   outline: "none",
  // },
  "& .MuiDataGrid-root": {
    border: "none",
  },
  "& .MuiDataGrid-cell": {
    borderBottom: "none",
    backgroundColor: "#ffffff",
  },
  "& .name-column--cell": {
    color: "grey",
  },
  "& .MuiDataGrid-virtualScroller": {
    backgroundColor: "#FFF",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#ffffff",
    borderBottom: "none",
    pointerEvents: "none",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "none",
    backgroundColor: "#ffffff",
  },
  // overflowY: "auto",
  borderRadius: "12px",
};
