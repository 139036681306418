import { useState } from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "../../styles";
import ActionAlert from "../user-profile/ActionAlert";
import CustomDialog from "../../components/CustomDialog";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { deleteSubject, useFetchSubjects } from "../../services/apis/subejct";
import AddSubjectDialog from "./AddSubjectDialog";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import AddButton from "../../components/common/AddButton";
import {
  DeleteSvgWithFunc,
  EditSvgWithFunc,
} from "../../components/common/SvgWithFunc";
import { SecondBox } from "../../components/common/CustomBox";
const TicketSubjects = () => {
  const [open, setOpen] = useState(false);
  const [selectedSubjectId, setSelectedSubjectId] = useState(null);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [openDeletedAlert, setOpenDeletedAlert] = useState(false);
  const [functypeparams, setFuncTypeParams] = useState(null);
  const [disableWhileDelete, setDisableWhileDelete] = useState(false);
  const { data, refetch, isFetching } = useFetchSubjects();
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };

  const handleAddSubject = () => {
    setOpen(true);
    setFuncTypeParams("add");
    setSelectedSubjectId(null);
  };
  const handleOpenDeleteAlert = (id) => {
    setSelectedSubjectId(id);
    setOpenDeletedAlert(true);
  };
  const handleEditSubject = (subjectId) => {
    setSelectedSubjectId(subjectId);
    setOpen(true);
    setFuncTypeParams("edit");
  };
  const handleDeleteSubject = async () => {
    if (selectedSubjectId) {
      setDisableWhileDelete(true);

      try {
        await deleteSubject(selectedSubjectId);
        setDisableWhileDelete(false);
        setSuccessDialogOpen(true);
        handleCloseDeleteAlert();
        refetch();
      } catch (error) {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      }
    }
  };
  const handleCloseDeleteAlert = () => {
    setOpenDeletedAlert(false);
  };
  const subjectColumns = [
    {
      field: "name_en",
      headerName: "Subject Name - English",
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
      flex: 1,
    },
    {
      field: "name_ar",
      headerName: "Subject Name - Arabic",
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "bold-header",
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box>
              <EditSvgWithFunc
                onClick={() => {
                  handleEditSubject(params.row.id);
                }}
              />
            </Box>
            <Box>
              <DeleteSvgWithFunc
                onClick={() => handleOpenDeleteAlert(params.row.id)}
              />
            </Box>
          </Box>
        );
      },
    },
  ];

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Ticket Subjects" marginL={"25px"} />
      <SecondBox>
        <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              gap: 2,
              pr: 2,
              mt: "3%",
            }}
          >
            <AddButton
              title={"+ New Subject"}
              secondDesign={true}
              onClick={() => {
                handleAddSubject();
              }}
            />
          </Box>
          <DataGrid
            rows={isFetching ? [] : data}
            columns={subjectColumns}
            loading={isFetching}
            autoHeight={true}
            components={{
              Pagination: () => null,
              Footer: () => null,
            }}
            localeText={{
              noRowsLabel: "No Ticket Subjects",
            }}
          />
        </Box>
      </SecondBox>
      <ActionAlert
        openAlert={openDeletedAlert}
        handleCloseAlert={handleCloseDeleteAlert}
        handleReject={handleDeleteSubject}
        message={`Are you sure you want to Delete this Subject ?`}
        label={"Delete"}
        disabled={disableWhileDelete}
      />
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Subject has been deleted Successfully."
        message=""
        buttonText="Go to Ticket Subjects"
      />
      <AddSubjectDialog
        functypeparams={functypeparams}
        fetchData={() => refetch()}
        handleResponse={() => {
          refetch();
        }}
        subjects={data}
        subjectId={selectedSubjectId}
        open={open}
        onClose={handleClose}
        label={functypeparams === "add" ? "Add" : "Save"}
      />
      <ReusableSnackbar handleClose={handleCloseSnack} />
    </Box>
  );
};

export default TicketSubjects;
