export const capitalizeFirstLetter = (str) => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1);
};

export const changeStatusColor = (styleObject, value) => {
  if (value === "approved" || value === "Accepted") {
    styleObject.background = "#E1FAEE";
    styleObject.font = "#24CF80";
    styleObject.circle = "#24CF80";
  }

  if (value === "rejected" || value === "Rejected") {
    styleObject.background = "#FAE1E1";
    styleObject.font = "#CF2424";
    styleObject.circle = "#CF2424";
  }

  if (value === "pending" || value === "Pending") {
    styleObject.background = "#FFF2C2";
    styleObject.font = "#ECB800";
    styleObject.circle = "#ECB800";
  }

  if (value === "No KYC") {
    styleObject.background = "#EBEBEB";
    styleObject.font = "#333";
    styleObject.circle = "#333";
  }
};
