import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "../../styles";
import { useNavigate } from "react-router-dom";
import ActionAlert from "../user-profile/ActionAlert";
import CustomDialog from "../../components/CustomDialog";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import EditBlockReasons from "./EditBlockReasons";
import { handlePageChange } from "../../services/utils/filters";
import {
  deleteClosedReason,
  fetchClosedReasons,
} from "../../services/apis/closedReasons";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import AddButton from "../../components/common/AddButton";
import { ItemsColumns } from "../../components/common/Columns";
const ClosedReasons = () => {
  const [disableWhileDelete, setDisableWhileDelete] = useState(false);

  const [open, setOpen] = useState(false);
  const [closedReasons, setClosedReasons] = useState([]);
  const [selectedBlockReasonId, setBlockReasonId] = useState(null);
  const [selectedBlockReason, setSelectedBlockReason] = useState(null);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [openDeletedAlert, setOpenDeletedAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(0);
  const [pagesCount, setPagesCount] = useState(10);
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
  });
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getClosedReasons = async () => {
    setLoading(true);
    setClosedReasons([]);
    try {
      const response = await fetchClosedReasons(queryParameters);
      const reasonscount = response.data.data.totalItems;
      const reasonsPagecount = response.data.data.totalPages;
      setTotalPages(reasonsPagecount);
      setPagesCount(reasonscount);
      setClosedReasons(response.data.data.result);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  const handleAddFAQ = () => {
    navigate(`/AddClosedReason`);
  };
  const handleOpenDeleteAlert = (id) => {
    setBlockReasonId(id);
    setOpenDeletedAlert(true);
  };
  const handleEditBlockReason = (blockReasonId) => {
    setSelectedBlockReason(blockReasonId);
    setOpen(true);
  };
  const handleDeleteBlockReason = async () => {
    if (selectedBlockReasonId) {
      setDisableWhileDelete(true);
      try {
        await deleteClosedReason(selectedBlockReasonId);
        setDisableWhileDelete(false);
        setSuccessDialogOpen(true);
        handleCloseDeleteAlert();
        getClosedReasons();
      } catch (error) {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      }
    }
  };
  const handleCloseDeleteAlert = () => {
    setOpenDeletedAlert(false);
  };
  const blockReasonsColumns = ItemsColumns([
    {
      field: "reason",
      specialRender: "customRender",
      renderFunction: (params) => params?.row?.trans?.en,
    },
    { field: "type", headerName: "Reason For" },
    {
      field: "action",
      actions: {
        edit: (params) => {
          handleEditBlockReason(params.row.id);
        },
        delete: (params) => {
          handleOpenDeleteAlert(params.row.id);
        },
      },
    },
  ]);
  useEffect(() => {
    getClosedReasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParameters]);
  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <DashboardMainHeader />

      <Header title="Closed Reasons for Tickets" marginL={"25px"} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          gap: 2,
          pr: 2,
        }}
      >
        <AddButton
          title={"+ New reason"}
          onClick={() => {
            handleAddFAQ();
          }}
        />
      </Box>
      <Box
        m="20px"
        sx={{
          height: "auto",
          background: "#FFF",
          borderRadius: "12px",
          boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Box m="40px 0 0 0" height="100%" sx={DataGridStyle}>
          <DataGrid
            rows={closedReasons}
            columns={blockReasonsColumns}
            loading={loading}
            rowsPerPageOptions={[10]}
            pageSize={10}
            totalPages={totalPages}
            rowCount={pagesCount || 0}
            paginationMode="server"
            onPageChange={(p) =>
              handlePageChange(p, queryParameters, setQueryParameters)
            }
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
              height: "630px",
            }}
            localeText={{
              noRowsLabel: "No Block Reasons",
            }}
          />
        </Box>
      </Box>
      <ActionAlert
        openAlert={openDeletedAlert}
        handleCloseAlert={handleCloseDeleteAlert}
        handleReject={handleDeleteBlockReason}
        message={`Are you sure you want to delete this Reason ?`}
        label={"Delete"}
        disabled={disableWhileDelete}
      />
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Reason has been deleted Successfully."
        message=""
        buttonText="Go to Block Reason"
      />
      <EditBlockReasons
        fetchData={getClosedReasons}
        setClosedReasons={setClosedReasons}
        closedReasons={closedReasons}
        blockReasonsId={selectedBlockReason}
        open={open}
        onClose={handleClose}
        label={"Save"}
      />
      <ReusableSnackbar handleClose={handleCloseSnack} />
    </Box>
  );
};

export default ClosedReasons;
