import { Box, Button, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Link, useParams } from "react-router-dom";
import {
  deleteRole,
  fetchRolesDetails,
} from "../../services/apis/roles-and-permissions";
import { TabContext, TabPanel } from "@mui/lab";
import { withStyles } from "@mui/styles";
import FirstTabPanel from "./FirstTabPanel";
import SecondTabPanel from "./SecondTabPanel";
import ThirdTabPanel from "./ThirdTabPanel";
import LoadingSpinner from "../../components/LoadingSpinner";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import ActionAlert from "../user-profile/ActionAlert";
import CustomDialog from "../../components/CustomDialog";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";

const ViewRole = () => {
  const AntTabs = withStyles({
    root: {
      borderBottom: "none",
    },
    indicator: {
      display: "none",
    },
  })(Tabs);
  const AntTab = withStyles((theme) => ({
    root: {
      "&:hover": {
        color: "#00303C",
        opacity: 1,
      },
      "&$selected": {
        color: "#ffffff",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        borderBottom: "none",
      },
      "&:focus": {
        color: "#00303C",
      },
    },
    selected: {
      "&$selected": {
        borderBottom: "none",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        color: "#ffffff !important",
      },
    },
  }))((props) => <Tab disableRipple {...props} />);
  const { id } = useParams();
  const [role, setRole] = useState(null);
  const [paths, setPaths] = useState([]);
  const [value, setValue] = useState("1");
  const [openDeletedAlert, setOpenDeletedAlert] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [disableWhileDelete, setDisableWhileDelete] = useState(false);
  const handleOpenDeleteAlert = (e) => {
    setOpenDeletedAlert(true);
  };
  const handleCloseDeleteAlert = () => {
    setOpenDeletedAlert(false);
  };
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getRole = async () => {
    try {
      const response = await fetchRolesDetails(id);
      const rolesdetials = await response.data.data;
      return rolesdetials;
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  const handleDelete = async () => {
    setDisableWhileDelete(true);
    try {
      await deleteRole(id);
      setDisableWhileDelete(false);
      setSuccessDialogOpen(true);
    } catch (error) {
      dispatch(
        setData({ openSnack: true, message: error?.response?.data?.message })
      );
    }
    handleCloseDeleteAlert();
  };
  useEffect(() => {
    const fetchData = async () => {
      const selectedRole = await getRole(id);
      const pathArray = id ? ["Roles", `${selectedRole?.name}`] : ["Roles"];
      setRole(selectedRole);
      setPaths(pathArray);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  if (!role) return <LoadingSpinner />;
  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <ActionAlert
        openAlert={openDeletedAlert}
        handleCloseAlert={handleCloseDeleteAlert}
        handleReject={handleDelete}
        message={`Are you sure you want to delete this role`}
        label={"Delete"}
        disabled={disableWhileDelete}
      />
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Role has been deleted Successfully."
        message=""
        buttonText="Go to Admins"
        buttonLink="/add-new-role"
      />
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
        <Box mt={4} mb={4}>
          {paths?.map((path, index) => (
            <span key={index}>
              {index > 0 && " / "}
              {index === paths?.length - 1 ? (
                <span style={{ color: "#666666", fontSize: "14px" }}>
                  {path}
                </span>
              ) : (
                <Link
                  style={{
                    color: "#E9C237",
                    textDecoration: "none",
                    fontSize: "14px",
                  }}
                  to={`/${paths.slice(0, index + 1).join("/")}`}
                  onClick={(e) => {
                    e.preventDefault();
                    window.history.back();
                  }}
                >
                  {path}
                </Link>
              )}
            </span>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Header title={role?.name} />
          <Button
            color="inherit"
            onClick={handleOpenDeleteAlert}
            sx={{
              "&:hover": {
                backgroundColor: "#F23045",
                color: "#ffffff",
              },
              backgroundColor: "transparent",
              width: "148px",
              height: "46px",
              borderRadius: "12px",
              color: "#F23045",
              border: disableWhileDelete ? "" : "2px solid #F23045",
              fontSize: "14px",
              marginRight: "20px",
            }}
            disabled={disableWhileDelete}
          >
            Delete
          </Button>
        </Box>
      </Box>
      <Box
        m="20px"
        sx={{
          height: "auto",
          background: "#FFFFFF",
          borderRadius: "12px",
          boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08);",
        }}
      >
        <Box sx={{ overflow: "auto" }} m="40px 0 0 0" height="auto">
          <TabContext value={value}>
            <Box
              sx={{
                width: "100%",
                paddingTop: "5%",
                paddingBottom: "1%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <AntTabs
                sx={{
                  backgroundColor: "#F5F6FF",
                  borderRadius: "9px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                value={value}
                onChange={handleChange}
                aria-label="ant example"
              >
                <AntTab
                  sx={{ width: "345px" }}
                  label="Role Details"
                  value="1"
                />
                <AntTab sx={{ width: "345px" }} label="Permissions" value="2" />
                <AntTab sx={{ width: "345px" }} label="Users" value="3" />
              </AntTabs>
            </Box>
            <TabPanel sx={{ marginLeft: "4%" }} value="1">
              <FirstTabPanel role={role} />
            </TabPanel>
            <TabPanel sx={{ marginLeft: "4%" }} value="2">
              <SecondTabPanel role={role} />
            </TabPanel>
            <TabPanel sx={{ marginLeft: "4%" }} value="3">
              <ThirdTabPanel role={role} />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
      <ReusableSnackbar handleClose={handleCloseSnack} />
    </Box>
  );
};
export default ViewRole;
