import React from "react";
import { MenuItem } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { axiosInstance } from "../services/config";
import { useDispatch } from "react-redux";
import { clearToken } from "../store/slices/authenticationSlice";
const Logout = ({ title, handleLoggedIn }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleLogOut = async () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      await axiosInstance.get(`api/users/logout`, {
        headers,
      });
      // window.close();
      // window.open("/login", "_blank");
      // navigate("/login", { replace: true });

      // Clear the history state to prevent back navigation
      window.history.pushState(null, null, "/login");
      window.history.replaceState(null, null, "/login");
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
      sessionStorage.removeItem("selectedTitle");
      sessionStorage.removeItem("isPageRefreshed");
      dispatch(clearToken());
    } catch (error) {
      console.log("logout error occured");
      if (error.response.status === 401) {
        // Clear the history state to prevent back navigation
        window.history.pushState(null, null, "/login");
        window.history.replaceState(null, null, "/login");
        navigate("/login", { replace: true });
        localStorage.removeItem("token");
        sessionStorage.removeItem("selectedTitle");
        sessionStorage.removeItem("isPageRefreshed");
        dispatch(clearToken());
      } else {
        // Clear the history state to prevent back navigation
        window.history.pushState(null, null, "/login");
        window.history.replaceState(null, null, "/login");
        navigate("/login", { replace: true });
        localStorage.removeItem("token");
        sessionStorage.removeItem("selectedTitle");
        sessionStorage.removeItem("isPageRefreshed");
        dispatch(clearToken());
        console.log("Logout error occurred");
      }
    }
  };

  return (
    <MenuItem
      style={{
        color: "#4C4C4C",
      }}
      icon={<LogoutIcon />}
      onClick={handleLogOut}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

export default Logout;
