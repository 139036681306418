import React from "react";
import {
  Button,
  Box,
  Typography,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

const rejectReasons = {
  unrecognizedIdentity: "The document does not appear to match your identity",
  blurryOrUnreadableImage:
    "The document image is not clear enough to read or verify",
  expiredDocument: "The document provided is no longer valid or has expired",
  mismatchedInformationAccount:
    "The information on the document does not match the details provided in your account",
  unsupportedDocumentType:
    "The document type submitted is not accepted for verification",
  poorQualityOrDamagedText:
    "The text on the document is not readable due to poor quality or damage",
  alteredOrFakeDocument:
    "The document appears to have been tampered with or is not authentic",
  incompleteDocumentSubmission:
    "All required pages of the document were not submitted",
  croppedImage:
    "The document image is cropped and does not show the full content",
  blurryOrUnclearImages: "Blurry or unclear images",
  glareOrShadows: "Glare or shadows on the ID or face",
  incompleteIDUpload: "Incomplete ID upload",
  expiredID: "Expired ID",
  unacceptedDocumentType: "Unaccepted document type",
  fakeOrTamperedID: "Fake or tampered ID",
  mismatchedInformation: "Mismatched information between ID and user details",
  mismatchedSelfieAndID: "Mismatched selfie and ID photo",
  unclearFaceInSelfie: "Face not clearly visible in the selfie",
  nonComplianceWithSelfieGuidelines:
    "Non-compliance with selfie guidelines (e.g., sunglasses, hats, filters)",
  nonRecentOrRealTimeSelfie: "Selfie not recent or not taken in real-time",
  incorrectAddressProvided: "Incorrect address provided",
  missingOrIncompleteDetails: "Missing or incomplete details",
  blacklistedIndividual:
    "Blacklisted individuals (e.g., on sanctions or PEP lists)",
  unsupportedCitizenship: "Citizenship from unsupported countries",
  corruptFileUpload: "Corrupt file upload",
  wrongFileFormat: "Wrong file format",
};
const ActionAlert = ({
  refetch,
  openAlert,
  handleCloseAlert,
  type,
  handleReject,
  label,
  message,
  alertType,
  disabled,
  ...props
}) => {
  return (
    <Dialog
      open={openAlert}
      onClose={handleCloseAlert}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent
        sx={{
          width: "552px",
          height: "431px",
          borderRadius: "24px",
          background: "#FFF",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "90%",
            height: "60%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
            >
              <path
                d="M31.9987 16.6669C33.1033 16.6669 33.9987 17.5624 33.9987 18.6669V34.6669C33.9987 35.7715 33.1033 36.6669 31.9987 36.6669C30.8941 36.6669 29.9987 35.7715 29.9987 34.6669V18.6669C29.9987 17.5624 30.8941 16.6669 31.9987 16.6669Z"
                fill="#E9C237"
              />
              <path
                d="M31.9987 45.3336C33.4715 45.3336 34.6654 44.1397 34.6654 42.6669C34.6654 41.1942 33.4715 40.0003 31.9987 40.0003C30.5259 40.0003 29.332 41.1942 29.332 42.6669C29.332 44.1397 30.5259 45.3336 31.9987 45.3336Z"
                fill="#E9C237"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.33203 32.0003C3.33203 16.1681 16.1665 3.33359 31.9987 3.33359C47.8309 3.33359 60.6654 16.1681 60.6654 32.0003C60.6654 47.8324 47.8309 60.6669 31.9987 60.6669C16.1665 60.6669 3.33203 47.8324 3.33203 32.0003ZM31.9987 7.33359C18.3757 7.33359 7.33203 18.3772 7.33203 32.0003C7.33203 45.6233 18.3757 56.6669 31.9987 56.6669C45.6217 56.6669 56.6654 45.6233 56.6654 32.0003C56.6654 18.3772 45.6217 7.33359 31.9987 7.33359Z"
                fill="#E9C237"
              />
            </svg>
          </Box>
          {type === "Reject" ? (
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                Select Rejection reason
              </Typography>
              <FormControl sx={{ width: "97%" }}>
                <Select
                  placeholder="Select Rejection reason"
                  displayEmpty
                  onChange={(e) => {
                    const selectedKey = e.target.value;
                    console.log("Selected Key: ", selectedKey);
                    props.setFormData({ reject_kyc_reason: selectedKey });
                  }}
                  value={props?.formData?.reject_kyc_reason || ""}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select Rejection reason</em>;
                    }
                    return rejectReasons[selected]; // Display the value based on the key
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {Object.entries(rejectReasons).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : null}
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Lato",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 600,
            }}
          >
            {message}
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              disabled={disabled}
              variant="contained"
              sx={{
                width: "200px",
                borderRadius: "6px",
                fontSize: "18px",
                background:
                  (label === "Confirm" && alertType === "KYC") ||
                  (label === "Unblock" && alertType === "Block") ||
                  (label === "Reactivate" && alertType === "Deactivate")
                    ? "#E9C237"
                    : "#E9C237",
                height: "46px",
                fontWeight: 600,
                "&:hover": {
                  background:
                    (label === "Confirm" && alertType === "KYC") ||
                    (label === "Unblock" && alertType === "Block") ||
                    (label === "Reactivate" && alertType === "Deactivate")
                      ? "#E9C237"
                      : "#E9C237",
                },
              }}
              onClick={handleReject}
            >
              {label}
            </Button>

            <Button
              variant="outlined"
              sx={{
                width: "200px",
                borderRadius: "6px",
                height: "46px",
                color:
                  (label === "Confirm" && alertType === "KYC") ||
                  (label === "Unblock" && alertType === "Block") ||
                  (label === "Reactivate" && alertType === "Deactivate")
                    ? "#E9C237"
                    : "#E9C237",
                fontWeight: 600,
                borderColor:
                  (label === "Confirm" && alertType === "KYC") ||
                  (label === "Unblock" && alertType === "Block") ||
                  (label === "Reactivate" && alertType === "Deactivate")
                    ? "#E9C237"
                    : "#E9C237",
                fontSize: "18px",
                "&:hover": {
                  borderColor: "#E9C237",
                },
              }}
              onClick={handleCloseAlert}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ActionAlert;
