import React from "react";
import { Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { MenuItem } from "react-pro-sidebar";
import { tokens } from "../theme";
 
const SiderbarItem = ({
  selected,
  setSelected,
  title,
  icon,
  to,
  isCollapsed,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleTitleClick = () => {
    setSelected(title);
    sessionStorage.setItem("selectedTitle", title);
  };
  return (
    <MenuItem
      active={selected === title}
      style={{
        borderRadius: "12px",
        backgroundColor: selected === title && !isCollapsed ? "#E9C237" : "",
        color: colors.grey[100],
        width: !isCollapsed ? "90%" : "85%",
        marginLeft: !isCollapsed ? "5%" : "0%",
        transition: "none",
        // display: isCollapsed ? "flex" : "",
        // justifyContent: isCollapsed ? "center" : "",
        // alignSelf: isCollapsed ? "center" : "",
        // alignItems: isCollapsed ? "center" : "",
      }}
      onClick={handleTitleClick}
      icon={icon}
    >
      <Typography
        sx={{
          color: selected === title ? "#F7F7F7" : "#4C4C4C",
          display: "flex",
          alignSelf: "center",
        }}
      >
        {title}
      </Typography>
      <Link to={to} />
    </MenuItem>
  );
};

export default SiderbarItem;
