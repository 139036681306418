import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  InputBase,
  Paper,
  Tab,
  Tabs,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import useFetchAllOrders, {
  useFetchOrderStatus,
  useEditOrder,
} from "../../services/apis/orders";
import { handlePageChange, handleSort } from "../../services/utils/filters";
import FilterDrawer from "../../components/common/FilterDrawer";
import OrderDetails from "./OrderDetails";
import Popover from "@mui/material/Popover";
import { handleFilterByNumber } from "../../services/utils/filters";
import { withStyles } from "@mui/styles";
import { TabContext, TabPanel } from "@mui/lab";
import { DataGridStyle } from "../../styles";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import StorageOrderDetails from "./StorageOrderDetails";
import FractionsOrder from "./FractionsOrder";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { FirstBox, SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";

const Orders = () => {
  const [value, setValue] = useState("1");
  const [open, setOpen] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [openDialog3, setOpenDialog3] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [orderDetailsData, setOrderDetailsData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverValue] = useState({});
  const openPop = Boolean(anchorEl);
  const id = openPop ? "simple-popover" : undefined;
  const getOrderType = (value) => {
    switch (value) {
      case "1":
        return [2];
      case "2":
        return [1];
      case "3":
        return [4];
      default:
        return [3];
    }
  };
  const [queryParameters, setQueryParameters] = useState({
    date_from: null,
    date_to: null,
    orderBy: null,
    sort: null,
    page: 0,
    order_type: getOrderType(value),
    orderStatus: "",
    query: null,
  });
  const {
    data,
    isFetching: isLoading,
    refetch: refetchOrders,
  } = useFetchAllOrders(queryParameters);
  const { data: orderStatus, refetch } = useFetchOrderStatus();
  const mutation = useEditOrder();
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const handleOpen = (params) => {
    if (params?.row?.order_type === 2 || params?.row?.order_type === 4) {
      setOrderDetails(params?.value);
      setOrderDetailsData(params?.row);
      setOpen(true);
    } else if (params?.row?.order_type === 1) {
      setOrderDetails(params?.value);
      setOrderDetailsData(params?.row);
      setOpenDialog2(true);
    } else if (params?.row?.order_type === 3) {
      setOrderDetails(params?.value);
      setOrderDetailsData(params?.row);
      setOpenDialog3(true);
    }
  };
  const handleClose = () => setOpen(false);
  const handleCloseDialog2 = () => setOpenDialog2(false);
  const handleCloseDialog3 = () => setOpenDialog3(false);

  const handlePopClose = () => {
    setAnchorEl(null);
  };

  const handleChangeOrderStatus = async (id, statusId) => {
    try {
      const body = {
        statusId,
      };
      await mutation.mutateAsync({ id, body }).then(() => {
        refetch();
        refetchOrders(queryParameters);
      });
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
  };

  const AntTabs = withStyles({
    root: {
      borderBottom: "none",
    },
    indicator: {
      display: "none",
    },
  })(Tabs);
  const AntTab = withStyles((theme) => ({
    root: {
      "&:hover": {
        color: "#00303C",
        opacity: 1,
      },
      "&$selected": {
        color: "#ffffff",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        borderBottom: "none",
      },
      "&:focus": {
        color: "#00303C",
      },
    },
    selected: {
      "&$selected": {
        borderBottom: "none",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        color: "#ffffff !important",
      },
    },
  }))((props) => <Tab disableRipple {...props} />);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    const updatedOrderType = getOrderType(newValue);
    setQueryParameters({
      ...queryParameters,
      date_from: null,
      date_to: null,
      orderBy: null,
      sort: null,
      page: 0,
      order_type: updatedOrderType,
      orderStatus: "",
      q: null,
    });
  };
  const handleSearchInput = (e) => {
    const { value } = e.target;
    setQueryParameters((prevParams) => ({
      ...prevParams,
      query: value.trim(),
    }));
  };

  const handleOrderTypeCheckbox = (e) => {
    handleFilterByNumber(
      e.target.value,
      "order_type",
      queryParameters,
      setQueryParameters
    );
  };

  const handleOrderStatusCheckbox = (e) => {
    handleFilterByNumber(
      e.target.value,
      "orderStatus",
      queryParameters,
      setQueryParameters
    );
  };

  useEffect(() => {
    const updatedQueryParameters = {
      ...queryParameters,
      page: 0,
      orderStatus: "",
      date_from: null,
      date_to: null,
      order_type: getOrderType(value),
      query: null,
    };
    setQueryParameters(updatedQueryParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const ordersColumns = ItemsColumns([
    { field: "id", headerName: "ID" },
    { field: "phone", headerName: "User ID", flex: 2 },
    { field: "created", headerName: "Date", date: true, flex: 2 },
    {
      field: "orderStatus",
      headerName: "Order Status",
      specialRender: "orderStatus",
    },
    {
      field: "address",
      headerName: "Delivery Area",
      specialRender: "customRender",
      renderFunction: (params) => params?.value?.city ?? "",
    },
    { field: "total", specialRender: "local" },
    {
      field: "orderStatusSort",
      headerName: "Change Status",
      flex: 2,
      specialRender: "changeStatus",
      orderStatus: orderStatus,
      orderStatusFunction: (params) => {
        handleChangeOrderStatus(
          params.row.id,
          orderStatus?.data[params.row.orderStatus.sort].id
        );
      },
    },
    {
      field: "orderDetails",
      headerName: "Actions",
      actions: {
        view: (params) => handleOpen(params),
      },
    },
  ]);
  const storageOrdersColumns = ItemsColumns([
    { field: "id", headerName: "ID" },
    { field: "phone", headerName: "User ID", flex: 2 },
    { field: "created", headerName: "Date", date: true, flex: 2 },
    { field: "totalWeight", headerName: "Total Weight", flex: 2 },
    { field: "storedFees", headerName: "Total Storage Fees", flex: 2 },
    { field: "total", specialRender: "local" },
    {
      field: "orderStatus",
      headerName: "Status",
      specialRender: "orderStatus",
    },
    {
      field: "orderDetails",
      headerName: "Actions",
      actions: {
        view: (params) => handleOpen(params),
      },
    },
  ]);
  const storedOrdersColumns = ItemsColumns([
    { field: "id", headerName: "ID" },
    { field: "phone", headerName: "User ID", flex: 2 },
    { field: "created", headerName: "Date", date: true, flex: 2 },
    {
      field: "orderStatus",
      headerName: "Order Status",
      specialRender: "orderStatus",
    },
    {
      field: "address",
      headerName: "Delivery Area",
      specialRender: "customRender",
      renderFunction: (params) => params?.value?.city ?? "",
    },
    { field: "deliveryFees", headerName: "Delivery Fees", flex: 2 },
    {
      field: "orderStatusSort",
      headerName: "Change Status",
      flex: 2,
      specialRender: "changeStatus",
      orderStatus: orderStatus,
      orderStatusFunction: (params) => {
        handleChangeOrderStatus(
          params.row.id,
          orderStatus?.data[params.row.orderStatus.sort].id
        );
      },
    },
    {
      field: "orderDetails",
      headerName: "Actions",
      actions: {
        view: (params) => handleOpen(params),
      },
    },
  ]);
  const fractionsOrdersColumns = ItemsColumns([
    { field: "id", headerName: "ID" },
    { field: "phone", headerName: "User ID", flex: 2 },
    { field: "created", headerName: "Date", date: true, flex: 2 },
    { field: "totalWeight", headerName: "Weight", flex: 2 },
    {
      field: "goldPrice",
      headerName: "Gold Price",
      flex: 1,
      specialRender: "customRender",
      renderFunction: (params) => {
        const goldPrices = params.row.orderDetails.map(
          (detail) => detail.price
        );
        const goldPriceString = goldPrices.join(", ");
        return (
          <Typography>
            {parseFloat(goldPriceString).toLocaleString()}
          </Typography>
        );
      },
    },
    {
      field: "fractionPrice",
      headerName: "Fraction Price",
      flex: 1,
      specialRender: "customRender",
      renderFunction: (params) => {
        const fractionPrices = params.row.orderDetails.map(
          (detail) => detail.total
        );
        const fractionPriceString = fractionPrices.join(", ");
        return (
          <Typography>
            {parseFloat(fractionPriceString)?.toLocaleString()}
          </Typography>
        );
      },
    },
    {
      field: "orderStatus",
      headerName: "Status",
      specialRender: "orderStatus",
    },
    {
      field: "orderDetails",
      headerName: "Actions",
      actions: {
        view: (params) => handleOpen(params),
      },
    },
  ]);

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Orders" marginL={"20px"} />
      <SecondBox>
        <FirstBox m="40px 0 0 0" height="100%">
          <Box
            m="40px 0 0 0"
            height="auto"
            style={{ backgroundColor: "#ffffff" }}
            sx={DataGridStyle}
          >
            <TabContext value={value}>
              <Box
                sx={{
                  width: "100%",
                  paddingTop: "2%",
                  paddingBottom: "1%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <AntTabs
                  sx={{
                    // backgroundColor: "red",
                    backgroundColor: "#F5F6FF",
                    borderRadius: "9px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "2%",
                    width: "100%",
                  }}
                  value={value}
                  onChange={handleChange}
                  aria-label="ant example"
                >
                  <AntTab
                    sx={{ width: "25%" }}
                    label="Delivery Orders"
                    value="1"
                  />
                  <AntTab
                    sx={{ width: "25%" }}
                    label="Storage Orders"
                    value="2"
                  />
                  <AntTab
                    sx={{ width: "25%" }}
                    label="Stored then Delivered Orders"
                    value="3"
                  />
                  <AntTab sx={{ width: "25%" }} label="Fractions" value="4" />
                </AntTabs>
              </Box>
              <TabPanel value="1">
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ display: "flex", width: "50%", gap: 2, mb: 2 }}>
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "600px",
                        height: "48px",
                        backgroundColor: "#F5F5F5",
                        boxShadow: "none",
                        borderRadius: "12px",
                      }}
                      onSubmit={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <IconButton
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                      >
                        <img
                          alt="search-normal"
                          src={`../../assets/search-normal.png`}
                        />
                      </IconButton>
                      <InputBase
                        onChange={handleSearchInput}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search Order Id ,National Id ,Phone number or Email ....."
                      />
                      <FilterDrawer
                        queryParameters={queryParameters}
                        setQueryParameters={setQueryParameters}
                        secondTitle="Order Status"
                        secondKey="orderStatus"
                        secondList={orderStatus?.data}
                        handleFirstCheckBox={handleOrderTypeCheckbox}
                        handleSecondCheckBox={handleOrderStatusCheckbox}
                      />
                    </Paper>
                  </Box>
                  <DataGrid
                    columns={ordersColumns}
                    rows={isLoading ? [] : data?.data?.result}
                    pageSize={10}
                    loading={isLoading}
                    rowCount={data?.data?.totalItems || 0}
                    totalPages={data?.data?.totalPages}
                    paginationMode="server"
                    onPageChange={(p) =>
                      handlePageChange(p, queryParameters, setQueryParameters)
                    }
                    rowsPerPageOptions={[10]}
                    sx={{
                      cursor: "pointer",
                      backgroundColor: "#ffffff",
                      height: "630px",
                    }}
                    onSortModelChange={(params) =>
                      handleSort(params, queryParameters, setQueryParameters)
                    }
                    localeText={{
                      noRowsLabel: "No Delivery Orders",
                    }}
                    sortingMode="server"
                  />
                </Box>
              </TabPanel>
              <TabPanel value="2">
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ display: "flex", width: "50%", gap: 2, mb: 2 }}>
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "600px",
                        height: "48px",
                        backgroundColor: "#F5F5F5",
                        boxShadow: "none",
                        borderRadius: "12px",
                      }}
                      onSubmit={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <IconButton
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                      >
                        <img
                          alt="search-normal"
                          src={`../../assets/search-normal.png`}
                        />
                      </IconButton>
                      <InputBase
                        onChange={handleSearchInput}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search Order Id ,National Id ,Phone number or Email ....."
                      />
                      <FilterDrawer
                        queryParameters={queryParameters}
                        setQueryParameters={setQueryParameters}
                        secondTitle="Order Status"
                        secondKey="orderStatus"
                        secondList={orderStatus?.data}
                        handleFirstCheckBox={handleOrderTypeCheckbox}
                        handleSecondCheckBox={handleOrderStatusCheckbox}
                      />
                    </Paper>
                  </Box>
                  <DataGrid
                    columns={storageOrdersColumns}
                    rows={isLoading ? [] : data?.data?.result}
                    pageSize={10}
                    loading={isLoading}
                    rowCount={data?.data?.totalItems || 0}
                    totalPages={data?.data?.totalPages}
                    paginationMode="server"
                    onPageChange={(p) =>
                      handlePageChange(p, queryParameters, setQueryParameters)
                    }
                    rowsPerPageOptions={[10]}
                    sx={{ cursor: "pointer", height: "630px" }}
                    onSortModelChange={(params) =>
                      handleSort(params, queryParameters, setQueryParameters)
                    }
                    localeText={{
                      noRowsLabel: "No Storage Orders",
                    }}
                    sortingMode="server"
                  />
                </Box>
              </TabPanel>
              <TabPanel value="3">
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ display: "flex", width: "50%", gap: 2, mb: 2 }}>
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "600px",
                        height: "48px",
                        backgroundColor: "#F5F5F5",
                        boxShadow: "none",
                        borderRadius: "12px",
                      }}
                      onSubmit={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <IconButton
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                      >
                        <img
                          alt="search-normal"
                          src={`../../assets/search-normal.png`}
                        />
                      </IconButton>
                      <InputBase
                        onChange={handleSearchInput}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search Order Id ,National Id ,Phone number or Email ....."
                      />
                      <FilterDrawer
                        queryParameters={queryParameters}
                        setQueryParameters={setQueryParameters}
                        secondTitle="Order Status"
                        secondKey="orderStatus"
                        secondList={orderStatus?.data}
                        handleFirstCheckBox={handleOrderTypeCheckbox}
                        handleSecondCheckBox={handleOrderStatusCheckbox}
                      />
                    </Paper>
                  </Box>
                  <DataGrid
                    columns={storedOrdersColumns}
                    rows={isLoading ? [] : data?.data?.result}
                    pageSize={10}
                    loading={isLoading}
                    rowCount={data?.data?.totalItems || 0}
                    totalPages={data?.data?.totalPages}
                    paginationMode="server"
                    onPageChange={(p) =>
                      handlePageChange(p, queryParameters, setQueryParameters)
                    }
                    rowsPerPageOptions={[10]}
                    sx={{ cursor: "pointer", height: "630px" }}
                    onSortModelChange={(params) =>
                      handleSort(params, queryParameters, setQueryParameters)
                    }
                    localeText={{
                      noRowsLabel: "No Stored Then Delivered Orders",
                    }}
                    sortingMode="server"
                  />
                </Box>
              </TabPanel>
              <TabPanel value="4">
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ display: "flex", width: "50%", gap: 2, mb: 2 }}>
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "600px",
                        height: "48px",
                        backgroundColor: "#F5F5F5",
                        boxShadow: "none",
                        borderRadius: "12px",
                      }}
                      onSubmit={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <IconButton
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                      >
                        <img
                          alt="search-normal"
                          src={`../../assets/search-normal.png`}
                        />
                      </IconButton>
                      <InputBase
                        onChange={handleSearchInput}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search Order Id ,National Id ,Phone number or Email ....."
                      />
                      <FilterDrawer
                        queryParameters={queryParameters}
                        setQueryParameters={setQueryParameters}
                        secondTitle="Order Status"
                        secondKey="orderStatus"
                        secondList={orderStatus?.data}
                        handleFirstCheckBox={handleOrderTypeCheckbox}
                        handleSecondCheckBox={handleOrderStatusCheckbox}
                      />
                    </Paper>
                  </Box>
                  <DataGrid
                    columns={fractionsOrdersColumns}
                    rows={isLoading ? [] : data?.data?.result}
                    pageSize={10}
                    loading={isLoading}
                    rowCount={data?.data?.totalItems || 0}
                    totalPages={data?.data?.totalPages}
                    paginationMode="server"
                    onPageChange={(p) =>
                      handlePageChange(p, queryParameters, setQueryParameters)
                    }
                    rowsPerPageOptions={[10]}
                    sx={{ cursor: "pointer", height: "630px" }}
                    onSortModelChange={(params) =>
                      handleSort(params, queryParameters, setQueryParameters)
                    }
                    localeText={{
                      noRowsLabel: "No Fractions Orders",
                    }}
                    sortingMode="server"
                  />
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </FirstBox>
      </SecondBox>
      <ReusableSnackbar handleClose={handleCloseSnack} />
      <Popover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>
          Building: {popoverValue?.building}
        </Typography>
        <Typography sx={{ p: 2 }}>Street: {popoverValue?.street}</Typography>
        <Typography sx={{ p: 2 }}>City: {popoverValue?.city}</Typography>
        <Typography sx={{ p: 2 }}>Town: {popoverValue?.town}</Typography>
      </Popover>
      <OrderDetails
        open={open}
        onClose={handleClose}
        data={orderDetails}
        detials={orderDetailsData}
      />
      <StorageOrderDetails
        open={openDialog2}
        onClose={handleCloseDialog2}
        data={orderDetails}
        detials={orderDetailsData}
      />
      <FractionsOrder
        open={openDialog3}
        onClose={handleCloseDialog3}
        data={orderDetails}
        detials={orderDetailsData}
      />
    </Box>
  );
};

export default Orders;
