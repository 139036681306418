import { Box, Typography } from "@mui/material";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import { DataGridStyle } from "../../styles";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import ActionAlert from "../user-profile/ActionAlert";
import CustomDialog from "../../components/CustomDialog";
import { deleteArticle, fetchArticles } from "../../services/apis/Articles";
import { useNavigate } from "react-router";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import AddButton from "../../components/common/AddButton";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
const Articles = () => {
  const [disableWhileDelete, setDisableWhileDelete] = useState(false);

  const [articleId, setArticleId] = useState(null);
  const [openDeletedAlert, setOpenDeletedAlert] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const {
    data: articlesData,
    isFetching,
    refetch,
  } = useQuery(["fetch_Articles"], () => fetchArticles(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteArticle, {
    onSuccess: () => {
      setDisableWhileDelete(false);
      refetch();
      queryClient.invalidateQueries("closedReasons");
      setSuccessDialogOpen(true);
      handleCloseDeleteAlert();
    },
    onError: (error) => {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    },
  });

  const handleDeleteArticle = async () => {
    setDisableWhileDelete(true);
    if (articleId) {
      mutation.mutate(articleId);
    }
  };
  const handleOpenDeleteAlert = (id) => {
    setArticleId(id);
    setOpenDeletedAlert(true);
  };
  const handleCloseDeleteAlert = () => {
    setOpenDeletedAlert(false);
  };
  const handleEditArticle = (params) => {
    const id = params.row.id;
    const Article = params.row;
    navigate(`/EditArticle/${id}`, {
      state: { selectedArticle: Article },
    });
  };
  const articlesColumns = ItemsColumns([
    {
      field: "sort",
      headerName: "Article Order",
      renderCell: (params) => {
        return (
          <Typography align="center">
            {params.row.sort === null ? "-" : params.value}
          </Typography>
        );
      },
    },
    {
      field: "articleheader",
      headerName: "Article Header",
      renderCell: (params) => params?.row?.trans?.en?.title,
    },
    {
      field: "is_hidden",
      headerName: "Article Status",
      renderCell: (params) =>
        params?.row?.sort === null ? (
          <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
            Hide
          </Typography>
        ) : (
          <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
            Show
          </Typography>
        ),
    },
    {
      field: "action",
      actions: {
        edit: (params) => handleEditArticle(params),
        delete: (params) => handleOpenDeleteAlert(params.row.id),
      },
    },
  ]);

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Articles" marginL={"25px"} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          gap: 2,
          pr: 2,
        }}
      >
        <AddButton
          title={"+ Add Article"}
          onClick={() => {
            navigate("/AddArticle");
          }}
        />
      </Box>

      <SecondBox>
        <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
          <DataGrid
            rows={isFetching ? [] : articlesData?.data?.data.result}
            columns={articlesColumns}
            loading={isFetching}
            rowsPerPageOptions={[10]}
            pageSize={10}
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
              height: "630px",
            }}
          />
        </Box>
      </SecondBox>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Article has been deleted Successfully."
        message=""
        buttonText="Go to Articles"
      />
      <ActionAlert
        openAlert={openDeletedAlert}
        handleCloseAlert={handleCloseDeleteAlert}
        handleReject={handleDeleteArticle}
        message={`Are you sure you want to delete this Article ?`}
        label={"Delete"}
        disabled={disableWhileDelete}
      />
      <ReusableSnackbar handleClose={handleCloseSnack} />
    </Box>
  );
};

export default Articles;
