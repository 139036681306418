import { Box, Button, InputBase, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useFetchUsers } from "../../services/apis/users";
import { formDate } from "../../services/helpers";
import { handlePageChange, checkString } from "../../services/utils/filters";
import FilterDrawer from "../../components/common/FilterDrawer";
import { handleFilterByString } from "../../services/utils/filters";
import Header from "../../components/Header";
import { saveAs } from "file-saver";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
const Users = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const [queryParameters, setQueryParameters] = useState({
    date_from: null,
    date_to: null,
    kyc_status: "",
    is_online: "all",
    orderBy: null,
    sort: null,
    page: 0,
    nationalId: null,
    phone: null,
    email: null,
  });
  const { data, isFetching } = useFetchUsers(queryParameters);
  const filterList = [
    { id: "no kycRequest", name: "no kycRequest" },
    { id: "pending", name: "pending" },
    { id: "approved", name: "approved" },
    { id: "rejected", name: "rejected" },
  ];
  const newUsersColumns = ItemsColumns([
    { field: "full_name", headerName: "Username" },
    { field: "email", flex: 2 },
    { field: "phone", headerName: "Phone Number" },
    { field: "currentBalance", headerName: "Balance", float: true },
    { field: "is_online", headerName: "Online Status", isOnline: true },
    { field: "created", headerName: "Join Date", date: true, flex: 2 },
    { field: "kyc_status", headerName: "KYC", kyc: true },
    {
      field: "viewUser",
      headerName: "Actions",
      actions: {
        view: (params) => {
          navigate(`/profile/${params.row.id}`);
        },
      },
    },
  ]);
  const handleSearchInput = (e) => {
    const { value } = e.target;
    checkString(value, queryParameters, setQueryParameters);
  };
  const handleUserFilterCheckbox = (e) => {
    handleFilterByString(
      e.target.value,
      "kyc_status",
      queryParameters,
      setQueryParameters
    );
  };
  const downloadUsers = async () => {
    try {
      const headers = [
        "ID",
        "Username",
        "Email",
        "Phone Number",
        "Balance",
        "Online Status",
        "Join Date",
        "KYC",
      ];
      const csvContent = [
        headers.join(","),
        ...data?.result?.map((user) => {
          return [
            user.id,
            user.full_name,
            user.email,
            user.phone,
            user.currentBalance ?? 0,
            user.is_online ? "online" : "offline",
            `"${formDate(user.created)}"`,
            user.kyc_status,
          ].join(",");
        }),
      ].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      saveAs(blob, "users.csv");
    } catch (error) {
      console.error("Error downloading users:", error);
    }
  };

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <DashboardMainHeader />
      <Header title="User Information" marginL={"20px"} />
      <SecondBox>
        <Box
          m="40px 0 0 0"
          height="100%"
          sx={{
            "& .MuiDataGrid-columnHeader.bold-header": {
              fontWeight: "bold",
              fontSize: "14px",
              color: "#000000",
            },
            "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
              backgroundColor: "#FFF",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#FFF",
              borderBottom: "none",
              pointerEvents: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "#FFF",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#FFF",
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                gap: 2,
                mb: 2,
                p: 2,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "600px",
                  height: "48px",
                  backgroundColor: "#F5F5F5",
                  boxShadow: "none",
                  borderRadius: "12px",
                }}
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <InputBase
                  onChange={handleSearchInput}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Email or Phone number....."
                />
                <FilterDrawer
                  queryParameters={queryParameters}
                  setQueryParameters={setQueryParameters}
                  firstList={filterList}
                  firstKey="kyc_status"
                  firstTitle="KYC Status"
                  secondTitle="Online Status"
                  handleFirstCheckBox={handleUserFilterCheckbox}
                  isUsers={true}
                />
              </Paper>
              <Box>
                <Button
                  onClick={() => downloadUsers()}
                  sx={{
                    borderRadius: "9px",
                    background: "#E9C237",
                    "&:hover": {
                      background: "#E9C237",
                    },
                    width: "171px",
                    height: "42px",
                    color: "#FFF",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                >
                  {"Download Report"}
                </Button>
              </Box>
            </Box>
          </Box>

          <DataGrid
            rows={isFetching ? [] : data?.data?.data?.result}
            columns={newUsersColumns}
            sx={{
              cursor: "pointer",
              height: "630px",
            }}
            loading={isFetching}
            hideFooterSelectedRowCount={true}
            rowCount={data?.data?.data?.totalItems || 0}
            paginationMode="server"
            rowsPerPageOptions={[10]}
            onPageChange={(p) =>
              handlePageChange(p, queryParameters, setQueryParameters)
            }
            pageSize={10}
            disableColumnMenu={true}
          />
        </Box>
      </SecondBox>
    </Box>
  );
};

export default Users;
