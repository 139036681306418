import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Typography } from "@mui/material";
import DateFiltering from "./DateFiltering";
import FilteringOptions from "./FilteringOptions";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoFilterOutline } from "react-icons/io5";

export default function FilterDrawer({
  queryParameters,
  setQueryParameters,
  firstTitle,
  secondTitle,
  firstList,
  secondList,
  firstKey,
  secondKey,
  handleFirstCheckBox,
  handleSecondCheckBox,
  isUsers,
  isRole,
  isGold,
}) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

 
  const toggleDrawer = (event, anchor, open) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <React.Fragment>
      <IoFilterOutline
        size={25}
        style={{ cursor: "pointer", marginRight: "10px" }}
        onClick={(event) => toggleDrawer(event, "left", true)}
      />
      {/* <img
        src={filterIcon}
        alt="filter"
        onClick={(event) => toggleDrawer(event, "left", true)}
        style={{ cursor: "pointer" }}
      /> */}
      <Drawer
        anchor="left"
        open={state["left"]}
        onClose={(event) => toggleDrawer(event, "left", false)}
      >
        <Box sx={{ width: 280, display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              //   alignItems:"center",
              padding: 4,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: "20%",
                }}
                onClick={(event) => toggleDrawer(event, "left", false)}
              >
                <IoIosCloseCircleOutline
                  // onClick={onClose}
                  color="#4C4C4C"
                  style={{
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                />
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                >
                  <path
                    d="M14.25 5.3075L13.1925 4.25L9 8.4425L4.8075 4.25L3.75 5.3075L7.9425 9.5L3.75 13.6925L4.8075 14.75L9 10.5575L13.1925 14.75L14.25 13.6925L10.0575 9.5L14.25 5.3075Z"
                    fill="#333333"
                    fillOpacity="0.87"
                  />
                </svg> */}
                <div>Close</div>
              </div>

              {/* <Button sx={{ mb: "2px" }}>Close</Button> */}
            </Box>
            {!isRole && (
              <>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 500,
                  }}
                >
                  Date
                </Typography>

                <DateFiltering
                  queryParameters={queryParameters}
                  setQueryParameters={setQueryParameters}
                />
              </>
            )}

            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 500,
                mt: 5,
              }}
            >
              {firstTitle}
            </Typography>
            <FilteringOptions
              firstTitle={firstTitle}
              filterList={firstList}
              queryParameters={queryParameters}
              k={firstKey}
              handleCheckbox={handleFirstCheckBox}
              isGold={isGold}
            />

            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 500,
                mt: 5,
              }}
            >
              {secondTitle}
            </Typography>

            <FilteringOptions
              filterList={secondList}
              k={secondKey}
              queryParameters={queryParameters}
              setQueryParameters={setQueryParameters}
              handleCheckbox={handleSecondCheckBox}
              isUsers={isUsers}
              isRole={isRole}
            />
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
