import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useEffect } from "react";
import columnsGenerator, { merge } from "./columnsGenerator";
import { useState } from "react";
import {
  addRemoveRoll,
  fetchPermissions,
} from "../../services/apis/roles-and-permissions";

import { changeKyc } from "../../services/apis/roles-and-permissions";
import { handlePageChange } from "../../services/utils/filters";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";

const PermissionsTable = ({ roles, colors, type, title }) => {
  const [tableData, setTableData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pagesCount, setPagesCount] = useState(10);
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
    orderBy: null,
    sort: null,
  });
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const dispatch = useDispatch()
    const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const handleCheckboxChange = async (event, permission, rid) => {
    try {
      if (event.target.checked) {
        permission.roles.push(rid);
        await addRemoveRoll(permission.id, rid);
      } else {
        permission.roles.splice(permission.roles.indexOf(rid), 1);
        await addRemoveRoll(permission.id, rid);
      }
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
  };

  const handleChangeKyc = async (id) => {
    try {
      await changeKyc(id);
      getPermissions();
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
  };

  const col = columnsGenerator(
    tableData,
    permissions,
    handleCheckboxChange,
    handleChangeKyc
  );

  const getPermissions = async () => {
    try {
      const response = await fetchPermissions(queryParameters, type);
      const p = response.data.data.result;
      const totalcount = response.data.data.totalItems;
      const totalPages = response.data.data.totalPages;
      setPermissions(p);
      merge(p, roles, setTableData, type, queryParameters);
      setPagesCount(totalcount);
      setTotalPages(totalPages);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  useEffect(() => {
    getPermissions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, queryParameters]);

  return (
    <Box m="40px 0 0 0" height="75vh">
      <DataGrid
        rows={tableData}
        columns={col}
        hideFooterSelectedRowCount={true}
        pageSize={10}
        paginationMode="server"
        rowCount={pagesCount}
        rowsPerPageOptions={[10]}
        totalPages={totalPages}
        onPageChange={(p) =>
          handlePageChange(p, queryParameters, setQueryParameters)
        }
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
      />
      <ReusableSnackbar
                 handleClose={handleCloseSnack}

      />
    </Box>
  );
};
export default PermissionsTable;
