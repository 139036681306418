import React from "react";
import { Box } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { handleFromDate, handleToDate } from "../../services/utils/filters";
import dayjs from "dayjs";
const BlockFilter = ({ queryParameters, setQueryParameters }) => {
  // const handleFromDate = (date) => {
  //   const formattedDate = date ? dayjs(date).format("MM/DD/YYYY") : "";
  //   setQueryParameters((prevParams) => ({
  //     ...prevParams,
  //     date_from: formattedDate,
  //   }));
  // };

  const handleToDate = (date) => {
    const formattedDate = date ? dayjs(date).format("MM/DD/YYYY") : "";
    setQueryParameters((prevParams) => ({
      ...prevParams,
      date_to: formattedDate,
    }));
  };
  return (
    <Box
      sx={{
        display: "flex",
        // width: "50%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label="From"
              onChange={(d) =>
                handleFromDate(d, queryParameters, setQueryParameters)
              }
              value={
                queryParameters.date_from !== ""
                  ? dayjs(queryParameters.date_from)
                  : null
              }
            />
          </DemoContainer>
        </LocalizationProvider> */}

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              value={
                queryParameters.date_to !== ""
                  ? dayjs(queryParameters.date_to)
                  : null
              }
              disablePast
              label="To"
              onChange={(d) =>
                handleToDate(d, queryParameters, setQueryParameters)
              }
              minDate={
                queryParameters.date_from !== ""
                  ? dayjs(queryParameters.date_from)
                  : null
              }
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default BlockFilter;
