import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Button, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import { Box } from "@mui/material";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";

const KycDialog = ({ openDialog, handleCloseDialog, image, handleApprove }) => {
  const [formData, setFormData] = useState({
    national_name: "",
    nationalId: "",
    confirmNationalId: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Allow only numbers and restrict length to 14
    if (name === "nationalId" || name === "confirmNationalId") {
      if (/^\d{0,14}$/.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const isValidNationalName = (name) => {
    const nameParts = name.trim().split(" ");
    return nameParts.length === 4 && nameParts.every((part) => part.length > 0);
  };
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const handleWheel = (event) => {
    event.target.blur();
    setTimeout(() => {
      event.target.focus();
    }, 0);
  };
  const handleSubmit = async () => {
    try {
      if (
        !formData.national_name ||
        !formData.nationalId ||
        !formData.confirmNationalId
      ) {
        dispatch(
          setData({
            openSnack: true,
            message: "Please fill in all required fields",
          })
        );
      } else if (formData.nationalId !== formData.confirmNationalId) {
        dispatch(
          setData({
            openSnack: true,
            message: "National ID doesn't match",
          })
        );
      } else if (
        formData.nationalId.length !== 14 ||
        formData.confirmNationalId.length !== 14
      ) {
        dispatch(
          setData({
            openSnack: true,
            message: "National ID must be 14 digits",
          })
        );
      } else if (!isValidNationalName(formData.national_name)) {
        dispatch(
          setData({
            openSnack: true,
            message:
              "Please Enter user's full name as displayed in his national id",
          })
        );
      } else {
        const dataToSend = {
          national_name: formData.national_name,
          nationalId: formData.nationalId,
        };
        await handleApprove(dataToSend);
      }
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error?.data?.data?.message,
        })
      );
    }
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "800px",
            minHeight: "201px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent sx={{}}>
          <Card sx={{ maxWidth: 800 }}>
            <div className="">
              <img src={image} alt="" />
            </div>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextField
                variant="standard"
                label="The quad name"
                name="national_name"
                onChange={handleChange}
              />
              <TextField
                sx={{
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                }}
                variant="standard"
                label="National ID"
                type="number"
                name="nationalId"
                onChange={handleChange}
                onWheel={handleWheel}
                maxLength={14}
                inputProps={{
                  maxLength: 14,
                }}
                // onPaste={handlePaste}
              />
              <TextField
                sx={{
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                }}
                variant="standard"
                label="Confirm National ID"
                type="number"
                name="confirmNationalId"
                onChange={handleChange}
                onWheel={handleWheel}
                inputProps={{
                  maxLength: 14,
                }}
                // onPaste={handlePaste}
              />
            </Box>
          </Card>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", ml: 2, mb: 2 }}
        >
          <Button
            onClick={handleSubmit}
            autoFocus
            variant="contained"
            sx={{
              background: "#E9C237",
              width: "150px",
              height: "40px",
              // padding: "0 30px",
              "&:hover": {
                background: "#E9C237",
              },
            }}
          >
            Approve
          </Button>
          <Button
            onClick={handleCloseDialog}
            sx={{
              "&:hover": {
                borderColor: "#E9C237",
              },
              color: "#E9C237",
              borderColor: "#E9C237",
              width: "150px",
              height: "40px",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ReusableSnackbar handleClose={handleCloseSnack} />
    </>
  );
};

export default KycDialog;
