import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { axiosInstance as axios } from "../../../services/config";
import TextFieldComponent from "../../../components/TextFieldComponent";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [submitStatus, setSubmitStatus] = useState(null);
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (/[A-Z]/.test(pathname)) {
      navigate("/forget-password");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const forget = async (email) => {
    try {
      const body = { email };
      const response = await axios.post(`/api/admin/forget-password`, body);
      return response;
    } catch (error) {
      if (error.response.data.status === 422) {
        console.log("validation ", error.response.data.validation);
        setErrorMessage("Invalid Email");
      } else {
        setErrorMessage(error.response.data.message);
        console.log("Error in forget password ", error.response.data.message);
      }
    }
  };

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;

    setEmail(enteredEmail);
  };
  const isEmailValid = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!isEmailValid()) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }
    try {
      const response = await forget(email);
      if (response.status === 200) {
        setSubmitStatus("success");
      } else if (response.status === 400 || response.status === 403) {
        console.log("error in forget password ", response.message);
        setErrorMessage(response.message);
      } else if (response.status === 422) {
        console.log("422 ", response.validation);
        setErrorMessage("Invalid Email");
      }
    } catch (error) {
      console.log("error ", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid sx={{ height: "100%" }} container spacing={4}>
      <Grid
        container
        sx={{
          flexDirection: "column",
          height: "100%",
          backgroundColor: "#ffffff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
        item
        xs={12}
        md={6}
        lg={6}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          noValidate
          autoComplete="off"
        >
          <Typography sx={{ color: "#E9C237", fontSize: "36px" }} variant="h3">
            {submitStatus === "success" ? "Reset Password" : "Forget Password"}
          </Typography>
          <Typography
            sx={{
              color: "#B3B3B3",
              fontSize: "14px",
              width: "500px",
              marginTop: "15px",
              marginBottom: "10px",
            }}
            variant="h3"
          >
            {submitStatus === "success"
              ? "You will receive an email to rest your password, Open the email and click on the provided link to proceed with the password reset."
              : "Provide the email address associated with your account in the field below. We'll send you a OTP to reset your password"}
          </Typography>
          {submitStatus === "success" ? (
            ""
          ) : (
            <TextFieldComponent
              type="text"
              style={{ width: "500px" }}
              name="Email"
              placeholder="Enter your Email"
              value={email}
              error={errorMessage}
              onChange={handleEmailChange}
            />
          )}
          {submitStatus === "success" ? (
            ""
          ) : (
            <Button
              disabled={isLoading}
              onClick={handleSubmit}
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#E9C237",
                "&:hover": { backgroundColor: "#E9C237" },
                height: "48px",
                width: "500px",
                marginTop: "10%",
                borderRadius: "9px",
              }}
            >
              Send OTP
            </Button>
          )}
        </Box>
      </Grid>
      <Grid
        container
        sx={{
          height: "100%",
          backgroundColor: "#F7F7F7",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        item
        xs={12}
        md={6}
        lg={6}
      >
        <Box
          sx={{
            width: "450px",
            height: "450px",
          }}
        >
          <img
            src={`../../assets/loginimage.png`}
            alt="loginimage"
            style={{
              height: "100%",
              width: "100%",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ForgetPassword;
