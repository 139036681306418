const getPaymentStatus = (transactions) => {
  const paymentStatus = new Map();
  paymentStatus.set(1, "Pending");
  paymentStatus.set(2, "Accepted");
  paymentStatus.set(3, "Rejected");

  loop(transactions, paymentStatus, "status_id");
};

const getTransactionType = (transactions) => {
  const transactionTypes = new Map();
  transactionTypes.set(1, "Deposit");
  transactionTypes.set(2, "Withdraw");
  transactionTypes.set(3, "Buy");
  transactionTypes.set(4, "Sell");
  transactionTypes.set(5, "Refund");

  loop(transactions, transactionTypes, "type");
};

const loop = (transactions, map, target) => {
  for (let i = 0; i < transactions.length; i++) {
    let value = map.get(transactions[i][target]);
    transactions[i][target] = value;
  }
};

const getUsername = (transactions) => {
  for (let i = 0; i < transactions.length; i++) {
    transactions[i].username = transactions[i].full_name;
  }
};

export const transactionsAdjustments = (transactions) => {
  getPaymentStatus(transactions);
  getTransactionType(transactions);
  getUsername(transactions);
  return transactions;
};

export const formDate = (stringDate) => {
  const date = new Date(stringDate);
  date.setHours(date.getHours() + 2);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
    timeZone: "UTC",
  };
  return date.toLocaleDateString("en-US", options);
};

export const getGoldTransactionType = (goldTransactions) => {
  const goldTransactionsTypes = new Map();
  goldTransactionsTypes.set(1, "Physical Store");
  goldTransactionsTypes.set(2, "Physical Delivery");
  goldTransactionsTypes.set(3, "Fractions");
  goldTransactionsTypes.set(4, "Stored then Delivered");

  loop(goldTransactions, goldTransactionsTypes, "goldType");
  return goldTransactions;
};

export const checkIfUserIsLogged = () => {
  const userToken = localStorage.getItem("token");
  if (!userToken) return false;

  return true;
};

export const checkTokenValidation = (response) => {
  if (response.status === 401) {
    const token = localStorage.getItem("token");
    if (token) localStorage.removeItem("token");
    return false;
  }
  return true;
};
