import { Box, Typography } from "@mui/material";
import { axiosInstance } from "../services/config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearToken } from "../store/slices/authenticationSlice";
import profile from "../svg/profile-circle.svg";
const DashboardMainHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogOut = async () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      await axiosInstance.get(`api/users/logout`, {
        headers,
      });
      // window.close();
      // window.open("/login", "_blank");
      // navigate("/login", { replace: true });
      // Navigate to login and replace history
      // Clear the history state to prevent back navigation
      window.history.pushState(null, null, "/login");
      window.history.replaceState(null, null, "/login");
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
      sessionStorage.removeItem("selectedTitle");
      sessionStorage.removeItem("isPageRefreshed");
      dispatch(clearToken());
    } catch (error) {
      console.error("Logout error:", error);
      if (error?.response?.status === 401) {
        // Clear the history state to prevent back navigation
        window.history.pushState(null, null, "/login");
        window.history.replaceState(null, null, "/login");
        navigate("/login", { replace: true });

        localStorage.removeItem("token");
        sessionStorage.removeItem("selectedTitle");
        sessionStorage.removeItem("isPageRefreshed");
        dispatch(clearToken());
      } else {
        // Clear the history state to prevent back navigation
        window.history.pushState(null, null, "/login");
        window.history.replaceState(null, null, "/login");
        navigate("/login", { replace: true });

        localStorage.removeItem("token");
        sessionStorage.removeItem("selectedTitle");
        sessionStorage.removeItem("isPageRefreshed");
        dispatch(clearToken());
        console.log("Logout error occurred");
      }
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        marginRight: "1%",
        marginTop: "2%",
        mb: "30px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
        p={1}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            onClick={() => {
              navigate("/AdminProfile");
            }}
            style={{ cursor: "pointer" ,width:"30px"}}
            alt="profile"
            src={profile}
          />

          <div
            onClick={handleLogOut}
            style={{
              marginLeft: "30px",
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#F7F7F7",
              borderRadius: "12px",
              padding: "10px 30px",
              justifyContent: "space-between",

              cursor: "pointer",
            }}
          >
            <img alt="logout" src={`../../assets/logout.png`} />
            <Typography
              sx={{
                color: "#4C4C4C",
                fontSize: "14px",
                fontWeight: "400",
                marginLeft: "15px",
              }}
            >
              Logout
            </Typography>
          </div>
        </Box>
      </Box>
    </Box>
  );
};
export default DashboardMainHeader;
