import { useQuery } from "react-query";
import { axiosInstance as axios } from "../config";
export const fetchBlockReasons = async ({ page, limit }) => {
  try {
    const params = page;
    if (limit !== undefined) {
      params.limit = limit;
    }
    const response = await axios.get(`/api/blockUserReasons/index`, {
      params,
    });
    return response.data?.data; // assuming you need the data part of the response
  } catch (error) {
    throw error;
  }
};

export const useBlockReasons = (page, limit) => {
  return useQuery(
    ["blockReasons", { page, limit }],
    () => fetchBlockReasons({ page, limit }),
    {
      keepPreviousData: true, // Keeps the previous data while fetching new data
      staleTime: 5000, // Time before the data is considered stale (in milliseconds)
      refetchOnWindowFocus: false,
    }
  );
};
export const addBlockReasons = async (body) => {
  try {
    const response = await axios.post("/api/blockUserReasons/add", body);
    return response;
  } catch (error) {
    throw error;
  }
};
export const deleteBlockReason = async (id) => {
  try {
    const response = await axios.get(`/api/blockUserReasons/delete/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const editBlockReason = async (id, body) => {
  try {
    const response = await axios.post(`/api/blockUserReasons/edit/${id}`, body);
    return response;
  } catch (error) {
    throw error;
  }
};
