import React, { useEffect, useRef } from "react";
import {
  Typography,
  Box,
  Divider,
  Paper,
  Container,
  Grid,
  Tab,
  Tabs,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  InputAdornment,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addReplay,
  adminChangeStatus,
  fetchAssignedAdmins,
  fetchTicketsDetails,
  reassign,
} from "../../services/apis/tickets";
import { formDate } from "../../services/helpers";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { TabPanel, TabContext } from "@mui/lab";
import { withStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import CloseTicketDialog from "./CloseTicketDialog";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { MenuProps } from "../../components/common/MenuProps";
import { imageBaseUrl } from "../../services/config";

const Message = ({ message, admin_id, created }) => {
  const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp)$/i;
  const [open, setOpen] = useState(false);
  return (
    <Box
      key={admin_id}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          color: "#B3B3B3",
          display: "flex",
          alignSelf: "center",
          marginBottom: "10px",
          fontSize: "10px",
        }}
      >
        {formDate(created)}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: !admin_id ? "flex-start" : "flex-end",
          mb: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "80%",
            flexDirection: !admin_id ? "row" : "row-reverse",
            alignItems: "center",
          }}
        >
          {imageExtensions.test(message) ? (
            <>
              <Dialog
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    boxShadow: "none",
                  },
                }}
              >
                <DialogContent
                  sx={{
                    width: "500px",
                    height: "778px",
                    padding: "0px",
                    overflow: "hidden",
                    backgroundColor: "transparent",
                  }}
                >
                  <img
                    src={`${imageBaseUrl}/${message}`}
                    alt="message"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </DialogContent>
              </Dialog>
              <img
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOpen(true);
                }}
                src={`${imageBaseUrl}/${message}`}
                alt="message"
                width={"150px"}
              />
            </>
          ) : (
            <Paper
              variant="outlined"
              sx={{
                p: 2,
                borderRadius: "12px",
                backgroundColor: admin_id ? "#E9C237" : "#F7F7F7",
                width: "35vw",
                border: "none",
                flexGrow: 1,
                ml: admin_id ? 1 : 0,
                mr: admin_id ? 0 : 1,
                overflow: "hidden",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: admin_id ? "#FFF" : "#4C4C4C",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                {message}
              </Typography>
            </Paper>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const TicketsDetails = () => {
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [ticketsDetailsInfo, setTicketsDetailsInfo] = useState([]);
  const [AssignedAdmins, setAssignedAdmins] = useState([]);
  const location = useLocation();
  const ticketsdetails = location.state.ticketsdetails;
  const [showReassignText, setShowReassignText] = useState(true);
  const handleReassignClick = () => {
    setShowReassignText(false);
  };
  const [openBlockedAlert, setOpenBlockedAlert] = useState(false);
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const AntTabs = withStyles({
    root: {
      borderBottom: "1px solid #e8e8e8",
    },
    indicator: {
      backgroundColor: "#E9C237",
    },
  })(Tabs);
  const AntTab = withStyles((theme) => ({
    root: {
      "&:hover": {
        color: "#E9C237",
        opacity: 1,
      },
      "&$selected": {
        color: "#E9C237 !important",
      },
      "&:focus": {
        color: "#E9C237 !important",
      },
    },
    selected: {
      "&$selected": {
        borderBottom: "none",
      },
    },
  }))((props) => <Tab disableRipple {...props} />);

  const ITEM_HEIGHTS = 36;
  const ITEM_PADDING_TOPS = 8;
  const MenuItemProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHTS * 4.5 + ITEM_PADDING_TOPS,
        width: 120,
        backgroundColor: "#FAFAFA",
        border: 0,
      },
    },
  };
  const [selectedTopic, setSelectedTopic] = useState(null);
  const handleTopicChange = async (event) => {
    try {
      const selectedId = event.target.value.id;
      const payload = {
        id: ticketsdetails?.id,
        admin_id: selectedId,
      };
      await reassign(payload);
      setSelectedTopic(event.target.value);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const containerRef = useRef(null);
  useEffect(() => {
    if (value === "2") {
      // Scroll to the bottom when component mounts or when ticketsDetailsInfo changes
      scrollToBottom();
    }
  }, [ticketsDetailsInfo, value]);

  const scrollToBottom = () => {
    // Scroll to the bottom of the container
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(`/tickets/${ticketsDetailsInfo?.user_id}`);
  };

  const getTicketsDetails = async () => {
    setLoadingDetails(true);
    setTicketsDetailsInfo([]);
    try {
      const response = await fetchTicketsDetails(ticketsdetails?.id);
      setTicketsDetailsInfo(response.data.data);
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
      setLoadingDetails(false);
    }
  };
  const getAssignedAdmins = async () => {
    setAssignedAdmins([]);
    try {
      const response = await fetchAssignedAdmins();
      setAssignedAdmins(response.data.data);
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
    }
  };

  const [formData, setFormData] = useState({
    id: ticketsdetails?.id,
    reply: "",
  });
  const handleSave = async () => {
    try {
      if (!formData.reply.trim()) {
        dispatch(
          setData({
            openSnack: true,
            message: "Reply cannot be empty.",
          })
        );
        return;
      }
      console.log(formData);
      await addReplay(formData);
      getTicketsDetails();
      setFormData({
        id: ticketsdetails?.id,
        reply: "",
      });
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && formData.reply.trim() !== "") {
      handleSave();
    }
  };
  useEffect(() => {
    getAssignedAdmins();
  }, []);

  useEffect(() => {
    getTicketsDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketsdetails?.id]);

  const [selectedStatus, setSelectedStatus] = useState(null);
  const handleStatusChange = async (event) => {
    const selectedStatusId = event.target.value.id;
    if (selectedStatusId === 4) {
      handleOpenBlockAlert();
    } else {
      try {
        const selectedId = event.target.value.id;
        const payload = {
          id: ticketsdetails?.id,
          status: selectedId,
        };
        await adminChangeStatus(payload);
        setSelectedStatus(event.target.value);
        getTicketsDetails();
      } catch (error) {
        if (error.response.status === 422) {
          dispatch(
            setData({
              openSnack: true,
              message: error.response.data.validation.status,
            })
          );
        } else {
          dispatch(
            setData({
              openSnack: true,
              message: error.response.data.message,
            })
          );
        }
      }
    }
  };
  const Status = [
    { id: 1, name: "Open" },
    { id: 2, name: "Pending" },
    { id: 3, name: "Resolved" },
    { id: 4, name: "Close" },
  ];
  const handleOpenBlockAlert = (e) => {
    setOpenBlockedAlert(true);
  };

  const handleCloseBlockAlert = () => {
    setOpenBlockedAlert(false);
  };
  return (
    <>
      <Box
        style={{
          overflowY: "auto",
          maxHeight: "calc(100vh)",
          width: "100%",
        }}
      >
        <CloseTicketDialog
          getTickets={getTicketsDetails}
          selectedClosedStatusId={ticketsdetails?.id}
          openAlert={openBlockedAlert}
          handleCloseAlert={handleCloseBlockAlert}
          message={`Are you sure you want to Close this Ticket ?`}
          label={"Confirm"}
        />
        <Box sx={{ ml: "20px", mb: "30px" }}>
          <DashboardMainHeader />
        </Box>
        <Container maxWidth="xl">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box
                sx={{
                  height: "80vh",
                  backgroundColor: "#fff",
                  borderRadius: "24px",
                  padding: "30px 20px 0px 20px",
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ color: "#202020", fontSize: "32px" }}>
                    {ticketsdetails?.full_name}
                  </Typography>
                  {!loadingDetails ? (
                    <>
                      {ticketsDetailsInfo.status !== 4 ? (
                        <Box
                          sx={{
                            width: "120px",
                            backgroundColor: "#FAFAFA",
                            borderRadius: "6px",
                            borderBottom: "none",
                          }}
                        >
                          <FormControl sx={{ width: "100%" }}>
                            <Select
                              displayEmpty
                              onChange={handleStatusChange}
                              value={selectedStatus || ""}
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected?.length === 0) {
                                  return (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        color:
                                          selectedStatus &&
                                          selectedStatus.id === 1
                                            ? "#FF453A"
                                            : selectedStatus &&
                                              selectedStatus.id === 2
                                            ? "#E9C237"
                                            : selectedStatus &&
                                              selectedStatus.id === 3
                                            ? "#3284FF"
                                            : ticketsDetailsInfo.status === 1
                                            ? "#FF453A"
                                            : ticketsDetailsInfo.status === 2
                                            ? "#E9C237"
                                            : ticketsDetailsInfo.status === 3
                                            ? "#3284FF"
                                            : "#30D158",
                                      }}
                                    >
                                      <span
                                        style={{
                                          width: "8px",
                                          height: "8px",
                                          borderRadius: "50%",
                                          backgroundColor:
                                            selectedStatus &&
                                            selectedStatus.id === 1
                                              ? "#FF453A"
                                              : selectedStatus &&
                                                selectedStatus.id === 2
                                              ? "#E9C237"
                                              : selectedStatus &&
                                                selectedStatus.id === 3
                                              ? "#3284FF"
                                              : ticketsDetailsInfo.status === 1
                                              ? "#FF453A"
                                              : ticketsDetailsInfo.status === 2
                                              ? "#E9C237"
                                              : ticketsDetailsInfo.status === 3
                                              ? "#3284FF"
                                              : "#30D158",
                                          marginRight: "8px",
                                        }}
                                      ></span>
                                      {ticketsDetailsInfo.status === 1
                                        ? "Open"
                                        : ticketsDetailsInfo.status === 2
                                        ? "Pending"
                                        : ticketsDetailsInfo.status === 3
                                        ? "Resolved"
                                        : ticketsDetailsInfo.status === 4
                                        ? "Closed"
                                        : "Open"}
                                    </Box>
                                  );
                                }
                                return (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        width: "8px",
                                        height: "8px",
                                        borderRadius: "50%",
                                        backgroundColor:
                                          selectedStatus &&
                                          selectedStatus.id === 1
                                            ? "#FF453A"
                                            : selectedStatus &&
                                              selectedStatus.id === 2
                                            ? "#E9C237"
                                            : selectedStatus &&
                                              selectedStatus.id === 3
                                            ? "#3284FF"
                                            : "#30D158",
                                        marginRight: "8px",
                                      }}
                                    ></span>
                                    {selected.name}
                                  </Box>
                                );
                              }}
                              MenuProps={MenuItemProps}
                              inputProps={{ "aria-label": "Without label" }}
                              sx={{
                                boxShadow: "none",
                                ".MuiOutlinedInput-notchedOutline": {
                                  border: 0,
                                },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 0,
                                  },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 0,
                                  },
                                "& .MuiSelect-select.MuiSelect-select": {
                                  color:
                                    selectedStatus && selectedStatus.id === 1
                                      ? "#FF453A"
                                      : selectedStatus &&
                                        selectedStatus.id === 2
                                      ? "#E9C237"
                                      : selectedStatus &&
                                        selectedStatus.id === 3
                                      ? "#3284FF"
                                      : "#30D158",
                                },
                              }}
                            >
                              {Status.map((item) => (
                                <MenuItem
                                  key={item.id}
                                  value={item}
                                  sx={{
                                    color:
                                      item.id === 1
                                        ? "#FF453A"
                                        : item.id === 2
                                        ? "#E9C237"
                                        : item.id === 3
                                        ? "#3284FF"
                                        : "#30D158",
                                  }}
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      ) : (
                        <Typography
                          sx={{ color: "#30D158", marginRight: "5%" }}
                        >
                          Closed
                        </Typography>
                      )}
                    </>
                  ) : null}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Typography sx={{ color: "#B3B3B3", fontSize: "12px" }}>
                    #{ticketsdetails?.id}
                  </Typography>
                  <Typography
                    sx={{
                      marginLeft: "20px",
                      color: "#B3B3B3",
                      fontSize: "12px",
                    }}
                  >
                    {formDate(ticketsdetails?.responseDate)}
                  </Typography>
                </Box>
                <TabContext value={value}>
                  <Box
                    sx={{
                      marginTop: "20px",
                      borderBottom: 1,
                      borderColor: "#F5F5F5",
                    }}
                  >
                    <AntTabs
                      value={value}
                      onChange={handleChange}
                      aria-label="ant example"
                      variant="fullWidth"
                    >
                      <AntTab label="Ticket Info" value="1" />
                      <AntTab label="Replies" value="2" />
                      <AntTab label="User Info" value="3" />
                      <AntTab label="Ticket History" value="4" />
                    </AntTabs>
                  </Box>
                  <TabPanel
                    sx={{
                      overflowX: "auto",
                      height: "50vh",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                    value="1"
                  >
                    {loadingDetails ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          alignSelf: "center",
                          justifyContent: "center",
                          height: "43vh",
                          width: "100%",
                        }}
                      >
                        <CircularProgress color="inherit" />
                      </Box>
                    ) : (
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <Typography
                            sx={{ color: "#666666", fontSize: "18px" }}
                          >
                            Ticket ID :
                          </Typography>
                          <Typography
                            sx={{
                              color: "#666666",
                              fontSize: "18px",
                              marginLeft: "10px",
                            }}
                          >
                            #{ticketsdetails?.id}
                          </Typography>
                        </Box>
                        <Divider
                          sx={{
                            backgroundColor: "#F5F5F5",
                            my: 2,
                          }}
                        />
                        <Box
                          sx={{
                            marginTop: "20px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "50%",
                            }}
                          >
                            <Typography
                              sx={{ color: "#666666", fontSize: "18px" }}
                            >
                              Ticket Subject :
                            </Typography>
                            <Typography
                              sx={{
                                color: "#666666",
                                fontSize: "18px",
                                marginLeft: "10px",
                              }}
                            >
                              {ticketsDetailsInfo.subject}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ color: "#666666", fontSize: "18px" }}
                            >
                              Category :
                            </Typography>
                            <Typography
                              sx={{
                                color: "#666666",
                                fontSize: "18px",
                                marginLeft: "10px",
                              }}
                            >
                              Orders
                            </Typography>
                          </Box>
                        </Box>
                        <Divider
                          sx={{
                            backgroundColor: "#F5F5F5",
                            my: 2,
                          }}
                        />
                        <Box
                          sx={{
                            marginTop: "20px",
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "50%",
                            }}
                          >
                            <Typography
                              sx={{ color: "#666666", fontSize: "16px" }}
                            >
                              Creation Date :
                            </Typography>
                            <Typography
                              sx={{
                                color: "#666666",
                                fontSize: "16px",
                                marginLeft: "5px",
                              }}
                            >
                              {formDate(ticketsDetailsInfo.created)}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ color: "#666666", fontSize: "16px" }}
                            >
                              Last Response Date :
                            </Typography>
                            <Typography
                              sx={{
                                color: "#666666",
                                fontSize: "16px",
                                marginLeft: "5px",
                              }}
                            >
                              {formDate(ticketsdetails?.responseDate)}
                            </Typography>
                          </Box>
                        </Box>
                        <Divider
                          sx={{
                            backgroundColor: "#F5F5F5",
                            my: 2,
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <Typography
                            sx={{ color: "#666666", fontSize: "18px" }}
                          >
                            Feedback :
                          </Typography>
                          <Typography
                            sx={{
                              color: "#666666",
                              fontSize: "18px",
                              marginLeft: "10px",
                            }}
                          >
                            {ticketsDetailsInfo?.feedback}
                          </Typography>
                        </Box>
                        <Divider
                          sx={{
                            backgroundColor: "#F5F5F5",
                            my: 2,
                          }}
                        />
                        <Box
                          sx={{
                            marginTop: "20px",
                            display: showReassignText ? "flex" : "none",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "50%",
                            }}
                          >
                            <Typography
                              sx={{ color: "#666666", fontSize: "18px" }}
                            >
                              Assign to :
                            </Typography>
                            <Typography
                              sx={{
                                color: "#666666",
                                fontSize: "18px",
                                marginLeft: "10px",
                              }}
                            >
                              {ticketsDetailsInfo?.assignName}
                            </Typography>
                          </Box>
                          {ticketsDetailsInfo.status !== 4 && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                              onClick={handleReassignClick}
                            >
                              <Typography
                                sx={{
                                  color: "#E9C237",
                                  fontSize: "18px",
                                  cursor: "pointer",
                                }}
                              >
                                Reassign
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            display: !showReassignText ? "block" : "none",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#4C4C4C",
                              marginBottom: "5px",
                              marginTop: "15px",
                            }}
                          >
                            Assign to :
                          </Typography>
                          <FormControl sx={{ width: "100%" }}>
                            <Select
                              displayEmpty
                              onChange={handleTopicChange}
                              value={selectedTopic || ""}
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected?.length === 0) {
                                  return <em>Assign to</em>;
                                }
                                return selected?.full_name;
                              }}
                              MenuProps={MenuProps}
                              inputProps={{ "aria-label": "Without label" }}
                              sx={{
                                boxShadow: "none",
                                ".MuiOutlinedInput-notchedOutline": {
                                  border: 1,
                                  borderColor: "#E9C237",
                                },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 1,
                                    borderColor: "#E9C237",
                                  },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 1,
                                    borderColor: "#E9C237",
                                  },
                              }}
                            >
                              {AssignedAdmins.map((item) => (
                                <MenuItem key={item.id} value={item}>
                                  {item.full_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    )}
                  </TabPanel>
                  <TabPanel
                    value="2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {loadingDetails ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          alignSelf: "center",
                          justifyContent: "center",
                          height: "43vh",
                          width: "100%",
                        }}
                      >
                        <CircularProgress color="inherit" />
                      </Box>
                    ) : (
                      <Box
                        pt={2}
                        sx={{
                          flexGrow: 1,
                          overflowY: "auto",
                          height: "50vh",
                          flexDirection: "column",
                          width: "100%",
                          alignItems: "center",
                          display: "flex",
                          scrollbarWidth: "none",
                          msOverflowStyle: "none",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                          // position: "relative",
                        }}
                      >
                        <Box
                          ref={containerRef}
                          sx={{
                            height: "50vh",
                            width: "100%",
                            overflowY: "auto",
                            scrollbarWidth: "none",
                            msOverflowStyle: "none",
                            "&::-webkit-scrollbar": {
                              display: "none",
                            },
                            // Adjust height as needed
                            /* Other styles */
                          }}
                        >
                          {ticketsDetailsInfo?.replies?.map((message) => (
                            <Message
                              key={`${message.created}+${message?.message}`}
                              message={message.message}
                              admin_id={message.admin_id}
                              created={message.created}
                            />
                          ))}
                        </Box>
                        {ticketsDetailsInfo.status !== 4 && (
                          <Box
                            sx={{
                              // position: "fixed",
                              display: "flex",
                              alignSelf: "center",
                              alignItems: "center",
                              bottom: "8%",
                              width: "100%",
                              zIndex: "999",
                            }}
                          >
                            <TextField
                              sx={{
                                "& fieldset": { border: "none" },
                                height: "48px",
                                borderRadius: "12px",
                                backgroundColor: "#F5F5F5",
                                // zIndex: "999",
                              }}
                              placeholder="Type your replay here ....."
                              fullWidth
                              value={formData.reply}
                              onKeyPress={handleKeyPress}
                              onChange={(event) => {
                                var temppayload = { ...formData };
                                temppayload.reply = event.target.value;
                                setFormData({ ...temppayload });
                              }}
                              InputProps={{
                                endAdornment: formData.reply.trim() !== "" && (
                                  <InputAdornment position="end">
                                    <svg
                                      cursor={"pointer"}
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      onClick={() => {
                                        handleSave();
                                      }}
                                    >
                                      <path
                                        d="M14.2189 21.6303C13.0389 21.6303 11.3689 20.8003 10.0489 16.8303L9.32891 14.6703L7.16891 13.9503C3.20891 12.6303 2.37891 10.9603 2.37891 9.78031C2.37891 8.61031 3.20891 6.93032 7.16891 5.60032L15.6589 2.77032C17.7789 2.06032 19.5489 2.27032 20.6389 3.35032C21.7289 4.43032 21.9389 6.21032 21.2289 8.33032L18.3989 16.8203C17.0689 20.8003 15.3989 21.6303 14.2189 21.6303ZM7.63891 7.03032C4.85891 7.96032 3.86891 9.06032 3.86891 9.78031C3.86891 10.5003 4.85891 11.6003 7.63891 12.5203L10.1589 13.3603C10.3789 13.4303 10.5589 13.6103 10.6289 13.8303L11.4689 16.3503C12.3889 19.1303 13.4989 20.1203 14.2189 20.1203C14.9389 20.1203 16.0389 19.1303 16.9689 16.3503L19.7989 7.86032C20.3089 6.32032 20.2189 5.06032 19.5689 4.41032C18.9189 3.76032 17.6589 3.68032 16.1289 4.19032L7.63891 7.03032Z"
                                        fill="#E9C237"
                                      />
                                      <path
                                        d="M10.1108 14.4C9.92078 14.4 9.73078 14.33 9.58078 14.18C9.29078 13.89 9.29078 13.41 9.58078 13.12L13.1608 9.53C13.4508 9.24 13.9308 9.24 14.2208 9.53C14.5108 9.82 14.5108 10.3 14.2208 10.59L10.6408 14.18C10.5008 14.33 10.3008 14.4 10.1108 14.4Z"
                                        fill="#E9C237"
                                      />
                                    </svg>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    )}
                  </TabPanel>

                  <TabPanel
                    sx={{
                      overflowX: "auto",
                      height: "46vh",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                    value="3"
                  >
                    {loadingDetails ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          alignSelf: "center",
                          justifyContent: "center",
                          height: "43vh",
                          width: "100%",
                        }}
                      >
                        <CircularProgress color="inherit" />
                      </Box>
                    ) : (
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ color: "#666666", fontSize: "18px" }}
                            >
                              User name :
                            </Typography>
                            <Typography
                              sx={{
                                color: "#666666",
                                fontSize: "18px",
                                marginLeft: "10px",
                              }}
                            >
                              {ticketsDetailsInfo?.user?.full_name}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#E9C237",
                                fontFamily: "Lato",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                textDecorationLine: "underline",
                                cursor: "pointer",
                              }}
                              onClick={handleNavigation}
                            >
                              See all User Tickets
                            </Typography>
                          </Box>
                        </Box>
                        <Divider
                          sx={{
                            backgroundColor: "#F5F5F5",
                            my: 2,
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{ color: "#666666", fontSize: "18px" }}
                          >
                            Phone number :
                          </Typography>

                          <Typography
                            sx={{
                              color: "#666666",
                              fontSize: "18px",
                              marginLeft: "10px",
                            }}
                          >
                            {ticketsDetailsInfo?.user?.phone}
                          </Typography>
                        </Box>
                        <Divider
                          sx={{
                            backgroundColor: "#F5F5F5",
                            my: 2,
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{ color: "#666666", fontSize: "18px" }}
                          >
                            Mail :
                          </Typography>

                          <Typography
                            sx={{
                              color: "#666666",
                              fontSize: "18px",
                              marginLeft: "10px",
                            }}
                          >
                            {ticketsDetailsInfo?.user?.email}
                          </Typography>
                        </Box>
                        <Divider
                          sx={{
                            backgroundColor: "#F5F5F5",
                            my: 2,
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{ color: "#666666", fontSize: "18px" }}
                          >
                            National ID Name :
                          </Typography>

                          <Typography
                            sx={{
                              color: "#666666",
                              fontSize: "18px",
                              marginLeft: "10px",
                            }}
                          >
                            {ticketsDetailsInfo?.user?.national_name}
                          </Typography>
                        </Box>
                        <Divider
                          sx={{
                            backgroundColor: "#F5F5F5",
                            my: 2,
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{ color: "#666666", fontSize: "18px" }}
                          >
                            National ID :
                          </Typography>

                          <Typography
                            sx={{
                              color: "#666666",
                              fontSize: "18px",
                              marginLeft: "10px",
                            }}
                          >
                            {ticketsDetailsInfo?.user?.nationalId}
                          </Typography>
                        </Box>
                        <Divider
                          sx={{
                            backgroundColor: "#F5F5F5",
                            my: 2,
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{ color: "#666666", fontSize: "18px" }}
                          >
                            User Status :
                          </Typography>

                          <Typography
                            sx={{
                              color:
                                ticketsDetailsInfo?.user?.deleted_at === null
                                  ? "#E9C237"
                                  : "#FF453A",
                              fontSize: "18px",
                              marginLeft: "10px",
                            }}
                          >
                            {ticketsDetailsInfo?.user?.deleted_at === null
                              ? "Active"
                              : "Blocked"}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </TabPanel>
                  <TabPanel value="4">
                    {loadingDetails ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          alignSelf: "center",
                          justifyContent: "center",
                          height: "43vh",
                          width: "100%",
                        }}
                      >
                        <CircularProgress color="inherit" />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          height: "300px",
                          overflowY: "auto",
                          scrollbarWidth: "none",
                          msOverflowStyle: "none",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                        }}
                      >
                        {ticketsDetailsInfo?.history?.map((item) => (
                          <Box
                            key={`${item.created}+${item?.message}`}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              height: "76px",
                            }}
                          >
                            <Divider
                              orientation="vertical"
                              sx={{
                                backgroundColor: "#E9C237",
                                height: "100%",
                                width: "3px",
                              }}
                            />
                            <Box
                              sx={{
                                marginLeft: "15px",
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                width: "100%",
                              }}
                            >
                              <Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#202020",
                                      fontSize: "20px",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {item?.type === "newTicket"
                                      ? "Ticket Opened"
                                      : item?.type === "userReply"
                                      ? "User Reply"
                                      : item?.type === "reAssign"
                                      ? "Ticket Reassigned "
                                      : item?.type === "changeStatus"
                                      ? "Ticket Closed"
                                      : ""}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#202020",
                                      fontSize: "14px",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {formDate(item.created)}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginTop: "10px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#202020",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {item?.type === "newTicket"
                                      ? `${ticketsDetailsInfo?.user?.full_name} Open Ticket`
                                      : item?.type === "userReply"
                                      ? "User Reply"
                                      : item?.type === "reAssign"
                                      ? "Ticket Reassigned "
                                      : item?.type === "changeStatus"
                                      ? "Ticket Closed"
                                      : ""}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </TabPanel>
                </TabContext>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <ReusableSnackbar handleClose={handleCloseSnack} />
      </Box>
    </>
  );
};

export default TicketsDetails;
