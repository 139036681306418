import { useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { fetchNotificationsAreas } from "../../services/apis/notifications";

const StepThreeNotification = ({ setFormData, formData, cities }) => {
  const [areaNames, setAreaNames] = useState([]);
  const [filteredAreaIds, setFilteredAreaIds] = useState([]);

  useEffect(() => {
    const fetchAreas = async () => {
      const payload = {
        cityIds: formData.city_id,
      };
      try {
        const response = await fetchNotificationsAreas(payload);
        response.data.data.map((area) => area.id);
        const fetchedData = response.data.data;
        const filteredAreas = formData.area_id
          .filter((id) => fetchedData.find((city) => city.id === id)?.name)
          .map((id) => fetchedData.find((city) => city.id === id)?.name);
        setAreaNames(filteredAreas);
        const newFilteredAreaIds = filteredAreas.map(
          (area) => fetchedData.find((f) => f.name === area)?.id
        );
        setFilteredAreaIds(newFilteredAreaIds);
        if (
          JSON.stringify(newFilteredAreaIds) !==
          JSON.stringify(formData.area_id)
        ) {
          setFormData((prevData) => ({
            ...prevData,
            area_id: newFilteredAreaIds,
          }));
        }
      } catch (error) {
        console.log(error.response.data.message);
      }
    };

    if (formData.city_id.length > 0) {
      fetchAreas();
    } else {
      setAreaNames([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.city_id, formData.area_id]);

  return (
    <Box sx={{ marginTop: "5%" }}>
      <Box>
        <Typography sx={{ color: "#666666", fontSize: "18px" }}>
          Notification Details :
        </Typography>
      </Box>
      <Box
        sx={{
          my: 3,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ color: "#808080", fontSize: "14px", fontWeight: "500" }}
        >
          Notification Title - English :
        </Typography>
        <Typography
          sx={{
            marginLeft: "5px",
            color: "#808080",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {formData?.trans[0]?.title}
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography
          sx={{ color: "#808080", fontSize: "14px", fontWeight: "400" }}
        >
          Notification - English :
        </Typography>
        <Typography
          sx={{
            marginLeft: "5px",
            color: "#808080",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {formData?.trans[0]?.message}
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography
          sx={{ color: "#808080", fontSize: "14px", fontWeight: "500" }}
        >
          Notification Title - Arabic :
        </Typography>
        <Typography
          sx={{
            marginLeft: "5px",
            color: "#808080",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {formData?.trans[1]?.title}
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography
          sx={{ color: "#808080", fontSize: "14px", fontWeight: "400" }}
        >
          Notification - Arabic :
        </Typography>
        <Typography
          sx={{
            marginLeft: "5px",
            color: "#808080",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {formData?.trans[1]?.message}
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography
          sx={{ color: "#808080", fontSize: "14px", fontWeight: "500" }}
        >
          Notification Title - Egyptian Arabic :
        </Typography>
        <Typography
          sx={{
            marginLeft: "5px",
            color: "#808080",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {formData?.trans[2]?.title}
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography
          sx={{ color: "#808080", fontSize: "14px", fontWeight: "400" }}
        >
          Notification - Egyptian Arabic :
        </Typography>
        <Typography
          sx={{
            marginLeft: "5px",
            color: "#808080",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {formData?.trans[2]?.message}
        </Typography>
      </Box>

      <Box sx={{ my: 4 }}>
        <Typography sx={{ color: "#666666", fontSize: "18px" }}>
          User Segmentation :
        </Typography>
      </Box>
      <Box
        sx={{
          width: "50%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "4%",
        }}
      >
        {(formData?.age || (formData?.age_from && formData?.age_to)) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ color: "#808080", fontSize: "14px", fontWeight: "500" }}
            >
              Age :
            </Typography>
            <Typography
              sx={{
                color: "#808080",
                fontSize: "14px",
                fontWeight: "400",
                marginLeft: "5px",
              }}
            >
              {formData?.age
                ? formData?.age
                : formData?.age_from && formData?.age_to !== null
                ? `${formData?.age_from}-${formData?.age_to}`
                : ""}
            </Typography>
          </Box>
        )}

        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{ color: "#808080", fontSize: "14px", fontWeight: "500" }}
          >
            Gender :
          </Typography>
          <Typography
            sx={{
              color: "#3284FF",
              fontSize: "14px",
              fontWeight: "400",
              marginLeft: "5px",
            }}
          >
            {formData?.gender ?? "All"}
          </Typography>
        </Box>
      </Box>
      {filteredAreaIds.length !== 0 && (
        <Box
          sx={{
            marginTop: "3%",
          }}
        >
          <Typography
            sx={{ color: "#808080", fontSize: "14px", fontWeight: "500" }}
          >
            Area:
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            <Grid container spacing={2}>
              {areaNames?.map((items, index) => (
                <Grid item key={index} xs={2}>
                  <Box
                    key={index}
                    style={{
                      display: "flex",
                      backgroundColor: "#F5F6FF",
                      borderRadius: "100px",
                      padding: "5px 10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#4C4C4C",
                        fontSize: "14px",
                        display: "flex",
                        alignSelf: "center",
                      }}
                    >
                      {items}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default StepThreeNotification;
