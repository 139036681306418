import { axiosInstance as axios } from "../config";

export const fetchUsersReports = async () => {
  try {
    const response = await axios.get(`/api/reports/users`);
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};
