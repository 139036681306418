import TextFieldComponent from "../../components/TextFieldComponent";
import { Box } from "@mui/material";

const StepOneTermsComponent = ({ setTermsData, termsData }) => {
  return (
    <Box sx={{ marginTop: "3%" }}>
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Title - English"
        placeholder="Enter Title - English"
        value={termsData.title_en}
        onChange={(event) =>
          setTermsData((prevPayload) => ({
            ...prevPayload,
            title_en: event.target.value,
          }))
        }
      />
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Term and Condition - English"
        placeholder="Enter Term and Condition - English"
        value={termsData.description_en}
        onChange={(event) =>
          setTermsData((prevPayload) => ({
            ...prevPayload,
            description_en: event.target.value,
          }))
        }
      />
    </Box>
  );
};
export default StepOneTermsComponent;
