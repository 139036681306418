import React from "react";
import { Button, Box, FormControl } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import TextFieldComponent from "../../components/TextFieldComponent";
import { useEffect } from "react";
import { editPaymentInfo } from "../../services/apis/paymentInfo";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";

const InstaPaymentInfoDialog = ({
  openEditDialog,
  handleCloseAlert,
  initialValues,
  label,
  setInstapayPayment,
  fetchData,
}) => {
  const [instaPayPayload, setInstaPayPayload] = useState({
    id: 2,
    meta: {
      IPA: "",
      beneficiaryName: "",
    },
  });
  const resetForm = () => {
    setInstaPayPayload({
      id: 2,
      meta: {
        IPA: initialValues.IPA || "",
        beneficiaryName: initialValues.beneficiaryName || "",
      },
    });
  };
  const dispatch = useDispatch()
    const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const handleClose = () => {
    resetForm();
    handleCloseAlert();
  };
  useEffect(() => {
    setInstaPayPayload((prevPayload) => ({
      ...prevPayload,
      meta: {
        ...prevPayload.meta,
        ...initialValues,
      },
    }));
  }, [initialValues]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await editPaymentInfo(instaPayPayload);
      const response = await fetchData();
      const data = response?.data?.data;
      const instaPayPaymentObject = data.length > 0 ? [data[1]] : [];
      setInstapayPayment(instaPayPaymentObject);
      handleCloseAlert();
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
  };
  return (
    <>
      <Dialog
        open={openEditDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "600px",
            height: "auto",
            paddingTop: "10%",
            paddingBottom: "10%",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box sx={{ color: "#000000", fontWeight: "400", fontSize: "24px" }}>
              InstaPay
            </Box>
            <FormControl sx={{ display: "flex" }}>
              <TextFieldComponent
                name="Beneficiary name"
                placeholder="Enter Beneficiary Name"
                value={instaPayPayload.meta.beneficiaryName}
                onChange={(event) => {
                  var temppayload = { ...instaPayPayload };
                  temppayload.meta.beneficiaryName = event.target.value;
                  setInstaPayPayload({ ...temppayload });
                }}
              />
              <TextFieldComponent
                name="Payment address (IPA)"
                placeholder="Enter Payment address (IPA)"
                value={instaPayPayload.meta.IPA}
                onChange={(event) => {
                  var temppayload = { ...instaPayPayload };
                  temppayload.meta.IPA = event.target.value;
                  setInstaPayPayload({ ...temppayload });
                }}
              />
            </FormControl>

            <Box sx={{ display: "flex", gap: 2, marginTop: "10px" }}>
              <Button
                variant="contained"
                sx={{
                  width: "200px",
                  borderRadius: "6px",
                  background: "#E9C237",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#E9C237",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: "200px",
                  borderRadius: "6px",
                  height: "48px",
                  color: "#E9C237",
                  fontWeight: 600,
                  borderColor: "#E9C237",
                  "&:hover": {
                    borderColor: "#E9C237",
                  },
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <ReusableSnackbar
                 handleClose={handleCloseSnack}

      />
    </>
  );
};

export default InstaPaymentInfoDialog;
