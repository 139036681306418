import { useState } from "react";
import { Box, useTheme, Container } from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import { withStyles } from "@mui/styles";
import UsersTabPanel from "./UsersTabPanel";
import PhysicalGoldTabPanel from "./PhysicalGoldTabPanel";
import FractionalGoldTabPanel from "./FractionalGoldTabPanel";
import { useMediaQuery } from "@mui/material";
import GeneralStatisticsTabPanel from "./GeneralStatisticsTabPanel";
import OrdersTabPanel from "./OrdersTabPanel";
import PaymentTabPanel from "./PaymentTabPanel";
import { fetchUsersReports } from "../../services/apis/overview";
import { useQuery } from "react-query";

//~
const Dashboard = () => {
  const theme = useTheme();

  const today = new Date();
  const date14DaysAgo = new Date();
  date14DaysAgo.setDate(today.getDate() - 14);

  const [value, setValue] = useState("1");

  const AntTabs = withStyles({
    root: {
      borderBottom: "none",
    },
    indicator: {
      display: "none",
    },
  })(Tabs);
  const AntTab = withStyles((theme) => ({
    root: {
      "&:hover": {
        color: "#00303C",
        opacity: 1,
      },
      "&$selected": {
        color: "#ffffff",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        borderBottom: "none",
      },
      "&:focus": {
        color: "#00303C",
      },
    },
    selected: {
      "&$selected": {
        borderBottom: "none",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        color: "#ffffff !important",
      },
    },
  }))((props) => <Tab disableRipple {...props} />);
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { data: usersReports, isFetching: usersIsFetching } = useQuery(
    ["fetch_users_reports"],
    () => fetchUsersReports(),
    {
      enabled: localStorage.getItem("token") !== null,
    }
  );

  if (usersIsFetching) return <LoadingSpinner />;

  return (
    <div
      style={{
        overflowY: "auto",
        maxHeight: "calc(100vh)",
      }}
    >
      <Container maxWidth="lg">
        {/* New dashboard  */}
        <DashboardMainHeader />
        <TabContext value={value}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AntTabs
              sx={{
                width: "100%",
                backgroundColor: "#F5F6FF",
                borderRadius: "9px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: isSmScreen ? "column" : "row",
              }}
              value={value}
              onChange={handleChange}
              aria-label="ant example"
            >
              <AntTab sx={{ width: "16.6%" }} label="Users" value="1" />
              <AntTab sx={{ width: "16.6%" }} label="Physical Gold" value="2" />
              <AntTab
                sx={{ width: "16.6%" }}
                label="Fractional Gold"
                value="3"
              />
              <AntTab
                sx={{ width: "16.6%" }}
                label="General Statistics"
                value="4"
              />
              <AntTab sx={{ width: "16.6%" }} label="Orders" value="5" />
              <AntTab sx={{ width: "16.6%" }} label="Payment" value="6" />
            </AntTabs>
          </Box>
          <TabPanel sx={{ padding: "24px 0px" }} value="1">
            <UsersTabPanel
              usersReports={usersIsFetching ? [] : usersReports}
              usersIsFetching={usersIsFetching}
            />
          </TabPanel>
          <TabPanel sx={{ padding: "24px 0px" }} value="2">
            <PhysicalGoldTabPanel />
          </TabPanel>
          <TabPanel sx={{ padding: "24px 0px" }} value="3">
            <FractionalGoldTabPanel />
          </TabPanel>
          <TabPanel sx={{ padding: "24px 0px" }} value="4">
            <GeneralStatisticsTabPanel />
          </TabPanel>
          <TabPanel sx={{ padding: "24px 0px" }} value="5">
            <OrdersTabPanel />
          </TabPanel>
          <TabPanel sx={{ padding: "24px 0px" }} value="6">
            <PaymentTabPanel />
          </TabPanel>
        </TabContext>
      </Container>
    </div>
  );
};

export default Dashboard;
