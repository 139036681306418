import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "../../styles";
import { useNavigate } from "react-router-dom";
import { ItemsColumns } from "../../components/common/Columns";
const ThirdTabPanel = ({ role }) => {
  const navigate = useNavigate();
  const handleClick = (params) => {
    const admin = params.row;
    navigate(`/ViewAdminProfile/${admin.id}`, { state: { admin } });
  };
  const userRolesColumns = ItemsColumns([
    { field: "id", headerName: "User ID" },
    { field: "full_name", headerName: "Username" },
    { field: "email" },
    { field: "phone" },
    {
      field: "created",
      headerName: "Creation Date",
      date: true,
    },
    {
      field: "Status",
      headerName: "Status",
      renderCell: (params) => (
        <Typography
          sx={{ marginLeft: "5px", color: "#3284FF", fontSize: "14px" }}
        >
          Active
        </Typography>
      ),
    },
    {
      field: "orderDetails",
      headerName: "Actions",
      actions: { view: (params) => handleClick(params) },
    },
  ]);

  return (
    <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
      <DataGrid
        disableSelectionOnClick
        sx={{
          backgroundColor: "#fff",
        }}
        autoHeight={true}
        rows={role?.users}
        columns={userRolesColumns}
        hideFooterPagination={true}
        localeText={{
          noRowsLabel: "No Users Assigned",
        }}
      />
    </Box>
  );
};
export default ThirdTabPanel;
