import TextFieldComponent from "../../components/TextFieldComponent";
import { Box, Typography } from "@mui/material";
import { MyEditor } from "../../components/MyEditor";

const StepTwoArticle = ({ setArticlesPayload, articlesPayload }) => {
  const handleTransChange = (local, field, newValue) => {
    setArticlesPayload((prevState) => {
      const updatedTrans = prevState?.trans?.map((transItem) => {
        if (transItem?.local === local) {
          return { ...transItem, [field]: newValue };
        }
        return transItem;
      });
      return { ...prevState, trans: updatedTrans };
    });
  };
  return (
    <Box sx={{ marginTop: "3%" }}>
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Arabic Article Header"
        placeholder="Enter Arabic Article Header"
        value={
          articlesPayload?.trans?.find((transItem) => transItem.local === "ar")
            .title
        }
        onChange={(event) => {
          const disallowEnglishRegex = /^[^a-zA-Z]*$/;
          if (
            event.target.value === "" ||
            disallowEnglishRegex.test(event.target.value)
          ) {
            handleTransChange("ar", "title", event.target.value);
          }
        }}
      />
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 400,
          color: "#4C4C4C",
          marginBottom: "5px",
          marginTop: "15px",
        }}
      >
        Article
      </Typography>
      <MyEditor
        placeholder={
          articlesPayload?.trans?.find((transItem) => transItem.local === "ar")
            .body
            ? ""
            : "Enter Arabic Article"
        }
        text={
          articlesPayload?.trans?.find((transItem) => transItem.local === "ar")
            .body
        }
        setText={(newContent) => {
          console.log("newContent", newContent);

          const disallowEnglishRegex = /^[^a-zA-Z]*$/;
          if (newContent === "" || disallowEnglishRegex.test(newContent)) {
            handleTransChange("ar", "body", newContent);
          }
        }}
      />
    </Box>
  );
};
export default StepTwoArticle;
