import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base";
import { styled } from "@mui/system";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const CustomTextAreaContainer = styled(BaseTextareaAutosize)(
  ({ theme, error }) => `
    width: 300px;
    maxWidth: 300px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${
      error
        ? theme.palette.error.main // Use your error color
        : theme.palette.mode === "dark"
        ? grey[700]
        : grey[200]
    };
    box-shadow: 0px 2px 24px ${
      theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
    }
    & textarea {
      width: 100%;
      border: none;
      outline: none;
      background: transparent;
      font-family: inherit;
      font-size: inherit;
      color: inherit;
    }

    &::before {
      content: attr(placeholder);
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      color: ${error ? theme.palette.error.main : grey[400]};
      transition: color 0.3s ease;
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
    &::placeholder {
      color: ${error ? theme.palette.error.main : grey[400]};
    }
  `
);
const ErrorText = styled("div")(
  ({ theme }) => `
    // position: absolute;
    // bottom: -20px;
    // left: 30px;
    margin-left:15px;
    color: ${theme.palette.error.main};
    font-size: 0.70rem;
  `
);
const CustomTextArea = ({ error, helperText, ...rest }) => {
  return (
    <>
      <CustomTextAreaContainer
        error={error}
        placeholder={helperText}
        {...rest}
      />
      {error && <ErrorText>{helperText || "Required"}</ErrorText>}
    </>
  );
};

export default CustomTextArea;
