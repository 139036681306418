import { axiosInstance as axios } from "../config";

export const fetchTerms = async () => {
  try {
    const response = await axios.get(`/api/terms/index`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addTerm = async (body) => {
  try {
    const response = await axios.post(`/api/terms/add`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editTerm = async (id, body) => {
  try {
    const response = await axios.post(`/api/terms/edit/${id}`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteTerm = async (id) => {
  try {
    const response = await axios.get(`/api/terms/delete/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
