import TextFieldComponent from "../../components/TextFieldComponent";
import { Box } from "@mui/material";

const StepTwoTermsComponent = ({ setTermsData, termsData }) => {
  return (
    <Box sx={{ marginTop: "3%" }}>
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Title - Arabic"
        placeholder="Enter Title - Arabic"
        value={termsData.title_ar}
        onChange={(event) => {
          const disallowEnglishRegex = /^[^a-zA-Z]*$/;
          if (
            event.target.value === "" ||
            disallowEnglishRegex.test(event.target.value)
          ) {
            setTermsData((prevPayload) => ({
              ...prevPayload,
              title_ar: event.target.value,
            }));
          }
        }}
      />
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Term and Condition - Arabic"
        placeholder="Enter Term and Condition - Arabic"
        value={termsData.description_ar}
        onChange={(event) => {
          const disallowEnglishRegex = /^[^a-zA-Z]*$/;
          if (
            event.target.value === "" ||
            disallowEnglishRegex.test(event.target.value)
          ) {
            setTermsData((prevPayload) => ({
              ...prevPayload,
              description_ar: event.target.value,
            }));
          }
        }}
      />
    </Box>
  );
};
export default StepTwoTermsComponent;
