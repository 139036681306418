import { axiosInstance as axios } from "../config";
// import axios from "axios";
export const addSplashScreen = async (body) => {
  try {
    const response = await axios.post(`/api/splashs/add`, body);

    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchAllSplashes = async () => {
  try {
    const response = await axios.get(`/api/splashs/index`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editSplash = async (id, body) => {
  try {
    const response = await axios.post(`/api/splashs/edit/${id}`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteSplash = async (id) => {
  try {
    const response = await axios.get(`/api/splashs/delete/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
