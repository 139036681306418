import TextFieldComponent from "../../../components/TextFieldComponent";
import {
  Avatar,
  Box,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CustomTextArea from "../../../components/CustomTextArea";
import { FaMinus } from "react-icons/fa6";
import { GoPlus } from "react-icons/go";
import UploadImage from "../../../components/UploadImage";
import DeleteIcon from "@mui/icons-material/Delete";

const StepOneTicketQuestions = ({
  setFaqData,
  faqData,
  type,
  handleTypeChange,
  subQuestions,
  handleAddSubQuestion,
  handleDeleteSubQuestion,
  subjectId,
  selectedTicketData,
  setIsValid,
}) => {
  //Image
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);

  const handleDeleteImage = () => {
    setImage(null);
    setFile(null);
    setFaqData({ ...faqData, image: "" });
  };
  // const handleEditClick = () => {
  //   if (fileInputRef.current) {
  //     fileInputRef.current.click();
  //     setHasChanges(true);
  //   }
  // };
  // const resetImageState = () => {
  //   if (hasChanges) {
  //     setImage(null);
  //     setHasChanges(false);
  //   }
  // };

  const handleFileInputChange = (event) => {
    if (event && event.target) {
      const selectedFile = event.target.files[0];
      if (selectedFile && selectedFile instanceof File) {
        setFile(selectedFile);
        const reader = new FileReader();
        reader.onload = (e) => {
          setImage(e.target.result);
        };
        reader.readAsDataURL(selectedFile);
        setFaqData((prevFaqData) => ({
          ...prevFaqData,
          image: selectedFile,
        }));
        // setInputKey(inputKey + 1);
      }
    }
  };

  //image
  const handleSubQuestionChange = (index, field, fieldType, value) => {
    setFaqData((prevFaqData) => ({
      ...prevFaqData,
      subQuestions: prevFaqData.subQuestions.map((subQuestion, i) => {
        if (i === index) {
          return {
            ...subQuestion,
            trans: subQuestion.trans.map((translation) => {
              if (translation.local === field) {
                return {
                  ...translation,
                  [fieldType]: value,
                };
              }
              return translation;
            }),
          };
        }
        return subQuestion;
      }),
    }));
  };
  useEffect(() => {
    const result = faqData?.subQuestions.map((item) => {
      if (item?.trans[0]?.question !== "" && item?.trans[0]?.answer !== "") {
        return true;
      } else {
        return false;
      }
    });
    if (
      faqData?.image !== "" &&
      faqData?.name !== "" &&
      faqData?.trans[0]?.question !== ""
    ) {
      if (type === "No" && faqData?.trans[0]?.answer) {
        setIsValid([true, false, false]);
      } else if (
        type === "Yes" &&
        !faqData?.trans[0]?.answer &&
        faqData?.subQuestions?.length > 0 &&
        !result.includes(false)
      ) {
        setIsValid([true, false, false]);
      } else {
        setIsValid([false, false, false]);
      }
    } else {
      setIsValid([false, false, false]);
    }
  }, [faqData, subQuestions, type, setIsValid]);
  useEffect(() => {
    if (setFaqData) {
      setFaqData((prevData) => {
        let updatedData = {
          ...prevData,
          trans: prevData.trans.map((item) => ({
            ...item,
            question:
              item.local === "en"
                ? faqData.trans.find((t) => t.local === "en").question
                : item.question,
            answer:
              item.local === "en" && type === "No"
                ? faqData.trans.find((t) => t.local === "en").answer
                : item.answer,
          })),
        };
        if (updatedData.trans) {
          updatedData.trans = updatedData.trans.map(({ answer, ...rest }) =>
            type === "Yes" ? rest : { ...rest, answer }
          );
        }
        return updatedData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFaqData, subQuestions, type]);

  return (
    <Box sx={{ marginTop: "3%" }}>
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Badge Name"
        placeholder="Shariah complaint"
        value={faqData.name}
        onChange={(event) => {
          setFaqData({ ...faqData, name: event.target.value });
        }}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#4C4C4C",
            marginBottom: "5px",
            marginTop: "15px",
          }}
        >
          Badge Description
        </Typography>
        <CustomTextArea
          style={{
            minHeight: "80px",
            maxHeight: "200px",
            maxWidth: "100%",
            minWidth: "100%",
            backgroundColor: "#FFF",
            borderRadius: "9px",
            boxShadow: "none",
            border: "1px solid #E9C237",
          }}
          name="Badge Description"
          placeholder="Enter Badge Description"
          value={faqData?.description}
          onChange={(event) => {
            setFaqData({ ...faqData, description: event.target.value });
          }}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#4C4C4C",
            marginBottom: "5px",
            marginTop: "15px",
          }}
        >
          Badge Image
        </Typography>
        {file ? (
          <Box
            sx={{
              width: "100%",
              borderRadius: "9px",
              background: "#FFF",
              boxShadow: "1px 1px 16px 0px rgba(0, 51, 102, 0.08)",
              display: "flex",
              height: "53px",
              padding: "16px",
              justifyContent: "space-between",
              marginTop: "5px",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                width: "100%",
                alignItems: "center",
              }}
            >
              <img src={`../../assets/uploadicon.png`} alt="upload" />
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                  }}
                >
                  {file?.name}
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(0, 0, 0, 0.60)",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    letterSpacing: "0.17px",
                    wordSpacing: "4px",
                  }}
                >
                  {Math.ceil(file?.size / 1024)}kb • Complete
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: "flex" }}>
              <IconButton
                aria-label="delete"
                size="large"
                sx={{ color: "#FF725E" }}
                onClick={handleDeleteImage}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>

              <Avatar
                sx={{
                  bgcolor: "#92E3A9",
                  height: "24px",
                  width: "24px",
                  alignSelf: "center",
                }}
              >
                <img src={`../../assets/checkicon.png`} alt="checkIcon" />
              </Avatar>
            </Box>
          </Box>
        ) : (
          <UploadImage
            setImage={setImage}
            setFile={setFile}
            image={image}
            handleChange={handleFileInputChange}
          />
        )}
      </Box>

      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Question"
        placeholder="Enter Question"
        value={faqData.trans.find((t) => t.local === "en").question}
        onChange={(event) => {
          const updatedPayload = { ...faqData };
          const enTranslation = updatedPayload.trans.find(
            (t) => t.local === "en"
          );
          if (enTranslation) {
            enTranslation.question = event.target.value;
            setFaqData(updatedPayload);
          }
        }}
      />
      {type === "No" && !subjectId && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#4C4C4C",
              marginBottom: "5px",
              marginTop: "15px",
            }}
          >
            Answer
          </Typography>
          <CustomTextArea
            style={{
              minHeight: "80px",
              maxHeight: "200px",
              maxWidth: "100%",
              minWidth: "100%",
              backgroundColor: "#FFF",
              borderRadius: "9px",
              boxShadow: "none",
              border: "1px solid #E9C237",
            }}
            name="Answer"
            placeholder="Enter Answer"
            value={faqData.trans.find((t) => t.local === "en").answer}
            onChange={(event) => {
              const updatedPayload = { ...faqData };
              const enTranslation = updatedPayload.trans.find(
                (t) => t.local === "en"
              );
              if (enTranslation) {
                enTranslation.answer = event.target.value;
                setFaqData(updatedPayload);
              }
            }}
          />
        </Box>
      )}
      {selectedTicketData?.subQuestionsCount === 0 && subjectId && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#4C4C4C",
              marginBottom: "5px",
              marginTop: "15px",
            }}
          >
            Answer
          </Typography>
          <CustomTextArea
            style={{
              minHeight: "80px",
              maxHeight: "200px",
              maxWidth: "100%",
              minWidth: "100%",
              backgroundColor: "#FFF",
              borderRadius: "9px",
              boxShadow: "none",
              border: "1px solid #E9C237",
            }}
            name="Answer"
            placeholder="Enter Answer"
            value={faqData.trans.find((t) => t.local === "en").answer}
            onChange={(event) => {
              const updatedPayload = { ...faqData };
              const enTranslation = updatedPayload.trans.find(
                (t) => t.local === "en"
              );
              if (enTranslation) {
                enTranslation.answer = event.target.value;
                setFaqData(updatedPayload);
              }
            }}
          />
        </Box>
      )}
      {!subjectId && (
        <Box
          sx={{
            marginTop: "15px",
            width: "100%",
          }}
        >
          <FormLabel
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#4C4C4C",
            }}
            id="demo-row-radio-buttons-group-label"
          >
            Have Sub Questions
          </FormLabel>
          <RadioGroup
            style={{
              marginTop: "15px",
            }}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleTypeChange}
            value={type}
          >
            <FormControlLabel
              style={{ marginRight: "4%" }}
              value="Yes"
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              style={{}}
              value="No"
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
        </Box>
      )}

      {type === "Yes" && (
        <Box>
          {!subjectId && (
            <Box>
              {faqData?.subQuestions?.map((subQuestion, index) => (
                <Box key={index}>
                  <TextFieldComponent
                    sx={{ "& fieldset": { border: "none" } }}
                    style={{
                      height: "48px",
                      width: "100%",
                      backgroundColor: "#F7F7F7",
                      borderRadius: "9px",
                    }}
                    name={`Sub Question ${index + 1}`}
                    placeholder={`Enter Sub Question ${index + 1}`}
                    value={
                      faqData.subQuestions[index].trans.find(
                        (t) => t.local === "en"
                      ).question
                    }
                    onChange={(event) =>
                      handleSubQuestionChange(
                        index,
                        "en",
                        "question",
                        event.target.value
                      )
                    }
                  />

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#4C4C4C",
                        marginBottom: "5px",
                        marginTop: "15px",
                      }}
                    >
                      Answer
                    </Typography>
                    <CustomTextArea
                      style={{
                        minHeight: "80px",
                        maxHeight: "200px",
                        maxWidth: "100%",
                        minWidth: "100%",
                        backgroundColor: "#FFF",
                        borderRadius: "9px",
                        boxShadow: "none",
                        border: "1px solid #E9C237",
                      }}
                      name="Answer"
                      placeholder="Enter Answer"
                      value={
                        faqData.subQuestions[index].trans.find(
                          (t) => t.local === "en"
                        ).answer
                      }
                      onChange={(event) =>
                        handleSubQuestionChange(
                          index,
                          "en",
                          "answer",
                          event.target.value
                        )
                      }
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      marginTop: "10px",
                      justifyContent: "flex-end",
                      flexDirection: "row",
                      alignItems: "center",
                      alignSelf: "flex-end",
                    }}
                  >
                    <Box
                      onClick={() => handleDeleteSubQuestion(index)}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          backgroundColor: "rgba(255, 69, 58, 0.3)",
                          borderRadius: "50%",
                          marginRight: "5px",
                          padding: "2px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FaMinus
                          color="#FF453A"
                          fontSize={12}
                          style={{
                            alignSelf: "center",
                          }}
                        />
                      </Box>
                      <Typography sx={{ color: "#FF453A", fontSize: "14px" }}>
                        Delete
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
          {!subjectId && (
            <Box
              sx={{
                display: "flex",
                marginTop: "30px",
                justifyContent: "flex-start",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                onClick={() => {
                  handleAddSubQuestion();
                }}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  backgroundColor: "rgba(233, 194, 55, 0.3)",
                  borderRadius: "50%",
                  marginRight: "5px",
                  padding: "2px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <GoPlus
                  color="#E9C237"
                  fontSize={14}
                  style={{
                    display: "flex",
                    alignSelf: "center",
                  }}
                />
              </Box>
              <Typography
                onClick={() => {
                  handleAddSubQuestion();
                }}
                sx={{
                  cursor: "pointer",
                  color: "#E9C237",
                  fontSize: "14px",
                }}
              >
                Add another sub question
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
export default StepOneTicketQuestions;
