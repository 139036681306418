// store.js
import { configureStore } from "@reduxjs/toolkit";
import authenticationReducer from "./slices/authenticationSlice";
import errorMessageReducer from "./slices/errorMessageSlice";
export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    errorMessage: errorMessageReducer,
  },
});
