import { useState } from "react";
import TextFieldComponent from "../../components/TextFieldComponent";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import CustomTextArea from "../../components/CustomTextArea";
import { useFetchSubjects } from "../../services/apis/subejct";
import { useLocation, useParams } from "react-router-dom";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import { editSubQuestion } from "../../services/apis/tickets";
import CustomDialog from "../../components/CustomDialog";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { MenuProps } from "../../components/common/MenuProps";
import { FirstTypography } from "../../components/common/CustomTypogrphy";
import LoadingSpinner from "../../components/LoadingSpinner";
const EditTicketQuestion = () => {
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const selectedTicketData = location?.state?.selectedTicketData;
  const dispatch = useDispatch();
  const { data, isLoading } = useFetchSubjects();

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };

  const lang = [
    { id: 1, local: "en", name: "English" },
    { id: 2, local: "ar", name: "Arabic" },
    { id: 3, local: "eg", name: "Egyptian Arabic" },
  ];
  const [formData, setFormData] = useState({
    contactUs_type_id: null,
    sort: null,
    local: "en",
    question: "",
    answer: "",
  });
  const handleTopicChange = (event) => {
    setSelectedTopic(event.target.value);
    setFormData((prevFaqData) => ({
      ...prevFaqData,
      contactUs_type_id: event.target.value.id,
    }));
  };
  const handleLangChange = (event) => {
    const selectedLang = event.target.value;
    const selectedLangObj = lang.find((item) => item.local === selectedLang);
    if (selectedLangObj) {
      if (selectedTicketData) {
        setFormData((prevState) => ({
          ...prevState,
          local: selectedLang,
          question:
            selectedLang === "en"
              ? selectedTicketData?.trans?.en?.question
              : selectedLang === "ar"
              ? selectedTicketData?.trans?.ar?.question
              : selectedTicketData?.trans?.eg?.question,
          answer:
            selectedLang === "en"
              ? selectedTicketData?.trans?.en?.answer
              : selectedLang === "ar"
              ? selectedTicketData?.trans?.ar?.answer
              : selectedTicketData?.trans?.eg?.answer,
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          local: selectedLang,
          question:
            prevState.trans.find((item) => item.local === selectedLang)
              ?.question || "",
          answer:
            prevState.trans.find((item) => item.local === selectedLang)
              ?.answer || "",
        }));
      }
    }
  };
  const handleSortChange = (event) => {
    const newValue = event.target.value.slice(0, 6);
    const sortValue = parseInt(newValue);
    setFormData((prevPayload) => ({
      ...prevPayload,
      sort: isNaN(sortValue) ? null : sortValue,
    }));
  };

  const handleSave = async () => {
    try {
      setSubmitting(true);
      let payload = { ...formData };
      // Remove answer if subQuestionsCount !== 0
      if (selectedTicketData?.subQuestionsCount !== 0) {
        delete payload.answer;
      }
      await editSubQuestion(id, payload);
      setSuccessDialogOpen(true);
    } catch (error) {
      if (error.response.status === 422) {
        dispatch(
          setData({
            openSnack: true,
            message: "Fields Should not be empty",
          })
        );
      } else {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      }
    } finally {
      setSubmitting(false);
    }
  };
  useEffect(() => {
    if (selectedTicketData && data) {
      const initialSelectedSubject = data.find(
        (subject) => subject.name_en === selectedTicketData.subject
      );
      setSelectedTopic(initialSelectedSubject);
      setFormData({
        contactUs_type_id: selectedTicketData.contactUs_type_id,
        sort: selectedTicketData?.sort,
        local: "en",
        question: selectedTicketData?.trans?.en?.question,
        answer: selectedTicketData?.trans?.en?.answer,
      });
    }
  }, [selectedTicketData, data]);
  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <Box>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <Box sx={{ mb: "3%" }}>
          <DashboardMainHeader />
        </Box>

        <Header title={"Edit Question"} />
      </Box>
      <Box
        m="20px"
        sx={{
          height: "70vh",
          background: "#FFFFFF",
          borderRadius: "12px",
          boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08)",
          overflow: "auto",
          padding: "4%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#4C4C4C",
              marginBottom: "5px",
              marginTop: "15px",
            }}
          >
            Select Language
          </Typography>
          <FormControl sx={{ width: "100%" }}>
            <Select
              displayEmpty
              onChange={handleLangChange}
              value={formData?.local || ""}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (!selected) {
                  return <em>{formData.title}</em>;
                }
                return lang.find((item) => item.local === selected)?.name || "";
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              {lang.map((item) => (
                <MenuItem key={item.local} value={item.local}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#4C4C4C",
              marginBottom: "5px",
              marginTop: "15px",
            }}
          >
            Select Subject
          </Typography>
          <FormControl sx={{ width: "100%" }}>
            <Select
              displayEmpty
              onChange={handleTopicChange}
              value={selectedTopic || ""}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected?.length === 0) {
                  return <em>Select Subject</em>;
                }
                return selected?.name_en;
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              {data?.map((item) => (
                <MenuItem key={item.id} value={item}>
                  {item.name_en}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ marginTop: "5px" }}>
          <TextFieldComponent
            style={{
              height: "48px",
              width: "100%",
              backgroundColor: "#FFF",
              borderRadius: "9px",
            }}
            type="number"
            name="Enter Order "
            placeholder="Enter Order"
            value={formData?.sort}
            onChange={handleSortChange}
          />
        </Box>
        <Box sx={{ marginTop: "10px" }}>
          <TextFieldComponent
            sx={{ "& fieldset": { border: "none" } }}
            style={{
              height: "48px",
              width: "100%",
              backgroundColor: "#F7F7F7",
              borderRadius: "9px",
            }}
            name={`Sub Question`}
            placeholder={`Enter Sub Question`}
            value={formData.question}
            onChange={(event) => {
              var temppayload = { ...formData };
              temppayload.question = event.target.value;
              setFormData({ ...temppayload });
            }}
          />
        </Box>
        {selectedTicketData?.subQuestionsCount === 0 && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <FirstTypography title={"Answer"} />
            <CustomTextArea
              style={{
                minHeight: "80px",
                maxHeight: "200px",
                maxWidth: "100%",
                minWidth: "100%",
                backgroundColor: "#FFF",
                borderRadius: "9px",
                boxShadow: "none",
                border: "1px solid #E9C237",
              }}
              name="Answer"
              placeholder="Enter Answer"
              value={formData.answer}
              onChange={(event) => {
                var temppayload = { ...formData };
                temppayload.answer = event.target.value;
                setFormData({ ...temppayload });
              }}
            />
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={submitting}
            onClick={handleSave}
            sx={{
              "&:hover": { backgroundColor: "#E9C237" },
              backgroundColor: "#E9C237",
              width: "200px",
              height: "46px",
              borderRadius: "12px",
              color: "#ffffff",
              fontSize: "14px",
              display: "flex",
              alignSelf: "flex-end",
              marginTop: "5%",
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Question has been Edited Successfully."
        message=""
        buttonText="Go to Ticket Questions"
        buttonLink="/ticketQuestions"
      />
      <ReusableSnackbar handleClose={handleCloseSnack} />
    </Box>
  );
};
export default EditTicketQuestion;
