import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { axiosInstance as axios } from "../../../services/config";
import TextFieldComponent from "../../../components/TextFieldComponent";
import { useLocation } from "react-router-dom";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";

const ResetPassword = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const otp = queryParams.get("otp");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const Reset = async (password, repeatPassword) => {
    try {
      const body = {
        email: email,
        otp: otp,
        password,
        repeatPassword,
      };
      const response = await axios.post(`/api/admin/set-password`, body);
      return response;
    } catch (error) {
      if (error.response.data.status === 422) {
        setErrorMessage("Error in reset password");
      } else {
        setErrorMessage(error.response.data.message);
        console.log("Error in reset password", error.response.data.message);
      }
    }
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleConfirmPasswordChange = (e) => {
    setRepeatPassword(e.target.value);
  };
  const isPasswordValid = (password) => {
    // Check if password has at least 8 characters
    if (password.length < 8) {
      setErrorMessage("Password should be at least 8 characters");
      return false;
    }
    // Check if password contains at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      setErrorMessage("Password should contain uppercase letter");
      return false;
    }

    // Check if password contains at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      setErrorMessage("Password should contain lowercase letter");
      return false;
    }
    // Check if password contains at least one special character
    if (!/[^A-Za-z0-9]/.test(password)) {
      setErrorMessage("Password should contain special character");
      return false;
    }
    if (!/\d/.test(password)) {
      setErrorMessage("Password should contain numbers");
      return false;
    }
    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== repeatPassword) {
      setErrorMessage("Password doesn't match!");
      return;
    } else if (!isPasswordValid(password)) {
      // setIsLoading(false);
    }
    try {
      if (!isPasswordValid(password)) {
        // setIsLoading(false);
      } else {
        const response = await Reset(password, repeatPassword);
        if (response.status === 200) {
          setOpenDialog(true);
        } else if (response.status === 400 || response.status === 403) {
          setErrorMessage(response.message);
        } else if (response.status === 422) {
          setErrorMessage("Invalid Email");
        }
      }
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Grid sx={{ height: "100%" }} container spacing={4}>
      <Grid
        container
        sx={{
          flexDirection: "column",
          height: "100%",
          backgroundColor: "#ffffff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
        item
        xs={12}
        md={6}
        lg={6}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ color: "#E9C237", fontSize: "36px" }} variant="h3">
            Reset Password
          </Typography>
          <Typography
            sx={{
              color: "#B3B3B3",
              fontSize: "14px",
              width: "500px",
              marginTop: "15px",
              marginBottom: "10px",
            }}
            variant="h3"
          >
            Securely set up your new password. Make sure it's unique and strong
            to enhance the security of your account.
          </Typography>
          <TextFieldComponent
            type="password"
            showPassword={showPassword}
            onTogglePasswordVisibility={() => setShowPassword(!showPassword)}
            style={{ width: "500px" }}
            name="Enter New Password"
            placeholder="Enter New Password"
            value={password}
            error={errorMessage}
            onChange={handlePasswordChange}
          />
          <TextFieldComponent
            type="password"
            showPassword={showPassword}
            onTogglePasswordVisibility={() => setShowPassword(!showPassword)}
            style={{ width: "500px" }}
            name="Confirm Password"
            placeholder="Confirm Password"
            value={repeatPassword}
            error={errorMessage}
            onChange={handleConfirmPasswordChange}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              alignSelf: "center",
              marginTop: "5%",
            }}
          ></Box>
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#E9C237",
              "&:hover": { backgroundColor: "#E9C237" },
              height: "48px",
              width: "500px",
              marginTop: "5%",
              borderRadius: "9px",
            }}
          >
            Reset Password
          </Button>
        </Box>
      </Grid>
      <Grid
        container
        sx={{
          height: "100%",
          backgroundColor: "#F7F7F7",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        item
        xs={12}
        md={6}
        lg={6}
      >
        <Box
          sx={{
            width: "450px",
            height: "450px",
          }}
        >
          <img
            src={`../../assets/loginimage.png`}
            alt="loginimage"
            style={{
              height: "100%",
              width: "100%",
            }}
          />
        </Box>
      </Grid>
      <Dialog
        open={openDialog}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: {
            width: "701px",
            height: "530px",
            borderRadius: "24px",
          },
        }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogContent
          sx={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#D9F4DD",
              height: "80px",
              width: "80px",
              alignSelf: "center",
              marginRight: "15px",
            }}
          >
            <img src={`../../assets/Vector.png`} alt="vector" />
          </Avatar>
          <Typography
            sx={{ marginTop: "10%", marginBottom: "10%" }}
            variant="h6"
          >
            Your password has been successfully updated.
            <br /> You can now log in with your new credentials.
          </Typography>
          <Button
            onClick={() => {
              navigate("/login");
            }}
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#E9C237",
              "&:hover": { backgroundColor: "#E9C237" },
              height: "46px",
              width: "291px",
              // marginTop: "10%",
              borderRadius: "9px",
            }}
          >
            Go to Login
          </Button>
        </DialogContent>
      </Dialog>
      <ReusableSnackbar handleClose={handleCloseSnack} />
    </Grid>
  );
};

export default ResetPassword;
