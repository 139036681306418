import { useMutation, useQuery } from "react-query";
import { axiosInstance as axios } from "../config";
// Define the function to fetch all orders
export const fetchAllOrders = async (q) => {
  try {
    const response = await axios.get(`/api/orders/index`, {
      params: {
        orderBy: q.orderBy,
        sort: q.sort,
        date_from: q.date_from,
        date_to: q.date_to,
        page: q.page,
        phone: q.phone,
        email: q.email,
        nationalId: q.nationalId,
        id: q.id,
        query: q.query,
        order_type: JSON.stringify(q.order_type),
        orderStatus: JSON.stringify(q.orderStatus),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Create the custom hook using useQuery
const useFetchAllOrders = (q) => {
  return useQuery(["orders", q], () => fetchAllOrders(q), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

export default useFetchAllOrders;

const editOrder = async ({ id, body }) => {
  const response = await axios.post(`/api/orders/edit/${id}`, body);
  return response.data;
};

export const useEditOrder = () => {
  return useMutation(({ id, body }) => editOrder({ id, body }));
};

// Define the function to fetch order status
export const fetchOrderStatus = async () => {
  try {
    const response = await axios.get(`/api/orderStatus/index`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Create the custom hook using useQuery
export const useFetchOrderStatus = () => {
  return useQuery("orderStatus", fetchOrderStatus, {
    refetchOnWindowFocus: false,
  });
};

export const allPendingOrders = async () => {
  try {
    const response = await axios.get(`/api/goldTransactions/pendingOrders`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const usePendingOrders = () => {
  return useQuery("pendingOrders", allPendingOrders, {
    refetchOnWindowFocus: false,
  });
};
export const ingotPendingOrders = async (q) => {
  try {
    const response = await axios.get(
      `/api/goldTransactions/pendingOrders-details`,
      {
        params: {
          type: q.type,
        },
      }
    );
    return response.data; // Assuming the data you need is in the 'data' property
  } catch (error) {
    throw error;
  }
};
export const useIngotPendingOrders = (q) => {
  return useQuery(["ingotPendingOrders", q], () => ingotPendingOrders(q), {
    refetchOnWindowFocus: false,
    enabled: !!q, // Ensure the query is only run if 'q' is provided
  });
};
export const fetchPendingOrdersDetails = async (id) => {
  try {
    const response = await axios.get(
      `/api/goldTransactions/pendingOrders-details/${id}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchPendingOrdersDiscrepancy = async (id) => {
  try {
    const response = await axios.get(
      `/api/goldTransactions/calculateDiscrepancy/${id}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const confirmPendingOrdersDetails = async (body) => {
  try {
    const response = await axios.post(
      `/api/goldTransactions/confirmPendingOrder`,
      body
    );
    return response;
  } catch (error) {
    throw error;
  }
};
