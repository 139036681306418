import { Box, Typography } from "@mui/material";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import { DataGridStyle } from "../../styles";
import { DataGrid } from "@mui/x-data-grid";
import { useFetchSellFractionsIngot } from "../../services/apis/inventory";
import { useState } from "react";
import MergeFractionDialog from "./MergeFractionDialog";
import SellFractionDialog from "./SellFractionDialog";
import { handlePageChange } from "../../services/utils/filters";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";

const SellFractionsIngot = () => {
  const [openMergeDialog, setOpenMergeDialog] = useState(false);
  const [openSellDialog, setOpenSellDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
  });
  const {
    data: sellFractionsIngot,
    isFetching,
    refetch,
  } = useFetchSellFractionsIngot(queryParameters);

  const handleCloseMergeDialog = () => {
    setOpenMergeDialog(false);
  };

  const handleCloseSellDialog = () => {
    setOpenSellDialog(false);
  };

  const handleOpenMergeDialog = (row) => {
    setSelectedRow(row);
    setOpenMergeDialog(true);
  };

  const handleOpenSellDialog = (row) => {
    setSelectedRow(row);
    setOpenSellDialog(true);
  };

  const handleActionClick = (row) => {
    if (row.soldWeight > 0) {
      handleOpenMergeDialog(row);
    } else {
      handleOpenSellDialog(row);
    }
  };
  const sellFractionsColumns = ItemsColumns([
    { field: "name", headerName: "Product" },
    { field: "serial", headerName: "Serial Number" },
    {
      field: "soldWeight",
      headerName: "Sold Weight",
      specialRender: "customRender",
      renderFunction: (params) => {
        const soldWeight = params.value ?? 0;
        return (
          <Typography
            sx={{ color: "#FF453A", fontSize: 14 }}
          >{`${soldWeight} Gm`}</Typography>
        );
      },
    },
    {
      field: "currentWeight",
      headerName: "Available Weight",
      specialRender: "customRender",
      renderFunction: (params) => {
        const currentWeight = params.value ?? 0;
        return (
          <Typography
            sx={{ color: "#30D158", fontSize: 14 }}
          >{`${currentWeight.toFixed(3)} Gm`}</Typography>
        );
      },
    },
    {
      field: "Action",
      actions: { view: (params) => handleActionClick(params.row) },
    },
  ]);

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Sell Fractions Ingot" marginL={"25px"} />
      <SecondBox>
        <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
          <DataGrid
            rows={isFetching ? [] : sellFractionsIngot?.data?.data?.result}
            rowCount={sellFractionsIngot?.data?.data.totalItems || 0}
            rowsPerPageOptions={[10]}
            totalPages={sellFractionsIngot?.data?.data.totalPages}
            columns={sellFractionsColumns}
            loading={isFetching}
            paginationMode="server"
            onPageChange={(p) =>
              handlePageChange(p, queryParameters, setQueryParameters)
            }
            pageSize={10}
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
              height: "630px",
            }}
          />
        </Box>
      </SecondBox>
      {selectedRow && (
        <>
          <MergeFractionDialog
            open={openMergeDialog}
            onClose={handleCloseMergeDialog}
            label={"Confirm"}
            row={selectedRow}
            refetch={refetch}
            data={sellFractionsIngot?.data?.data}
          />
          <SellFractionDialog
            open={openSellDialog}
            onClose={handleCloseSellDialog}
            label={"Sell"}
            refetch={refetch}
            row={selectedRow}
          />
        </>
      )}
    </Box>
  );
};

export default SellFractionsIngot;
