import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import { imageBaseUrl } from "../../services/config";
import { IoIosCloseCircleOutline } from "react-icons/io";

const ProductDetails = ({ products, open, onClose, productId, value }) => {
  const productDetails = products?.find((product) => product.id === productId);

  return (
    <Dialog
      maxWidth="700px"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        borderRadius: "24px",
      }}
      PaperProps={{
        style: {
          borderRadius: "24px",
        },
      }}
    >
      <DialogContent
        sx={{
          padding: "5% 5% 10% 10%",
          height: "auto",
          width: "700px",
          borderRadius: "24px",
          background: "#FFF",
          display: "flex",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <Box
          sx={{
            justifyContent: "center",
            height: "auto",
            width: "100%",
            borderRadius: "24px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignSelf: "center",
          }}
        >
          <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
            <IoIosCloseCircleOutline
              onClick={onClose}
              color="#4C4C4C"
              style={{
                width: "24px",
                height: "24px",
                cursor: "pointer",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                color: "#4C4C4C",
                fontWeight: "400",
                fontSize: "24px",
                display: "flex",
                alignSelf: "flex-start",
              }}
            >
              Product Details
            </Box>
          </Box>
          {value === "1" ? (
            <Box
              style={{
                alignSelf: "center",
                width: "120px",
                height: "120px",
                marginBottom: "32px",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={`${imageBaseUrl}/${productDetails?.photo}`}
                alt=""
              />
            </Box>
          ) : (
            <></>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "50%",
              }}
            >
              <Typography
                sx={{ fontWeight: "500", fontSize: "14px", color: "#808080" }}
              >
                Name:
              </Typography>
              <Typography
                style={{
                  marginLeft: "10px",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#808080",
                }}
              >
                {productDetails?.trans?.en}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontWeight: "500", fontSize: "14px", color: "#808080" }}
              >
                Weight :
              </Typography>
              <Typography
                style={{
                  marginLeft: "10px",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#808080",
                }}
              >
                {productDetails?.weight} Gm
              </Typography>
            </Box>
          </Box>
          <Box style={{ borderBottom: "1px solid #E0E0E0", width: "90%" }} />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "50%",
              }}
            >
              <Typography
                sx={{ fontWeight: "500", fontSize: "14px", color: "#808080" }}
              >
                Type :
              </Typography>
              <Typography
                style={{
                  marginLeft: "10px",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#808080",
                }}
              >
                {value === "1" ? "Ingot" : "Fractions"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontWeight: "500", fontSize: "14px", color: "#808080" }}
              >
                Stock Status :
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  borderRadius: "100px",
                  background: "#40C7540D",
                  padding: "8px 32px",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "10px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="8"
                    viewBox="0 0 9 8"
                    fill="none"
                  >
                    <circle
                      cx="4.22852"
                      cy="4"
                      r="4"
                      fill={
                        productDetails?.availability === 1
                          ? "#40C754"
                          : "#F23045"
                      }
                    />
                  </svg>
                </Box>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color:
                      productDetails?.availability === 1
                        ? "#40C754"
                        : "#F23045",
                  }}
                >
                  {productDetails?.availability === 1
                    ? "In Stock"
                    : "Out of Stock"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box style={{ borderBottom: "1px solid #E0E0E0", width: "90%" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {value === "1" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "50%",
                }}
              >
                <Typography
                  sx={{ fontWeight: "500", fontSize: "14px", color: "#808080" }}
                >
                  Storage fees :
                </Typography>
                <Typography
                  style={{
                    marginLeft: "10px",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#808080",
                  }}
                >
                  {productDetails?.stored_fees} EGP
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "50%",
              }}
            >
              <Typography
                sx={{ fontWeight: "500", fontSize: "14px", color: "#808080" }}
              >
                Product :
              </Typography>
              <Typography
                style={{
                  marginLeft: "10px",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#808080",
                }}
              >
                {productDetails?.karat === 21
                  ? "Coins"
                  : productDetails?.karat === 24
                  ? "Ingots"
                  : ""}
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProductDetails;
