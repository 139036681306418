import { Box, Grid, Typography } from "@mui/material";
import DonutChart from "./donutChart";
import OverViewMainCard from "../../components/OverViewMainCard";

const OrdersTabPanel = () => {
  const today = new Date();
  const date14DaysAgo = new Date();
  date14DaysAgo.setDate(today.getDate() - 14);

  return (
    <Box>
      <Box>
        <Typography
          sx={{ color: "#808080", fontSize: "16px", fontWeight: "500" }}
        >
          OverView
        </Typography>
      </Box>
      {/* users overview */}

      <Grid container spacing={1.5} pt={4}>
        {/* Main Cards Start  */}
        <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
          <OverViewMainCard
            backgroundColor="#FFFFFF"
            title="Pending Orders"
            total={"10,000"}
            value={"Order"}
          />
        </Grid>
        <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
          <OverViewMainCard
            backgroundColor="#FFFFFF"
            title="Orders in Progress"
            total={"521,562"}
            value={"Order"}
          />
        </Grid>
        <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
          <OverViewMainCard
            backgroundColor="#FFFFFF"
            title="Completed Orders"
            total={"1,521,562"}
            value={"Order"}
          />
        </Grid>
        {/* End Main Cards  */}
        <Grid
          p={1}
          item
          mt={1.5}
          sx={{
            backgroundColor: "#ffffff",
            height: "500px",
            borderRadius: "24px",
            marginLeft: "1%",
          }}
          xs={12}
          md={12}
          lg={3.8}
        >
          <DonutChart
            type="donut"
            sx={{ borderRadius: "24px", boxShadow: "none" }}
            title="User Sentiment Analysis"
            chart={{
              series: [
                {
                  label: "Positive",
                  value: 65,
                },
                {
                  label: "Neutral",
                  value: 30,
                },
                {
                  label: "Negative",
                  value: 20,
                },
              ],
            }}
            donutLabel="Total Orders"
          />
        </Grid>
      </Grid>

      {/* end users overview */}
    </Box>
  );
};
export default OrdersTabPanel;
