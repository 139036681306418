import { Typography, Box, Divider, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import UserInfoField from "../user-profile/UserInfoField";
import Header from "../../components/Header";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { formDate } from "../../services/helpers";

const NotificationDetails = () => {
  const selectedSegmentedData = useLocation()?.state?.selectedSegment;
  return (
    <Box
      style={{
        overflowY: "auto",
        maxHeight: "calc(100vh)",
        width: "100%",
      }}
    >
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>

      <Header title={"Notifications"} marginL={"40px"} />
      <Box
        m="20px"
        sx={{
          display: "flex",
          width: "95%",
          flexDirection: "column",
          gap: 2,
          background: "#fff",
          alignItems: "center",
          height: "auto",
          borderRadius: "24px",
          paddingBottom: "5%",
          paddingLeft: "2%",
          paddingRight: "2%",
        }}
      >
        <Box
          sx={{
            dispaly: "flex",
            width: "95%",
            marginTop: "3%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Box>
              <Typography
                sx={{ color: "#666666", fontSize: "18px", fontWeight: 500 }}
              >
                Notification Details :
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <UserInfoField
                title="Date"
                data={formDate(selectedSegmentedData?.created)}
              />
            </Box>
            <Divider sx={{ backgroundColor: "#F5F5F5" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <UserInfoField
                title="Notification Title - English"
                data={selectedSegmentedData?.title?.en}
              />
            </Box>
            <Divider sx={{ backgroundColor: "#F5F5F5" }} />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <UserInfoField
                title="Notification - English"
                data={selectedSegmentedData?.message?.en}
              />
            </Box>
            <Divider sx={{ backgroundColor: "#F5F5F5" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <UserInfoField
                title="Notification Title - Arabic"
                data={selectedSegmentedData?.title?.ar}
              />
            </Box>
            <Divider sx={{ backgroundColor: "#F5F5F5" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <UserInfoField
                title="Notification - Arabic"
                data={selectedSegmentedData?.message?.ar}
              />
            </Box>
            <Divider sx={{ backgroundColor: "#F5F5F5" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <UserInfoField
                title="Notification Title - Egyptian Arabic"
                data={selectedSegmentedData?.title?.eg}
              />
            </Box>
            <Divider sx={{ backgroundColor: "#F5F5F5" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <UserInfoField
                title="Notification - Egyptian Arabic"
                data={selectedSegmentedData?.message?.eg}
              />
            </Box>
            <Divider sx={{ backgroundColor: "#F5F5F5" }} />
            <Box sx={{ marginTop: "3%" }}>
              <Typography
                sx={{ color: "#666666", fontSize: "18px", fontWeight: 500 }}
              >
                User Segmentation :
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <UserInfoField
                title="Age"
                data={
                  selectedSegmentedData?.meta?.age
                    ? selectedSegmentedData?.meta?.age
                    : selectedSegmentedData?.meta?.age_from &&
                      selectedSegmentedData?.meta?.age_to
                    ? `${selectedSegmentedData?.meta?.age_from} - ${selectedSegmentedData?.meta?.age_to}`
                    : ""
                }
              />
              <Box
                sx={{
                  gap: 1,
                  width: "85%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#595959",
                    alignSelf: "center",
                  }}
                >
                  Gender :
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#3284FF",
                    alignSelf: "center",
                    marginLeft: "5px",
                  }}
                >
                  {selectedSegmentedData?.meta?.gender ?? "All"}
                </Typography>
              </Box>
              {/* <UserInfoField title="Gender" data={"Male"} /> */}
            </Box>
            <Divider sx={{ backgroundColor: "#F5F5F5" }} />
            <Box
              sx={{
                marginTop: "3%",
              }}
            >
              <Typography sx={{ color: "#4C4C4C", fontSize: "20px" }}>
                Area :
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                <Grid container spacing={2}>
                  {selectedSegmentedData?.meta?.areas?.map((items, index) => (
                    <Grid item xs={2}>
                      <Box
                        style={{
                          display: "flex",
                          backgroundColor: "#F5F6FF",
                          borderRadius: "100px",
                          padding: "5px 10px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#4C4C4C",
                            fontSize: "14px",
                            display: "flex",
                            alignSelf: "center",
                          }}
                        >
                          {items?.name}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationDetails;
