import React from "react";
import Box from "@mui/material/Box";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import TextFieldComponent from "../../components/TextFieldComponent";
import { useEffect } from "react";
import { useState } from "react";
import { fetchAdminProfile } from "../../services/apis/users";
import LoadingSpinner from "../../components/LoadingSpinner";

const AdminProfile = () => {
  const [adminProfile, setAdminProfile] = useState([]);
  const [loading, setLoading] = useState(true);
  const getAdminInfo = async () => {
    try {
      const response = await fetchAdminProfile();
      const responseData = response?.data?.data;
      setAdminProfile(responseData);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAdminInfo();
  }, []);
  if (loading) return <LoadingSpinner />;

  return (
    <Box sx={{}}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "5%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
            backgroundColor: "#FFFFFF",
            width: "592px",
            height: "449px",
            alignSelf: "center",
            borderRadius: "24px",
            flexDirection: "column",
          }}
        >
          <TextFieldComponent
            disabled={true}
            sx={{ "& fieldset": { border: "none" } }}
            style={{
              height: "48px",
              width: "400px",
              backgroundColor: "#F7F7F7",
              borderRadius: "9px",
            }}
            name="Full Name"
            placeholder="Full Name"
            value={adminProfile?.full_name}
          />
          <TextFieldComponent
            disabled={true}
            sx={{ "& fieldset": { border: "none" } }}
            style={{
              height: "48px",
              width: "400px",
              backgroundColor: "#F7F7F7",
              borderRadius: "9px",
            }}
            name="Email"
            placeholder="Email"
            value={adminProfile?.email}
          />
          <TextFieldComponent
            disabled={true}
            sx={{ "& fieldset": { border: "none" } }}
            style={{
              height: "48px",
              width: "400px",
              backgroundColor: "#F7F7F7",
              borderRadius: "9px",
            }}
            name="Role"
            placeholder="Role"
            value={adminProfile?.role}
          />
          <TextFieldComponent
            disabled={true}
            sx={{ "& fieldset": { border: "none" } }}
            style={{
              height: "48px",
              width: "400px",
              backgroundColor: "#F7F7F7",
              borderRadius: "9px",
            }}
            name="National ID"
            placeholder="National ID"
            value={adminProfile?.nationalId}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AdminProfile;
