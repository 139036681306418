// path/to/authenticationSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  severity: "error",
  variant: "filled",
  openSnack: false,
};

export const errorMessageSlice = createSlice({
  name: "errorMessage",
  initialState,
  reducers: {
    setData: (state, action) => {
      const { message, severity, variant, openSnack } = action.payload;
      if (message !== undefined) state.message = message;
      if (severity !== undefined) state.severity = severity;
      if (variant !== undefined) state.variant = variant;
      if (openSnack !== undefined) state.openSnack = openSnack;
    },
  },
});

export const { setData } = errorMessageSlice.actions;
export default errorMessageSlice.reducer;
