import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  InputBase,
  Paper,
  Tabs,
  Tab,
  useTheme,
  IconButton,
} from "@mui/material";
import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Popover from "@mui/material/Popover";
import { useGoldTransactions } from "../../services/apis/transactions";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { DataGridStyle } from "../../styles";
import { handleSort } from "../../services/utils/filters";
import FilterDrawer from "../../components/common/FilterDrawer";

import { handleFilterByNumber } from "../../services/utils/filters";
import { TabContext, TabPanel } from "@mui/lab";
import { withStyles } from "@mui/styles";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import GoldTransactionModal from "./GoldTransactionModal";
import FractionTransactionModal from "./FractionTransactionModal";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";

const GoldTransactions = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [goldTransactionsDetails, setGoldTransactionsDetails] = useState([]);
  const [fractionsTransactionsDetails, setFractionsTransactionsDetails] =
    useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverValue, setPopoverValue] = useState(null);
  const open = Boolean(anchorEl);
  const [openDialog, setOpenDialog] = useState(false);
  const [openFractionDialog, setOpenFractionDialog] = useState(false);
  const [queryParameters, setQueryParameters] = useState({
    date_from: null,
    date_to: null,
    orderBy: null,
    sort: null,
    page: 0,
    status: "",
    goldType: [1, 2, 4],
    goldTransactionType: [1, 2],
    query: null,
  });
  const { data: goldData, isFetching: goldLoading } =
    useGoldTransactions(queryParameters);
  console.log("goldData", goldData);
  const [value, setValue] = useState("1");
  const AntTabs = withStyles({
    root: {
      borderBottom: "none",
    },
    indicator: {
      display: "none",
    },
  })(Tabs);
  const AntTab = withStyles((theme) => ({
    root: {
      "&:hover": {
        color: "#00303C",
        opacity: 1,
      },
      "&$selected": {
        color: "#ffffff",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        borderBottom: "none",
      },
      "&:focus": {
        color: "#00303C",
      },
    },
    selected: {
      "&$selected": {
        borderBottom: "none",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        color: "#ffffff !important",
      },
    },
  }))((props) => <Tab disableRipple {...props} />);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "1") {
      setQueryParameters({
        ...queryParameters,
        date_from: null,
        date_to: null,
        orderBy: null,
        sort: null,
        page: 0,
        status: "",
        goldType: [1, 2, 4],
        goldTransactionType: [1, 2],
        query: null,
      });
    } else if (newValue === "2") {
      setQueryParameters({
        ...queryParameters,
        date_from: null,
        date_to: null,
        orderBy: null,
        sort: null,
        page: 0,
        status: "",
        goldType: [3],
        goldTransactionType: [1, 2],
        query: null,
      });
    }
  };
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();

  const getRowId = (row) => row.goldTransactionId ?? row.id;

  const handleCellClick = (params, event) => {
    const { row, field } = params;
    if (field === "username") navigate(`/profile/${row.user.id}`);
    if (field === "totalWeight") {
      setAnchorEl(event.currentTarget);
      setPopoverValue(params.formattedValue);
    }
  };

  const handleSearchInput = (e) => {
    const { value } = e.target;
    setQueryParameters((prevParams) => ({
      ...prevParams,
      query: value.trim(),
    }));
  };

  const handleGoldBarsCheckox = (e) => {
    handleFilterByNumber(
      e.target.value,
      "goldBarIds",
      queryParameters,
      setQueryParameters
    );
  };

  const handleGoldTypeCheckbox = (e) => {
    handleFilterByNumber(
      e.target.value,
      "goldType",
      queryParameters,
      setQueryParameters
    );
  };
  const handleOpenDialog = (params) => {
    setGoldTransactionsDetails(params?.row);
    setOpenDialog(true);
  };
  const handleOpenFractionDialog = (params) => {
    setFractionsTransactionsDetails(params?.row);
    setOpenFractionDialog(true);
  };
  const handleCloseDialog = () => setOpenDialog(false);
  const handleCloseFractionDialog = () => setOpenFractionDialog(false);
  const newGoldTransactionsColumns = ItemsColumns([
    { field: "user_phone", headerName: "User Phone No.", flex: 2 },
    { field: "created", headerName: "Date", date: true, flex: 2 },
    {
      field: "amount",
      headerName: "Ingot Amount",
      flex: 2,
      specialRender: "abs",
    },
    { field: "goldBarName", headerName: "Product Name", flex: 2 },
    {
      field: "total_weight",
      headerName: "Total Weight",
      specialRender: "totalWeight",
    },
    { field: "price", headerName: "Gold Price", specialRender: "absLocal" },
    { field: "total", headerName: "Total Price", specialRender: "absLocal" },
    {
      field: "goldTransactionType",
      headerName: "Type",
      specialRender: "goldTransactionType",
    },
    {
      field: "serials",
      headerName: "Serial No.",
      specialRender: "serials",
      serialsFunction: (params) => handleOpenDialog(params),
    },
  ]);
  const newFractionTransactionsColumns = ItemsColumns([
    { field: "user_phone", headerName: "User Phone No.", flex: 2 },
    { field: "created", headerName: "Date", date: true, flex: 2 },
    {
      field: "total_weight",
      headerName: "Weight",
      specialRender: "totalWeight",
    },
    { field: "price", headerName: "Gold Price", specialRender: "absLocal" },
    { field: "total", headerName: "Total Price", specialRender: "absLocal" },
    {
      field: "goldTransactionType",
      headerName: "Type",
      specialRender: "goldTransactionType",
    },
    {
      field: "serials",
      headerName: "Serial No.",
      specialRender: "serials",
      serialsFunction: (params) => handleOpenFractionDialog(params),
    },
  ]);
 

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Gold Transactions" marginL={"20px"} />
      <SecondBox>
        <Box
          m="40px 0 0 0"
          height="100%"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
              backgroundColor: "#FFF",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#FFF",
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "#FFF",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#FFF",
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
            <TabContext value={value}>
              <Box
                sx={{
                  width: "100%",
                  paddingTop: "2%",
                  paddingBottom: "1%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <AntTabs
                  sx={{
                    backgroundColor: "#F5F6FF",
                    borderRadius: "9px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  value={value}
                  onChange={handleChange}
                  aria-label="ant example"
                >
                  <AntTab
                    sx={{ width: "512px" }}
                    label="Gold Ingots Transactions"
                    value="1"
                  />
                  <AntTab
                    sx={{ width: "512px" }}
                    label="Fractions transactions"
                    value="2"
                  />
                </AntTabs>
              </Box>
              <TabPanel sx={{}} value="1">
                <Box
                  sx={{
                    height: "auto",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ display: "flex", width: "50%", gap: 2, mb: 2 }}>
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "600px",
                        height: "48px",
                        backgroundColor: "#F5F5F5",
                        boxShadow: "none",
                        borderRadius: "12px",
                      }}
                      onSubmit={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <IconButton
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                      >
                        <img
                          alt="search-normal"
                          src={`../../assets/search-normal.png`}
                        />
                      </IconButton>
                      <InputBase
                        onChange={handleSearchInput}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search National Id ,Phone number or Email ....."
                      />
                      <FilterDrawer
                        queryParameters={queryParameters}
                        setQueryParameters={setQueryParameters}
                        handleFirstCheckBox={handleGoldBarsCheckox}
                        handleSecondCheckBox={handleGoldTypeCheckbox}
                        isGold={true}
                      />
                    </Paper>
                  </Box>

                  <DataGrid
                    rows={goldLoading ? [] : goldData?.data}
                    columns={newGoldTransactionsColumns}
                    pageSize={10}
                    loading={goldLoading}
                    rowsPerPageOptions={[10]}
                    rowCount={goldData?.totalItems || 0}
                    getRowId={getRowId}
                    paginationMode="server"
                    onPageChange={(page) => {
                      setQueryParameters({ ...queryParameters, page });
                    }}
                    onCellClick={handleCellClick}
                    sx={{
                      cursor: "pointer",
                      height: "630px",
                    }}
                    onSortModelChange={(params) =>
                      handleSort(params, queryParameters, setQueryParameters)
                    }
                    sortingMode="server"
                  />
                </Box>
              </TabPanel>
              <TabPanel sx={{}} value="2">
                <Box
                  sx={{
                    height: "auto",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ display: "flex", width: "50%", gap: 2, mb: 2 }}>
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: "600px",
                        height: "48px",
                        backgroundColor: "#F5F5F5",
                        boxShadow: "none",
                        borderRadius: "12px",
                      }}
                      onSubmit={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <InputBase
                        onChange={handleSearchInput}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search National Id ,Phone number or Email ....."
                      />
                      <FilterDrawer
                        queryParameters={queryParameters}
                        setQueryParameters={setQueryParameters}
                        handleFirstCheckBox={handleGoldBarsCheckox}
                        handleSecondCheckBox={handleGoldTypeCheckbox}
                        isGold={true}
                      />
                    </Paper>
                  </Box>
                  <DataGrid
                    rows={goldLoading ? [] : goldData?.data}
                    columns={newFractionTransactionsColumns}
                    pageSize={10}
                    loading={goldLoading}
                    rowsPerPageOptions={[10]}
                    rowCount={goldData?.totalItems || 0}
                    getRowId={getRowId}
                    paginationMode="server"
                    onPageChange={(page) => {
                      setQueryParameters({ ...queryParameters, page });
                    }}
                    onCellClick={handleCellClick}
                    sx={{ cursor: "pointer", height: "630px" }}
                    onSortModelChange={(params) =>
                      handleSort(params, queryParameters, setQueryParameters)
                    }
                    sortingMode="server"
                  />
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ p: 2 }}>{popoverValue}</Typography>
          </Popover>
        </Box>
      </SecondBox>
      <GoldTransactionModal
        open={openDialog}
        onClose={handleCloseDialog}
        data={goldTransactionsDetails}
      />
      <FractionTransactionModal
        open={openFractionDialog}
        onClose={handleCloseFractionDialog}
        data={fractionsTransactionsDetails}
      />
    </Box>
  );
};

export default GoldTransactions;
