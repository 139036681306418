import { Box, Dialog, DialogContent, Divider } from "@mui/material";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { formDate } from "../../../services/helpers";

const RolesCategoriesModal = ({ categories, open, onClose, categoryId }) => {
  const categoriesDetails = categories.find(
    (category) => category.id === categoryId
  );
  return (
    <Dialog
      maxWidth="800px"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          borderRadius: 24,
        },
      }}
    >
      <DialogContent
        sx={{
          padding: "5% 5% 10% 10%",
          height: "500px",
          width: "800px",
          borderRadius: "24px",
          background: "#FFF",
          display: "flex",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <Box
          sx={{
            justifyContent: "center",
            height: "auto",
            width: "100%",
            borderRadius: "24px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignSelf: "center",
          }}
        >
          <Box
            sx={{ display: "flex", alignSelf: "flex-end", marginTop: "5px" }}
          >
            <IoIosCloseCircleOutline
              onClick={onClose}
              color="#4C4C4C"
              style={{
                width: "24px",
                height: "24px",
                cursor: "pointer",
              }}
            />
          </Box>
          <Box
            sx={{
              color: "#666666",
              fontWeight: "500",
              fontSize: "24px",
              display: "flex",
              alignSelf: "flex-start",
            }}
          >
            Category details :
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              marginTop: "5%",
            }}
          >
            <Box
              sx={{
                color: "#666666",
                fontWeight: "400",
                fontSize: "14px",
                display: "flex",
                alignSelf: "flex-start",
              }}
            >
              Category name :
            </Box>
            <Box
              sx={{
                color: "#666666",
                fontWeight: "400",
                fontSize: "14px",
                display: "flex",
                marginLeft: "10px",
              }}
            >
              {categoriesDetails?.name}
            </Box>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box
            sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
          >
            <Box
              sx={{
                color: "#666666",
                fontWeight: "400",
                fontSize: "14px",
                display: "flex",
                alignSelf: "flex-start",
              }}
            >
              Role Description :
            </Box>
            <Box
              sx={{
                color: "#666666",
                fontWeight: "400",
                fontSize: "14px",
                display: "flex",
                marginLeft: "10px",
              }}
            >
              {categoriesDetails?.description}
            </Box>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box
            sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
          >
            <Box
              sx={{
                color: "#666666",
                fontWeight: "400",
                fontSize: "14px",
                display: "flex",
                alignSelf: "flex-start",
              }}
            >
              Type :
            </Box>
            <Box
              sx={{
                color: "#666666",
                fontWeight: "400",
                fontSize: "14px",
                display: "flex",
                marginLeft: "10px",
              }}
            >
              {categoriesDetails?.is_built === 0 ? "Created" : "Built-in"}
            </Box>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box
            sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
          >
            <Box
              sx={{
                color: "#666666",
                fontWeight: "400",
                fontSize: "14px",
                display: "flex",
                alignSelf: "flex-start",
              }}
            >
              Creation Date :
            </Box>
            <Box
              sx={{
                color: "#666666",
                fontWeight: "400",
                fontSize: "14px",
                display: "flex",
                marginLeft: "10px",
              }}
            >
              {formDate(categoriesDetails?.created)}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RolesCategoriesModal;
