import { useState } from "react";
import TextFieldComponent from "../../components/TextFieldComponent";
import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import CustomDialog from "../../components/CustomDialog";
import { addClosedReasons } from "../../services/apis/closedReasons";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { SecondBox } from "../../components/common/CustomBox";

const AddClosedReason = () => {
  const [reasonsPayload, setReasonsPayload] = useState({
    type: "user",
    trans: [
      {
        local: "en",
        reason: "",
      },
      {
        local: "ar",
        reason: "",
      },
      {
        local: "eg",
        reason: "",
      },
    ],
  });
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSave = async () => {
    try {
      const isEmpty = reasonsPayload.trans.some(
        (transItem) => transItem.reason.trim() === ""
      );

      if (isEmpty) {
        dispatch(
          setData({
            openSnack: true,
            message: "Please fill in all fields",
          })
        );
        return;
      }
      setLoading(true);
      await addClosedReasons(reasonsPayload);
      setSuccessDialogOpen(true);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const handleTransChange = (local, field, newValue) => {
    setReasonsPayload((prevState) => {
      const updatedTrans = prevState?.trans?.map((transItem) => {
        if (transItem?.local === local) {
          return { ...transItem, [field]: newValue };
        }
        return transItem;
      });
      return { ...prevState, trans: updatedTrans };
    });
  };
  return (
    <Box
      style={{
        overflowY: "auto",
        maxHeight: "calc(100vh)",
      }}
    >
      <DashboardMainHeader />

      <Header title="Add Reason" marginL={"25px"} />
      <SecondBox>
        <Box
          m="40px 0 0 0"
          height="auto"
          style={{
            paddingTop: "8%",
            paddingLeft: "9%",
            paddingBottom: "4%",
            width: "90%",
            alignSelf: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              marginTop: "15px",
              width: "100%",
            }}
          >
            <FormLabel
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#4C4C4C",
              }}
              id="demo-row-radio-buttons-group-label"
            >
              Reason For
            </FormLabel>
            <RadioGroup
              style={{
                marginTop: "15px",
              }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(event) => {
                const selectedType = event.target.value;
                setReasonsPayload((prevPayload) => ({
                  ...prevPayload,
                  type: selectedType,
                }));
              }}
              value={reasonsPayload.type}
            >
              <FormControlLabel
                style={{
                  marginRight: "4%",
                  color: "#4C4C4C",
                  fontSize: "24px",
                }}
                value="user"
                control={
                  <Radio
                    sx={{
                      "&, &.Mui-checked": {
                        color: "#E9C237",
                      },
                      "&, &.Mui-unchecked": {
                        color: "#B3B3B3",
                      },
                    }}
                  />
                }
                label="User"
              />
              <FormControlLabel
                style={{ color: "#4C4C4C", fontSize: "24px" }}
                value="admin"
                control={
                  <Radio
                    sx={{
                      "&, &.Mui-checked": {
                        color: "#E9C237",
                      },
                      "&, &.Mui-unchecked": {
                        color: "#B3B3B3",
                      },
                    }}
                  />
                }
                label="Admin"
              />
            </RadioGroup>
          </Box>
          <TextFieldComponent
            sx={{ "& fieldset": { border: "none" } }}
            style={{
              height: "48px",
              width: "100%",
              backgroundColor: "#F7F7F7",
              borderRadius: "9px",
            }}
            name="Reason - English"
            placeholder="eg. New Items"
            value={
              reasonsPayload?.trans?.find(
                (transItem) => transItem.local === "en"
              ).reason
            }
            onChange={(event) =>
              handleTransChange("en", "reason", event.target.value)
            }
          />
          <TextFieldComponent
            sx={{ "& fieldset": { border: "none" } }}
            style={{
              height: "48px",
              width: "100%",
              backgroundColor: "#F7F7F7",
              borderRadius: "9px",
            }}
            name="Reason - Arabic"
            placeholder="eg. New Items"
            value={
              reasonsPayload?.trans?.find(
                (transItem) => transItem.local === "ar"
              ).reason
            }
            onChange={(event) =>
              handleTransChange("ar", "reason", event.target.value)
            }
          />
          <TextFieldComponent
            sx={{ "& fieldset": { border: "none" } }}
            style={{
              height: "48px",
              width: "100%",
              backgroundColor: "#F7F7F7",
              borderRadius: "9px",
            }}
            name="Reason - Egyptian Arabic"
            placeholder="eg. New Items"
            value={
              reasonsPayload?.trans?.find(
                (transItem) => transItem.local === "eg"
              ).reason
            }
            onChange={(event) =>
              handleTransChange("eg", "reason", event.target.value)
            }
          />
          <Button
            disabled={loading}
            onClick={handleSave}
            sx={{
              "&:hover": { backgroundColor: "#E9C237" },
              backgroundColor: "#E9C237",
              width: "200px",
              height: "46px",
              borderRadius: "12px",
              color: "#ffffff",
              fontSize: "14px",
              display: "flex",
              alignSelf: "flex-end",
              marginTop: "5%",
            }}
          >
            Save
          </Button>
        </Box>
      </SecondBox>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Reason has been added Successfully."
        message=""
        buttonText="Go to Closed Reasons for Tickets"
        buttonLink="/closedReasons"
      />
      <ReusableSnackbar handleClose={handleCloseSnack} />
    </Box>
  );
};
export default AddClosedReason;
