import { useState } from "react";
import { IconButton, InputBase, Paper, useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import DashboardMainHeader from "../../components/DashboardMainHeader";

import { checkString, handlePageChange } from "../../services/utils/filters";
import FilterDrawer from "../../components/common/FilterDrawer";
import { useFetchFractions } from "../../services/apis/transactions";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";

const FractionsTransfer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [queryParameters, setQueryParameters] = useState({
    date_from: null,
    date_to: null,
    orderBy: null,
    sort: null,
    page: 0,
    phone: null,
    goldTransactionType: [3, 4],
  });
  const { data, isFetching } = useFetchFractions(queryParameters);
  const handleSearchInput = (e) => {
    const { value } = e.target;
    checkString(value, queryParameters, setQueryParameters);
  };
  const fractionsTransferColumns = ItemsColumns([
    { field: "id", headerName: "Transaction ID" },
    { field: "senderPhone", headerName: "From" },
    {
      field: "receiverPhone",
      headerName: "To",
      specialRender: "customRender",
      renderFunction: (params) => params?.row?.receiver?.phone,
    },
    {
      field: "total_weight",
      headerName: "Amount",
      specialRender: "totalWeight",
    },
    {
      field: "created",
      headerName: "Date",
      date: true,
    },
  ]);

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Fractions Transfer" marginL={"20px"} />
      <SecondBox>
        <Box
          m="40px 0 0 0"
          height="100%"
          sx={{
            "& .MuiDataGrid-columnHeader.bold-header": {
              fontWeight: "bold",
              fontSize: "14px",
              color: "#000000",
            },
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
              backgroundColor: "#FFF",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#FFF",
              borderBottom: "none",
              pointerEvents: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "#FFF",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#FFF",
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <Box sx={{ display: "flex", width: "50%", gap: 2, mb: 2, p: 2 }}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "600px",
                height: "48px",
                backgroundColor: "#F5F5F5",
                boxShadow: "none",
                borderRadius: "12px",
              }}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <img
                  alt="search-normal"
                  src={`../../assets/search-normal.png`}
                />
              </IconButton>
              <InputBase
                onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search National Id or Phone number....."
              />
              <FilterDrawer
                queryParameters={queryParameters}
                setQueryParameters={setQueryParameters}
              />
            </Paper>
          </Box>
          <DataGrid
            rows={isFetching ? [] : data?.data?.result}
            columns={fractionsTransferColumns}
            pageSize={10}
            loading={isFetching}
            rowsPerPageOptions={[10]}
            rowCount={data?.data?.totalItems || 0}
            paginationMode="server"
            onPageChange={(p) =>
              handlePageChange(p, queryParameters, setQueryParameters)
            }
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
              height: "630px",
            }}
            totalPages={data?.data?.totalPages}
          />
        </Box>
      </SecondBox>
    </Box>
  );
};

export default FractionsTransfer;
