import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  Collapse,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import { DataGridStyle } from "../../styles";
import { useEffect } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { formDate } from "../../services/helpers";

const FractionsOrder = React.forwardRef((props, ref) => {
  console.log("Props", props);
  const cellsStyle = {
    // border: "1px solid #F2F2F2",
    // p: "20px",
  };
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleExpandedRow = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };
  useEffect(() => {}, [props]);
  return (
    <Dialog
      maxWidth="1000px"
      open={props.open}
      onClose={() => {
        props.onClose();
        setExpandedRow(null);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          width: "900px",
          height: "auto",
          // minHeight: "500px",
          borderRadius: "24px",
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            alignSelf: "flex-end",
            marginTop: "5px",
            float: "right",
          }}
        >
          <IoIosCloseCircleOutline
            onClick={() => {
              props.onClose();
              setExpandedRow(null);
            }}
            color="#4C4C4C"
            style={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
            }}
          />
        </Box>
        <Box sx={{ marginTop: "5%", marginLeft: "4%", marginBottom: "2%" }}>
          <Typography
            sx={{ fontSize: "24px", fontWeight: "500", color: "#666666" }}
          >
            Order Details
          </Typography>
        </Box>
        <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
          <TableContainer>
            <Table sx={{ borderCollapse: "collapse" }}>
              <TableHead sx={{}}>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>
                    Order ID
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>User ID</TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Gold Price
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>Weight</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Date</TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Serial No.
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.data?.map((data) => (
                  <React.Fragment key={data.id}>
                    <TableRow style={{ cursor: "pointer" }}>
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {data.id}
                      </TableCell>
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {props?.detials?.phone}
                      </TableCell>
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {data?.price}
                      </TableCell>
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {data.totalWeight} Gm
                      </TableCell>

                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {formDate(data?.created)}
                      </TableCell>
                      <TableCell
                        sx={cellsStyle}
                        style={{ textAlign: "center" }}
                      >
                        {props?.data[0]?.serials.length > 0 && (
                          <Typography
                            variant="body2"
                            color="#E9C237"
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={() => toggleExpandedRow(data.id)}
                          >
                            {expandedRow === data.id ? "Hide" : "See More"}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    {expandedRow === data.id && (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <Collapse
                            in={expandedRow === data.id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box
                              p={1}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={
                                  {
                                    // width: "30%",
                                  }
                                }
                              >
                                <Typography
                                  sx={{
                                    color: "#4C4C4C",
                                    fontSize: "14px",
                                  }}
                                >
                                  Serial numbers :{" "}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  flex: 1,
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                  gap: "10px",
                                }}
                              >
                                {data?.serials?.map((serial, index) => (
                                  <Typography
                                    key={index}
                                    sx={{
                                      color: "#808080",
                                      fontSize: "14px",
                                      marginLeft: "10px",
                                      backgroundColor:
                                        "rgba(122, 128, 240, 0.03)",
                                      padding: "8px 24px",
                                      borderRadius: "50px",
                                    }}
                                  >
                                    {serial}
                                  </Typography>
                                ))}
                              </Box>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
    </Dialog>
  );
});

export default FractionsOrder;
