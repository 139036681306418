import React, { useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Divider,
  Switch,
  Snackbar,
  Alert,
} from "@mui/material";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import UserInfoField from "../../user-profile/UserInfoField";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "../../../styles";
import Header from "../../../components/Header";
import {
  changeInventoryRequestStatus,
  fetchInventoryRequestSerials,
} from "../../../services/apis/inventory";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";

const StockDetails = () => {
  const { id } = useParams();
  const [requestSerials, setRequestSerials] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const requestData = location.state && location.state.requestData;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleInStockButtonClick = async () => {
    if (selectedRows.length > 0) {
      try {
        const requestData = {
          ids: selectedRows,
          availability: 1,
        };
        await changeInventoryRequestStatus(requestData);
        setOpen(true);
        setSelectedRows([]);
        getRequestSerials();
      } catch (error) {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      }
    } else {
      dispatch(
        setData({
          openSnack: true,
          message: "No rows selected for 'In Stock'",
        })
      );
    }
  };
  const handleOutOfStockButtonClick = async () => {
    if (selectedRows.length > 0) {
      try {
        const requestData = {
          ids: selectedRows,
          availability: 0,
        };
        await changeInventoryRequestStatus(requestData);
        setOpen(true);
        setSelectedRows([]);
        getRequestSerials();
      } catch (error) {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      }
    } else {
      dispatch(
        setData({
          openSnack: true,
          message: "No rows selected for 'Out Of Stock'",
        })
      );
    }
  };
  const getRequestSerials = async () => {
    setLoading(true);
    setRequestSerials([]);
    try {
      const response = await fetchInventoryRequestSerials(id);
      const request = response?.data?.data?.data;
      setRequestSerials(request);
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (selectedRowIds) => {
    setSelectedRows(selectedRowIds);
  };

  const requestsColumns = [
    {
      field: "serial",
      headerName: "Serial Number",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "availability",
      headerName: "Stock Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let color = "";
        if (params.row.availability === 0) color = "#CF2424";
        if (params.row.availability === 1) color = "#24CF80";
        return (
          <Typography sx={{ color: color }}>
            {params.row.availability === 0 ? "Out Of Stock" : "In Stock"}
          </Typography>
        );
      },
    },
    {
      field: "sold_to",
      headerName: "Availability",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let color = "";
        if (params.row.sold_to !== null) color = "#CF2424";
        if (params.row.sold_to === null) color = "#24CF80";
        return (
          <Typography sx={{ color: color }}>
            {params.row.sold_to === null ? "Available" : "Sold"}
          </Typography>
        );
      },
    },

    {
      field: "action",
      headerName: "Change Stock Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.row.sold_to !== null) {
          return null;
        }
        const isChecked = params.row.availability === 1;
        const handleSwitchToggle = async (event) => {
          const newAvailability = event.target.checked ? 1 : 0;
          const id = params.row.id;

          try {
            const requestData = {
              ids: [id],
              availability: newAvailability,
            };
            await changeInventoryRequestStatus(requestData);
            setOpen(true);
            setRequestSerials((prevSerials) =>
              prevSerials.map((serial) =>
                serial.id === id
                  ? { ...serial, availability: newAvailability }
                  : serial
              )
            );
          } catch (error) {
            console.error("Error updating availability:", error);
          }
        };
        return (
          <Switch
            checked={isChecked}
            onChange={handleSwitchToggle}
            {...label}
            sx={{
              "& .MuiSwitch-thumb": {
                color: isChecked ? "#E9C237" : "#E9C237",
              },
              "& .MuiSwitch-track": {
                backgroundColor: isChecked ? "#0000001F" : "#0000001F",
              },
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    getRequestSerials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!requestSerials) return <LoadingSpinner />;
  return (
    <Box sx={{ height: "auto" }}>
      <Header title={`${requestData?.name} Details`} marginL={"20px"} />
      <Box
        m="20px"
        sx={{
          display: "flex",
          width: "95%",
          flexDirection: "column",
          gap: 2,
          background: "#F0F0F0",
          alignItems: "center",
          overflowY: "auto",
          height: "90vh",
        }}
      >
        <Box sx={{ dispaly: "flex", width: "97%", marginTop: "3%" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <UserInfoField title="Name" data={requestData?.name} />
              <UserInfoField title="Type" data={requestData?.type} />
              <UserInfoField title="weight" data={requestData?.weight} />
            </Box>
            <Divider sx={{ backgroundColor: "#F5F5F5" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  width: "50%",
                  alignItems: "center",
                }}
              >
                <Typography>Product Status:</Typography>
                <Box
                  sx={{
                    gap: 1,
                    display: "flex",
                    borderRadius: "100px",
                    background: "#40C7540D",
                    padding: "8px 32px",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "5px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="8"
                      viewBox="0 0 9 8"
                      fill="none"
                    >
                      <circle
                        cx="4.22852"
                        cy="4"
                        r="4"
                        fill={
                          requestData?.availability === 1
                            ? "#40C754"
                            : "#F23045"
                        }
                      />
                    </svg>
                  </Box>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color:
                        requestData?.availability === 1 ? "#40C754" : "#F23045",
                    }}
                  >
                    {requestData?.availability === 1
                      ? "In Stock"
                      : "Out of Stock"}
                  </Typography>
                </Box>
              </Box>
              <UserInfoField title="Quantity" data={requestData?.amount} />
            </Box>
            <Divider />
          </Box>
        </Box>
        <Typography
          sx={{
            alignSelf: "flex-start",
            color: "#666666",
            fontSize: "20px",
            fontWeight: "500",
            marginLeft: "2%",
          }}
        >
          Serials
        </Typography>
        <Box
          sx={{
            width: "95%",
            height: "58vh",
            background: "#FFF",
            borderRadius: "12px",
            boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08);",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              margin: "2% 2%",
            }}
          >
            <Button
              onClick={handleInStockButtonClick}
              sx={{
                width: "142px",
                "&:hover": {
                  backgroundColor: "#E9C237",
                },
                color: "#ffffff",
                height: "36px",
                backgroundColor: "#E9C237",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                marginRight: "10px",
                cursor: "pointer",
              }}
            >
              In Stock
            </Button>
            <Button
              onClick={handleOutOfStockButtonClick}
              variant="outlined"
              sx={{
                "&:hover": {
                  backgroundColor: "#ffffff",
                  borderColor: "#E9C237",
                },
                width: "142px",
                height: "36px",
                backgroundColor: "#ffffff",
                color: "#E9C237",
                borderColor: "#E9C237",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                cursor: "pointer",
              }}
            >
              Out Of Stock
            </Button>
          </Box>

          <Box m="40px 0 0 0" height="60vh" sx={DataGridStyle}>
            <DataGrid
              components={{
                Footer: () => null,
              }}
              checkboxSelection
              loading={loading}
              rows={requestSerials}
              columns={requestsColumns}
              getRowId={(row) => row.id}
              onSelectionModelChange={handleCheckboxChange}
            />
          </Box>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Status Changed Successfully
            </Alert>
          </Snackbar>
        </Box>
      </Box>
      <ReusableSnackbar handleClose={handleCloseSnack} />
    </Box>
  );
};

export default StockDetails;
