import { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
// import { fetchRoles } from "../../services/apis/roles-and-permissions";
// import { fetchAdmins } from "../../services/apis/users";
import { DataGridStyle } from "../../styles";
import Header from "../../components/Header";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { withStyles } from "@mui/styles";
import { TabContext, TabPanel } from "@mui/lab";
import FirstAdminTabPanel from "./ViewAdminsPanel/FirstAdminTabPanel";
import SecondAdminTabPanel from "./ViewAdminsPanel/SecondAdminTapPanel";
import AddNewAdminModal from "./AddNewAdminModal";
import AddButton from "../../components/common/AddButton";

const AddNewUser = () => {
  const [value, setValue] = useState("1");
  const [openDialog, setOpenDialog] = useState(false);
  const [queryParameters] = useState({
    page: 0,
    orderBy: null,
    sort: null,
  });
  const AntTabs = withStyles({
    root: {
      borderBottom: "none",
    },
    indicator: {
      display: "none",
    },
  })(Tabs);
  const AntTab = withStyles((theme) => ({
    root: {
      "&:hover": {
        color: "#00303C",
        opacity: 1,
      },
      "&$selected": {
        color: "#ffffff",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        borderBottom: "none",
      },
      "&:focus": {
        color: "#00303C",
      },
    },
    selected: {
      "&$selected": {
        borderBottom: "none",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        color: "#ffffff !important",
      },
    },
  }))((props) => <Tab disableRipple {...props} />);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = async () => {
    setOpenDialog(false);
  };
  const getRoles = async () => {
    try {
      // setLoading(true);
      // const response = await fetchRoles();
      // const roles = response.data.data;
      // setRoles(roles);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const getAdmins = async () => {
    try {
      // setLoading(true);
      // const response = await fetchAdmins(queryParameters);
      // const admins = response.data.data.result;
      // const adminscount = response.data.data.totalItems;
      // const adminsPagecount = response.data.data.totalPages;
      // setTotalPages(adminsPagecount);
      // setPagesCount(adminscount);
      // setAdmins(admins);
    } catch (error) {
      console.log(error.response.data.message);
    }
    // finally {
    //   setLoading(false);
    // }
  };
  useEffect(() => {
    getAdmins();
    getRoles();
  }, [queryParameters]);
  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Admins" marginL={"25px"} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          gap: 2,
          pr: 2,
        }}
      >
        <AddButton
          title={"+ New Admin"}
          onClick={() => {
            handleOpen();
          }}
        />
      </Box>
      <Box
        m="20px"
        sx={{
          height: "auto",
          background: "#FFF",
          borderRadius: "12px",
          boxShadow: "1px 1px 16px 0px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
          <TabContext value={value}>
            <Box
              sx={{
                width: "100%",
                paddingTop: "5%",
                paddingBottom: "1%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <AntTabs
                sx={{
                  backgroundColor: "#F5F6FF",
                  borderRadius: "9px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                value={value}
                onChange={handleChange}
                aria-label="ant example"
              >
                <AntTab sx={{ width: "345px" }} label="Admins" value="1" />
                <AntTab sx={{ width: "345px" }} label="Requests" value="2" />
                {/* <AntTab sx={{ width: "345px" }} label="Logs" value="3" /> */}
              </AntTabs>
            </Box>
            <TabPanel sx={{ marginLeft: "4%" }} value="1">
              <FirstAdminTabPanel />
            </TabPanel>
            <TabPanel sx={{ marginLeft: "4%" }} value="2">
              <SecondAdminTabPanel />
            </TabPanel>
            {/* <TabPanel sx={{ marginLeft: "4%" }} value="3">
              <ThirdAdminTabPanel />
            </TabPanel> */}
          </TabContext>
        </Box>
      </Box>
      <AddNewAdminModal
        onAddAdmin={getAdmins}
        open={openDialog}
        onClose={handleClose}
        label={"Save"}
      />
    </Box>
  );
};

export default AddNewUser;
