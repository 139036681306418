import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { checkIfUserIsLogged } from "../services/helpers";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (
      !checkIfUserIsLogged() &&
      location.pathname !== "/register" &&
      location.pathname !== "/forget-password" &&
      location.pathname !== "/ResetPassword" &&
      location.pathname !== "/deactivatedAdmin"
    ) {
    }
    // navigate("/login");
  }, [location.pathname, navigate]);

  return children;
};

export default ProtectedRoute;
