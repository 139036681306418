import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Tab, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import Header from "../../components/Header";
import { fetchPaymentInfo } from "../../services/apis/paymentInfo";
import { withStyles } from "@mui/styles";
import PaymentInfoDialog from "./PaymentInfoDialog";
import InstaPaymentInfoDialog from "./InstaPaymentInfoDialog";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { DataGridStyle } from "../../styles";
 import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";

const PaymentInfo = () => {
  const [bankPayment, setBankPayment] = useState([]);
  const [instapayPayment, setInstapayPayment] = useState([]);
  const [value, setValue] = useState("1");
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openInstaPayEditDialog, setOpenInstaPayEditDialog] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const AntTabs = withStyles({
    root: {
      borderBottom: "none",
    },
    indicator: {
      display: "none",
    },
  })(Tabs);
  const AntTab = withStyles((theme) => ({
    root: {
      "&:hover": {
        color: "#00303C",
        opacity: 1,
      },
      "&$selected": {
        color: "#ffffff",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        borderBottom: "none",
      },
      "&:focus": {
        color: "#00303C",
      },
    },
    selected: {
      "&$selected": {
        borderBottom: "none",
        backgroundColor: "#E9C237",
        borderRadius: "9px",
        color: "#ffffff !important",
      },
    },
  }))((props) => <Tab disableRipple {...props} />);

  const handleResponse = async () => {
    try {
      setLoading(true);
      const response = await fetchPaymentInfo();
      const data = await response?.data?.data;
      const bankPaymentObject = data.length > 0 ? [data[2]] : [];
      const instapayPaymentObject = data.length > 0 ? [data[1]] : [];
      setBankPayment(bankPaymentObject);
      setInstapayPayment(instapayPaymentObject);
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenPaymentInfo = (e) => {
    setOpenEditDialog(true);
  };
  const handleClosePaymentInfoDialog = () => {
    setOpenEditDialog(false);
  };
  const handleOpenInstaPayPaymentInfo = (e) => {
    setOpenInstaPayEditDialog(true);
  };
  const handleCloseInstaPayPaymentInfoDialog = () => {
    setOpenInstaPayEditDialog(false);
  };
  const bankPaymentColumns = ItemsColumns([
    {
      field: "accountName",
      headerName: "Account Name",
      specialRender: "customRender",
      renderFunction: (params) => params.row.meta.accountName,
    },
    {
      field: "bankName",
      headerName: "Bank name",
      specialRender: "customRender",
      renderFunction: (params) => params.row.meta.bankName,
    },
    {
      field: "IBAN",
      specialRender: "customRender",
      renderFunction: (params) => params.row.meta.IBAN,
    },
    {
      field: "swiftCode",
      headerName: "Swift code",
      specialRender: "customRender",
      renderFunction: (params) => params.row.meta.swiftCode,
    },
    {
      field: "Action",
      actions: {
        edit: (params) => handleOpenPaymentInfo(params.row),
      },
    },
  ]);
  const instapayPaymentColumns = ItemsColumns([
    {
      field: "beneficiaryName",
      headerName: "Beneficiary name",
      specialRender: "customRender",
      renderFunction: (params) => params.row.meta.beneficiaryName,
    },
    {
      field: "IPA",
      headerName: "Payment address (IPA)",
      specialRender: "customRender",
      renderFunction: (params) => params.row.meta.IPA,
    },
    {
      field: "Action",
      actions: {
        edit: (params) => handleOpenInstaPayPaymentInfo(params.row),
      },
    },
  ]);

  useEffect(() => {
    handleResponse();
  }, []);
  return (
    <Box
      sx={{
        "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#FFF",
          borderBottom: "none",
          pointerEvents: "none",
        },
      }}
    >
      <DashboardMainHeader />
      <Header title="Payment Information" marginB={"30px"} marginL={"20px"} />
      <PaymentInfoDialog
        fetchData={fetchPaymentInfo}
        setBankPayment={setBankPayment}
        initialValues={{
          accountName: bankPayment[0]?.meta.accountName || "",
          bankName: bankPayment[0]?.meta.bankName || "",
          IBAN: bankPayment[0]?.meta.IBAN || "",
          swiftCode: bankPayment[0]?.meta.swiftCode || "",
        }}
        openEditDialog={openEditDialog}
        handleCloseAlert={handleClosePaymentInfoDialog}
        label={"Save"}
      />
      <InstaPaymentInfoDialog
        fetchData={fetchPaymentInfo}
        setInstapayPayment={setInstapayPayment}
        initialValues={{
          IPA: instapayPayment[0]?.meta.IPA || "",
          beneficiaryName: instapayPayment[0]?.meta.beneficiaryName || "",
        }}
        openEditDialog={openInstaPayEditDialog}
        handleCloseAlert={handleCloseInstaPayPaymentInfoDialog}
        label={"Save"}
      />
      <SecondBox>
        <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
          <TabContext value={value}>
            <Box
              sx={{
                width: "100%",
                paddingTop: "5%",
                paddingBottom: "1%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <AntTabs
                sx={{
                  backgroundColor: "#F5F6FF",
                  borderRadius: "9px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                value={value}
                onChange={handleChange}
                aria-label="ant example"
              >
                <AntTab
                  sx={{ width: "512px", display: "flex" }}
                  label="Bank Account"
                  value="1"
                />
                <AntTab sx={{ width: "512px" }} label="Instapay" value="2" />
              </AntTabs>
            </Box>
            <TabPanel sx={{ marginLeft: "4%" }} value="1">
              <DataGrid
                disableSelectionOnClick
                sx={{
                  backgroundColor: "#fff",
                }}
                loading={loading}
                autoHeight
                rows={bankPayment}
                columns={bankPaymentColumns}
                hideFooterPagination={true}
              />
            </TabPanel>
            <TabPanel sx={{ marginLeft: "4%" }} value="2">
              <DataGrid
                disableSelectionOnClick
                sx={{
                  backgroundColor: "#fff",
                }}
                loading={loading}
                autoHeight
                rows={instapayPayment}
                columns={instapayPaymentColumns}
                hideFooterPagination={true}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </SecondBox>
    </Box>
  );
};
export default PaymentInfo;
