import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "../../styles";
import { ItemsColumns } from "../../components/common/Columns";

const SecondTabPanel = ({ role }) => {
  const rolesColumns = ItemsColumns([
    { field: "name", headerName: "Permission" },
    { field: "description", flex: 3 },
  ]);

  return (
    <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
      <DataGrid
        disableSelectionOnClick
        sx={{
          backgroundColor: "#ffffff",
        }}
        autoHeight={true}
        rows={role?.permissions}
        columns={rolesColumns}
        hideFooterPagination={true}
        localeText={{
          noRowsLabel: "No Permissions assigned",
        }}
      />
    </Box>
  );
};
export default SecondTabPanel;
