import { Box, IconButton, InputBase, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "../../../styles";
import { useNavigate } from "react-router-dom";
import { useIngotPendingOrders } from "../../../services/apis/orders";
import { ItemsColumns } from "../../../components/common/Columns";

const ThirdPendingTabPanel = () => {
  const navigate = useNavigate();

  const { data, isFetching } = useIngotPendingOrders({
    type: "fraction",
  });

  const handleClick = (params) => {
    const ingot = params.row;
    navigate(`/pendingOrders/${ingot.order_id}`);
  };

  const fractionsColumns = ItemsColumns([
    { field: "order_id", headerName: "Order ID" },
    { field: "phone", headerName: "User Phone number" },
    { field: "total_weight", headerName: "Amount" },
    { field: "created", date: true },
    { field: "total", headerName: "Deposit" },
    {
      field: "orderDetails",
      headerName: "Actions",
      actions: { view: (params) => handleClick(params) },
    },
  ]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          width: "50%",
          gap: 2,
          mb: 2,
        }}
      >
        <Paper
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            width: "600px",
            height: "48px",
            backgroundColor: "#F5F5F5",
            boxShadow: "none",
            borderRadius: "12px",
          }}
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <img
              alt="search-normal"
              src={`../../../assets/search-normal.png`}
            />
          </IconButton>
          <InputBase
            // onChange={handleSearchInput}
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Email , National ID or Phone number"
          />
        </Paper>
      </Box>
      <Box
        sx={{
          height: "auto",
          background: "#FFF",
          borderRadius: "12px",
        }}
      >
        <Box m="40px 0 0 0" height="100%" sx={DataGridStyle}>
          <DataGrid
            rows={isFetching ? [] : data?.data}
            columns={fractionsColumns}
            loading={isFetching}
            autoHeight={true}
            sx={{
              cursor: "pointer",
              backgroundColor: "#ffffff",
            }}
            getRowId={(row) => row?.order_id || ""}
            components={{
              Footer: () => null,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default ThirdPendingTabPanel;
