import React from "react";
import {
  Button,
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import TextFieldComponent from "../../components/TextFieldComponent";
import { useEffect } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import CustomDialog from "../../components/CustomDialog";
import CustomTextArea from "../../components/CustomTextArea";
import { editTerm } from "../../services/apis/terms";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { MenuProps } from "../../components/common/MenuProps";

const EditTerm = ({
  open,
  onClose,
  label,
  setTerms,
  terms,
  fetchData,
  termId,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const termToEdit = terms?.find((term) => term.id === termId);
  const [productsPayload, setProductsPayload] = useState({
    local: "en",
    title_ar: "",
    description_ar: "",
    title_en: "",
    description_en: "",
  });
  const lang = [
    { id: 1, local: "en", name: "English" },
    { id: 2, local: "ar", name: "Arabic" },
  ];
  const dispatch = useDispatch();
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setData({ openSnack: false }));
  };
  const handleLangChange = (event) => {
    const selectedLang = event.target.value;
    const selectedLangObj = lang.find((item) => item.local === selectedLang);
    if (selectedLangObj) {
      if (termToEdit) {
        setProductsPayload((prevState) => ({
          ...prevState,
          local: selectedLang,
          title:
            selectedLang === "en" ? termToEdit?.title_en : termToEdit?.title_ar,
          description:
            selectedLang === "en"
              ? termToEdit?.description_en
              : termToEdit?.description_ar,
        }));
      }
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let dataToSend = {};

      if (productsPayload.local === "en") {
        if (
          productsPayload.title_en.trim() !== "" &&
          productsPayload.description_en.trim() !== ""
        ) {
          dataToSend = {
            title_en: productsPayload.title_en,
            description_en: productsPayload.description_en,
          };
        }
      } else if (productsPayload.local === "ar") {
        if (
          productsPayload.title_ar.trim() !== "" &&
          productsPayload.description_ar.trim() !== ""
        ) {
          dataToSend = {
            title_ar: productsPayload.title_ar,
            description_ar: productsPayload.description_ar,
          };
        }
      }

      // Check if dataToSend is empty
      if (Object.keys(dataToSend).length === 0) {
        dispatch(
          setData({
            openSnack: true,
            message: "Fields Should not be empty",
          })
        );

        return;
      }
      setIsSubmitting(true);
      await editTerm(termId, dataToSend);
      onClose();
      setSuccessDialogOpen(true);
      const response = await fetchData();
      const data = response?.data?.data;
      setTerms(data);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error?.data?.data?.message,
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    if (termToEdit) {
      setProductsPayload({
        local: "en",
        title_en: termToEdit?.title_en,
        description_en: termToEdit?.description_en,
        title_ar: termToEdit?.title_ar,
        description_ar: termToEdit?.description_ar,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termId, terms, open]);
  return (
    <>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="Term has been updated Successfully."
        message=""
        buttonText="Go to Terms and Conditions"
      />
      <ReusableSnackbar handleClose={handleCloseSnack} />
      <Dialog
        maxWidth="850px"
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "850px",
            height: "auto",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            paddingTop: "5%",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              width: "80%",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={onClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  color: "#000000",
                  fontWeight: "400",
                  fontSize: "24px",
                  display: "flex",
                  alignSelf: "flex-start",
                }}
              >
                Edit Term and Condition
              </Box>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                Select Language
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  displayEmpty
                  onChange={handleLangChange}
                  value={productsPayload?.local || ""}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>{productsPayload.name}</em>;
                    }
                    return (
                      lang.find((item) => item.local === selected)?.name || ""
                    );
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {lang.map((item) => (
                    <MenuItem key={item.local} value={item.local}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <>
              <TextFieldComponent
                sx={{ "& fieldset": { border: "none" } }}
                style={{
                  height: "48px",
                  width: "608px",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                }}
                name="Title"
                placeholder="Enter Title"
                value={
                  productsPayload?.local === "en"
                    ? productsPayload?.title_en
                    : productsPayload?.title_ar
                }
                onChange={(event) => {
                  const disallowEnglishRegex = /^[^a-zA-Z]*$/;
                  if (
                    event.target.value === "" ||
                    disallowEnglishRegex.test(event.target.value)
                  ) {
                    var temppayload = { ...productsPayload };
                    temppayload.title_en =
                      productsPayload?.local === "en"
                        ? event.target.value
                        : productsPayload?.title_en;
                    temppayload.title_ar =
                      productsPayload?.local === "ar"
                        ? event.target.value
                        : productsPayload?.title_ar;
                    setProductsPayload({ ...temppayload });
                  } else {
                    if (productsPayload?.local === "en") {
                      var temppayloadEn = { ...productsPayload };
                      temppayloadEn.title_en =
                        productsPayload?.local === "en"
                          ? event.target.value
                          : productsPayload?.title_en;
                      temppayloadEn.title_ar =
                        productsPayload?.local === "ar"
                          ? event.target.value
                          : productsPayload?.title_ar;
                      setProductsPayload({ ...temppayloadEn });
                    }
                  }
                }}
              />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#4C4C4C",
                    marginBottom: "5px",
                    marginTop: "15px",
                  }}
                >
                  Term and Condition
                </Typography>
                <CustomTextArea
                  style={{
                    minHeight: "54px",
                    maxHeight: "200px",
                    maxWidth: "608px",
                    minWidth: "608px",
                    backgroundColor: "#F7F7F7",
                    borderRadius: "9px",
                    boxShadow: "none",
                    border: "none",
                  }}
                  name="Term and Condition"
                  placeholder="Enter Term and Condition"
                  value={
                    productsPayload?.local === "en"
                      ? productsPayload?.description_en
                      : productsPayload?.description_ar
                  }
                  onChange={(event) => {
                    const disallowEnglishRegex = /^[^a-zA-Z]*$/;
                    if (
                      event.target.value === "" ||
                      disallowEnglishRegex.test(event.target.value)
                    ) {
                      var temppayload = { ...productsPayload };
                      temppayload.description_en =
                        productsPayload?.local === "en"
                          ? event.target.value
                          : productsPayload?.description_en;
                      temppayload.description_ar =
                        productsPayload?.local === "ar"
                          ? event.target.value
                          : productsPayload?.description_ar;
                      setProductsPayload({ ...temppayload });
                    } else {
                      if (productsPayload?.local === "en") {
                        var temppayloadEn = { ...productsPayload };
                        temppayloadEn.description_en =
                          productsPayload?.local === "en"
                            ? event.target.value
                            : productsPayload?.description_en;
                        temppayloadEn.description_ar =
                          productsPayload?.local === "ar"
                            ? event.target.value
                            : productsPayload?.description_ar;
                        setProductsPayload({ ...temppayloadEn });
                      }
                    }
                  }}
                />
              </Box>
            </>

            <Box sx={{ display: "flex", gap: 2, marginTop: "10px" }}>
              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  background: "#E9C237",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#E9C237",
                  },
                }}
                onClick={handleSubmit}
              >
                {label}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#E9C237",
                  fontWeight: 600,
                  borderColor: "#E9C237",
                  "&:hover": {
                    borderColor: "#E9C237",
                  },
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditTerm;
