import { useState } from "react";
import TextFieldComponent from "../../components/TextFieldComponent";
import {
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { MenuProps } from "../../components/common/MenuProps";

const StepOneFaqComponent = ({ setFaqData, faqData }) => {
  const [selectedTopic, setSelectedTopic] = useState("");
  const topic = [
    { id: 1, name: "Is Popular", is_popular: 1 },
    { id: 2, name: "Other", is_popular: 0 },
  ];
  const handleTopicChange = (event) => {
    console.log(event.target.value.id);
    setSelectedTopic(event.target.value);
    setFaqData((prevFaqData) => ({
      ...prevFaqData,
      is_popular: event.target.value.id,
    }));
  };

  return (
    <Box sx={{ marginTop: "3%" }}>
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#4C4C4C",
            marginBottom: "5px",
            marginTop: "15px",
          }}
        >
          Select Topic
        </Typography>

        <FormControl sx={{ width: "100%" }}>
          <Select
            displayEmpty
            onChange={handleTopicChange}
            value={selectedTopic || ""}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (selected?.length === 0) {
                return <em>Select Topic</em>;
              }
              return selected?.name;
            }}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            {topic.map((item) => (
              <MenuItem key={item.id} value={item}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Question"
        placeholder="Enter Question"
        value={faqData.trans.find((t) => t.local === "en").title}
        onChange={(event) => {
          const updatedPayload = { ...faqData };
          const enTranslation = updatedPayload.trans.find(
            (t) => t.local === "en"
          );
          if (enTranslation) {
            enTranslation.title = event.target.value;
            setFaqData(updatedPayload);
          }
        }}
      />
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Answer"
        placeholder="Enter Answer"
        value={faqData.trans.find((t) => t.local === "en").description}
        onChange={(event) => {
          const updatedPayload = { ...faqData };
          const enTranslation = updatedPayload.trans.find(
            (t) => t.local === "en"
          );
          if (enTranslation) {
            enTranslation.description = event.target.value;
            setFaqData(updatedPayload);
          }
        }}
      />
    </Box>
  );
};
export default StepOneFaqComponent;
